import MessagesContextProvider from 'modules/dashboard/sub-modules/messages/context/messages';

import MessagesInfoWrapper from 'modules/dashboard/sub-modules/messages/ui/wrappers/MessagesInfo';

export default function Messages() {
  return (
    <MessagesContextProvider>
      <MessagesInfoWrapper />
    </MessagesContextProvider>
  );
}
