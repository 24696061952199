"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LARGE_OTHER_FILE_MESSAGE = exports.isOtherFileTooBig = exports.LARGE_VIDEO_FILE_MESSAGE = exports.isVideoFileTooBig = exports.LARGE_IMAGE_FILE_MESSAGE = exports.ID_FILE_ERROR_MESSAGE = exports.isImageFileTooBig = void 0;
const oneMb = 1024 * 1024;
const thirtyMb = oneMb * 30;
const oneHundredFourtyMb = oneMb * 140;
const sixteenMb = oneMb * 16;
const isImageFileTooBig = (size) => size > thirtyMb;
exports.isImageFileTooBig = isImageFileTooBig;
exports.ID_FILE_ERROR_MESSAGE = "Sorry, an error occurred. Please check your file and try again. Make sure you're using a valid image file and that the image is no larger than 20mb. If you continue to have trouble, try taking a photo of your ID, or you can just take a selfie for now.";
exports.LARGE_IMAGE_FILE_MESSAGE = "Sorry, an error occurred. Please check your file and try again. Make sure you're using a valid file and that the file is no larger than 25mb.";
const isVideoFileTooBig = (size) => size > oneHundredFourtyMb;
exports.isVideoFileTooBig = isVideoFileTooBig;
exports.LARGE_VIDEO_FILE_MESSAGE = "Sorry, an error occurred. Please check your file and try again. Make sure you're using a valid file and that the file is no larger than 140mb.";
const isOtherFileTooBig = (size) => size > sixteenMb;
exports.isOtherFileTooBig = isOtherFileTooBig;
exports.LARGE_OTHER_FILE_MESSAGE = "Sorry, an error occurred. Please check your file and try again. Make sure you're using a valid file and that the file is no larger than 16mb.";
