import { first, isEmpty, uniqueId } from 'lodash';
import { useState } from 'react';

import { uploadMediaFile } from 'client/dist/generated/alloy';

import { showErrorNotification } from 'shared/components/core/Notification';

import { getLargeFileMessage, isFileTooBig } from 'shared/lib/file';

import { useMessagesContext } from '../context/messages';
import { getFileNameFromBlob } from '../lib/file-name';

type CreateMessage = {
  text: string;
  files: {
    id: string;
    blob: Blob;
    name: string;
    type: string;
  }[];
};

const INIT_NEW_MESSAGE = {
  text: '',
  files: [],
};

export default function useMessagingFooterState() {
  const { messagingStatus, sendMessage } = useMessagesContext();

  const [newMessage, setNewMessage] = useState<CreateMessage>(INIT_NEW_MESSAGE);

  const [isVideoOpen, setIsVideoOpen] = useState(false);
  const [isAudioOpen, setIsAudioOpen] = useState(false);

  const onSendMessage = async () => {
    const newMessageTemp = newMessage;

    try {
      if (!newMessage.files.length && isEmpty(newMessage.text.trim())) {
        return;
      }

      setNewMessage(INIT_NEW_MESSAGE);

      const uploadedFiles = !!newMessage.files.length
        ? await Promise.all(newMessage.files.map(({ blob }) => uploadMediaFile({ file: blob })))
        : await Promise.resolve([]);

      await sendMessage({
        channel: 'patient',
        text: newMessageTemp.text,
        files: uploadedFiles.map(({ fileId }) => ({ id: fileId })),
      });
    } catch (error) {
      setNewMessage(newMessageTemp);

      showErrorNotification(
        'An unknown error occurred, please try again. If the error continues to persist, please reach out to Alloy',
      );
    }
  };

  /**
   * you can technically only add one file at a time
   */
  const onAttachFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files || [];
    const file = first(files);

    if (!file) return;

    if (isFileTooBig(file)) {
      showErrorNotification(getLargeFileMessage(file), true);

      return;
    }

    const newFiles = Array.from(files).map((file) => ({
      id: uniqueId(),
      blob: file,
      name: file.name,
      type: file.type,
    }));

    setNewMessage((prev) => ({ ...prev, files: [...prev.files, ...newFiles] }));
  };

  const onRemoveFile = (id: string) => {
    const updatedFiles = newMessage.files.filter((file) => file.id !== id);

    setNewMessage((prev) => ({ ...prev, files: updatedFiles }));
  };

  const onAddFile = (blob: Blob, baseName: string) => {
    const name = getFileNameFromBlob(blob, baseName);
    const type = blob.type || 'application/octet-stream';

    setNewMessage((prev) => ({
      ...prev,
      files: [...prev.files, { id: uniqueId(), blob, name, type }],
    }));
  };

  return {
    messagingStatus,

    newMessage,
    setNewMessage,

    isVideoOpen,
    setIsVideoOpen,

    isAudioOpen,
    setIsAudioOpen,

    onSendMessage,
    onAttachFile,
    onRemoveFile,
    onAddFile,
  };
}
