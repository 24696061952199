import './RecordAudioModal.scss';

import { faMicrophone, faStop, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AlloyModal from 'shared/components/core/AlloyModal';

import useRecordAudioModalState from '../../hooks/useRecordAudioModalState';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSave: (blob: Blob, baseName: string) => void;
}

export default function RecordAudioModal({ isOpen, onClose, onSave }: Props) {
  const {
    isRecording,

    audioBlob,

    hasAudioAccess,

    onStartRecording,
    onStopRecording,

    onSubmit,
    onRemove,
    onCloseModal,
  } = useRecordAudioModalState({ isOpen, onClose, onSave });

  return (
    <AlloyModal isOpen={isOpen} onClose={onCloseModal} drawerClass='record-audio-modal' size='sm'>
      <div className='modal-body'>
        {hasAudioAccess ? (
          <div className='audio-modal-block'>
            {!!audioBlob ? (
              <>
                <h4 className='modal-title'>Audio Recorded</h4>

                <div className='modal-player-wrapper'>
                  <audio controls data-sentry-mask>
                    <source src={URL.createObjectURL(audioBlob)} type={audioBlob.type} />
                    Your browser does not support audio playback.
                  </audio>
                </div>

                <div className='modal-btns-block'>
                  <button type='button' className='primary-button' onClick={onSubmit}>
                    Save
                  </button>

                  <button type='button' className='secondary-border-button' onClick={onRemove}>
                    Record a new audio
                  </button>
                </div>
              </>
            ) : isRecording ? (
              <>
                <h4 className='modal-title'>Recording...</h4>

                <div className='modal-btns-block'>
                  <button type='button' className='primary-button' onClick={onStopRecording}>
                    <FontAwesomeIcon icon={faStop} /> Stop Recording
                  </button>
                  <button type='button' className='secondary-border-button' onClick={onRemove}>
                    <FontAwesomeIcon icon={faTrash} /> Discard
                  </button>
                </div>
              </>
            ) : (
              <>
                <h4 className='modal-title'>Record Audio</h4>

                <div className='modal-btns-block'>
                  <button type='button' className='primary-button' onClick={onStartRecording}>
                    <FontAwesomeIcon icon={faMicrophone} /> Start Recording
                  </button>
                </div>
              </>
            )}
          </div>
        ) : (
          <div className='audio-modal-block'>
            <h4 className='audio-title'>No Microphone Access</h4>

            <p className='audio-text'>
              To record audio, please change your browser settings to allow microphone access
            </p>
          </div>
        )}
      </div>
    </AlloyModal>
  );
}
