"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RENEWAL_QUESTIONNAIRE = void 0;
exports.RENEWAL_QUESTIONNAIRE = {
    title: 'Renewal',
    questions: [
        {
            title: 'Products Requesting Renewal',
            slug: 'products-requesting-renewal',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'Hidden',
            options: [],
            mdiCaseQuestion: true,
        },
        {
            title: 'Products Disqualified',
            slug: 'products-disqualified',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'Hidden',
            options: [],
            mdiCaseQuestion: true,
        },
        {
            title: 'Have {relevantProducts} been helpful in relieving your symptoms?',
            slug: 'have-medications-been-helpful',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'SingleChoice',
            headerTitle: '',
            options: [
                {
                    answer: 'Yes',
                    slug: 'have-medications-been-helpful-yes',
                },
                {
                    answer: 'No',
                    slug: 'have-medications-been-helpful-no',
                },
            ],
            mdiCaseQuestion: true,
            relevantProductCategories: [
                'mht',
                'birth-control',
                'vaginal-health',
                'non-hormonal-treatment',
                'sexual-health',
                'skin-health',
            ],
        },
        {
            title: 'Please tell us about the symptoms you are still experiencing.',
            slug: 'symptoms-still-experiencing',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'MultipleLine',
            headerTitle: 'Your medical history:',
            placeholder: 'Type here',
            options: [],
            orLogic: {
                'have-medications-been-helpful': ['have-medications-been-helpful-no'],
            },
            mdiCaseQuestion: true,
            relevantProductCategories: [
                'mht',
                'non-hormonal-treatment',
                'birth-control',
                'vaginal-health',
                'skin-health',
                'sexual-health',
            ],
        },
        {
            title: 'Have you had any unscheduled or undiagnosed vaginal bleeding in the past year?',
            slug: 'unscheduled-undiagnosed-vaginal-bleeding-past-year',
            description: '',
            isRequired: true,
            isImportant: true,
            userKey: '',
            type: 'SingleChoice',
            headerTitle: 'Your medical history:',
            options: [
                {
                    answer: 'Yes',
                    slug: 'unscheduled-vaginal-bleeding-past-year-yes',
                },
                {
                    answer: 'No',
                    slug: 'unscheduled-vaginal-bleeding-past-year-no',
                },
            ],
            mdiCaseQuestion: true,
            relevantProductCategories: ['mht', 'birth-control', 'vaginal-health'],
        },
        {
            title: 'Did you skip or miss any doses of your hormones?',
            slug: 'skip-miss-doses-hormones',
            description: '',
            isRequired: true,
            isImportant: true,
            userKey: '',
            type: 'SingleChoice',
            headerTitle: 'Your medical history:',
            options: [
                {
                    answer: 'Yes',
                    slug: 'skip-miss-doses-hormones-yes',
                },
                {
                    answer: 'No',
                    slug: 'skip-miss-doses-hormones-no',
                },
            ],
            orLogic: {
                'unscheduled-undiagnosed-vaginal-bleeding-past-year': [
                    'unscheduled-vaginal-bleeding-past-year-yes',
                ],
            },
            mdiCaseQuestion: true,
            relevantProductCategories: ['mht', 'birth-control', 'vaginal-health'],
        },
        {
            title: 'Has this been resolved?',
            slug: 'resolved',
            description: '',
            isRequired: true,
            isImportant: true,
            userKey: '',
            type: 'SingleChoice',
            headerTitle: 'Your medical history:',
            options: [
                {
                    answer: 'Yes',
                    slug: 'resolved-yes',
                },
                {
                    answer: 'No',
                    slug: 'resolved-no',
                },
            ],
            orLogic: {
                'unscheduled-undiagnosed-vaginal-bleeding-past-year': [
                    'unscheduled-undiagnosed-vaginal-bleeding-past-year-yes',
                ],
            },
            mdiCaseQuestion: true,
            relevantProductCategories: ['mht', 'birth-control', 'vaginal-health'],
        },
        {
            title: 'Has it been evaluated by your doctor?',
            slug: 'evaluated-by-doctor',
            description: '',
            isRequired: true,
            isImportant: true,
            userKey: '',
            type: 'SingleChoice',
            headerTitle: 'Your medical history:',
            options: [
                {
                    answer: 'Yes',
                    slug: 'evaluated-by-doctor-yes',
                },
                {
                    answer: 'No',
                    slug: 'evaluated-by-doctor-no',
                },
            ],
            orLogic: {
                'unscheduled-undiagnosed-vaginal-bleeding-past-year': [
                    'unscheduled-undiagnosed-vaginal-bleeding-past-year-yes',
                ],
            },
            mdiCaseQuestion: true,
            relevantProductCategories: ['mht', 'birth-control', 'vaginal-health'],
        },
        {
            title: 'Please provide any additional details for your doctor to review, including the last time this happened and any evaluation you have had done:',
            slug: 'provide-additional-details-doctor-review',
            description: '',
            isRequired: true,
            isImportant: true,
            userKey: '',
            type: 'MultipleLine',
            headerTitle: 'Your medical history:',
            placeholder: 'Type here',
            options: [],
            orLogic: {
                'unscheduled-undiagnosed-vaginal-bleeding-past-year': [
                    'unscheduled-undiagnosed-vaginal-bleeding-past-year-yes',
                ],
            },
            mdiCaseQuestion: true,
            relevantProductCategories: ['mht', 'birth-control', 'vaginal-health'],
        },
        {
            title: 'Please indicate if you have or have had any of the following conditions:',
            slug: 'indicate-if-had-conditions',
            description: '',
            isRequired: true,
            isImportant: true,
            userKey: '',
            type: 'MultipleChoice',
            headerTitle: 'Your medical history:',
            options: [
                {
                    slug: 'indicate-if-had-conditions-none',
                    answer: 'None',
                },
                {
                    slug: 'indicate-if-had-conditions-heart-attacks',
                    answer: 'Heart attacks',
                },
                {
                    slug: 'indicate-if-had-conditions-breast-cancer',
                    answer: 'Personal history of breast cancer',
                },
                {
                    slug: 'indicate-if-had-conditions-strokes',
                    answer: 'Strokes',
                },
                {
                    slug: 'indicate-if-had-conditions-blood-clots-legs-lungs',
                    answer: 'Blood clots in legs or lungs',
                },
                {
                    slug: 'indicate-if-had-conditions-positive-brca',
                    answer: 'Positive BRCA 1 / 2 test',
                },
                {
                    slug: 'indicate-if-had-conditions-post-meno-bleeding',
                    answer: "Unexplained episodes of post-menopausal bleeding (if you've had no period in over a year)",
                },
                {
                    slug: 'indicate-if-had-conditions-migraines',
                    answer: 'Migraines',
                },
                {
                    slug: 'indicate-if-had-conditions-liver-disease',
                    answer: 'Liver disease',
                },
                {
                    slug: 'indicate-if-had-conditions-lupus',
                    answer: 'Lupus',
                },
                {
                    slug: 'indicate-if-had-conditions-endometrial-cancer',
                    answer: 'Endometrial cancer',
                },
                {
                    slug: 'indicate-if-had-conditions-diabetes-injectable-insulin',
                    answer: 'Diabetes and taking injectable insulin (not oral)',
                },
                {
                    slug: 'indicate-if-had-conditions-diabetes-not-injectable-insulin',
                    answer: 'Diabetes and NOT taking injectable insulin',
                },
                {
                    slug: 'indicate-if-had-conditions-elevated-cholesterol',
                    answer: 'Elevated cholesterol',
                },
                {
                    slug: 'indicate-if-had-conditions-high-blood-pressure',
                    answer: 'High blood pressure',
                },
                {
                    slug: 'indicate-if-had-conditions-clotting-risk',
                    answer: 'Genetic condition that increases your risk for clotting',
                },
                {
                    slug: 'indicate-if-had-conditions-lichen-sclerosis',
                    answer: 'Lichen Sclerosis or other vulvar skin condition',
                },
                {
                    slug: 'indicate-if-had-conditions-other',
                    answer: 'Other',
                },
            ],
            mdiCaseQuestion: true,
            relevantProductCategories: ['mht', 'birth-control', 'vaginal-health'],
        },
        {
            title: 'What stage endometrial cancer?',
            slug: 'stage-endometrial-cancer',
            description: '',
            isRequired: true,
            isImportant: true,
            userKey: '',
            type: 'SingleChoice',
            headerTitle: 'Your medical history:',
            options: [
                {
                    answer: 'Stage 1 or 2',
                    slug: 'stage-endometrial-cancer-1-or-2',
                },
                {
                    answer: 'Stage 3 or 4',
                    slug: 'stage-endometrial-cancer-3-or-4',
                },
            ],
            orLogic: {
                'indicate-if-had-conditions': ['indicate-if-had-conditions-endometrial-cancer'],
            },
            mdiCaseQuestion: true,
            relevantProductCategories: ['mht', 'birth-control', 'vaginal-health'],
        },
        {
            title: 'Have you had an oophorectomy and/or bilateral mastectomy?',
            slug: 'oophorectomy-andor-bilateral-mastectomy',
            description: '',
            isRequired: true,
            isImportant: true,
            userKey: '',
            type: 'SingleChoice',
            headerTitle: 'Your medical history:',
            options: [
                {
                    answer: 'I have had an oophorectomy',
                    slug: 'oophorectomy-andor-bilateral-mastectomy-have-had-oophorectomy',
                },
                {
                    answer: 'I have had a bilateral mastectomy',
                    slug: 'oophorectomy-andor-bilateral-mastectomy-have-had-mastectomy',
                },
                {
                    answer: 'I have had both an oophorectomy and a bilateral mastectomy',
                    slug: 'oophorectomy-andor-bilateral-mastectomy-have-had-both',
                },
                {
                    answer: 'I have had neither an oophorectomy or a bilateral mastectomy',
                    slug: 'oophorectomy-andor-bilateral-mastectomy-have-had-neither',
                },
            ],
            orLogic: {
                'indicate-if-had-conditions': ['indicate-if-had-conditions-positive-brca'],
            },
            mdiCaseQuestion: true,
            relevantProductCategories: ['mht', 'birth-control', 'vaginal-health'],
        },
        {
            title: 'You have options!',
            slug: 'breast-cancer-treatment-awareness',
            description: '**While systemic hormone therapy is generally not prescribed to patients with a history of invasive breast cancer, there are other treatment options available to manage your menopause symptoms.\\***  \n\n**All doctors who prescribe for Alloy are menopause-trained and follow the guidelines provided by ACOG and The Menopause Society.**  \n\nYour consultation will provide:  \n * Access to a specialist in menopause care  \n * Information on suitable treatment options  \n * Resources to help manage your symptoms  \n\nMany breast cancer patients are candidates for vaginal estrogen and non-hormonal treatments. A consultation can help you explore these options and help you find an approach for your individual needs.  \n\n ###### *Please note that if you have a BRCA gene mutation but have not had breast cancer, you are still a candidate for hormone therapy! For additional information, check out our [Breast Cancer FAQ](https://www.myalloy.com/breast-cancer-faqs).',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'MarkdownCheckbox',
            options: [
                {
                    slug: 'breast-cancer-treatment-awareness-confirm',
                    answer: 'I have read and understand',
                },
            ],
            orLogic: {
                'indicate-if-had-conditions': ['indicate-if-had-conditions-breast-cancer'],
            },
            mdiCaseQuestion: true,
            relevantProductCategories: ['mht', 'birth-control', 'vaginal-health'],
        },
        {
            title: 'Please provide the year of your breast cancer diagnosis, stage of breast cancer, and estrogen receptor status.',
            slug: 'provide-year-breast-cancer-diagnosis',
            description: '',
            isRequired: true,
            isImportant: true,
            userKey: '',
            type: 'MultipleLine',
            headerTitle: 'Your medical history:',
            placeholder: 'Type here',
            options: [],
            orLogic: {
                'indicate-if-had-conditions': ['indicate-if-had-conditions-breast-cancer'],
            },
            mdiCaseQuestion: true,
            relevantProductCategories: ['mht', 'birth-control', 'vaginal-health'],
        },
        {
            title: 'What year did the blood clot occur? What happened? Provide as much detail as possible.',
            slug: 'year-blood-clot-occur-what-happened',
            description: '',
            isRequired: true,
            isImportant: true,
            userKey: '',
            type: 'MultipleLine',
            headerTitle: 'Your medical history:',
            placeholder: 'Type here',
            options: [],
            orLogic: {
                'indicate-if-had-conditions': ['indicate-if-had-conditions-blood-clots-legs-lungs'],
            },
            mdiCaseQuestion: true,
            relevantProductCategories: ['mht', 'birth-control', 'vaginal-health'],
        },
        {
            title: 'Have you seen your gynecologist and/or had an endometrial biopsy to address the unexplained bleeding?',
            slug: 'seen-gynecologist-endometrial-biopsy-unexplained-bleeding',
            description: '',
            isRequired: true,
            isImportant: true,
            userKey: '',
            type: 'SingleChoice',
            headerTitle: 'Your medical history:',
            options: [
                {
                    slug: 'seen-gynecologist-endometrial-biopsy-unexplained-bleeding-yes',
                    answer: 'Yes',
                },
                {
                    slug: 'seen-gynecologist-endometrial-biopsy-unexplained-bleeding-no',
                    answer: 'No',
                },
            ],
            orLogic: {
                'indicate-if-had-conditions': ['indicate-if-had-conditions-post-meno-bleeding'],
            },
            mdiCaseQuestion: true,
            relevantProductCategories: ['mht', 'birth-control', 'vaginal-health'],
        },
        {
            title: 'Please provide more details on your liver disease, including when you were diagnosed.',
            slug: 'provide-details-liver-disease',
            description: '',
            isRequired: true,
            isImportant: true,
            userKey: '',
            type: 'MultipleLine',
            headerTitle: 'Your medical history:',
            placeholder: 'Type here',
            options: [],
            orLogic: {
                'indicate-if-had-conditions': ['indicate-if-had-conditions-liver-disease'],
            },
            mdiCaseQuestion: true,
            relevantProductCategories: ['mht', 'birth-control', 'vaginal-health'],
        },
        {
            title: 'Is your blood pressure well-controlled? Well-controlled is defined as 130/80 or less.',
            slug: 'blood-pressure-well-controlled',
            description: '',
            isRequired: true,
            isImportant: true,
            userKey: '',
            type: 'MultipleLine',
            headerTitle: 'Your medical history:',
            placeholder: 'Type here',
            options: [],
            orLogic: {
                'indicate-if-had-conditions': ['indicate-if-had-conditions-high-blood-pressure'],
            },
            mdiCaseQuestion: true,
            relevantProductCategories: ['mht', 'birth-control', 'vaginal-health'],
        },
        {
            title: 'What is your genetic condition that increases your risk for clotting?',
            slug: 'genetic-condition-increases-risk-clotting',
            description: '',
            isRequired: true,
            isImportant: true,
            userKey: '',
            type: 'SingleChoice',
            headerTitle: 'Your medical history:',
            options: [
                {
                    answer: 'Factor V Leiden',
                    slug: 'clotting-risk-factor-v-leiden',
                },
                {
                    answer: 'Prothrombin',
                    slug: 'clotting-risk-prothrombin',
                },
                {
                    answer: 'Protein C or S deficiency',
                    slug: 'clotting-risk-c-s-deficiency',
                },
                {
                    answer: 'Other',
                    slug: 'clotting-risk-other',
                },
            ],
            orLogic: {
                'indicate-if-had-conditions': ['Genetic condition that increases your risk for clotting'],
            },
            mdiCaseQuestion: true,
            relevantProductCategories: ['mht', 'birth-control', 'vaginal-health'],
        },
        {
            title: 'Have you had a hysterectomy (surgical removal of the uterus)?',
            slug: 'hysterectomy',
            description: '',
            isRequired: true,
            isImportant: true,
            userKey: '',
            type: 'SingleChoice',
            headerTitle: 'Your medical history:',
            options: [
                {
                    answer: 'Yes',
                    slug: 'hysterectomy-yes',
                },
                {
                    answer: 'No',
                    slug: 'hysterectomy-no',
                },
            ],
            mdiCaseQuestion: true,
            relevantProductCategories: ['mht'],
        },
        {
            title: 'When was your last mammogram?',
            slug: 'last-mammogram',
            description: '',
            isRequired: true,
            isImportant: true,
            userKey: '',
            type: 'SingleChoice',
            headerTitle: 'Your medical history:',
            options: [
                {
                    answer: 'Within two years',
                    slug: 'last-mammogram-within-two-years',
                },
                {
                    answer: "It's been more than two years (or never had one)",
                    slug: 'last-mammogram-more-than-two-years',
                },
                {
                    answer: 'I have had a double mastectomy',
                    slug: 'last-mammogram-double-mastectomy',
                },
            ],
            mdiCaseQuestion: true,
            relevantProductCategories: ['mht', 'birth-control'],
        },
        {
            title: 'What were the results of your last mammogram?',
            slug: 'results-last-mammogram',
            description: '',
            isRequired: true,
            isImportant: true,
            userKey: '',
            type: 'SingleChoice',
            headerTitle: 'Your medical history:',
            options: [
                {
                    answer: 'Normal',
                    slug: 'results-last-mammogram-normal',
                },
                {
                    answer: 'I had another visit for additional views, but was told everything was normal',
                    slug: 'results-last-mammogram-additional-then-normal',
                },
                {
                    answer: 'Abnormal',
                    slug: 'results-last-mammogram-abnormal',
                },
            ],
            orLogic: {
                'last-mammogram': ['last-mammogram-within-two-years'],
            },
            mdiCaseQuestion: true,
            relevantProductCategories: ['mht', 'birth-control'],
        },
        {
            title: 'Do you currently smoke or vape products with nicotine?',
            slug: 'smoke-cigarettes',
            description: '',
            isRequired: true,
            isImportant: true,
            userKey: '',
            type: 'SingleChoice',
            headerTitle: 'Your medical history:',
            options: [
                {
                    answer: 'Yes',
                    slug: 'smoke-cigarettes-yes',
                },
                {
                    answer: 'No',
                    slug: 'smoke-cigarettes-no',
                },
            ],
            mdiCaseQuestion: true,
            relevantProductCategories: ['mht', 'birth-control'],
        },
        {
            title: 'Are you currently using any birth control products?',
            slug: 'using-birth-control-products',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'SingleChoice',
            headerTitle: 'Medications and allergies:',
            options: [
                {
                    answer: 'Yes',
                    slug: 'using-birth-control-products-yes',
                },
                {
                    answer: 'No',
                    slug: 'using-birth-control-products-no',
                },
            ],
            whyAskingDescription: 'It is important for the doctor to understand other medications you are currently using to provide overall care, but you can take vaginal estradiol cream with birth control!',
            mdiCaseQuestion: true,
            relevantProductCategories: ['mht', 'birth-control'],
        },
        {
            title: 'Which birth control product are you currently using?',
            slug: 'which-birth-control-products',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'MultipleChoice',
            headerTitle: 'Medications and allergies:',
            options: [
                {
                    answer: 'Birth control pill',
                    slug: 'which-birth-control-products-pill',
                },
                {
                    answer: 'Birth control patch',
                    slug: 'which-birth-control-products-patch',
                },
                {
                    answer: 'Birth control vaginal ring (Nuvaring or Annovera)',
                    slug: 'which-birth-control-products-ring',
                },
                {
                    answer: 'Nexplanon contraceptive arm implant',
                    slug: 'which-birth-control-products-arm-impant',
                },
                {
                    answer: 'Hormonal IUD',
                    slug: 'which-birth-control-products-hormonal-iud',
                },
                {
                    answer: 'Non-hormonal IUD (Copper T or Paragard)',
                    slug: 'which-birth-control-products-non-hormonal-iud',
                },
                {
                    answer: 'I have had sterilization (tubal ligation)',
                    slug: 'which-birth-control-products-tubal-ligation',
                },
                {
                    answer: 'My partner has a vasectomy',
                    slug: 'which-birth-control-products-vasectomy',
                },
                {
                    answer: 'Condoms',
                    slug: 'which-birth-control-products-condoms',
                },
                {
                    answer: 'Phexxi',
                    slug: 'which-birth-control-products-phexxi',
                },
                {
                    answer: 'Other',
                    slug: 'which-birth-control-products-other',
                },
            ],
            orLogic: {
                'using-birth-control-products': ['using-birth-control-products-yes'],
            },
            mdiCaseQuestion: true,
            relevantProductCategories: ['mht', 'birth-control'],
        },
        {
            title: 'Which hormonal IUD are you currently using?',
            slug: 'which-hormonal-iud-using',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'SingleChoice',
            headerTitle: 'Medications and allergies:',
            options: [
                {
                    answer: 'Skyla',
                    slug: 'which-hormonal-iud-syla',
                },
                {
                    answer: 'Mirena',
                    slug: 'which-hormonal-iud-mirena',
                },
                {
                    answer: 'Kyleena',
                    slug: 'which-hormonal-iud-kyleena',
                },
                {
                    answer: 'Lilletta',
                    slug: 'which-hormonal-iud-lilletta',
                },
                {
                    answer: 'Other',
                    slug: 'which-hormonal-iud-other',
                },
            ],
            orLogic: {
                'which-birth-control-products': ['which-birth-control-products-hormonal-iud'],
            },
            mdiCaseQuestion: true,
            relevantProductCategories: ['mht', 'birth-control'],
        },
        {
            title: 'Which other hormonal IUD are you currently using?',
            slug: 'other-hormonal-iud-using',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'MultipleLine',
            headerTitle: 'Medications and allergies:',
            placeholder: 'Type here',
            options: [],
            orLogic: {
                'which-hormonal-iud-using': ['which-hormonal-iud-other'],
            },
            mdiCaseQuestion: true,
            relevantProductCategories: ['mht', 'birth-control'],
        },
        {
            title: 'When was your last hormonal IUD inserted?',
            slug: 'hormonal-iud-inserted',
            description: 'Please provide the month and year.',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'SingleLine',
            headerTitle: 'Medications and allergies:',
            placeholder: 'Type here',
            options: [],
            orLogic: {
                'which-birth-control-products': ['which-birth-control-products-hormonal-iud'],
            },
            mdiCaseQuestion: true,
            relevantProductCategories: ['mht', 'birth-control'],
        },
        {
            title: 'Are you currently taking any Selective Serotonin Reuptake Inhibitors, or SSRIs?',
            slug: 'taking-selective-serotonin-reuptake-inhibitors-or-ssris',
            description: '',
            isRequired: true,
            isImportant: true,
            userKey: '',
            type: 'SingleChoice',
            headerTitle: 'Your medical history:',
            options: [
                {
                    answer: 'Yes',
                    slug: 'taking-ssris-yes',
                },
                {
                    answer: 'No',
                    slug: 'taking-ssris-no',
                },
            ],
            mdiCaseQuestion: true,
            relevantProductCategories: ['non-hormonal-treatment'],
        },
        {
            title: 'Are you currently using blood thinners and/or nitrates?',
            slug: 'currently-taking-blood-thinners-nitrates',
            description: '',
            isRequired: true,
            isImportant: true,
            userKey: '',
            type: 'SingleChoice',
            headerTitle: 'Your medical history:',
            options: [
                {
                    answer: 'Yes',
                    slug: 'currently-taking-blood-thinners-nitrates-yes',
                },
                {
                    answer: 'No',
                    slug: 'currently-taking-blood-thinners-nitrates-no',
                },
            ],
            mdiCaseQuestion: true,
            relevantProductCategories: ['sexual-health'],
        },
        {
            title: 'Please list all current medications.',
            slug: 'list-all-other-medications',
            description: 'If you have no medications, continue to the next question.',
            isRequired: false,
            isImportant: false,
            userKey: 'currentMedications',
            type: 'Medications',
            headerTitle: 'Your medical history:',
            options: [],
            mdiCaseQuestion: true,
            relevantProductCategories: [
                'mht',
                'non-hormonal-treatment',
                'birth-control',
                'vaginal-health',
                'skin-health',
                'sexual-health',
            ],
        },
        {
            title: 'Are you allergic to Sildenafil, Pentoxifylline, and/or Ergoloid Mesylate?',
            slug: 'allergic-to-sildenafil-pentoxifylline-ergoloid-mesylate',
            description: '',
            isRequired: true,
            isImportant: true,
            userKey: '',
            type: 'SingleChoice',
            headerTitle: 'Your medical history:',
            options: [
                {
                    answer: 'Yes',
                    slug: 'allergic-to-sildenafil-pentoxifylline-ergoloid-mesylate-yes',
                },
                {
                    answer: 'No',
                    slug: 'allergic-to-sildenafil-pentoxifylline-ergoloid-mesylate-no',
                },
            ],
            mdiCaseQuestion: true,
            relevantProductCategories: ['sexual-health'],
        },
        {
            title: 'Do you have any allergies?',
            slug: 'any-allergies',
            description: 'This includes all allergies, intolerances, and reactions to foods or previous medications (e.g. peanut allergies)',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'SingleChoice',
            headerTitle: 'Your medical history:',
            options: [
                {
                    answer: 'Yes',
                    slug: 'any-allergies-yes',
                },
                {
                    answer: 'No',
                    slug: 'any-allergies-no',
                },
            ],
            mdiCaseQuestion: true,
            relevantProductCategories: [
                'mht',
                'non-hormonal-treatment',
                'birth-control',
                'vaginal-health',
                'skin-health',
                'sexual-health',
            ],
        },
        {
            title: 'Please list all allergies or reactions to medications or adhesives.',
            slug: 'list-all-allergies-and-reactions',
            description: '',
            isRequired: false,
            isImportant: false,
            userKey: 'currentAllergies',
            type: 'Allergies',
            headerTitle: 'Your medical history:',
            options: [],
            orLogic: {
                'any-allergies': ['any-allergies-yes'],
            },
            mdiCaseQuestion: true,
            relevantProductCategories: [
                'mht',
                'non-hormonal-treatment',
                'birth-control',
                'vaginal-health',
                'skin-health',
                'sexual-health',
            ],
        },
        {
            title: 'Are you pregnant and/or breastfeeding?',
            slug: 'pregnant-andor-breastfeeding',
            description: '',
            isRequired: true,
            isImportant: true,
            userKey: '',
            type: 'SingleChoice',
            headerTitle: 'Your medical history:',
            options: [
                {
                    answer: 'Yes',
                    slug: 'pregnant-andor-breastfeeding-yes',
                },
                {
                    answer: 'No',
                    slug: 'pregnant-andor-breastfeeding-no',
                },
            ],
            mdiCaseQuestion: true,
            relevantProductCategories: ['sexual-health'],
        },
        {
            title: 'Please indicate if any close relatives (parent and/or sibling) have had a history of any of the following conditions:',
            slug: 'close-relatives-parent-sibling-history-conditions',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'MultipleChoice',
            headerTitle: 'Your medical history:',
            options: [
                {
                    answer: 'None',
                    slug: 'family-history-conditions-none',
                },
                {
                    answer: 'Heart attacks',
                    slug: 'family-history-conditions-heart-attacks',
                },
                {
                    answer: 'Blood clotting',
                    slug: 'family-history-conditions-blood-clotting',
                },
                {
                    answer: 'Strokes',
                    slug: 'family-history-conditions-strokes',
                },
                {
                    answer: 'BRCA 1 / 2 diagnosis',
                    slug: 'family-history-conditions-brca-1-2-diagnosis',
                },
                {
                    answer: 'Breast cancer',
                    slug: 'family-history-conditions-breast-cancer',
                },
                {
                    answer: 'Endometrial cancer',
                    slug: 'family-history-conditions-endometrial-cancer',
                },
                {
                    answer: 'Ovarian cancer',
                    slug: 'family-history-conditions-ovarian-cancer',
                },
                {
                    answer: 'Osteoporosis',
                    slug: 'family-history-conditions-osteoporosis',
                },
            ],
            mdiCaseQuestion: true,
            relevantProductCategories: ['mht', 'birth-control'],
        },
        {
            title: "Is there anything else you'd like to tell the doctor?",
            slug: 'anything-else-share-doctor',
            description: '',
            conclusion: true,
            isRequired: false,
            isImportant: false,
            userKey: '',
            type: 'MultipleLine',
            headerTitle: 'Your medical history:',
            placeholder: 'Type here',
            options: [],
            mdiCaseQuestion: true,
            relevantProductCategories: [
                'mht',
                'non-hormonal-treatment',
                'birth-control',
                'vaginal-health',
                'skin-health',
                'sexual-health',
            ],
        },
    ],
};
