import { sub } from 'date-fns';
import { isEqual } from 'lodash';

import { ExperienceCategory } from 'common/dist/models/experience';

import { LocalPreCustomer } from 'modules/shared/models/local-pre-customer';

/**
 * check whether the local submission exists based on specific criteria matching categories
 * and also being within the last 2 weeks
 *
 * @param categories ExperienceCategory[]
 * @param localPreCustomer LocalPreCustomer
 * @returns boolean
 */
export const localSubmissionExists = (
  categories: ExperienceCategory[],
  localPreCustomer: LocalPreCustomer,
): boolean => {
  const twoWeeksBeforeNow = sub(new Date(), { weeks: 2 });

  const sortedPreviousCategories = [
    ...(localPreCustomer.previousCategoriesForSubmission || []),
  ].sort();

  const submissionExists =
    isEqual(sortedPreviousCategories, categories.sort()) &&
    !!localPreCustomer.recentSubmissionDate &&
    twoWeeksBeforeNow <= new Date(localPreCustomer.recentSubmissionDate) &&
    !!localPreCustomer.alloySubmissionId;

  return submissionExists;
};
