import { Loader } from 'rsuite';

import { SYMPTOM_SURVEY_QUESTIONNAIRE } from 'common/dist/questionnaire/symptom-survey';

import QuestionnaireWrapper from 'modules/questionnaire/ui/wrappers/Questionnaire';

import useSymptomSurveyState from '../../hooks/useSymptomSurveyState';

export default function SymptomQuestionnaireWrapper() {
  const { isLoading, onBack, onSubmit } = useSymptomSurveyState();

  if (isLoading) return <Loader />;

  return (
    <QuestionnaireWrapper
      questions={SYMPTOM_SURVEY_QUESTIONNAIRE.questions}
      previousResults={{}}
      onSubmit={onSubmit}
      onBack={onBack}
      hideProgressBar
      basePath='/symptomsurvey'
      metaTitle='Symptom Survey | Alloy'
    />
  );
}
