import './Messaging.scss';

import MessagingContentBlock from '../blocks/MessagingContent';
import MessagingFooterBlock from '../blocks/MessagingFooter';
import MessagingHeaderBlock from '../blocks/MessagingHeader';

export default function MessagingWrapper() {
  return (
    <div className='messsaging-wrapper'>
      <MessagingHeaderBlock />
      <MessagingContentBlock />
      <MessagingFooterBlock />
    </div>
  );
}
