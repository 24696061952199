import classNames from 'classnames';

import { DeepProduct, useGetAllSubscriptionsForCustomer } from 'client/dist/generated/alloy';
import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';

import useProductPrice from 'modules/shared/hooks/useProductPrice';

import { getDeepProductIdsFrom } from 'modules/shared/lib/product';
import { getProductNameWithDosage } from 'modules/shared/lib/product/name';
import { extractCategories } from 'modules/shared/lib/subscriptions/product';

interface Props {
  product: GroupedContentfulProduct;
  parent?: GroupedContentfulProduct;
  showDosage?: boolean;
  onEdit?: (productIds: DeepProduct['id'][]) => void;
}

export default function ProductListCardBlock({
  product,
  parent,
  showDosage = false,
  onEdit,
}: Props) {
  const { alloyProduct, contentfulProduct } = product;

  const { data: subscriptions = [] } = useGetAllSubscriptionsForCustomer();

  const { unbundledPrice, bundledPrice } = useProductPrice({
    groupedProduct: product,
    parents: !!parent ? [parent] : [],
  });

  const recurrenceType = alloyProduct.parent[0].recurrenceType;

  const isRx = !contentfulProduct.fields.categories.every((category) => category === 'gut-health');

  const foundSubscription = subscriptions.find((sub) =>
    sub.products.find((p) => alloyProduct.parent.map((pf) => pf.id).includes(p.product.id)),
  );

  const prescription = !!foundSubscription
    ? foundSubscription.products.find((p) =>
        alloyProduct.parent.map((pf) => pf.id).includes(p.product.id),
      )?.prescription
    : undefined;

  const renewal = !!foundSubscription
    ? foundSubscription.products.find((p) =>
        alloyProduct.parent.map((pf) => pf.id).includes(p.product.id),
      )?.renewal
    : undefined;

  const namesAndDosage = showDosage
    ? getProductNameWithDosage(product)
    : [{ cleanName: contentfulProduct.fields.title }];

  const prescriptionActiveSub = !!prescription && foundSubscription?.status === 'ACTIVE';
  const prescriptionPausedSub = !!prescription && foundSubscription?.status === 'PAUSED';
  const prescriptionInactiveSub = !!prescription && foundSubscription?.status === 'INACTIVE';

  const isBundledProduct =
    unbundledPrice - bundledPrice !== 0 &&
    !extractCategories(product).includes('skin-health') &&
    !!parent;

  return (
    <div className={classNames('product-block', !!onEdit && 'add-border')}>
      <div className='product-img-content-wrapper'>
        <div className='product-img-wrapper'>
          {isRx && <span className='product-rx-tag'>rx</span>}

          <img
            src={contentfulProduct.fields.photo.fields.file.url}
            alt={contentfulProduct.fields.title}
            className='product-img'
          />
        </div>

        <div className='product-content-wrapper'>
          {namesAndDosage.map(({ cleanName, dosage }, pIndex) => (
            <p className='content-title' key={pIndex}>
              {cleanName}
              {!!dosage && <span className='title-dosage-tag'>{dosage}</span>}
            </p>
          ))}

          {isBundledProduct && !!unbundledPrice && !!bundledPrice && (
            <p className='content-tag'>
              ${(unbundledPrice - bundledPrice).toFixed(0)} off with purchase of{' '}
              {getProductNameWithDosage(parent)[0].cleanName}
            </p>
          )}

          <p className='content-text'>
            {isBundledProduct ? (
              <>
                <span className='text-orange'>${bundledPrice.toFixed(2)}</span>
                <span className='text-strike-through'>${unbundledPrice.toFixed(2)}</span>
              </>
            ) : (
              <>${unbundledPrice.toFixed(2)}</>
            )}{' '}
            ({recurrenceType === 'RECURRING' ? '3-month supply' : 'One-time fill'})
          </p>

          {prescriptionActiveSub && (
            <p className='content-text'>
              {prescription.fillsRemaining} refill
              {prescription.fillsRemaining !== 1 && 's'} left
            </p>
          )}

          {(prescriptionPausedSub || prescriptionInactiveSub) && (
            <p className='content-text'>
              Refills: {prescription.fillsRemaining}
              <span className='text-tag'>{prescriptionPausedSub ? 'Paused' : 'Cancelled'}</span>
            </p>
          )}
        </div>
      </div>

      {!!onEdit && prescription?.fillsRemaining !== 0 && !renewal?.hasExpired && (
        <div className='product-action-wrapper'>
          <button className='action-link' onClick={() => onEdit(getDeepProductIdsFrom(product))}>
            Edit
          </button>
        </div>
      )}
    </div>
  );
}
