import './Identity.scss';

import removeIcon from 'modules/shared/sub-modules/verify-identity/assets/images/remove-icon.png';

import { verifyIdentityItems } from 'modules/shared/sub-modules/verify-identity/data/verify-identity';

interface Props {
  file: Blob | null;
  onRemove: () => void;
}

export default function IdentityBlock({ file, onRemove }: Props) {
  if (file) {
    return (
      <div className='identity-img-block'>
        <img src={URL.createObjectURL(file)} alt='valid id' className='identity-img' />

        <button className='identity-img-remove-icon' onClick={onRemove}>
          <img src={removeIcon} alt='remove icon' />
        </button>
      </div>
    );
  } else {
    return (
      <div className='identity-block'>
        {verifyIdentityItems.map((item, index) => (
          <div className='identity-wrapper' key={index}>
            <p className='identity-title'>{item.title}</p>
            <p className='identity-text'>{item.text}</p>

            {item.bullets && (
              <ol className='identity-bullets'>
                {item.bullets.map((lineItem, index) => (
                  <li key={index} className='identity-bullet-item'>
                    {lineItem}
                  </li>
                ))}
              </ol>
            )}
          </div>
        ))}
      </div>
    );
  }
}
