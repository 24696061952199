import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  DeepProduct,
  PreSubmission,
  SubmissionAnswer,
  postPreIdentifiedSubmission,
  postSubmission,
  runSuggestions,
} from 'client/dist/generated/alloy';
import { getItem } from 'client/dist/localstorage';
import ProductRegistry from 'client/dist/product/productRegistry';

import { useExperience } from 'modules/shared/context/experience';

import useCart from 'modules/shared/hooks/useCart';

import { collateHiddenSubmissionAnswers } from 'modules/questionnaire/lib/intake';
import { getPreviousResults } from 'modules/questionnaire/lib/results';
import {
  getIntakeCategories,
  retrieveCategoriesFromUrl,
} from 'modules/shared/lib/experience/experience';
import { retrieveFlowFromUrl } from 'modules/shared/lib/experience/experience-flow';
import { getGroupedQualifiedProducts } from 'modules/shared/lib/product';

import { QuestionnaireAnswers } from 'modules/questionnaire/models/questionnaire-answers';
import { LocalPreCustomer } from 'modules/shared/models/local-pre-customer';

import { updateLocalPreCustomer } from 'modules/shared/store/local-pre-customer-slice';

import { useAppSelector } from 'shared/store/reducers';

export default function useIntakeState() {
  const dispatch = useDispatch();
  const location = useLocation();

  const { onNext } = useExperience();

  const dateStarted = new Date();

  const flow = retrieveFlowFromUrl(location);
  const categories = retrieveCategoriesFromUrl(location);

  const intakeQuestions = flow.intakeQuestions!;

  const [previousResults, setPreviousResults] = useState<QuestionnaireAnswers>({});
  const [isLoading, setIsLoading] = useState(true);
  const [disqualifiedProducts, setDisqualifiedProducts] = useState<DeepProduct[]>([]);

  const { isAuthenticated, customer } = useAppSelector((state) => state.alloy);
  const { localPreCustomer } = useAppSelector((state) => state.experience);

  const { updateCart } = useCart();

  useEffect(() => {
    initialize();
  }, []);

  const initialize = () => {
    let previousResults = getPreviousResults(intakeQuestions);
    const recentIntakeAnswers = getItem('recentIntakeAnswers', true);

    if (
      recentIntakeAnswers &&
      recentIntakeAnswers.categories?.sort().toString() === categories.sort().toString()
    ) {
      previousResults = {
        ...previousResults,
        ...recentIntakeAnswers.answers,
      };
    }

    setPreviousResults(previousResults);
    setIsLoading(false);
  };

  const onSubmit = async (answers: SubmissionAnswer[]) => {
    try {
      setIsLoading(true);

      const flow = retrieveFlowFromUrl(location);
      const categories = retrieveCategoriesFromUrl(location);
      const intakeCategories = getIntakeCategories(categories);
      const questions = flow.intakeQuestions!;

      const updatedAnswers = collateHiddenSubmissionAnswers(
        answers,
        questions,
        localPreCustomer,
        customer,
      );

      const submission: PreSubmission = {
        categories: intakeCategories,
        started: dateStarted,
        answers: updatedAnswers,
      };

      const { id: submissionId } = isAuthenticated
        ? await postSubmission(submission)
        : await postPreIdentifiedSubmission(submission);

      const newLocalPreCustomer: Partial<LocalPreCustomer> = {
        alloySubmissionId: submissionId,
        previousCategoriesForSubmission: intakeCategories,
        recentSubmissionDate: dateStarted.toISOString(),
      };

      dispatch(updateLocalPreCustomer(newLocalPreCustomer));

      // as long as the customer is not on consult flow, we should be able to just fetch submission
      // and run the suggestions against, this will allow us to check if all products against flow are disqualified
      // and if so we show dq otherwise filtering against not showing them products happens in the
      // review page!
      if (categories.every((c) => c !== 'mht')) {
        const suggestions = await runSuggestions({ submissionId });

        const flowProducts = await ProductRegistry.get().getDefaultProductsByIds(flow.productIds);

        const fetchedProducts = await getGroupedQualifiedProducts(
          flowProducts,
          categories,
          !!submissionId,
        );

        if (fetchedProducts.flat().length === 0) {
          setDisqualifiedProducts(suggestions.disqualified.map((dq) => dq.product));
          setIsLoading(false);
          return;
        }

        updateCart({
          products: fetchedProducts.flat(),
        });
      }

      onNext();
    } catch (error) {
      setIsLoading(false);
    }
  };

  return { isLoading, disqualifiedProducts, intakeQuestions, previousResults, onSubmit };
}
