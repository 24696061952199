import { differenceInDays, format } from 'date-fns';
import { minBy } from 'lodash';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useGetAllSubscriptionsForCustomer } from 'client/dist/generated/alloy';

import useCart from 'modules/shared/hooks/useCart';

import useGroupedProducts from 'shared/hooks/product/useGroupedProducts';

export default function useRequestConfirmationState() {
  const navigate = useNavigate();
  const location = useLocation();

  const { cart, clearCart } = useCart();

  const searchParams = new URLSearchParams(location.search);

  const { data: subscriptions = [] } = useGetAllSubscriptionsForCustomer();

  const nextShipment = minBy(subscriptions, (sub) => new Date(sub.nextRecurrenceOn));
  const nextRecurrence = nextShipment
    ? format(new Date(nextShipment.nextRecurrenceOn), 'MM/dd/yyyy')
    : undefined;
  const recurrenceDaysDiff = nextShipment
    ? Math.abs(differenceInDays(new Date(), new Date(nextShipment.nextRecurrenceOn)))
    : 0;

  const nextShipmentProducts =
    nextShipment?.products
      .filter((pfr) => pfr.product.category !== 'mht')
      .map((pfr) => pfr.product) ?? [];

  const { products: shipmentProducts } = useGroupedProducts({
    deepProducts: nextShipmentProducts,
  });

  // if a customer has a prescription for something they are switching over to,
  // we don't need to display content that focuses around waiting for doc
  // since we know they will be all set with the sub products swapped
  const hasPrescription =
    searchParams.has('hasPrescription') && searchParams.get('hasPrescription') === 'true';

  useEffect(() => {
    if (cart.products.length === 0) {
      clearCart();

      navigate('/', { replace: true });
    }
  });

  const getProductHug = () => {
    switch (cart.checkoutType) {
      case 'CUSTOMER_FORM_FACTOR_CHANGE': {
        if (hasPrescription) {
          return 'New treatment';
        } else {
          return 'Requested from doctor';
        }
      }
      case 'CUSTOMER_DOSAGE_CHANGE': {
        return 'New dose requested';
      }
      default: {
        return 'Requested from doctor';
      }
    }
  };

  return {
    hasPrescription,
    nextRecurrence,
    recurrenceDaysDiff,
    shipmentProducts,

    getProductHug,
  };
}
