import { ReactNode, createContext, useContext, useEffect, useRef, useState } from 'react';

import {
  AdaptedCreateMdiMessage,
  AdaptedMdiMessage,
  MessagingStatus,
  createMessage,
  getMessagesByPage,
  useGetMessagesByPage,
  useGetMessagingStatus,
} from 'client/dist/generated/alloy';

import { sendExceptionToSentry } from 'modules/tracking/lib/sentry';

import { useAppSelector } from 'shared/store/reducers';

interface Props {
  messages: AdaptedMdiMessage[];
  messagingStatus: MessagingStatus;

  isLoadingMessages: boolean;
  isLoadingMessagingStatus: boolean;

  onScroll: (e: React.UIEvent<HTMLDivElement>) => void;
  scrollContainerRef?: React.RefObject<HTMLDivElement>;

  sendMessage: (message: AdaptedCreateMdiMessage) => Promise<void>;
}

export const MessagesContext = createContext<Props>({
  messages: [],
  messagingStatus: 'PENDING',

  isLoadingMessages: false,
  isLoadingMessagingStatus: false,

  onScroll: () => {},
  scrollContainerRef: undefined,

  sendMessage: () => Promise.resolve(),
});

export const useMessagesContext = () => useContext(MessagesContext);

interface ProviderProps {
  children: ReactNode;
}

export default function MessagesContextProvider({ children }: ProviderProps) {
  const customer = useAppSelector((state) => state.alloy.customer!);

  const POLLING_MESSAGES_SECONDS = 15000;

  const [messages, setMessages] = useState<AdaptedMdiMessage[]>([]);
  const [page, setPage] = useState(1);
  const [isFetching, setIsFetching] = useState(false);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  /**
   *  similar to useeffect below for polling, if the user always stays on the first page
   * allow to refresh every 15 seconds. reason for the separation based on page is that if a
   * user starts scrolling, we don't want to guess the pages and mess with it so this
   * feels like the quickest/best way atm
   */
  const { data: messagesPage, isLoading: isLoadingMessages } = useGetMessagesByPage(
    { page },
    {
      swr: {
        refreshInterval: page === 1 ? POLLING_MESSAGES_SECONDS : 0,
        keepPreviousData: true,
        revalidateOnFocus: false,
      },
    },
  );
  const { data: messagingStatus = 'PENDING', isLoading: isLoadingMessagingStatus } =
    useGetMessagingStatus();

  const isLastPage = messagesPage?.meta?.currentPage === messagesPage?.meta?.lastPage;

  /**
   * when retrieving messages as the user scrolls, there could be discrepency on retrieved
   * messages due to when we a user sends a message, we don't want to recall the fetch method
   * for many reasons but mainly minimize api calls + create issues with already fetched messages
   *
   * this allows for deduping and having the most up to data messages diplayed!
   */
  const deduplicateMessages = (newMessages: AdaptedMdiMessage[]) => {
    const uniqueMessagesMap = new Map<string, AdaptedMdiMessage>();
    [...messages, ...newMessages]
      .filter(
        (message) =>
          !message.userType?.toLowerCase().includes('system') ||
          message.text?.includes('To complete your medical visit'),
      )
      .forEach((message) => {
        uniqueMessagesMap.set(message.id, message);
      });

    return Array.from(uniqueMessagesMap.values()).sort(
      (a, b) => new Date(b.createdAt!).getTime() - new Date(a.createdAt!).getTime(),
    );
  };

  useEffect(() => {
    if (messagesPage?.data && messagesPage.data.length > 0) {
      const scrollContainer = scrollContainerRef.current;

      // Preserve the current scroll position before adding new messages
      const currentScrollHeight = scrollContainer?.scrollHeight || 0;
      const currentScrollTop = scrollContainer?.scrollTop || 0;

      setMessages(deduplicateMessages(messagesPage.data));

      if (scrollContainer) {
        const newScrollHeight = scrollContainer.scrollHeight;

        scrollContainer.scrollTop = newScrollHeight - (currentScrollHeight - currentScrollTop);
      }

      setIsFetching(false);
    }
  }, [messagesPage]);

  /**
   * if not on the first page after some scrolling, we need to be able to poll the recent messages
   * every 15 seconds to get fresh messages as if the messaging is "live"
   */
  useEffect(() => {
    if (page !== 1) {
      const interval = setInterval(async () => {
        const latestMessagesPage = await getMessagesByPage({ page: 1 });

        if (latestMessagesPage?.data) {
          setMessages((prev) => deduplicateMessages([...prev, ...latestMessagesPage.data]));
        }
      }, POLLING_MESSAGES_SECONDS);

      return () => clearInterval(interval);
    }
  }, [page]);

  const onScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const scrollContainer = e.currentTarget;
    const distanceFromBottom =
      scrollContainer.scrollHeight + scrollContainer.scrollTop - scrollContainer.clientHeight;

    const nearTop = distanceFromBottom <= 10;

    if (nearTop && !isFetching && !isLastPage) {
      setIsFetching(true);
      setPage((prev) => prev + 1);
    }
  };

  const scrollToBottom = () => {
    const scrollContainer = scrollContainerRef.current;

    if (scrollContainer) {
      scrollContainer.scrollTop = 0;
    }
  };

  const sendMessage = async (content: AdaptedCreateMdiMessage) => {
    const tempMessage: AdaptedMdiMessage = {
      id: 'temp-id',
      patientId: customer.mdiPatientId!,
      text: content.text,
      userType: 'Patient',
      createdAt: new Date().toISOString(),
    };

    setMessages((prev) => deduplicateMessages([tempMessage, ...prev]));

    scrollToBottom();

    try {
      const saved = await createMessage(content);

      setMessages((prev) =>
        deduplicateMessages([saved, ...prev.filter((m) => m.id !== tempMessage.id)]),
      );
    } catch (error) {
      sendExceptionToSentry(error as Error);

      setMessages((prev) => prev.filter((m) => m.id !== tempMessage.id));
    }
  };

  return (
    <MessagesContext.Provider
      value={{
        messages,
        messagingStatus,

        isLoadingMessages,
        isLoadingMessagingStatus,

        onScroll,
        scrollContainerRef,

        sendMessage,
      }}
    >
      {children}
    </MessagesContext.Provider>
  );
}
