import './MessagingContent.scss';

import { Skeleton } from '@mui/material';
import classNames from 'classnames';

import { useMessagesContext } from '../../context/messages';
import MessageBubble from '../content/MessageBubble';

export default function MessagingContentBlock() {
  const {
    messages,
    messagingStatus,

    isLoadingMessages,

    onScroll,
    scrollContainerRef,
  } = useMessagesContext();

  return (
    <div className='messaging-content-block' ref={scrollContainerRef} onScroll={onScroll}>
      {!!messages.length &&
        messages.map((message) => (
          <div
            key={message.id}
            className={classNames(
              'message-bubble-section',
              message.userType?.includes('Patient') && 'patient-message',
            )}
          >
            <MessageBubble
              message={message}
              type={message.userType?.includes('Patient') ? 'patient' : 'other'}
            />
          </div>
        ))}

      {!messages.length && (
        <div className='message-empty-wrapper'>
          <p className='empty-title'>No messages yet!</p>
          <p className='empty-text'>Send your first message below.</p>
        </div>
      )}

      {messagingStatus === 'PARTNER_DOWN' && (
        <div className='message-empty-wrapper'>
          <p className='empty-title'>Hold tight!</p>
          <p className='empty-text'>Messaging is temporarily down. We'll be back soon.</p>
        </div>
      )}

      {isLoadingMessages && (
        <>
          <div className='message-bubble-wrapper customer-message'>
            <Skeleton variant='rectangular' width={225} height={40} />
          </div>

          <div className='message-bubble-wrapper'>
            <Skeleton variant='rectangular' width={225} height={40} />
          </div>
        </>
      )}
    </div>
  );
}
