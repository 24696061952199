import { getUniversalCookie, setUniversalCookie } from '../cookies';
import { v4 as uuidv4 } from 'uuid';
/**
 * The vendor expects an identifier, but our current prepurchase identifiers arent stable or generalizable enough.
 *
 * Plus this frees the vendor (tinuiti, braze) from any dependencies.
 *
 * Make up an ID for this device. This won't track through an abandon into a new device, but that's okay
 */
export function getLeadId(vendor, leadPrePurchaseId) {
    const maybeLeadId = getUniversalCookie(leadPrePurchaseId);
    if (maybeLeadId) {
        // since it already has a lead id, we return it! no new id created
        return { leadId: maybeLeadId, isNewId: false, vendor: vendor };
    }
    else {
        const leadId = uuidv4();
        setUniversalCookie(leadPrePurchaseId, leadId);
        // no lead id yet, return the new id generated
        return { leadId: leadId, isNewId: true, vendor: vendor };
    }
}
