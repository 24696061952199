import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';

import { AlloyCart } from '../models/cart';

const INITIAL_CART_STATE: AlloyCart = {
  products: [],
  taxAmountToCollect: 0,
  promotionCode: {
    id: '',
    name: '',
    discountAmount: 0,
    isReferral: false,
  },
  shipping: undefined,
  referralCreditBalance: 0,
  isPurchased: false,
  shipNow: true,
  agreement: {
    isAgreedTerms: false,
    isOptedSmsMarketing: true,
  },
};

const cartSlice = createSlice({
  name: 'cart',
  initialState: INITIAL_CART_STATE,
  reducers: {
    create: (state, action: PayloadAction<GroupedContentfulProduct[]>) => {
      return { ...state, products: action.payload };
    },
    update: (state, action: PayloadAction<Partial<AlloyCart>>) => {
      return { ...state, ...action.payload };
    },
    clear: () => {
      return { ...INITIAL_CART_STATE };
    },
  },
});

export const { create, update, clear } = cartSlice.actions;

export const cartReducer = cartSlice.reducer;
