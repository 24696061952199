import { ReactNode, createContext, useContext, useState } from 'react';

import {
  PreSubmission,
  cancel,
  postSubmission,
  processCancelRetention,
} from 'client/dist/generated/alloy';
import getIntakesByCategory from 'common/dist/intake/getIntakesByCategory';
import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';

import { ReasonType, reasons } from 'modules/dashboard/sub-modules/manage-subscription/data/reason';

import { getAnswersForSubmission } from 'modules/questionnaire/lib/submission';
import { getDeepProductIdsFrom } from 'modules/shared/lib/product';

import { QuestionnaireAnswers } from 'modules/questionnaire/models/questionnaire-answers';

import { useQueryParams } from 'shared/context/url/query';

import { useSubscriptionContext } from './manage';
import ProductContextProvider from './product';

interface Props {
  reasonType: ReasonType | null;
  setReasonType: (type: ReasonType | null) => void;

  onCancel: () => Promise<void>;
  onRetentionSave: (submissionId: string) => Promise<void>;
  onSaveSubmission: (answers?: QuestionnaireAnswers) => Promise<string>;

  isLoading: boolean;
  setIsLoading: (loading: boolean) => void;
}

export const CancelContext = createContext<Props>({
  reasonType: null,
  setReasonType: () => {},

  onCancel: () => Promise.resolve(),
  onRetentionSave: () => Promise.resolve(),
  onSaveSubmission: () => Promise.resolve(''),

  isLoading: false,
  setIsLoading: () => {},
});

export const useCancelContext = () => useContext(CancelContext);

export default function CancelContextProvider({
  children,
  product,
}: {
  children: ReactNode;
  product: GroupedContentfulProduct;
}) {
  const { subscription } = useSubscriptionContext();
  const { setParam } = useQueryParams();

  const [isLoading, setIsLoading] = useState(false);
  const [reasonType, setReasonType] = useState<ReasonType | null>(null);

  const onCancel = async () => {
    setIsLoading(true);

    const reason = reasons.find((r) => r.type === reasonType);
    const payload = {
      deepProductIds: getDeepProductIdsFrom(product),
      stripeSubscriptionId: subscription.stripeSubscriptionId,
      reason: reason?.text,
    };

    await cancel(payload);

    setParam('outcome', 'cancel');

    setIsLoading(false);
  };

  const onRetentionSave = async (submissionId: string) => {
    setIsLoading(true);

    const payload = {
      deepProductIds: getDeepProductIdsFrom(product),
      submissionId,
      subscriptionId: subscription.stripeSubscriptionId,
    };

    await processCancelRetention(payload);

    setParam('outcome', 'retention');

    setIsLoading(false);
  };

  const onSaveSubmission = async (answers: QuestionnaireAnswers = {}) => {
    setIsLoading(true);

    const reason = reasons.find((r) => r.type === reasonType);

    const updatedAnswers = {
      'cancel-reason': reason?.text || 'ERROR',
      ...answers,
    };

    const results = getAnswersForSubmission(getIntakesByCategory(['cancel']), updatedAnswers);

    const submission: PreSubmission = {
      categories: ['cancel'],
      started: new Date(),
      answers: results,
    };

    const { id } = await postSubmission(submission);

    setIsLoading(false);

    return id;
  };

  return (
    <CancelContext.Provider
      value={{
        reasonType,
        setReasonType,

        onCancel,
        onRetentionSave,
        onSaveSubmission,

        isLoading,
        setIsLoading,
      }}
    >
      <ProductContextProvider product={product}>{children}</ProductContextProvider>
    </CancelContext.Provider>
  );
}
