import { Checkout } from 'client/dist/generated/alloy';

import { CognitoUser } from 'modules/shared/models/cognito-user';
import { RequestingDeepProductIds } from 'modules/shared/models/requesting-deep-products';

import { cartReducer } from 'modules/shared/store/cart-slice';
import { localPreCustomerReducer } from 'modules/shared/store/local-pre-customer-slice';

import { Address } from 'shared/models/customer-address';

import {
  BILLING_ADDRESS,
  CHECKOUT,
  CHECKOUT_EDITING_SHIPPING,
  COGNITO_USER,
  LICENSE_EXISTS,
  REQUESTING_PRODUCTS,
} from 'shared/store/actions/GlobalTypes';

export interface ExperienceState {
  cart: ReturnType<typeof cartReducer>;

  requestingDeepProductIds: RequestingDeepProductIds;

  licenseExists: boolean;

  cognitoUser?: CognitoUser;

  localPreCustomer: ReturnType<typeof localPreCustomerReducer>;

  checkout?: Checkout;

  isEditingShipping: boolean;
  billingAddress: Address;
}

const INIT_STATE: Omit<ExperienceState, 'cart' | 'localPreCustomer'> = {
  requestingDeepProductIds: [],

  licenseExists: false,

  cognitoUser: undefined,

  isEditingShipping: false,
  billingAddress: {},
};

type ExperienceAction =
  | { type: typeof REQUESTING_PRODUCTS; payload: RequestingDeepProductIds }
  | { type: typeof LICENSE_EXISTS; payload: boolean }
  | { type: typeof COGNITO_USER; payload: CognitoUser }
  | { type: typeof CHECKOUT_EDITING_SHIPPING; payload: boolean }
  | { type: typeof BILLING_ADDRESS; payload: Address }
  | { type: typeof CHECKOUT; payload: Checkout };

const handleOtherActions = (action: ExperienceAction): Partial<Omit<ExperienceState, 'cart'>> => {
  switch (action.type) {
    case REQUESTING_PRODUCTS: {
      return { requestingDeepProductIds: action.payload };
    }

    case LICENSE_EXISTS: {
      return { licenseExists: action.payload };
    }

    case COGNITO_USER: {
      return { cognitoUser: action.payload };
    }

    case CHECKOUT_EDITING_SHIPPING: {
      return { isEditingShipping: action.payload };
    }

    case BILLING_ADDRESS: {
      return { billingAddress: action.payload };
    }

    case CHECKOUT: {
      return { checkout: action.payload };
    }

    default:
      return {}; // no changes for unhandled actions
  }
};

export default function ExperienceReducer(
  state: ExperienceState = {
    ...INIT_STATE,
    cart: cartReducer(undefined, { type: '' }),
    localPreCustomer: localPreCustomerReducer(undefined, { type: '' }),
  },
  action: ExperienceAction,
): ExperienceState {
  const otherUpdates = handleOtherActions(action);

  return {
    ...state,
    ...otherUpdates,
    cart: cartReducer(state.cart, action),
    localPreCustomer: localPreCustomerReducer(state.localPreCustomer, action),
  };
}
