import { first } from 'lodash';
import { useState } from 'react';

import { uploadAvSyncVideo, useGetMessagingStatus } from 'client/dist/generated/alloy';

import {
  showErrorNotification,
  showSuccessNotification,
} from 'shared/components/core/Notification';

import { getLargeFileMessage, isFileTooBig } from 'shared/lib/file';

import { getFileNameFromBlob } from '../lib/file-name';

type File = {
  blob: Blob;
  name: string;
  type: string;
};

export default function useAvSyncUploadState() {
  const [file, setFile] = useState<File | null>(null);
  const [isVideoOpen, setIsVideoOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { mutate } = useGetMessagingStatus();

  const onAttachFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files || [];
    const selectedFile = first(files);

    if (!selectedFile) return;

    if (isFileTooBig(selectedFile)) {
      showErrorNotification(getLargeFileMessage(selectedFile), true);

      return;
    }

    setFile({
      blob: selectedFile,
      name: selectedFile.name,
      type: selectedFile.type,
    });
  };

  const onRemoveFile = () => setFile(null);

  const onAddFile = (blob: Blob, baseName: string) => {
    const name = getFileNameFromBlob(blob, baseName);
    const type = blob.type || 'application/octet-stream';

    setFile({ blob, name, type });
  };

  const onContinue = async () => {
    try {
      if (file) {
        setIsLoading(true);

        await uploadAvSyncVideo({
          file: file.blob,
        });

        showSuccessNotification('Successfully uploaded video!');

        await mutate();

        setFile(null);

        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  return {
    file,

    isLoading,

    isVideoOpen,
    setIsVideoOpen,

    onRemoveFile,
    onAttachFile,
    onAddFile,
    onContinue,
  };
}
