import { VendorPdfInvoiceInfo } from '../models/vendor-pdf-invoice-info';

/**
 * Maps each vendor to its invoice info.
 */
export const VENDOR_PDF_INVOICE_INFO_MAP = new Map<string, VendorPdfInvoiceInfo>([
  [
    'CUREXA',
    {
      name: 'Curexa',
      street: '3007 Ocean Heights Ave',
      cityStateZip: 'Egg Harbor Township, NJ 08234',
      phoneNumber: '(855) 927-0390',
    },
  ],
  [
    'GOGO_MEDS',
    {
      name: 'GoGoMeds',
      street: '525 Alexandria Pike Suite 100',
      cityStateZip: 'Southgate, KY 41071',
      phoneNumber: '(888) 795-5826',
    },
  ],
  [
    'JUNGLE_JIMS',
    {
      name: "Jungle Jim's Pharmacy",
      street: '5484 Dixie Highway',
      cityStateZip: 'Fairfield, OH 45014',
      phoneNumber: '(513) 858-3784',
    },
  ],
]);
