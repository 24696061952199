import './MessageBubble.scss';

import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { format } from 'date-fns';
import ReactMarkdown from 'react-markdown';

import { AdaptedMdiMessage } from 'client/dist/generated/alloy';

import { getMessageName } from '../../lib/message-name';
import { renderWithLinks } from '../../lib/render-links';

interface Props {
  message: AdaptedMdiMessage;
  type: 'patient' | 'other';
}

export default function MessageBubble({ message, type }: Props) {
  const linkFiles = (message.files || []).filter((file) => file.mimeType?.includes('pdf'));
  const otherFiles = (message.files || [])?.filter((file) => !file.mimeType?.includes('pdf'));

  const messageFullName = getMessageName(message);

  return (
    <div className={classNames('message-bubble-wrapper', type + '-bubble')}>
      <div className='message-bubble-block'>
        {message.text && (
          <div className='message-bubble-text-content' data-private='lipsum' data-sentry-mask>
            {/* you might be wondering why we need the bottom, well for now mdi on system
            messages for requesting av sync OR new license on the doctors side, we don't have
            flow within eng to set that up so this is the best thing to make sure the url is clean
            and clickable (since it comes back as not from mdi) */}
            <ReactMarkdown
              className='text-content-markdown sentry-mask'
              components={{
                p({ children }) {
                  return (
                    <p>
                      {children.map((child) =>
                        typeof child === 'string' ? renderWithLinks(child, 'this link') : child,
                      )}
                    </p>
                  );
                },
              }}
            >
              {message.text}
            </ReactMarkdown>
          </div>
        )}

        {!!linkFiles.length &&
          linkFiles.map((file) => (
            <div className='message-bubble-file-block message-bubble-text-content' key={file.id}>
              <a
                key={file.id}
                href={file.url}
                target='_blank'
                rel='noreferrer'
                className='file-content-link'
              >
                <FontAwesomeIcon icon={faPaperclip} size='sm' />
                <span className='content-link' data-private data-sentry-mask>
                  {file.name}
                </span>
              </a>
            </div>
          ))}

        {!!otherFiles.length &&
          otherFiles.map((file) => (
            <div className='message-bubble-file-block' key={file.id}>
              {file.mimeType?.includes('image') && (
                <a
                  key={file.id}
                  href={file.url}
                  target='_blank'
                  rel='noreferrer'
                  className='file-content-link'
                >
                  <img
                    src={file.url}
                    alt={file.name}
                    className='content-img'
                    data-private
                    data-sentry-block
                  />
                </a>
              )}

              {file.mimeType?.includes('audio') && (
                <audio
                  key={file.id}
                  src={file.url}
                  controls
                  className='file-content-audio'
                  data-private
                  data-sentry-block
                />
              )}

              {file.mimeType?.includes('video') && (
                <video
                  key={file.id}
                  src={file.url}
                  controls
                  className='file-content-video'
                  data-private
                  data-sentry-block
                />
              )}
            </div>
          ))}

        {message.createdAt && (
          <span className='message-bubble-text'>
            {type === 'patient' ? 'You | ' : `${messageFullName} | `}
            {format(new Date(message.createdAt), "MMM d 'at' hh:mm aa")}
          </span>
        )}
      </div>
    </div>
  );
}
