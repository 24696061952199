import {
  LARGE_IMAGE_FILE_MESSAGE,
  LARGE_OTHER_FILE_MESSAGE,
  LARGE_VIDEO_FILE_MESSAGE,
  isImageFileTooBig,
  isOtherFileTooBig,
  isVideoFileTooBig,
} from 'common/dist/file/size';

export const isFileTooBig = (blob: Blob): boolean => {
  switch (true) {
    case blob.type.includes('image'):
      return isImageFileTooBig(blob.size);
    case blob.type.includes('video'):
      return isVideoFileTooBig(blob.size);
    default:
      return isOtherFileTooBig(blob.size);
  }
};

export const getLargeFileMessage = (blob: Blob): string => {
  switch (true) {
    case blob.type.includes('image'):
      return LARGE_IMAGE_FILE_MESSAGE;
    case blob.type.includes('video'):
      return LARGE_VIDEO_FILE_MESSAGE;
    default:
      return LARGE_OTHER_FILE_MESSAGE;
  }
};

export const b64toBlob = (dataURI: any): Blob => {
  var byteString = atob(dataURI.split(',')[1]);
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);

  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ab], { type: 'image/jpeg' });
};
