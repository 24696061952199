import { SubscriptionWithRenewal } from 'client/dist/generated/alloy';

/**
 * Check if subscription is manageable-- at least one renewable or fills remaining item.
 * @param subscription
 * @returns
 */
export const canManageSubscription = (subscription?: SubscriptionWithRenewal) =>
  !!subscription &&
  !subscription.products.every(
    (pfr) => pfr.renewal?.hasExpired || pfr.prescription?.fillsRemaining === 0,
  );

/**
 * Check if at least one product is expired or has no refills.
 * @param subscription
 * @returns
 */
export const someExpiredOrNoRefills = (subscription: SubscriptionWithRenewal): boolean =>
  subscription.products.some(
    (pfr) => pfr.renewal?.hasExpired || pfr.prescription?.fillsRemaining === 0,
  );
