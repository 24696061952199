import './ReviewsSection.scss';

import SectionWrapper from 'shared/components/wrappers/Section';

import TrustpilotReviewCarouselBlock from '../blocks/TrustpilotReviewCarousel';

export default function ReviewsSectionWrapper() {
  return (
    <SectionWrapper sectionClasses='dashboard-treatment-plan-section'>
      <TrustpilotReviewCarouselBlock />
    </SectionWrapper>
  );
}
