"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lodash_1 = require("lodash");
const cancel_1 = require("./cancel");
const doctor_survey_1 = require("./doctor-survey");
const hair_health_1 = require("./hair-health");
const heard_about_us_survey_1 = require("./heard-about-us-survey");
const mht_1 = require("./mht");
const renewal_1 = require("./renewal");
const sexual_health_1 = require("./sexual-health");
const skin_health_1 = require("./skin-health");
const symptom_survey_1 = require("./symptom-survey");
const vaginal_health_1 = require("./vaginal-health");
const questionnaires = {
    'skin-health': skin_health_1.SKIN_QUESTIONNAIRE,
    mht: mht_1.MHT_QUESTIONNAIRE,
    'sexual-health': sexual_health_1.SEXUAL_QUESTIONNAIRE,
    renewal: renewal_1.RENEWAL_QUESTIONNAIRE,
    'vaginal-health': vaginal_health_1.VAGINAL_QUESTIONNAIRE,
    'symptom-survey': symptom_survey_1.SYMPTOM_SURVEY_QUESTIONNAIRE,
    'hair-health': hair_health_1.HAIR_QUESTIONNAIRE,
    'heard-about-us-survey': heard_about_us_survey_1.HEADER_ABOUT_US_SURVEY_QUESTIONNAIRE,
    'doctor-survey': doctor_survey_1.DOCTOR_SURVEY_QUESTIONNAIRE,
    cancel: cancel_1.CANCEL_QUESTIONNAIRE,
};
exports.default = (categories) => (0, lodash_1.pick)(questionnaires, categories);
