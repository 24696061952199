import ReactMarkdown from 'react-markdown';
import { useDispatch } from 'react-redux';

import { Question as AlloyQuestion } from 'common/dist/models/questionnaire';

import doctors from 'modules/shared/assets/images/doctors.png';

import { interpolateQuestionText } from 'modules/questionnaire/lib/format';

import { QuestionnaireAnswers } from 'modules/questionnaire/models/questionnaire-answers';
import { RelevantProduct } from 'modules/shared/models/experience';

import { updateLocalPreCustomer } from 'modules/shared/store/local-pre-customer-slice';

import { DateField } from 'shared/components/fields/DateField';
import { NumberField } from 'shared/components/fields/NumberField';
import { SelectField } from 'shared/components/fields/SelectField';
import { SelectFieldsGroup } from 'shared/components/fields/SelectFieldsGroup';
import { TextAreaField } from 'shared/components/fields/TextAreaField';
import { TextField } from 'shared/components/fields/TextField';

import AllergiesQuestionBlock from './AllergiesQuestion';
import MedicationsQuestionBlock from './MedicationsQuestion';

import QuestionLayoutWrapper from '../wrappers/QuestionLayout';

interface Props {
  question: AlloyQuestion;
  answers: QuestionnaireAnswers;
  followUps?: QuestionnaireAnswers;
  setFollowUps?: (followUps: QuestionnaireAnswers) => void;
  setAnswers: (answers: QuestionnaireAnswers) => void;
  relevantProducts?: RelevantProduct[];
  introduction?: JSX.Element;
}

/**
 * Question handles each individual question and how to render it. It has many different types but all fall back to the same idea
 * of saving off an answer to its parent component
 */
export const QuestionBlock = ({
  question,
  answers,
  followUps,
  setAnswers,
  setFollowUps,
  relevantProducts,
  introduction,
}: Props) => {
  const dispatch = useDispatch();

  const questionTitle = interpolateQuestionText(
    question.title,
    relevantProducts?.map((rp) => rp.name),
    'and',
  );

  switch (question.type) {
    case 'MultipleChoice': {
      return (
        <QuestionLayoutWrapper
          title={question.title}
          description={question.description}
          whyAskingDescription={question.whyAskingDescription}
          introduction={introduction}
        >
          <SelectFieldsGroup
            selectedIds={(answers[question.slug] as string[]) || []}
            onChange={(checkboxAnswer) => {
              setAnswers({ ...answers, [question.slug]: checkboxAnswer });
            }}
            followUps={followUps}
            setFollowUps={setFollowUps}
            options={question.options.map((option) => ({
              id: option.slug,
              text: option.answer,
              ...(option.followUp && {
                followUp: {
                  title: option.followUp.title,
                  slug: option.followUp.slug,
                  description: option.followUp.description,
                },
              }),
            }))}
          />
        </QuestionLayoutWrapper>
      );
    }

    case 'SingleChoice': {
      return (
        <QuestionLayoutWrapper
          title={questionTitle}
          description={question.description}
          whyAskingDescription={question.whyAskingDescription}
          introduction={introduction}
        >
          <SelectFieldsGroup
            selectedIds={!!answers[question.slug] ? ([answers[question.slug]] as string[]) : []}
            onChange={(checkboxAnswers) => {
              const singleChoiceAnswer: string =
                (checkboxAnswers[checkboxAnswers.length - 1] as string) ?? '';

              let updatedAnswers: QuestionnaireAnswers = {
                ...answers,
                [question.slug]: singleChoiceAnswer,
              };

              setAnswers(updatedAnswers);
            }}
            options={question.options
              .filter(
                (o) =>
                  !o.parentOptionSlug ||
                  (o.parentOptionSlug &&
                    Object.values(answers).flat().includes(o.parentOptionSlug)),
              )
              .map((option) => ({
                id: option.slug,
                text: option.answer,
                ...(option.parentOptionSlug && {
                  parentOptionSlugId: option.parentOptionSlug,
                }),
              }))}
            isSingleSelect
          />
        </QuestionLayoutWrapper>
      );
    }

    case 'MarkdownCheckbox': {
      return (
        <QuestionLayoutWrapper wrapperClasses='content-markdown-wrapper' title={questionTitle}>
          <ReactMarkdown
            components={{
              a: ({ href, children, ...props }: React.HTMLProps<HTMLAnchorElement>) => {
                const prodMarketingBase = 'https://www.myalloy.com';

                // Check if href exists
                if (href) {
                  const newHref = href.replace(
                    prodMarketingBase,
                    process.env.REACT_APP_MARKETING_URL || prodMarketingBase,
                  );

                  return (
                    <a href={newHref} target='_blank' rel='noopener noreferrer' {...props}>
                      {children}
                    </a>
                  );
                }

                return <span {...props}>{children}</span>;
              },
            }}
          >
            {question.description}
          </ReactMarkdown>

          <SelectFieldsGroup
            selectedIds={(answers[question.slug] as string[]) ?? []}
            onChange={(checkboxAnswer) => {
              setAnswers({ ...answers, [question.slug]: checkboxAnswer });
            }}
            followUps={followUps}
            setFollowUps={setFollowUps}
            options={question.options.map((option) => ({
              id: option.slug,
              text: option.answer,
              ...(option.followUp && {
                followUp: {
                  title: option.followUp.title,
                  slug: option.followUp.slug,
                  description: option.followUp.description,
                },
              }),
            }))}
          />
        </QuestionLayoutWrapper>
      );
    }

    case 'SingleLine': {
      return (
        <QuestionLayoutWrapper
          title={question.title}
          description={question.description}
          whyAskingDescription={question.whyAskingDescription}
          introduction={introduction}
        >
          <TextField
            name={question.slug}
            value={(answers[question.slug] as string) || ''}
            placeholder={question.placeholder}
            onChange={(textAnswer) => setAnswers({ ...answers, [question.slug]: textAnswer })}
            data-private='lipsum'
            data-sentry-mask
          />
        </QuestionLayoutWrapper>
      );
    }

    case 'MultipleLine': {
      return (
        <QuestionLayoutWrapper
          title={question.title}
          description={question.description}
          whyAskingDescription={question.whyAskingDescription}
          introduction={introduction}
        >
          {question.slug === 'anything-else-share-doctor' && (
            <img src={doctors} alt='doctors' className='question-img' />
          )}

          <TextAreaField
            name={question.slug}
            value={(answers[question.slug] as string) || ''}
            rows={4}
            placeholder={question.placeholder}
            onChange={(textAnswer) => setAnswers({ ...answers, [question.slug]: textAnswer })}
            data-private='lipsum'
            data-sentry-mask
          />
        </QuestionLayoutWrapper>
      );
    }

    case 'Date': {
      return (
        <QuestionLayoutWrapper
          title={question.title}
          description={question.description}
          whyAskingDescription={question.whyAskingDescription}
          introduction={introduction}
        >
          <DateField
            name={question.slug}
            value={answers[question.slug] as string}
            placeholder={question.placeholder}
            onChange={(dateAnswer) => {
              if (question.userKey === 'dob' && !dateAnswer.includes('_')) {
                dispatch(
                  updateLocalPreCustomer({
                    dateOfBirth: dateAnswer,
                  }),
                );
              }

              setAnswers({ ...answers, [question.slug]: dateAnswer });
            }}
          />
        </QuestionLayoutWrapper>
      );
    }

    case 'Select': {
      return (
        <QuestionLayoutWrapper
          title={question.title}
          description={question.description}
          whyAskingDescription={question.whyAskingDescription}
          introduction={introduction}
        >
          <SelectField
            name={question.slug}
            value={answers[question.slug] as string}
            placeholder={question.placeholder}
            data={question.options.map((option) => ({ value: option.slug, label: option.answer }))}
            onChange={(selectAnswer) => {
              if (selectAnswer) {
                setAnswers({ ...answers, [question.slug]: selectAnswer });
              }
            }}
          />
        </QuestionLayoutWrapper>
      );
    }

    case 'Number': {
      return (
        <QuestionLayoutWrapper
          title={question.title}
          description={question.description}
          whyAskingDescription={question.whyAskingDescription}
          introduction={introduction}
        >
          <NumberField
            name={question.slug}
            value={parseInt(answers[question.slug] as string)}
            placeholder={question.placeholder}
            onChange={(numberAnswer) => {
              if ((numberAnswer as number) <= 1000) {
                setAnswers({ ...answers, [question.slug]: numberAnswer.toString() });
              }
            }}
          />
        </QuestionLayoutWrapper>
      );
    }

    case 'Information': {
      return <QuestionLayoutWrapper title={question.title} description={question.description} />;
    }

    case 'Allergies': {
      return <AllergiesQuestionBlock question={question} introduction={introduction} />;
    }

    case 'Medications': {
      return <MedicationsQuestionBlock question={question} introduction={introduction} />;
    }

    default:
      return <></>;
  }
};
