import { bindActionCreators } from '@reduxjs/toolkit';
import { first, isEmpty, uniqueId } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  PickCustomerAllergyNameOrReactionOrDetails as PickCustomerAllergyFields,
  createCustomerAllergies,
  deactivateCustomerAllergy,
  updateCustomerAllergies,
} from 'client/dist/generated/alloy';

import { updateCustomer } from 'modules/shared/store/customer';
import { updateLocalPreCustomer } from 'modules/shared/store/local-pre-customer-slice';

import { useAppSelector } from 'shared/store/reducers';

import { AllergyEdit } from '../models/allergy-edit';

const INIT_ALLERGY: PickCustomerAllergyFields = {
  name: '',
  reaction: '',
  details: '',
};

export default function useAllergiesQuestionState() {
  const dispatch = useDispatch();

  const [newAllergy, setNewAllergy] = useState<PickCustomerAllergyFields>(INIT_ALLERGY);

  const { customer, isAuthenticated } = useAppSelector((state) => state.alloy);
  const { localPreCustomer } = useAppSelector((state) => state.experience);

  const [allergies, setAllergies] = useState<AllergyEdit[]>(
    (isAuthenticated
      ? customer?.currentAllergies || []
      : localPreCustomer.currentAllergies || []
    ).map((allergy) => ({ editId: uniqueId(), allergy, isEditing: false })),
  );

  const newAllergyRef = useRef(newAllergy);
  const allergiesRef = useRef(allergies);

  const dispatchUpdateCustomer = bindActionCreators(updateCustomer, dispatch);

  useEffect(() => {
    newAllergyRef.current = newAllergy;
  }, [JSON.stringify(newAllergy)]);

  useEffect(() => {
    allergiesRef.current = allergies;
  }, [JSON.stringify(allergies)]);

  useEffect(() => {
    return () => {
      handleUnmount();
    };
  }, []);

  /**
   * TODO: with the current design, we need to be able to handle unmount since there is no clear save and continue button
   * this is something that would absolutely love to fix for phase 2 where the ui is a bit better and allows for more
   * clear adding, editing, and removing without relying on continue if you have a sort of filled out new field
   *
   * for phase 2, also incorporate a more shared layout with medications that way the hooks don't feel super the same,
   * reason for not fully consolidating atm is timing
   *
   * reason for using a ref for allergies is because on unmount, the useEffect won't have the most recent data
   * without using a ref, would have just used the redux values but it is the same thing, no recent data on unmount.
   * if you add a dependency then this unmount creates duplicate values anytime you use the add new allergy button
   */
  const handleUnmount = async () => {
    const latestNewAllergy = newAllergyRef.current;
    const latestAllergies = allergiesRef.current;

    if (isEmpty(latestNewAllergy.name.trim()) || !latestAllergies) return;

    if (isAuthenticated) {
      const createdAllergies = await createCustomerAllergies([latestNewAllergy]);

      // since partial on allergies (we know these will exist if auth) so just init
      const currentAllergies = latestAllergies
        .filter(({ allergy }) => !!allergy.id)
        .map(({ allergy }) => ({
          id: allergy.id!,
          customerId: allergy.customerId!,
          details: allergy.details,
          name: allergy.name!,
          reaction: allergy.reaction,
          active: allergy.active!,
        }));

      dispatchUpdateCustomer({
        currentAllergies: [...currentAllergies, ...createdAllergies],
      });
    } else {
      const currentAllergies = latestAllergies.map(({ allergy }) => allergy);

      dispatch(
        updateLocalPreCustomer({
          currentAllergies: [...currentAllergies, latestNewAllergy],
        }),
      );
    }
  };

  /**
   * used to create an allergy on action of 'add another allergy' either in the db and attach to customer in redux or
   * create locally and attach via redux
   */
  const onCreate = async () => {
    let newAllergyData: AllergyEdit = {
      editId: uniqueId(),
      allergy: newAllergy,
      isEditing: false,
    };

    if (isAuthenticated) {
      const allergies = await createCustomerAllergies([newAllergy]);

      dispatchUpdateCustomer({
        currentAllergies: [...(customer?.currentAllergies || []), ...allergies],
      });

      newAllergyData.allergy = first(allergies)!;
    } else {
      dispatch(
        updateLocalPreCustomer({
          currentAllergies: [...(localPreCustomer.currentAllergies || []), newAllergy],
        }),
      );
    }

    setAllergies((prevAllergies) => [...prevAllergies, newAllergyData]);

    setNewAllergy(INIT_ALLERGY);
  };

  const onUpdate = async (id: string) => {
    const allergyIndex = allergies.findIndex(({ editId }) => editId === id);

    if (allergyIndex === -1) return;

    const updatedAllergy = allergies[allergyIndex].allergy;

    if (isAuthenticated && !!updatedAllergy.id) {
      const updatedAllergies = await updateCustomerAllergies([
        {
          id: updatedAllergy.id,
          name: updatedAllergy.name!,
          reaction: updatedAllergy.reaction,
          details: updatedAllergy.details,
        },
      ]);

      dispatchUpdateCustomer({
        currentAllergies: (customer?.currentAllergies || []).map((existingAllergy) => {
          const updatedAllergy = updatedAllergies.find(
            (allergy) => allergy.id === existingAllergy.id,
          );

          return updatedAllergy || existingAllergy;
        }),
      });
    } else {
      const updatedAllergies = [...(localPreCustomer.currentAllergies || [])];
      updatedAllergies[allergyIndex] = updatedAllergy;

      dispatch(
        updateLocalPreCustomer({
          currentAllergies: updatedAllergies,
        }),
      );
    }

    setAllergies((prev) => {
      const newAllergies = [...prev];

      newAllergies[allergyIndex].isEditing = false;

      return newAllergies;
    });
  };

  const onOpenEdit = (id: string) => {
    const allergyIndex = allergies.findIndex(({ editId }) => editId === id);

    if (allergyIndex === -1) return;

    setAllergies((prev) =>
      prev.map((allergy, index) =>
        index === allergyIndex ? { ...allergy, isEditing: true } : allergy,
      ),
    );
  };

  const onEditAllergy = (id: string, key: string, value: string) =>
    setAllergies((prev) =>
      prev.map((prevAllergyEdit) =>
        prevAllergyEdit.editId === id
          ? {
              ...prevAllergyEdit,
              allergy: { ...prevAllergyEdit.allergy, [key]: value },
            }
          : prevAllergyEdit,
      ),
    );

  const onRemove = async (id: string) => {
    const allergyIndex = allergies.findIndex(({ editId }) => editId === id);

    if (allergyIndex === -1) return;

    const allergyToRemove = allergies[allergyIndex].allergy;

    if (!allergyToRemove) return;

    if (isAuthenticated && !!allergyToRemove.id) {
      await deactivateCustomerAllergy(allergyToRemove.id);

      const customerAllergies = (customer?.currentAllergies || []).filter(
        (allergy) => allergy.id !== allergyToRemove.id,
      );

      dispatchUpdateCustomer({
        currentAllergies: customerAllergies,
      });
    } else if (!isAuthenticated) {
      const updatedAllergies = [...(localPreCustomer.currentAllergies || [])];

      updatedAllergies.splice(allergyIndex, 1);

      dispatch(
        updateLocalPreCustomer({
          currentAllergies: updatedAllergies,
        }),
      );
    }

    setAllergies((prev) => prev.filter((_, index) => index !== allergyIndex));
  };

  return {
    allergies,

    newAllergy,
    setNewAllergy,

    onUpdate,
    onOpenEdit,
    onEditAllergy,
    onRemove,
    onCreate,
  };
}
