import { SubscriptionWithRenewal } from 'client/dist/generated/alloy';
import ProductRegistry from 'client/dist/product/productRegistry';
import { DeepProduct } from 'common/dist/products/productFrequency';
import areProductsEquivalent from 'common/dist/products/utils/areProductsEquivalent';

/**
 * allows to pass in a sub and deep product ids and
 * get the unbundled deep product ids from that sub.
 *
 * @param subscription SubscriptionWithRenewal
 * @param deepProductIds DeepProduct['id'][] or number[]
 * @returns DeepProduct['id'][] or number[]
 */
export const getUnbundledDeepProductIdsFrom = async (
  subscription: SubscriptionWithRenewal,
  deepProductIds: DeepProduct['id'][],
): Promise<DeepProduct['id'][]> => {
  const products = (await ProductRegistry.get().alloyProducts).filter((ap) =>
    deepProductIds.includes(ap.id),
  );
  return subscription.products
    .filter((pfr) => products.some((dp) => areProductsEquivalent([dp, pfr.product])))
    .map((p) => p.product.id);
};
