import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import useCart from 'modules/shared/hooks/useCart';

import { retrieveCategoriesFromUrl } from 'modules/shared/lib/experience/experience';
import { getDeepProductsFromGroupedProducts } from 'modules/shared/lib/product';
import sendAbandonEvent from 'modules/tracking/lib/sendAbandonEvent';

export default function useInitCheckout() {
  const location = useLocation();

  const { cart } = useCart();

  useEffect(() => {
    window.scrollTo(0, 0);

    const products = getDeepProductsFromGroupedProducts(cart.products);
    const categories = retrieveCategoriesFromUrl(location);

    sendAbandonEvent({
      event: 'CHECKOUT_SHOWN',
      categories,
      experience: 'checkout',
      products,
    });
  }, []);
}
