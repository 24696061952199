import './Cancel.scss';

import { upperCase } from 'lodash';
import { useEffect, useState } from 'react';

import { reasons } from 'modules/dashboard/sub-modules/manage-subscription/data/reason';

import { CancelStep } from 'modules/dashboard/sub-modules/manage-subscription/models/cancel-step';

import { useQueryParams } from 'shared/context/url/query';

import { useCancelContext } from '../../context/cancel';
import ConfirmationBlock from '../blocks/Confirmation';
import RetentionBlock from '../blocks/Retention';
import StartBlock from '../blocks/Start';

interface Props {
  onUpdateLoading: (isLoading: boolean) => void;
}

export default function CancelWrapper({ onUpdateLoading }: Props) {
  const { setParam, getParam } = useQueryParams();
  const { isLoading } = useCancelContext();

  const [cancelStep, setCancelStep] = useState<CancelStep>('START');

  const outcome = upperCase(getParam('outcome') || '');

  useEffect(() => {
    // based on whether we find the reason slug or if there is outcome, we need to update state of
    // cancel step so that customer can be shown one of the blocks below
    const foundReasonSlug = getParam('reason');
    const reason = reasons.find((r) => r.slug === foundReasonSlug);

    if (!!outcome || !!reason) setCancelStep('RETENTION');
  }, []);

  useEffect(() => {
    onUpdateLoading(isLoading);
  }, [isLoading, onUpdateLoading]);

  const onReschedule = () => {
    setParam('manage', 'reschedule');
  };

  const onContinue = (step: CancelStep) => setCancelStep(step);

  return (
    <div className='cancel-wrapper'>
      {cancelStep === 'START' && <StartBlock onReschedule={onReschedule} onContinue={onContinue} />}

      {cancelStep === 'RETENTION' && !outcome && <RetentionBlock />}

      {outcome && <ConfirmationBlock />}
    </div>
  );
}
