"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SKIN_QUESTIONNAIRE = void 0;
exports.SKIN_QUESTIONNAIRE = {
    title: "Let's talk about your skin",
    questions: [
        {
            title: 'What goals do you have for your skin?',
            slug: 'skin-goals',
            description: 'Check all that apply:',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'MultipleChoice',
            headerTitle: 'Your Skin:',
            options: [
                {
                    answer: 'Firmer, smoother skin',
                    slug: 'skin-goals-firmer-smoother-skin',
                },
                {
                    answer: 'Reducing fine lines and wrinkles',
                    slug: 'skin-goals-reducing-fine-lines-and-wrinkles',
                },
                {
                    answer: 'Controlling acne breakouts',
                    slug: 'skin-goals-controlling-acne-breakouts',
                },
                {
                    answer: 'Moist, glowy skin',
                    slug: 'skin-goals-moist-glowy-skin',
                },
                {
                    answer: 'Reducing discoloration',
                    slug: 'skin-goals-reducing-discoloration',
                },
            ],
            mdiCaseQuestion: true,
        },
        {
            title: 'How sensitive is your skin?',
            slug: 'how-sensitive-skin',
            description: 'This will let us know if your skin is easily irritated when trying new products.',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'SingleChoice',
            headerTitle: 'Your Skin:',
            options: [
                {
                    answer: 'My skin is easily irritated',
                    slug: 'how-sensitive-skin-easily-irritated',
                },
                {
                    answer: 'My skin is rarely irritated',
                    slug: 'how-sensitive-skin-rarely-irritated',
                },
                {
                    answer: "I'm not sure",
                    slug: 'how-sensitive-skin-not-sure',
                },
            ],
            mdiCaseQuestion: true,
        },
        {
            title: 'Are you currently using tretinoin or retin-A?',
            slug: 'currently-tretinoin',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'SingleChoice',
            headerTitle: 'Your Skin:',
            options: [
                {
                    answer: 'Yes',
                    slug: 'currently-tretinoin-yes',
                },
                {
                    answer: 'No',
                    slug: 'currently-tretinoin-no',
                },
            ],
            mdiCaseQuestion: true,
        },
        {
            title: 'What strength tretinoin or retin-A are you using?',
            slug: 'strength-tretinoin-using',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'SingleChoice',
            headerTitle: 'Your Skin:',
            options: [
                {
                    answer: '~0.025%',
                    slug: 'strength-tretinoin-using-0025',
                },
                {
                    answer: '~0.05%',
                    slug: 'strength-tretinoin-using-005',
                },
                {
                    answer: '~0.08%',
                    slug: 'strength-tretinoin-using-008',
                },
                {
                    answer: '~0.1%',
                    slug: 'strength-tretinoin-using-01',
                },
                {
                    answer: "I don't know",
                    slug: 'strength-tretinoin-using-i-dont-know',
                },
            ],
            orLogic: {
                'currently-tretinoin': ['currently-tretinoin-yes'],
            },
            mdiCaseQuestion: true,
        },
        {
            title: 'Do you have any of the following skin conditions?',
            slug: 'any-skin-conditions',
            description: 'Select all that apply:',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'MultipleChoice',
            headerTitle: 'Your Skin:',
            options: [
                {
                    answer: 'None',
                    slug: 'any-skin-conditions-none',
                },
                {
                    answer: 'Perioral dermatitis',
                    slug: 'any-skin-conditions-perioral-dermatitis',
                },
                {
                    answer: 'Eczema',
                    slug: 'any-skin-conditions-eczema',
                },
                {
                    answer: 'Rosacea',
                    slug: 'any-skin-conditions-rosacea',
                },
                {
                    answer: 'Highly sensitive skin',
                    slug: 'any-skin-conditions-highly-sensitive-skin',
                },
                {
                    answer: 'Skin cancer',
                    slug: 'any-skin-conditions-skin-cancer',
                },
                {
                    answer: 'Other',
                    slug: 'any-skin-conditions-other',
                },
            ],
            mdiCaseQuestion: true,
        },
        {
            title: 'Please list all other skin conditions.',
            slug: 'list-skin-conditions',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'MultipleLine',
            headerTitle: 'Your Skin:',
            placeholder: 'Type here',
            options: [],
            orLogic: {
                'any-skin-conditions': ['any-skin-conditions-other'],
            },
            mdiCaseQuestion: true,
        },
        {
            title: 'Do you have any allergies?',
            slug: 'any-allergies',
            description: 'This includes all allergies, intolerances, and reactions to foods or previous medications (e.g. peanut allergies)',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'SingleChoice',
            headerTitle: 'Your Health:',
            options: [
                {
                    answer: 'Yes',
                    slug: 'any-allergies-yes',
                },
                {
                    answer: 'No',
                    slug: 'any-allergies-no',
                },
            ],
            mdiCaseQuestion: true,
            priority: true,
        },
        {
            title: 'Please note any of the following allergies.',
            slug: 'allergies-that-apply',
            description: 'Select all that apply:',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'MultipleChoice',
            headerTitle: 'Your Health:',
            options: [
                {
                    answer: 'Tretinoin',
                    slug: 'allergies-that-apply-tretinoin',
                },
                {
                    answer: 'Niacinamide',
                    slug: 'allergies-that-apply-niacinamide',
                },
                {
                    answer: 'Vitamin E',
                    slug: 'allergies-that-apply-vitamin-e',
                },
                {
                    answer: 'Glycerin',
                    slug: 'allergies-that-apply-glycerin',
                },
                {
                    answer: 'Peptides',
                    slug: 'allergies-that-apply-peptides',
                },
                {
                    answer: 'Hyaluronic acid',
                    slug: 'allergies-that-apply-hyaluronic-acid',
                },
                {
                    answer: 'Other',
                    slug: 'allergies-that-apply-other',
                },
            ],
            orLogic: {
                'any-allergies': ['any-allergies-yes'],
            },
            mdiCaseQuestion: true,
        },
        {
            title: 'Please list all allergies or reactions to medications or adhesives.',
            slug: 'list-all-allergies-and-reactions',
            description: '',
            isRequired: false,
            isImportant: false,
            userKey: 'currentAllergies',
            type: 'Allergies',
            headerTitle: 'Your Health:',
            options: [],
            orLogic: {
                'allergies-that-apply': ['allergies-that-apply-other'],
            },
            mdiCaseQuestion: true,
            priority: true,
        },
        {
            title: 'Are you pregnant and/or breastfeeding?',
            slug: 'pregnant-andor-breastfeeding',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'SingleChoice',
            headerTitle: 'Your Health:',
            options: [
                {
                    answer: 'Yes',
                    slug: 'pregnant-andor-breastfeeding-yes',
                },
                {
                    answer: 'No',
                    slug: 'pregnant-andor-breastfeeding-no',
                },
            ],
            mdiCaseQuestion: true,
        },
        {
            title: 'Please list all current medications.',
            slug: 'list-all-other-medications',
            description: 'If you have no medications, continue to the next question.',
            isRequired: false,
            isImportant: false,
            userKey: 'currentMedications',
            type: 'Medications',
            headerTitle: 'Your Health:',
            options: [],
            mdiCaseQuestion: true,
        },
        {
            title: 'What is your date of birth?',
            slug: 'date-of-birth',
            description: '',
            conclusion: true,
            isRequired: true,
            isImportant: false,
            userKey: 'dob',
            type: 'Date',
            headerTitle: 'Your Health:',
            placeholder: 'MM/DD/YYYY',
            options: [],
            mdiCaseQuestion: true,
        },
        {
            title: 'Anything else you would like to share with the doctor?',
            slug: 'anything-else-share-doctor',
            description: '',
            conclusion: true,
            isRequired: false,
            isImportant: true,
            userKey: '',
            type: 'MultipleLine',
            headerTitle: 'Your Health:',
            placeholder: 'Type here',
            options: [],
            mdiCaseQuestion: true,
        },
    ],
};
