import { useState } from 'react';

import { useGetAllSubscriptionsForCustomer } from 'client/dist/generated/alloy';
import { NINETY_DAY_RECURRING_ID } from 'common/dist/products/productRegistry';

import { getSubscriptionsWithStatus } from 'modules/shared/lib/subscriptions/status';

import { useAppSelector } from 'shared/store/reducers';

import { someExpiredOrNoRefills } from '../../manage-subscription/lib/subscription-manage';

export default function useSubscriptionsState() {
  const customer = useAppSelector((state) => state.alloy.customer!);

  const [isLoadingUpdates, setIsLoadingUpdates] = useState(false);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [didBundle, setDidBundle] = useState(false);

  const {
    data: subscriptions = [],
    isLoading: isLoadingSubs,
    mutate,
  } = useGetAllSubscriptionsForCustomer();

  const { activeSubs } = getSubscriptionsWithStatus(subscriptions);

  const filteredSubs = activeSubs.filter((sub) =>
    sub.products.some((pfr) => pfr.product.frequencyId === NINETY_DAY_RECURRING_ID),
  );

  // only 90 day subs can be bundled at this moment
  const canBundleSubs =
    process.env.REACT_APP_SHOW_V3_SUBSCRIPTIONS_BUNDLING === 'true' &&
    filteredSubs.length > 1 &&
    !filteredSubs.some((subscription) => someExpiredOrNoRefills(subscription));

  const onBundled = async (bundled: boolean) => {
    setIsLoadingUpdates(true);

    await mutate();

    setDidBundle(bundled);
    setIsLoadingUpdates(false);
  };

  const isLoading = isLoadingUpdates || isLoadingSubs;

  return {
    isLoading,

    customer,
    subscriptions,
    filteredSubs,

    isDrawerOpen,
    setIsDrawerOpen,

    setIsLoadingUpdates,

    canBundleSubs,
    didBundle,

    onBundled,
  };
}
