"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Returns true if EVERYTHING is the same (but price).
 *
 * @param deepProduct
 * @param productId
 * @param frequencyId
 * @param dosageId
 * @param formFactorId
 * @param checkFrequency - boolean default true. Determines whether you want this function to check frequency for you or not
 */
function sameProductDosageFormFactorFrequency(deepProduct, productId, frequencyId, dosageId, formFactorId, checkFrequency = true) {
    return (deepProduct.productId === productId &&
        deepProduct.dosageId === dosageId &&
        deepProduct.formFactorId === formFactorId &&
        (checkFrequency ? deepProduct.frequencyId === frequencyId : true));
}
exports.default = sameProductDosageFormFactorFrequency;
