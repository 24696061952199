import React from 'react';

interface EmailUsModel {
  title?: string;
  btnClass?: string;
}

export const EmailUs: React.FC<EmailUsModel> = ({
  title = 'support@myalloy.com',
  btnClass = '',
}) => {
  return (
    <a className={btnClass} href='mailto:support@myalloy.com'>
      {title}
    </a>
  );
};
