import './AllergyMedication.scss';

import { isEmpty } from 'lodash';
import { Fragment } from 'react';

import { Question as AlloyQuestion } from 'common/dist/models/questionnaire';

import useMedicationsQuestionState from 'modules/questionnaire/hooks/useMedicationsQuestionState';

import { TextField } from 'shared/components/fields/TextField';

import plusIcon from './../../assets/svg/plus-icon.svg';

import QuestionLayoutWrapper from '../wrappers/QuestionLayout';

interface Props {
  question: AlloyQuestion;
  introduction?: JSX.Element;
}

export default function MedicationsQuestionBlock({ question, introduction }: Props) {
  const {
    medications,

    newMedication,
    setNewMedication,

    onUpdate,
    onOpenEdit,
    onEditMedication,
    onRemove,
    onCreate,
  } = useMedicationsQuestionState();

  return (
    <QuestionLayoutWrapper
      title={question.title}
      description={question.description}
      whyAskingDescription={question.whyAskingDescription}
      introduction={introduction}
    >
      <div className='allergy-medication-content-wrapper'>
        {medications.map(({ editId, medication, isEditing }) =>
          isEditing ? (
            <Fragment key={editId}>
              <div className='allergy-medication-edit-block'>
                <TextField
                  name={editId + '-name'}
                  label='Medication name'
                  value={medication.name}
                  placeholder='Enter here'
                  onChange={(name) => onEditMedication(editId, 'name', name)}
                  data-private='lipsum'
                  data-sentry-mask
                />

                <TextField
                  name={editId + '-dose'}
                  label='Dosage'
                  value={medication.dose}
                  placeholder='Enter if applicable'
                  onChange={(dose) => onEditMedication(editId, 'dose', dose)}
                  data-private='lipsum'
                  data-sentry-mask
                />

                <button
                  className='allergy-medication-link'
                  type='button'
                  onClick={() => onUpdate(editId)}
                  disabled={isEmpty(medication.name?.trim())}
                >
                  <span className='link-underline'>Save changes</span>
                </button>
              </div>

              <div className='allergy-medication-divider' />
            </Fragment>
          ) : (
            <Fragment key={editId}>
              <div className='allergy-medication-view-block'>
                <div className='view-content'>
                  <p className='view-text'>{medication.name}</p>

                  {medication.dose && <p className='view-text'>{medication.dose}</p>}
                </div>

                <div className='view-actions'>
                  <button
                    className='allergy-medication-link'
                    type='button'
                    onClick={() => onOpenEdit(editId)}
                  >
                    <span className='link-underline'>Edit</span>
                  </button>

                  <div className='actions-divider' />

                  <button
                    className='allergy-medication-link'
                    type='button'
                    onClick={() => onRemove(editId)}
                  >
                    <span className='link-underline'>Remove</span>
                  </button>
                </div>
              </div>

              <div className='allergy-medication-divider' />
            </Fragment>
          ),
        )}

        <div className='allergy-medication-edit-block'>
          <TextField
            name='new-medication-name'
            label='Medication name'
            value={newMedication.name}
            placeholder='Enter here'
            onChange={(medicationName) =>
              setNewMedication((prevMedication) => ({ ...prevMedication, name: medicationName }))
            }
            data-private='lipsum'
            data-sentry-mask
          />

          <TextField
            name='new-medication-dose'
            label='Dosage'
            value={newMedication.dose}
            placeholder='Enter if applicable'
            onChange={(medicationDose) =>
              setNewMedication((prevMedication) => ({ ...prevMedication, dose: medicationDose }))
            }
            data-private='lipsum'
            data-sentry-mask
          />

          <button
            type='button'
            className='allergy-medication-link mt-2'
            onClick={() => onCreate()}
            disabled={isEmpty(newMedication.name?.trim())}
          >
            <img src={plusIcon} alt='plus icon' />
            <span className='link-underline'>Add another medication</span>
          </button>
        </div>
      </div>
    </QuestionLayoutWrapper>
  );
}
