import { Dispatch } from '@reduxjs/toolkit';

import { Customer } from 'client/dist/generated/alloy';

import { CUSTOMER, LICENSE_EXISTS } from 'shared/store/actions/GlobalTypes';
import { RootState } from 'shared/store/reducers';

const updateCustomer = (updatedCustomer: Partial<Customer>) => {
  return (dispatch: Dispatch, getState: () => RootState) => {
    const customer = {
      ...getState().alloy.customer,
      ...updatedCustomer,
    };

    dispatch({ type: CUSTOMER, payload: customer });
  };
};

const updateLicenseExists = (exists: boolean) => {
  return (dispatch: Dispatch) => {
    dispatch({ type: LICENSE_EXISTS, payload: exists });
  };
};

export { updateCustomer, updateLicenseExists };
