import { ProductFrequencyRenewal } from 'client/dist/generated/alloy';
import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';
import areProductsEquivalent from 'common/dist/products/utils/areProductsEquivalent';

import { getDeepProductsFromGroupedProducts } from 'modules/shared/lib/product';

/**
 * Allows for customers to only go into Request Experience based on products
 * they can get (depending on if they have a prescription for specific product or not)
 *
 * @param products GroupedContentfulProduct[]
 * @param prescriptions ProductFrequencyRenewal[]
 * @returns DeepProduct[]
 */
export const filterRequestingProducts = (
  products: GroupedContentfulProduct[],
  prescriptions: ProductFrequencyRenewal[],
) => {
  const deepProducts = getDeepProductsFromGroupedProducts(products);

  return deepProducts.filter((dp) => {
    const hasPrescription = prescriptions.find((pfr) => areProductsEquivalent([pfr.product, dp]));

    return !hasPrescription;
  });
};
