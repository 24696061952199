import { first, xor } from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';

import useCart from 'modules/shared/hooks/useCart';

import {
  filterMhtProductsFrom,
  filterNonMhtProductsFrom,
  sortMhtProducts,
} from 'modules/dashboard/sub-modules/treatment-plan/lib/products';

import usePendingData from './usePendingData';

interface ReturnProps {
  products: GroupedContentfulProduct[][];
  type: 'APPROVAL' | 'RETENTION';

  isLoading: boolean;

  selectedProducts: GroupedContentfulProduct[];
  onSelectProduct: (
    product: GroupedContentfulProduct,
    prevSelected?: GroupedContentfulProduct[],
  ) => void;
  onSwitch: (prevSelected: GroupedContentfulProduct, product: GroupedContentfulProduct) => void;
  onContinueCheckout: () => void;
}

export default function usePendingState(): ReturnProps {
  const navigate = useNavigate();

  const { updateCart } = useCart();

  const [selectedProducts, setSelectedProducts] = useState<GroupedContentfulProduct[]>([]);

  const {
    customerApprovalProducts,
    customerRetentionProducts,
    isLoadingGroupedProducts,
    isLoading,
  } = usePendingData();

  const products = customerApprovalProducts.length
    ? customerApprovalProducts
    : customerRetentionProducts;

  useEffect(() => {
    if (isLoadingGroupedProducts) {
      return;
    }

    const mhtProducts = sortMhtProducts(filterMhtProductsFrom(products.flat()));
    const nonMhtProducts = filterNonMhtProductsFrom(products).flat();

    const potentialCustomerApproval =
      mhtProducts.length !== 0 ? [first(mhtProducts)!, ...nonMhtProducts] : nonMhtProducts;

    setSelectedProducts(potentialCustomerApproval);
  }, [products, isLoadingGroupedProducts]);

  /**
   * Selecting a product and attaching to our cart or removing!
   */
  const onSelectProduct = (
    product: GroupedContentfulProduct,
    prevSelected: GroupedContentfulProduct[] = selectedProducts,
  ) => {
    const selected = xor(prevSelected, [product]);

    updateCart({
      products: selected,
    });

    setSelectedProducts(selected);
  };

  const onSwitch = async (
    prevSelected: GroupedContentfulProduct,
    product: GroupedContentfulProduct,
  ) => {
    const switchSelected = selectedProducts.filter(
      (gcp) => gcp.contentfulProduct.sys.id !== prevSelected.contentfulProduct.sys.id,
    );

    onSelectProduct(product, switchSelected);
  };

  const onContinueCheckout = () => {
    updateCart({
      products: selectedProducts,
    });

    navigate('/treatment-plan/checkout');
  };

  return {
    products,
    type: customerApprovalProducts.length ? 'APPROVAL' : 'RETENTION',

    isLoading: isLoading || isLoadingGroupedProducts,

    selectedProducts,
    onSelectProduct,
    onSwitch,
    onContinueCheckout,
  };
}
