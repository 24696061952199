import './AllergyMedication.scss';

import { isEmpty } from 'lodash';
import { Fragment } from 'react';

import { Question as AlloyQuestion } from 'common/dist/models/questionnaire';

import useAllergiesQuestionState from 'modules/questionnaire/hooks/useAllergiesQuestionState';

import { TextAreaField } from 'shared/components/fields/TextAreaField';
import { TextField } from 'shared/components/fields/TextField';

import plusIcon from './../../assets/svg/plus-icon.svg';

import QuestionLayoutWrapper from '../wrappers/QuestionLayout';

interface Props {
  question: AlloyQuestion;
  introduction?: JSX.Element;
}

export default function AllergiesQuestionBlock({ question, introduction }: Props) {
  const {
    allergies,

    newAllergy,
    setNewAllergy,

    onUpdate,
    onOpenEdit,
    onEditAllergy,
    onRemove,
    onCreate,
  } = useAllergiesQuestionState();

  return (
    <QuestionLayoutWrapper
      title={question.title}
      description={question.description}
      whyAskingDescription={question.whyAskingDescription}
      introduction={introduction}
    >
      <div className='allergy-medication-content-wrapper'>
        {allergies.map(({ editId, allergy, isEditing }) =>
          isEditing ? (
            <Fragment key={editId}>
              <div className='allergy-medication-edit-block'>
                <TextField
                  name={editId + '-name'}
                  label='Allergy'
                  value={allergy.name}
                  placeholder='Enter here'
                  onChange={(name) => onEditAllergy(editId, 'name', name)}
                  data-private='lipsum'
                  data-sentry-mask
                />

                <TextField
                  name={editId + '-reaction'}
                  label='Reaction type'
                  value={allergy.reaction}
                  placeholder='Enter here'
                  onChange={(reaction) => onEditAllergy(editId, 'reaction', reaction)}
                  data-private='lipsum'
                  data-sentry-mask
                />

                <TextAreaField
                  name={editId + '-details'}
                  label='Add details'
                  value={allergy.details}
                  placeholder='Add details if applicable'
                  onChange={(details) => onEditAllergy(editId, 'details', details)}
                  data-private='lipsum'
                  data-sentry-mask
                />

                <button
                  className='allergy-medication-link'
                  type='button'
                  onClick={() => onUpdate(editId)}
                  disabled={isEmpty(allergy.name?.trim())}
                >
                  <span className='link-underline'>Save changes</span>
                </button>
              </div>

              <div className='allergy-medication-divider' />
            </Fragment>
          ) : (
            <Fragment key={editId}>
              <div className='allergy-medication-view-block'>
                <div className='view-content'>
                  <p className='view-text'>{allergy.name}</p>

                  {allergy.reaction && <p className='view-text'>{allergy.reaction}</p>}
                  {allergy.details && <p className='view-text text-italic'>{allergy.details}</p>}
                </div>

                <div className='view-actions'>
                  <button
                    className='allergy-medication-link'
                    type='button'
                    onClick={() => onOpenEdit(editId)}
                  >
                    <span className='link-underline'>Edit</span>
                  </button>

                  <div className='actions-divider' />

                  <button
                    className='allergy-medication-link'
                    type='button'
                    onClick={() => onRemove(editId)}
                  >
                    <span className='link-underline'>Remove</span>
                  </button>
                </div>
              </div>

              <div className='allergy-medication-divider' />
            </Fragment>
          ),
        )}

        <div className='allergy-medication-edit-block'>
          <TextField
            name='new-allergy-name'
            label='Allergy'
            value={newAllergy.name}
            placeholder='Enter here'
            onChange={(name) => setNewAllergy((prev) => ({ ...prev, name }))}
            data-private='lipsum'
            data-sentry-mask
          />

          <TextField
            name='new-allergy-reaction'
            label='Reaction type'
            value={newAllergy.reaction}
            placeholder='Enter here'
            onChange={(reaction) => setNewAllergy((prev) => ({ ...prev, reaction }))}
            data-private='lipsum'
            data-sentry-mask
          />

          <TextAreaField
            name='new-allergy-details'
            label='Add details'
            value={newAllergy.details}
            placeholder='Add details if applicable'
            onChange={(details) => setNewAllergy((prev) => ({ ...prev, details }))}
            data-private='lipsum'
            data-sentry-mask
          />

          <button
            type='button'
            className='allergy-medication-link mt-2'
            onClick={onCreate}
            disabled={isEmpty(newAllergy.name?.trim())}
          >
            <img src={plusIcon} alt='plus icon' />
            <span className='link-underline'>Add another allergy</span>
          </button>
        </div>
      </div>
    </QuestionLayoutWrapper>
  );
}
