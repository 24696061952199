import './HeaderInfoCard.scss';

import { format, startOfToday } from 'date-fns';

import { useSubscriptionContext } from 'modules/dashboard/sub-modules/manage-subscription/context/manage';

import { convertCentsToDollars } from 'shared/lib/convert';

import { someExpiredOrNoRefills } from '../../lib/subscription-manage';
import AddressContentBlock from '../content/Address';
import PaymentContentBlock from '../content/Payment';

interface Props {
  onReschedule: () => void;
  onShipNow: () => void;
}

export default function HeaderInfoCardBlock({ onReschedule, onShipNow }: Props) {
  const { subscription } = useSubscriptionContext();

  return (
    <>
      <div className='header-info-card-block'>
        <div className='header-content-wrapper'>
          <div className='content-block with-spacing'>
            <p className='content-header-title'>Next Delivery</p>

            {!someExpiredOrNoRefills(subscription) && (
              <div className='content-btns-block d-none d-md-flex'>
                <button className='secondary-border-button' onClick={onReschedule}>
                  Reschedule
                </button>

                <button className='primary-button' onClick={onShipNow}>
                  Ship now
                </button>
              </div>
            )}
          </div>

          <div className='content-block'>
            <p className='content-text'>
              <span className='text-bold'>Next Shipment</span>{' '}
              {format(subscription.nextRecurrenceOn, 'MM/dd/yyyy')}
            </p>

            {/* <p className='content-text'>
              <span className='text-bold'>Last Shipment</span>{' '}
              {format(subscription.nextRecurrenceOn, 'MM/dd/yyyy')}
            </p> */}

            <p className='content-text'>
              <span className='text-bold'>Total</span> $
              {convertCentsToDollars(subscription.upcomingInvoice?.total)}
            </p>
          </div>
        </div>

        <div className='info-content-wrapper'>
          <AddressContentBlock />
          <PaymentContentBlock />
        </div>

        <div className='content-btns-block d-grid d-md-none'>
          <button className='secondary-border-button' onClick={onReschedule}>
            Reschedule
          </button>

          <button className='primary-button' onClick={onShipNow}>
            Ship now
          </button>
        </div>
      </div>
    </>
  );
}
