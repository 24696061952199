import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * kodif is funky, you can't exactly edit any elements within the iframe
 * so we need to just edit the entire iframe in itself
 *
 * the idea below is if chat is closed, we place the icon higher
 * if the chat is open then we place at the original place.
 *
 * we need to also account for potential flicker on load that way the widget
 * doesn't show up at the bottom then go to higher level
 */
export default function useKodif() {
  const { pathname } = useLocation();

  useEffect(() => {
    const allowedPaths = ['-experience', 'symptomsurvey', 'doctor-survey', 'dsar', 'checkout'];

    const shouldMoveWidget = allowedPaths.some((substring) => pathname.includes(substring));

    const updateIframeClass = () => {
      const iframe = document.querySelector('iframe[src*="kodif"]') as HTMLElement;

      if (!iframe) {
        return;
      }

      // only on messages do we want to hide the widget completely
      if (pathname.includes('messages')) {
        iframe.classList.add('kodif-chat-widget');
        iframe.classList.add('kodif-chat-hidden');

        return;
      }

      iframe.classList.remove('kodif-chat-hidden');

      if (!shouldMoveWidget) {
        iframe.classList.remove('kodif-chat-open');
        iframe.classList.remove('kodif-chat-closed');

        setTimeout(() => {
          iframe.classList.add('kodif-chat-widget');
        }, 1000);

        return;
      } else {
        const iframeHeight = iframe.offsetHeight;

        const isHeight100Percent =
          iframe.style.height === '100%' || getComputedStyle(iframe).height === '100%';

        // adjust height of widget whether it is open or closed
        if (isHeight100Percent || iframeHeight >= 100) {
          iframe.classList.add('kodif-chat-open');
          iframe.classList.remove('kodif-chat-closed');
        } else {
          iframe.classList.add('kodif-chat-closed');
          iframe.classList.remove('kodif-chat-open');
        }

        // add with a slight delay to avoid flicker
        setTimeout(() => {
          iframe.classList.add('kodif-chat-widget');
        }, 1000);
      }
    };

    const observer = new MutationObserver(() => {
      const iframe = document.querySelector('iframe[src*="kodif"]') as HTMLElement;

      if (iframe) {
        updateIframeClass();

        // attach ResizeObserver for dynamic height changes
        const resizeObserver = new ResizeObserver(() => {
          updateIframeClass();
        });

        resizeObserver.observe(iframe);

        // stop observing for iframe insertion
        observer.disconnect();

        // cleanup ResizeObserver when component unmounts
        return () => {
          resizeObserver.disconnect();
        };
      }
    });

    observer.observe(document.body, { childList: true, subtree: true });

    // cleanup MutationObserver when component unmounts
    return () => {
      observer.disconnect();
    };
  }, [pathname]);
}
