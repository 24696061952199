import { AdaptedMdiMessage } from 'client/dist/generated/alloy';

export const getMessageName = (message: AdaptedMdiMessage) => {
  const isDoctor = message.userType?.toLowerCase().includes('clinician');
  const isSupport = message.userType?.toLowerCase().includes('support');
  const isSystem = message.userType?.toLowerCase().includes('system');

  const fullName = message.user?.fullName || '';

  const doctorFullName = 'Dr. ' + fullName;
  const supportFullName = 'Support | ' + fullName;
  const systemFullName = 'System';

  switch (true) {
    case isDoctor:
      return doctorFullName;
    case isSupport:
      return supportFullName;
    case isSystem:
      return systemFullName;
    default:
      return fullName;
  }
};
