"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MHT_QUESTIONNAIRE = void 0;
exports.MHT_QUESTIONNAIRE = {
    title: 'Your medical history:',
    questions: [
        {
            title: 'Does patient require av sync?',
            slug: 'patient-require-av-sync',
            description: '',
            isRequired: false,
            isImportant: true,
            userKey: '',
            type: 'Hidden',
            options: [],
            mdiCaseQuestion: true,
        },
        {
            title: 'Requesting additional products',
            slug: 'requesting-additional-products',
            description: '',
            isRequired: false,
            isImportant: true,
            userKey: '',
            type: 'Hidden',
            options: [],
            mdiCaseQuestion: true,
        },
        {
            title: "Let's discuss the menopausal symptoms bothering you most.",
            slug: 'discuss-menopausal-symptoms',
            description: 'Select all symptoms that you are experiencing:',
            isRequired: true,
            isImportant: false,
            userKey: '',
            headerTitle: 'Your symptoms:',
            type: 'MultipleChoice',
            options: [
                {
                    answer: 'Hot flashes',
                    slug: 'menopausal-symptoms-hot-flashes',
                },
                {
                    answer: 'Night sweats',
                    slug: 'menopausal-symptoms-night-sweats',
                },
                {
                    answer: 'Palpitations',
                    slug: 'menopausal-symptoms-palpitations',
                },
                {
                    answer: 'Joint pain',
                    slug: 'menopausal-symptoms-joint-pain',
                },
                {
                    answer: 'Weight gain',
                    slug: 'menopausal-symptoms-weight-gain',
                },
                {
                    answer: 'Fatigue',
                    slug: 'menopausal-symptoms-fatigue',
                },
                {
                    answer: 'Trouble with sleep',
                    slug: 'menopausal-symptoms-trouble-sleep',
                },
                {
                    answer: 'Brain fog (e.g. poor memory, difficulty concentrating)',
                    slug: 'menopausal-symptoms-brain-fog',
                },
                {
                    answer: 'Irritability',
                    slug: 'menopausal-symptoms-none-irritability',
                },
                {
                    answer: 'Depressed mood',
                    slug: 'menopausal-symptoms-depressed-mood',
                },
                {
                    answer: 'Anxiety',
                    slug: 'menopausal-symptoms-anxiety',
                },
                {
                    answer: 'Mood swings',
                    slug: 'menopausal-symptoms-mood-swings',
                },
                {
                    answer: 'Vaginal issues (e.g. burning, itching, vaginal dryness, decreased lubrication)',
                    slug: 'menopausal-symptoms-vaginal-issues',
                },
                {
                    answer: 'Urinary symptoms (e.g. urinary frequency, urgency, frequent UTIs, incontinence)',
                    slug: 'menopausal-symptoms-urinary-symptoms',
                },
                {
                    answer: 'Painful sex',
                    slug: 'menopausal-symptoms-painful-sex',
                },
                {
                    answer: 'Decreased sexual arousal',
                    slug: 'menopausal-symptoms-decreased-arousal',
                },
                {
                    answer: 'Facial acne',
                    slug: 'menopausal-symptoms-facial-acne',
                },
                {
                    answer: 'Other facial skin concerns (e.g. collagen production, moisture, firmness, pore size, wrinkles, elasticity)',
                    slug: 'menopausal-symptoms-facial-skin-concerns',
                },
                {
                    answer: 'None',
                    slug: 'menopausal-symptoms-none',
                },
            ],
            mdiCaseQuestion: true,
        },
        {
            title: 'Please share any additional details about your symptoms with the doctor.',
            slug: 'symptoms-elaborations',
            description: '',
            isRequired: false,
            isImportant: true,
            userKey: '',
            type: 'MultipleLine',
            placeholder: 'Type here',
            headerTitle: 'Your symptoms:',
            options: [],
            mdiCaseQuestion: true,
        },
        {
            title: 'When was your last menstrual period?',
            slug: 'what-date-last-period',
            description: 'Approximate month and year',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'SingleLine',
            headerTitle: 'Your menstrual cycle:',
            placeholder: 'Type here',
            options: [],
            whyAskingDescription: 'When you got your last period gives us information on which treatment might be best for you.',
            mdiCaseQuestion: true,
        },
        {
            title: 'Which of the following best describes your menstrual cycle in the past 12 months?',
            slug: 'describe-menstrual-cycle-past-year',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'SingleChoice',
            headerTitle: 'Your menstrual cycle:',
            options: [
                {
                    answer: 'My periods are regular',
                    slug: 'past-year-periods-regular',
                },
                {
                    answer: 'My periods are starting to become unpredictable (e.g., too close together, skipping periods or a change in duration or amount of flow)',
                    slug: 'past-year-periods-unpredictable',
                },
                {
                    answer: 'I have not had a period in over a year',
                    slug: 'past-year-no-period',
                },
                {
                    answer: 'My menstrual cycle has been impacted by a medical procedure',
                    slug: 'past-year-medical-procedure',
                },
            ],
            whyAskingDescription: "We don't mean to pry-- but knowing these details helps us determine what stage of menopause (or perimenopause) you're in.",
            mdiCaseQuestion: true,
        },
        {
            title: 'Which of the following describes how your periods are becoming more unpredictable?',
            slug: 'period-unpredictable',
            description: 'Check all that apply:',
            isRequired: true,
            isImportant: true,
            userKey: '',
            type: 'MultipleChoice',
            headerTitle: 'Your menstrual cycle:',
            options: [
                {
                    slug: 'period-unpredictable-bleeding-21-days',
                    answer: 'Bleeding is more frequent than every 21 days',
                },
                {
                    slug: 'period-unpredictable-bleeding-8-days',
                    answer: 'Bleeding last longer than 8 days',
                },
                {
                    slug: 'period-unpredictable-periods-heavier',
                    answer: 'Periods are now heavier',
                },
                {
                    slug: 'period-unpredictable-tampon-1-2-hours',
                    answer: 'I bleed through a tampon or pad in 1-2 hours',
                },
                {
                    slug: 'period-unpredictable-bleeding-less-frequent',
                    answer: 'Bleeding is less frequent and/or I skip more than 2 months at a time',
                },
                {
                    slug: 'period-unpredictable-bleeding-lighter',
                    answer: 'Bleeding is much lighter',
                },
                {
                    slug: 'period-unpredictable-other',
                    answer: 'Other',
                },
            ],
            orLogic: {
                'describe-menstrual-cycle-past-year': ['past-year-periods-unpredictable'],
            },
            mdiCaseQuestion: true,
        },
        {
            title: 'Please describe how your periods are becoming more unpredictable.',
            slug: 'describe-how-periods-becoming-unpredictable',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'MultipleLine',
            headerTitle: 'Your menstrual cycle:',
            placeholder: 'Type here',
            options: [],
            orLogic: {
                'period-unpredictable': ['period-unpredictable-other'],
            },
            mdiCaseQuestion: true,
        },
        {
            title: 'Have you had a recent GYN evaluation?',
            slug: 'how-recent-gyn-evaluation',
            description: 'Check all that apply:',
            isRequired: true,
            isImportant: true,
            userKey: '',
            type: 'MultipleChoice',
            headerTitle: 'Your menstrual cycle:',
            options: [
                {
                    slug: 'how-recent-gyn-evaluation-regular',
                    answer: 'I have had a regular GYN evaluation in past 12 months',
                },
                {
                    slug: 'how-recent-gyn-evaluation-transvaginal-ultrasound',
                    answer: 'I have had a pelvic or transvaginal ultrasound',
                },
                {
                    slug: 'how-recent-gyn-evaluation-endometrial-biopsy',
                    answer: 'I have had an endometrial biopsy',
                },
                {
                    slug: 'how-recent-gyn-evaluation-d-c',
                    answer: 'I have had a D&C',
                },
                {
                    slug: 'how-recent-gyn-evaluation-hysteroscopy',
                    answer: 'I have had a hysteroscopy',
                },
                {
                    slug: 'how-recent-gyn-evaluation-blood-work-done',
                    answer: 'I have had blood work done',
                },
                {
                    slug: 'how-recent-gyn-evaluation-other',
                    answer: 'Other - please explain',
                },
            ],
            orLogic: {
                'period-unpredictable': [
                    'period-unpredictable-bleeding-21-days',
                    'period-unpredictable-bleeding-8-days',
                    'period-unpredictable-periods-heavier',
                    'period-unpredictable-tampon-1-2-hours',
                ],
            },
            mdiCaseQuestion: true,
        },
        {
            title: 'Please tell us more.',
            slug: 'please-tell-us-more',
            description: 'Please provide details, or in your own words, a summary of relevant results.',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'MultipleLine',
            headerTitle: 'Your menstrual cycle:',
            placeholder: 'Type here',
            options: [],
            orLogic: {
                'how-recent-gyn-evaluation': [
                    'how-recent-gyn-evaluation-transvaginal-ultrasound',
                    'how-recent-gyn-evaluation-endometrial-biopsy',
                    'how-recent-gyn-evaluation-d-c',
                    'how-recent-gyn-evaluation-hysteroscopy',
                    'how-recent-gyn-evaluation-blood-work-done',
                    'how-recent-gyn-evaluation-other',
                ],
            },
            mdiCaseQuestion: true,
        },
        {
            title: 'Has it been 10 years since your last period?',
            slug: '10-years-since-last-period',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'SingleChoice',
            headerTitle: 'Your menstrual cycle:',
            options: [
                {
                    slug: '10-years-yes',
                    answer: 'Yes',
                },
                {
                    slug: '10-years-no',
                    answer: 'No',
                },
            ],
            orLogic: {
                'describe-menstrual-cycle-past-year': ['past-year-no-period'],
            },
            whyAskingDescription: "We just want to know where you're at! How long you have been in menopause and which treatments, if any, you have been using, help to determine the right treatment for you today. According to The Menopause Society guidelines, for women over 60 or women who have not had a period in over 10 years and have not started MHT, the patch should be recommended over the pill.",
            mdiCaseQuestion: true,
        },
        {
            title: 'Which of the following medical procedures has impacted your menstrual cycle?',
            slug: 'medical-procedures-impacted-menstrual-cycle',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'SingleChoice',
            headerTitle: 'Your menstrual cycle:',
            options: [
                {
                    answer: 'Bilateral oophorectomy',
                    slug: 'medical-procedures-bilateral-oophorectomy',
                },
                {
                    answer: 'Endometrial ablation',
                    slug: 'medical-procedures-endometrial-ablation',
                },
                {
                    answer: 'Uterine artery embolization (UAE)',
                    slug: 'medical-procedures-uterine-embolization',
                },
                {
                    answer: 'Hormonal IUD',
                    slug: 'medical-procedures-hormonal-iud',
                },
                {
                    answer: 'Hysterectomy (surgical removal of the uterus)',
                    slug: 'medical-procedures-hysterectomy',
                },
                {
                    answer: 'Other',
                    slug: 'medical-procedures-other',
                },
            ],
            orLogic: {
                'describe-menstrual-cycle-past-year': ['past-year-medical-procedure'],
            },
            mdiCaseQuestion: true,
        },
        {
            title: 'How many periods have you had in the past 12 months?',
            slug: 'number-of-periods-past-year',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'SingleChoice',
            headerTitle: 'Your menstrual cycle:',
            options: [
                {
                    slug: 'periods-past-year-0-2',
                    answer: '0-2',
                },
                {
                    answer: '3-5',
                    slug: 'periods-past-year-3-5',
                },
                {
                    answer: '6-12+',
                    slug: 'periods-past-year-6-12+',
                },
            ],
            orLogic: {
                'describe-menstrual-cycle-past-year': [
                    'past-year-periods-regular',
                    'past-year-periods-unpredictable',
                ],
                'medical-procedures-impacted-menstrual-cycle': [
                    'medical-procedures-endometrial-ablation',
                    'medical-procedures-hormonal-iud',
                ],
            },
            mdiCaseQuestion: true,
        },
        {
            title: 'At what age did you have a bilateral oophorectomy (removal of both ovaries)?',
            slug: 'age-bilateral-oophorectomy',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'MultipleLine',
            headerTitle: 'Your menstrual cycle:',
            placeholder: 'Type here',
            options: [],
            orLogic: {
                'medical-procedures-impacted-menstrual-cycle': [
                    'medical-procedures-bilateral-oophorectomy',
                ],
            },
            mdiCaseQuestion: true,
        },
        {
            title: 'Why did you have a bilateral oophorectomy (removal of both ovaries)?',
            slug: 'why-bilateral-oophorectomy',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'MultipleLine',
            headerTitle: 'Your menstrual cycle:',
            placeholder: 'Type here',
            options: [],
            orLogic: {
                'medical-procedures-impacted-menstrual-cycle': [
                    'medical-procedures-bilateral-oophorectomy',
                ],
            },
            mdiCaseQuestion: true,
        },
        {
            title: 'What other medical procedure has impacted your menstrual cycle?',
            slug: 'other-medical-procedure-impacted-menstrual-cycle',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'MultipleLine',
            headerTitle: 'Your menstrual cycle:',
            placeholder: 'Type here',
            options: [],
            orLogic: {
                'medical-procedures-impacted-menstrual-cycle': ['medical-procedures-other'],
            },
            mdiCaseQuestion: true,
        },
        {
            title: 'Please indicate if you have or have had any of the following conditions.',
            slug: 'indicate-if-had-conditions',
            description: 'In order for your doctor to make the right treatment recommendation, we need to know a few things about your medical history.',
            isRequired: true,
            isImportant: true,
            userKey: '',
            type: 'MultipleChoice',
            headerTitle: 'Your medical history:',
            options: [
                {
                    slug: 'indicate-if-had-conditions-none',
                    answer: 'None',
                },
                {
                    slug: 'indicate-if-had-conditions-post-meno-bleeding',
                    answer: "Unexplained episodes of postmenopausal bleeding (if you've had no period in over a year)",
                },
                {
                    slug: 'indicate-if-had-conditions-breast-cancer',
                    answer: 'Personal history of breast cancer',
                },
                {
                    slug: 'indicate-if-had-conditions-endometrial-cancer',
                    answer: 'Endometrial cancer (uterine cancer)',
                },
                {
                    slug: 'indicate-if-had-conditions-high-blood-pressure',
                    answer: 'High blood pressure',
                },
                {
                    slug: 'indicate-if-had-conditions-elevated-cholesterol',
                    answer: 'Elevated cholesterol',
                },
                {
                    slug: 'indicate-if-had-conditions-heart-attacks',
                    answer: 'Heart attack',
                },
                {
                    slug: 'indicate-if-had-conditions-strokes',
                    answer: 'Stroke',
                },
                {
                    slug: 'indicate-if-had-conditions-blood-clots-legs-lungs',
                    answer: 'Blood clots in legs or lungs',
                },
                {
                    slug: 'indicate-if-had-conditions-clotting-risk',
                    answer: 'Genetic condition that increases your risk for clotting',
                },
                {
                    slug: 'indicate-if-had-conditions-positive-brca',
                    answer: 'Positive BRCA 1 / 2 test',
                },
                {
                    slug: 'indicate-if-had-conditions-diabetes-injectable-insulin',
                    answer: 'Diabetes and taking injectable insulin (not oral)',
                },
                {
                    slug: 'indicate-if-had-conditions-diabetes-not-injectable-insulin',
                    answer: 'Diabetes and NOT taking injectable insulin (incl. taking oral insulin)',
                },
                {
                    slug: 'indicate-if-had-conditions-migraines',
                    answer: 'Migraines',
                },
                {
                    slug: 'indicate-if-had-conditions-liver-disease',
                    answer: 'Liver disease',
                },
                {
                    slug: 'indicate-if-had-conditions-lupus',
                    answer: 'Lupus',
                },
                {
                    slug: 'indicate-if-had-conditions-lichen-sclerosis',
                    answer: 'Lichen Sclerosis or other vulvar skin condition',
                },
                {
                    slug: 'indicate-if-had-conditions-endometriosis',
                    answer: 'Endometriosis',
                },
                {
                    slug: 'indicate-if-had-conditions-other',
                    answer: 'Other',
                },
            ],
            whyAskingDescription: 'The information is helpful for your doctor to create the best treatment plan for you.',
            mdiCaseQuestion: true,
        },
        {
            title: 'Have you had an evaluation for the unexplained episodes of post-menopausal bleeding?',
            slug: 'eval-post-men-bleeding',
            description: '',
            isRequired: true,
            isImportant: true,
            userKey: '',
            type: 'SingleChoice',
            headerTitle: 'Your health history:',
            options: [
                {
                    slug: 'eval-post-men-bleeding-yes',
                    answer: 'Yes',
                },
                {
                    slug: 'eval-post-men-bleeding-no',
                    answer: 'No',
                },
            ],
            orLogic: {
                'indicate-if-had-conditions': ['indicate-if-had-conditions-post-meno-bleeding'],
            },
            mdiCaseQuestion: true,
        },
        {
            title: 'Please select the type of evaluation.',
            slug: 'type-eval-post-men-bleeding',
            description: 'Check all that apply:',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'MultipleChoice',
            headerTitle: 'Your health history:',
            placeholder: 'Type here',
            options: [
                {
                    slug: 'type-eval-post-men-bleeding-pelvic-exam',
                    answer: 'Pelvic exam',
                },
                {
                    slug: 'type-eval-post-men-bleeding-pelvic-transvaginal',
                    answer: 'Pelvic or transvaginal ultrasound',
                },
                {
                    slug: 'type-eval-post-men-bleeding-endometrial-biopsy',
                    answer: 'Endometrial biopsy',
                },
                {
                    slug: 'type-eval-post-men-bleeding-hysteroscopy',
                    answer: 'Hysteroscopy',
                },
                {
                    slug: 'type-eval-post-men-bleeding-d-c',
                    answer: 'D&C',
                },
                {
                    slug: 'type-eval-post-men-bleeding-other',
                    answer: 'Other',
                },
            ],
            orLogic: {
                'eval-post-men-bleeding': ['eval-post-men-bleeding-yes'],
            },
            mdiCaseQuestion: true,
        },
        {
            title: 'Please provide more detail.',
            slug: 'detail-eval-post-men-bleeding',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'MultipleLine',
            headerTitle: 'Your health history:',
            placeholder: 'Type here',
            options: [],
            orLogic: {
                'eval-post-men-bleeding': ['eval-post-men-bleeding-yes'],
            },
            mdiCaseQuestion: true,
        },
        {
            title: 'You have options!',
            slug: 'breast-cancer-treatment-awareness',
            description: '**While systemic hormone therapy is generally not prescribed to patients with a history of invasive breast cancer, there are other treatment options available to manage your menopause symptoms.\\***  \n\n**All doctors who prescribe for Alloy are menopause-trained and follow the guidelines provided by ACOG and The Menopause Society.**  \n\nYour consultation will provide:  \n * Access to a specialist in menopause care  \n * Information on suitable treatment options  \n * Resources to help manage your symptoms  \n\nMany breast cancer patients are candidates for vaginal estrogen and non-hormonal treatments. A consultation can help you explore these options and help you find an approach for your individual needs.  \n\n ###### *Please note that if you have a BRCA gene mutation but have not had breast cancer, you are still a candidate for hormone therapy! For additional information, check out our [Breast Cancer FAQ](https://www.myalloy.com/breast-cancer-faqs).',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'MarkdownCheckbox',
            options: [
                {
                    slug: 'breast-cancer-treatment-awareness-agree',
                    answer: 'I have read and understand',
                },
            ],
            orLogic: {
                'indicate-if-had-conditions': ['indicate-if-had-conditions-breast-cancer'],
            },
            mdiCaseQuestion: true,
        },
        {
            title: 'Please provide the year of your breast cancer diagnosis, stage of breast cancer, estrogen receptor status.',
            slug: 'provide-year-breast-cancer-diagnosis',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'MultipleLine',
            headerTitle: 'Your health history:',
            placeholder: 'Type here',
            options: [],
            orLogic: {
                'indicate-if-had-conditions': ['indicate-if-had-conditions-breast-cancer'],
            },
            mdiCaseQuestion: true,
        },
        {
            title: 'What stage of endometrial cancer?',
            slug: 'stage-endometrial-cancer',
            description: '',
            isRequired: true,
            isImportant: true,
            userKey: '',
            type: 'SingleChoice',
            headerTitle: 'Your health history:',
            options: [
                {
                    answer: 'Stage 1 or 2',
                    slug: 'stage-endometrial-cancer-1-or-2',
                },
                {
                    answer: 'Stage 3 or 4',
                    slug: 'stage-endometrial-cancer-3-or-4',
                },
            ],
            orLogic: {
                'indicate-if-had-conditions': ['indicate-if-had-conditions-endometrial-cancer'],
            },
            whyAskingDescription: "If you've been diagnosed with stage 1 or 2 endometrial cancer, you are still a candidate for vaginal estradiol cream; if your cancer was stage 3 or 4, your doctor will review your case and decide if vaginal estrogen is appropriate for you.",
            mdiCaseQuestion: true,
        },
        {
            title: 'Is your blood pressure well-controlled? Well-controlled is defined as 130/80 or less.',
            slug: 'blood-pressure-well-controlled',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'MultipleLine',
            headerTitle: 'Your medical history:',
            placeholder: 'Type here',
            options: [],
            orLogic: {
                'indicate-if-had-conditions': ['indicate-if-had-conditions-high-blood-pressure'],
            },
            mdiCaseQuestion: true,
        },
        {
            title: 'What year did the blood clot occur? What happened? Provide as much detail as possible.',
            slug: 'year-blood-clot-occur-what-happened',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'MultipleLine',
            headerTitle: 'Your medical history:',
            placeholder: 'Type here',
            options: [],
            orLogic: {
                'indicate-if-had-conditions': ['indicate-if-had-conditions-blood-clots-legs-lungs'],
            },
            mdiCaseQuestion: true,
        },
        {
            title: 'What is your genetic condition that increases your risk for clotting?',
            slug: 'genetic-condition-increases-risk-clotting',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'SingleChoice',
            headerTitle: 'Your medical history:',
            options: [
                {
                    answer: 'Factor V Leiden',
                    slug: 'clotting-risk-factor-v-leiden',
                },
                {
                    answer: 'Prothrombin',
                    slug: 'clotting-risk-prothrombin',
                },
                {
                    answer: 'Protein C or S deficiency',
                    slug: 'clotting-risk-c-s-deficiency',
                },
                {
                    answer: 'Other',
                    slug: 'clotting-risk-other',
                },
            ],
            orLogic: {
                'indicate-if-had-conditions': ['indicate-if-had-conditions-clotting-risk'],
            },
            mdiCaseQuestion: true,
        },
        {
            title: 'Have you had an oophorectomy and/or bilateral mastectomy?',
            slug: 'oophorectomy-andor-bilateral-mastectomy',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'SingleChoice',
            headerTitle: 'Your medical history:',
            options: [
                {
                    answer: 'I have had an oophorectomy',
                    slug: 'oophorectomy-andor-bilateral-mastectomy-have-had-oophorectomy',
                },
                {
                    answer: 'I have had a bilateral mastectomy',
                    slug: 'oophorectomy-andor-bilateral-mastectomy-have-had-mastectomy',
                },
                {
                    answer: 'I have had both an oophorectomy and a bilateral mastectomy',
                    slug: 'oophorectomy-andor-bilateral-mastectomy-have-had-both',
                },
                {
                    answer: 'I have had neither an oophorectomy or a bilateral mastectomy',
                    slug: 'oophorectomy-andor-bilateral-mastectomy-have-had-neither',
                },
            ],
            orLogic: {
                'indicate-if-had-conditions': ['indicate-if-had-conditions-positive-brca'],
            },
            whyAskingDescription: 'We are asking for this information as it is relevant to your health and medical history, though being BRCA 1/2 positive is NOT a disqualification for Menopausal Hormone Treatment.',
            mdiCaseQuestion: true,
        },
        {
            title: 'At what age did you have an oophorectomy?',
            slug: 'age-oophorectomy',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'MultipleLine',
            headerTitle: 'Your medical history:',
            placeholder: 'Type here',
            options: [],
            orLogic: {
                'oophorectomy-andor-bilateral-mastectomy': [
                    'oophorectomy-andor-bilateral-mastectomy-have-had-oophorectomy',
                    'oophorectomy-andor-bilateral-mastectomy-have-had-both',
                ],
            },
            mdiCaseQuestion: true,
        },
        {
            title: 'Have you been diagnosed with migraines with aura?',
            slug: 'diagnosed-migraines-with-aura',
            description: '(Also called classic migraines, they are recurring headaches that strike after or at the same time as sensory disturbances like flashes of light, blind spots, and other vision changes or tingling in your hand or face.)',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'SingleChoice',
            headerTitle: 'Your medical history:',
            options: [
                {
                    slug: 'diagnosed-migraines-yes',
                    answer: 'Yes',
                },
                {
                    slug: 'diagnosed-migraines-no',
                    answer: 'No',
                },
            ],
            orLogic: {
                'indicate-if-had-conditions': ['indicate-if-had-conditions-migraines'],
            },
            mdiCaseQuestion: true,
        },
        {
            title: 'Please provide more details on your liver disease, including when you were diagnosed.',
            slug: 'provide-details-liver-disease',
            description: 'Provide as much detail as possible.',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'MultipleLine',
            headerTitle: 'Your medical history:',
            placeholder: 'Type here',
            options: [],
            orLogic: {
                'indicate-if-had-conditions': ['indicate-if-had-conditions-liver-disease'],
            },
            mdiCaseQuestion: true,
        },
        {
            title: 'Please provide the stage of your endometriosis if you know it.',
            slug: 'provide-endometriosis-stage',
            description: 'Did you have extensive disease (e.g. endometriosis implants on the bowel, bladder, etc.)?',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'MultipleLine',
            headerTitle: 'Your medical history:',
            placeholder: 'Type here',
            options: [],
            orLogic: {
                'indicate-if-had-conditions': ['indicate-if-had-conditions-endometriosis'],
            },
            mdiCaseQuestion: true,
        },
        {
            title: 'What other conditions have you been currently or previously diagnosed with that are not listed above?',
            slug: 'other-conditions-diagnosed-ie-another-cancer',
            description: '(e.g. another cancer). Provide as much detail as possible.',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'MultipleLine',
            headerTitle: 'Your medical history:',
            placeholder: 'Type here',
            options: [],
            orLogic: {
                'indicate-if-had-conditions': ['indicate-if-had-conditions-other'],
            },
            whyAskingDescription: "Let us help you! In order to give you the best medical advice, it's important to know if there is anything important that might affect the recommended course of treatment.",
            mdiCaseQuestion: true,
        },
        {
            title: 'Have you had a hysterectomy (surgical removal of the uterus)?',
            slug: 'hysterectomy',
            description: '',
            isRequired: true,
            isImportant: true,
            userKey: '',
            type: 'SingleChoice',
            headerTitle: 'Your medical history:',
            options: [
                {
                    answer: 'Yes',
                    slug: 'hysterectomy-yes',
                },
                {
                    answer: 'No',
                    slug: 'hysterectomy-no',
                },
            ],
            whyAskingDescription: "We're all a little different! Your recommended menopausal treatment will vary depending on whether or not you have a uterus.",
            mdiCaseQuestion: true,
        },
        {
            title: 'At what age did you have a hysterectomy?',
            slug: 'age-hysterectomy',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'MultipleLine',
            headerTitle: 'Your medical history:',
            placeholder: 'Type here',
            options: [],
            orLogic: {
                hysterectomy: ['hysterectomy-yes'],
            },
            mdiCaseQuestion: true,
        },
        {
            title: 'Why did you have a hysterectomy?',
            slug: 'why-hysterectomy',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'MultipleLine',
            headerTitle: 'Your medical history:',
            placeholder: 'Type here',
            options: [],
            orLogic: {
                hysterectomy: ['hysterectomy-yes'],
            },
            mdiCaseQuestion: true,
        },
        {
            title: 'Did you also have your ovaries removed with your uterus?',
            slug: 'ovaries-removed-hysterectomy',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'SingleChoice',
            headerTitle: 'Your medical history:',
            options: [
                {
                    answer: 'Yes',
                    slug: 'ovaries-removed-hysterectomy-yes',
                },
                {
                    answer: 'No',
                    slug: 'ovaries-removed-hysterectomy-no',
                },
            ],
            orLogic: {
                hysterectomy: ['hysterectomy-yes'],
            },
            mdiCaseQuestion: true,
        },
        {
            title: 'When was your last mammogram?',
            slug: 'last-mammogram',
            description: "It's all a part of good self care! We believe it is important to have a baseline view of your breast tissue prior to starting treatment for menopausal symptoms.",
            isRequired: true,
            isImportant: true,
            userKey: '',
            type: 'SingleChoice',
            headerTitle: "Let's talk about mammograms:",
            options: [
                {
                    answer: 'Within the last two years',
                    slug: 'last-mammogram-within-two-years',
                },
                {
                    answer: "It's been more than two years",
                    slug: 'last-mammogram-more-than-two-years',
                },
                {
                    answer: 'I have never had one',
                    slug: 'last-mammogram-never-had-one',
                },
                {
                    answer: 'I have had a double mastectomy',
                    slug: 'last-mammogram-double-mastectomy',
                },
            ],
            whyAskingDescription: "We recommend annual mammograms for women over 40. At Alloy, we require that women have a mammogram or breast MRI at least every two years in order for us to prescribe systemic menopausal hormone treatment (MHT). If you're overdue, don't worry. If you're otherwise a good candidate for MHT, we can get you started with a 3-month supply, though we will require you to get a mammogram to continue after that. Ultrasound alone or thermography are not an appropriate breast screening modality and are not a replacement for mammograms.",
            mdiCaseQuestion: true,
        },
        {
            title: 'What were the results of your last mammogram?',
            slug: 'results-last-mammogram',
            description: '',
            isRequired: true,
            isImportant: true,
            userKey: '',
            type: 'SingleChoice',
            headerTitle: "Let's talk about mammograms:",
            options: [
                {
                    answer: 'Normal',
                    slug: 'results-last-mammogram-normal',
                },
                {
                    answer: 'I had another visit for additional views, but was told everything was normal',
                    slug: 'results-last-mammogram-additional-then-normal',
                },
                {
                    answer: 'Abnormal',
                    slug: 'results-last-mammogram-abnormal',
                },
            ],
            orLogic: {
                'last-mammogram': ['last-mammogram-within-two-years'],
            },
            whyAskingDescription: 'We want to make sure the girls are in good shape before recommending treatment!',
            mdiCaseQuestion: true,
        },
        {
            title: 'Please provide any details on the result.',
            slug: 'provide-details-on-mammogram-result',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'MultipleLine',
            headerTitle: "Let's talk about mammograms:",
            placeholder: 'Type here',
            options: [],
            orLogic: {
                'results-last-mammogram': ['results-last-mammogram-abnormal'],
            },
            whyAskingDescription: "Safety matters! Let's make sure that you have no contraindications for any potential menopausal treatments!",
            mdiCaseQuestion: true,
        },
        {
            title: 'Do you currently smoke or vape products with nicotine?',
            slug: 'smoke-cigarettes',
            description: '',
            isRequired: true,
            isImportant: true,
            userKey: '',
            type: 'SingleChoice',
            headerTitle: 'Nicotine use:',
            options: [
                {
                    answer: 'Yes',
                    slug: 'smoke-cigarettes-yes',
                },
                {
                    answer: 'No',
                    slug: 'smoke-cigarettes-no',
                },
            ],
            whyAskingDescription: 'No judgments here, but safety first! Smoking or vaping nicotine products can increase your risk of blood clots and cardiovascular issues.',
            mdiCaseQuestion: true,
        },
        {
            title: 'Are you currently using any birth control products?',
            slug: 'using-birth-control-products',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'SingleChoice',
            headerTitle: 'Medications and allergies:',
            options: [
                {
                    answer: 'Yes',
                    slug: 'using-birth-control-products-yes',
                },
                {
                    answer: 'No',
                    slug: 'using-birth-control-products-no',
                },
            ],
            whyAskingDescription: 'It is important for the doctor to understand other medications you are currently using to provide overall care, but you can take vaginal estradiol cream with birth control!',
            mdiCaseQuestion: true,
        },
        {
            title: 'Which birth control product are you currently using?',
            slug: 'which-birth-control-products',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'MultipleChoice',
            headerTitle: 'Medications and allergies:',
            options: [
                {
                    answer: 'Birth control pill',
                    slug: 'which-birth-control-products-pill',
                },
                {
                    answer: 'Birth control patch',
                    slug: 'which-birth-control-products-patch',
                },
                {
                    answer: 'Birth control vaginal ring (Nuvaring or Annovera)',
                    slug: 'which-birth-control-products-ring',
                },
                {
                    answer: 'Nexplanon contraceptive arm implant',
                    slug: 'which-birth-control-products-arm-impant',
                },
                {
                    answer: 'Hormonal IUD',
                    slug: 'which-birth-control-products-hormonal-iud',
                },
                {
                    answer: 'Non-hormonal IUD (Copper T or Paragard)',
                    slug: 'which-birth-control-products-non-hormonal-iud',
                },
                {
                    answer: 'I have had sterilization (tubal ligation)',
                    slug: 'which-birth-control-products-tubal-ligation',
                },
                {
                    answer: 'My partner has a vasectomy',
                    slug: 'which-birth-control-products-vasectomy',
                },
                {
                    answer: 'Condoms',
                    slug: 'which-birth-control-products-condoms',
                },
                {
                    answer: 'Phexxi',
                    slug: 'which-birth-control-products-phexxi',
                },
                {
                    answer: 'Other',
                    slug: 'which-birth-control-products-other',
                },
            ],
            orLogic: {
                'using-birth-control-products': ['using-birth-control-products-yes'],
            },
            mdiCaseQuestion: true,
        },
        {
            title: 'Which hormonal IUD are you currently using?',
            slug: 'which-hormonal-iud-using',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'SingleChoice',
            headerTitle: 'Medications and allergies:',
            options: [
                {
                    answer: 'Skyla',
                    slug: 'which-hormonal-iud-syla',
                },
                {
                    answer: 'Mirena',
                    slug: 'which-hormonal-iud-mirena',
                },
                {
                    answer: 'Kyleena',
                    slug: 'which-hormonal-iud-kyleena',
                },
                {
                    answer: 'Lilletta',
                    slug: 'which-hormonal-iud-lilletta',
                },
                {
                    answer: 'Other',
                    slug: 'which-hormonal-iud-other',
                },
            ],
            orLogic: {
                'which-birth-control-products': ['which-birth-control-products-hormonal-iud'],
            },
            mdiCaseQuestion: true,
        },
        {
            title: 'Which other hormonal IUD are you currently using?',
            slug: 'other-hormonal-iud-using',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'MultipleLine',
            headerTitle: 'Medications and allergies:',
            placeholder: 'Type here',
            options: [],
            orLogic: {
                'which-hormonal-iud-using': ['which-hormonal-iud-other'],
            },
            mdiCaseQuestion: true,
        },
        {
            title: 'When was your last hormonal IUD inserted?',
            slug: 'hormonal-iud-inserted',
            description: 'Please provide the month and year.',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'SingleLine',
            headerTitle: 'Medications and allergies:',
            placeholder: 'Type here',
            options: [],
            orLogic: {
                'which-birth-control-products': ['which-birth-control-products-hormonal-iud'],
            },
            mdiCaseQuestion: true,
        },
        {
            title: 'Do you require contraception?',
            slug: 'require-contraception',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'SingleChoice',
            headerTitle: 'Medications and allergies:',
            options: [
                {
                    answer: 'Yes',
                    slug: 'require-contraception-yes',
                },
                {
                    answer: 'No',
                    slug: 'require-contraception-no',
                },
            ],
            mdiCaseQuestion: true,
        },
        {
            title: 'Have you ever taken any menopausal hormone treatment?',
            slug: 'taking-or-stopped-taking-menopausal-hormone-treatment',
            description: '(e.g. birth control pills, compounded hormones or creams, sprays, gels, or hormonal pellets)',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'SingleChoice',
            headerTitle: 'Medications and allergies:',
            options: [
                {
                    answer: 'Yes, I am currently taking hormones',
                    slug: 'taking-or-stopped-taking-mht-yes',
                },
                {
                    answer: 'I recently stopped taking hormones (within the last 90 days)',
                    slug: 'taking-or-stopped-taking-mht-recently-stopped',
                },
                {
                    answer: 'I have taken hormones in the past',
                    slug: 'taking-or-stopped-taking-mht-taken-past',
                },
                {
                    answer: 'No',
                    slug: 'taking-or-stopped-taking-mht-no',
                },
            ],
            whyAskingDescription: 'We want to make sure that you get the right treatment at the right dose for you.',
            mdiCaseQuestion: true,
        },
        {
            title: 'What menopausal hormone treatment are you currently taking or have stopped taking?',
            slug: 'menopausal-hormone-treatment-taking-or-stopped-taking',
            description: 'Please include name of medication and dosage.',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'MultipleLine',
            headerTitle: 'Medications and allergies:',
            placeholder: 'Type here',
            options: [],
            orLogic: {
                'taking-or-stopped-taking-menopausal-hormone-treatment': [
                    'taking-or-stopped-taking-mht-yes',
                    'taking-or-stopped-taking-mht-recently-stopped',
                    'taking-or-stopped-taking-mht-taken-past',
                ],
            },
            mdiCaseQuestion: true,
        },
        {
            title: 'Please let us know more about your current or past hormone therapy.',
            slug: 'current-hormone-therapy',
            description: 'Check all that apply:',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'MultipleChoice',
            headerTitle: 'Medications and allergies:',
            options: [
                {
                    answer: 'I am happy with my current hormone therapy',
                    slug: 'current-hormone-therapy-happy',
                },
                {
                    answer: 'My symptoms are not completely gone on my current hormone therapy',
                    slug: 'current-hormone-therapy-symptoms-not-completely-gone',
                },
                {
                    answer: 'I am having side effects to my current hormone therapy',
                    slug: 'current-hormone-therapy-side-effects',
                },
                {
                    answer: 'I do not need a RX for hormone therapy, I am getting one from my own clinician',
                    slug: 'current-hormone-therapy-not-need-rx',
                },
                {
                    answer: 'I would like Alloy to recommend and provide the hormone therapy',
                    slug: 'current-hormone-therapy-alloy-recommend-provide',
                },
                {
                    answer: 'I would like a change from my current hormone therapy',
                    slug: 'current-hormone-therapy-change-from-current',
                },
                {
                    answer: 'I have tried birth control pills for perimenopausal symptoms and I want other options like MHT',
                    slug: 'current-hormone-therapy-tried-birth-control',
                },
                {
                    answer: 'None of the above',
                    slug: 'current-hormone-therapy-none',
                },
            ],
            orLogic: {
                'taking-or-stopped-taking-menopausal-hormone-treatment': [
                    'taking-or-stopped-taking-mht-yes',
                    'taking-or-stopped-taking-mht-recently-stopped',
                    'taking-or-stopped-taking-mht-taken-past',
                ],
            },
            mdiCaseQuestion: true,
        },
        {
            title: 'Please add more details.',
            slug: 'menopausal-hormone-treatment-more-details',
            description: '',
            isRequired: false,
            isImportant: false,
            userKey: '',
            type: 'MultipleLine',
            headerTitle: 'Medications and allergies:',
            placeholder: 'Type here',
            options: [],
            orLogic: {
                'taking-or-stopped-taking-menopausal-hormone-treatment': [
                    'taking-or-stopped-taking-mht-yes',
                    'taking-or-stopped-taking-mht-recently-stopped',
                    'taking-or-stopped-taking-mht-taken-past',
                ],
            },
            whyAskingDescription: "The recommended treatments will depend on how long it's been since your last period and what treatments you have used since then.",
            mdiCaseQuestion: true,
        },
        {
            title: 'Are you currently taking any anti-depressants?',
            slug: 'taking-selective-serotonin-reuptake-inhibitors-or-ssris',
            description: '(e.g. Paxil, Effexor, Lexapro, Zoloft, Prozac, Wellbutrin, Desprammine)',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'SingleChoice',
            headerTitle: 'Medications and allergies:',
            options: [
                {
                    answer: 'Yes',
                    slug: 'taking-ssris-yes',
                },
                {
                    answer: 'No',
                    slug: 'taking-ssris-no',
                },
            ],
            whyAskingDescription: "Low dose SSRIs are a non-hormonal option to treat hot flashes, but we don't want to double up, and if you are already taking a full dose and still getting sweaty, then you will need something else.",
            mdiCaseQuestion: true,
        },
        {
            title: 'What anti-depressant are you currently taking?',
            slug: 'ssris-taking',
            description: 'Please include name of medication and dosage',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'MultipleLine',
            headerTitle: 'Medications and allergies:',
            placeholder: 'Type here',
            options: [],
            orLogic: {
                'taking-selective-serotonin-reuptake-inhibitors-or-ssris': ['taking-ssris-yes'],
            },
            mdiCaseQuestion: true,
        },
        {
            title: 'Please list all current medications.',
            slug: 'list-all-other-medications',
            description: 'If you have no medications, continue to the next question.',
            isRequired: false,
            isImportant: false,
            userKey: 'currentMedications',
            type: 'Medications',
            headerTitle: 'Medications and allergies:',
            options: [],
            mdiCaseQuestion: true,
        },
        {
            title: 'Do you have any additional allergies or reactions to medications or adhesives?',
            slug: 'any-allergies',
            description: 'This includes all allergies, intolerances, and reactions to foods or previous medications (e.g. peanut allergies)',
            conclusion: true,
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'SingleChoice',
            headerTitle: 'Your health history:',
            options: [
                {
                    answer: 'Yes',
                    slug: 'any-allergies-yes',
                },
                {
                    answer: 'No',
                    slug: 'any-allergies-no',
                },
            ],
            mdiCaseQuestion: true,
        },
        {
            title: 'Please list all allergies or reactions to medications or adhesives.',
            slug: 'list-all-allergies-and-reactions',
            description: '',
            conclusion: true,
            isRequired: false,
            isImportant: false,
            userKey: 'currentAllergies',
            type: 'Allergies',
            headerTitle: 'Your health history:',
            options: [],
            orLogic: {
                'any-allergies': ['any-allergies-yes'],
            },
            whyAskingDescription: 'Our aim is to be thorough and provide you with the best options for you.',
            mdiCaseQuestion: true,
        },
        {
            title: 'Please indicate any close relatives (parent and/or sibling) who have a history of any of the following conditions.',
            slug: 'close-relatives-parent-sibling-history-conditions',
            description: '',
            conclusion: true,
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'MultipleChoice',
            headerTitle: 'Your medical history:',
            options: [
                {
                    answer: 'None',
                    slug: 'family-history-conditions-none',
                },
                {
                    answer: 'Blood clotting',
                    slug: 'family-history-conditions-blood-clotting',
                },
                {
                    answer: 'Heart attacks',
                    slug: 'family-history-conditions-heart-attacks',
                },
                {
                    answer: 'Strokes',
                    slug: 'family-history-conditions-strokes',
                },
                {
                    answer: 'BRCA 1 / 2 diagnosis',
                    slug: 'family-history-conditions-brca-1-2-diagnosis',
                },
                {
                    answer: 'Breast cancer',
                    slug: 'family-history-conditions-breast-cancer',
                },
                {
                    answer: 'Endometrial cancer',
                    slug: 'family-history-conditions-endometrial-cancer',
                },
                {
                    answer: 'Ovarian cancer',
                    slug: 'family-history-conditions-ovarian-cancer',
                },
                {
                    answer: 'Osteoporosis',
                    slug: 'family-history-conditions-osteoporosis',
                },
                {
                    answer: 'I don’t know (e.g. adopted or no information)',
                    slug: 'family-history-conditions-i-dont-know',
                },
            ],
            whyAskingDescription: 'Knowing your family history helps your doctor formulate your best treatment. Please know that your family history does not preclude you from hormonal therapy options.',
            mdiCaseQuestion: true,
        },
        {
            title: 'What is your date of birth?',
            slug: 'date-of-birth',
            description: '',
            conclusion: true,
            isRequired: true,
            isImportant: false,
            userKey: 'dob',
            type: 'Date',
            headerTitle: 'Your health:',
            placeholder: 'MM/DD/YYYY',
            options: [],
            mdiCaseQuestion: false,
        },
        {
            title: 'Age as of completion',
            slug: 'age-as-of-completion',
            description: '',
            conclusion: true,
            isRequired: true,
            isImportant: false,
            userKey: 'age',
            type: 'Hidden',
            headerTitle: 'Your health:',
            options: [],
            mdiCaseQuestion: true,
        },
        {
            title: "What's your height?",
            slug: 'how-tall-are-you',
            description: 'Height',
            conclusion: true,
            isRequired: true,
            isImportant: false,
            userKey: 'height',
            type: 'Select',
            headerTitle: 'Your medical history:',
            defaultValue: 'how-tall-are-you-60',
            options: [
                {
                    answer: '4 foot',
                    slug: 'how-tall-are-you-48',
                },
                {
                    answer: '4 foot 1 inch',
                    slug: 'how-tall-are-you-49',
                },
                {
                    answer: '4 foot 2 inches',
                    slug: 'how-tall-are-you-50',
                },
                {
                    answer: '4 foot 3 inches',
                    slug: 'how-tall-are-you-51',
                },
                {
                    answer: '4 foot 4 inches',
                    slug: 'how-tall-are-you-52',
                },
                {
                    answer: '4 foot 5 inches',
                    slug: 'how-tall-are-you-53',
                },
                {
                    answer: '4 foot 6 inches',
                    slug: 'how-tall-are-you-54',
                },
                {
                    answer: '4 foot 7 inches',
                    slug: 'how-tall-are-you-55',
                },
                {
                    answer: '4 foot 8 inches',
                    slug: 'how-tall-are-you-56',
                },
                {
                    answer: '4 foot 9 inches',
                    slug: 'how-tall-are-you-57',
                },
                {
                    answer: '4 foot 10 inches',
                    slug: 'how-tall-are-you-58',
                },
                {
                    answer: '4 foot 11 inches',
                    slug: 'how-tall-are-you-59',
                },
                {
                    answer: '5 foot',
                    slug: 'how-tall-are-you-60',
                },
                {
                    answer: '5 foot 1 inch',
                    slug: 'how-tall-are-you-61',
                },
                {
                    answer: '5 foot 2 inches',
                    slug: 'how-tall-are-you-62',
                },
                {
                    answer: '5 foot 3 inches',
                    slug: 'how-tall-are-you-63',
                },
                {
                    answer: '5 foot 4 inches',
                    slug: 'how-tall-are-you-64',
                },
                {
                    answer: '5 foot 5 inches',
                    slug: 'how-tall-are-you-65',
                },
                {
                    answer: '5 foot 6 inches',
                    slug: 'how-tall-are-you-66',
                },
                {
                    answer: '5 foot 7 inches',
                    slug: 'how-tall-are-you-67',
                },
                {
                    answer: '5 foot 8 inches',
                    slug: 'how-tall-are-you-68',
                },
                {
                    answer: '5 foot 9 inches',
                    slug: 'how-tall-are-you-69',
                },
                {
                    answer: '5 foot 10 inches',
                    slug: 'how-tall-are-you-70',
                },
                {
                    answer: '5 foot 11 inches',
                    slug: 'how-tall-are-you-71',
                },
                {
                    answer: '6 foot',
                    slug: 'how-tall-are-you-72',
                },
                {
                    answer: '6 foot 1 inch',
                    slug: 'how-tall-are-you-73',
                },
                {
                    answer: '6 foot 2 inches',
                    slug: 'how-tall-are-you-74',
                },
                {
                    answer: '6 foot 3 inches',
                    slug: 'how-tall-are-you-75',
                },
                {
                    answer: '6 foot 4 inches',
                    slug: 'how-tall-are-you-76',
                },
                {
                    answer: '6 foot 5 inches',
                    slug: 'how-tall-are-you-77',
                },
                {
                    answer: '6 foot 6 inches',
                    slug: 'how-tall-are-you-78',
                },
                {
                    answer: '6 foot 7 inches',
                    slug: 'how-tall-are-you-79',
                },
                {
                    answer: '6 foot 8 inches',
                    slug: 'how-tall-are-you-80',
                },
                {
                    answer: '6 foot 9 inches',
                    slug: 'how-tall-are-you-81',
                },
                {
                    answer: '6 foot 10 inches',
                    slug: 'how-tall-are-you-82',
                },
                {
                    answer: '6 foot 11 inches',
                    slug: 'how-tall-are-you-83',
                },
                {
                    answer: '7 foot',
                    slug: 'how-tall-are-you-84',
                },
            ],
            mdiCaseQuestion: true,
            groupedSection: 'GS-19',
        },
        {
            title: "What's your weight?",
            slug: 'what-is-your-weight',
            description: 'Weight',
            conclusion: true,
            isRequired: true,
            isImportant: false,
            userKey: 'weight',
            type: 'Number',
            headerTitle: 'Your medical history:',
            defaultValue: 150,
            placeholder: 'Input a number in lbs',
            options: [],
            whyAskingDescription: "To personalize your treatment recommendation, it's helpful to consider BMI as part of the equation.",
            mdiCaseQuestion: true,
            groupedSection: 'GS-19',
        },
        {
            title: 'BMI',
            slug: 'bmi',
            description: '',
            conclusion: true,
            isRequired: true,
            isImportant: false,
            userKey: 'bmi',
            type: 'Hidden',
            options: [],
            mdiCaseQuestion: true,
        },
        {
            title: 'Anything else you would like to share with the doctor?',
            slug: 'anything-else-share-doctor',
            description: '',
            conclusion: true,
            isRequired: false,
            isImportant: true,
            userKey: '',
            type: 'MultipleLine',
            placeholder: 'Type here',
            options: [],
            mdiCaseQuestion: true,
        },
    ],
};
