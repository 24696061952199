import { isEmpty } from 'lodash';

import { cleanNonNumberValues } from 'modules/authentication/lib/clean-non-number-values';

import { ExperienceComponentModel } from 'modules/shared/models/experience';

import CheckoutExperienceSection from 'modules/checkout-experience/ui/wrappers/CheckoutExperienceSection';

import arrowRight from 'shared/assets/svg/arrows/arrow-right-white.svg';

import Loader from 'shared/components/content/Loader';
import Form from 'shared/components/core/Form';
import { CodeField } from 'shared/components/fields/CodeField';
import { EmailField } from 'shared/components/fields/EmailField';
import { PasswordField } from 'shared/components/fields/PasswordField';

import useVerificationState from '../../hooks/useVerificationState';

export default function VerificationFormWrapper({
  onBack,
  onNext,
  standaloneVerificationParams,
}: ExperienceComponentModel) {
  const {
    isLoading,

    cognitoUser,

    verificationForm,
    setVerificationForm,

    onResendCode,
    onSubmit,
  } = useVerificationState({
    onNext,
    onBack,
    standaloneVerificationParams,
  });

  if (isLoading) return <Loader />;

  return (
    <CheckoutExperienceSection>
      <Form onSubmit={onSubmit}>
        <h1 className='content-title'>Enter your verification code</h1>
        <p className='content-text'>
          {standaloneVerificationParams?.context?.includes('UNCONFIRMED') &&
            `Your account requires verification. `}
          We sent the verification code to {verificationForm.email}. Enter the code below to verify
          your account. Your code expires after 24 hours.
        </p>

        {(!cognitoUser || !cognitoUser.email) && !standaloneVerificationParams?.email && (
          <EmailField
            name='email'
            label='Email Address'
            value={verificationForm.email}
            placeholder='Enter your email address'
            onChange={(value) => setVerificationForm({ ...verificationForm, email: value })}
          />
        )}

        {(!cognitoUser || !cognitoUser.password) && !standaloneVerificationParams?.password && (
          <PasswordField
            name='password'
            label='Re-Enter Password'
            value={verificationForm.password}
            placeholder='Enter password'
            hideChecksSucceeded
            onChange={(_, value) => setVerificationForm({ ...verificationForm, password: value })}
          />
        )}

        <CodeField
          name='code'
          label='Code'
          placeholder='Enter code here'
          value={verificationForm.code}
          onChange={(value) => {
            setVerificationForm({
              ...verificationForm,
              code: cleanNonNumberValues(value, 6),
            });
          }}
        />

        {verificationForm.email && (
          <div className='content-resend-code-wrapper'>
            <p className='resend-code-title'>Didn't receive a code?</p>
            <p className='resend-code-text'>
              We sent the verification code to {verificationForm.email}. Be sure to check your
              promotions and spam folders in case the email is redirected!
            </p>

            <button type='button' onClick={onResendCode} className='primary-link-button'>
              Resend code
            </button>
          </div>
        )}

        <button
          className='primary-button full-width-button'
          type='submit'
          disabled={
            isEmpty(verificationForm.email) ||
            (!cognitoUser && isEmpty(verificationForm.password)) ||
            isEmpty(verificationForm.code) ||
            verificationForm.code.length !== 6
          }
        >
          Verify account
          <img src={arrowRight} alt='arrow right' className='btn-arrow' />
        </button>
      </Form>
    </CheckoutExperienceSection>
  );
}
