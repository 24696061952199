import TrustpilotSliderWidget from './TrustpilotSliderWidget';

export default function TrustpilotReviewCarouselBlock() {
  return (
    <div className='review-carousel-wrapper'>
      <h2 className='review-carousel-title'>What Alloy customers are saying:</h2>
      <TrustpilotSliderWidget dataTemplateId='54ad5defc6454f065c28af8b' />
    </div>
  );
}
