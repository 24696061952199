import { patchCustomer } from 'client/dist/generated/alloy';

import { brazeSetPartial } from 'modules/tracking/lib/braze';

import { transformDateString } from 'shared/lib/date';
import { formatPhone } from 'shared/lib/phone';

import { ProfileUpdates } from '../models/profile-updates';

/**
 * updates customer in db + braze (moved out of redux appropriately)
 *
 * given the current alloy user and the selected profile attribute updates,
 * update the alloy user.
 *
 * this also sets some user properties on braze based in the updates made to the database
 *
 * @param profileUpdates ProfileUpdates
 * @returns Customer
 */
export const updateCustomerWithProfileUpdates = async (profileUpdates: ProfileUpdates) => {
  const saved = await patchCustomer({
    ...profileUpdates,
    dateOfBirth: profileUpdates.dateOfBirth
      ? new Date(transformDateString(profileUpdates.dateOfBirth, 'YYYY-MM-DD')!)
      : undefined,
    phoneNumber: profileUpdates.phoneNumber ? formatPhone(profileUpdates.phoneNumber) : undefined,
  });

  // this only gets invoked if the previous await was successful
  brazeSetPartial(saved);

  return saved;
};
