import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useGetAllSubscriptionsForCustomer } from 'client/dist/generated/alloy';

import useCart from 'modules/shared/hooks/useCart';

import { retrieveCategoriesFromUrl } from 'modules/shared/lib/experience/experience';
import { getDeepProductsFromGroupedProducts } from 'modules/shared/lib/product';
import { getSubscriptionsWithStatus } from 'modules/shared/lib/subscriptions/status';
import sendAbandonEvent from 'modules/tracking/lib/sendAbandonEvent';

export default function useInitCheckout() {
  const location = useLocation();

  const { cart, updateCart } = useCart();

  const { data: subscriptions = [] } = useGetAllSubscriptionsForCustomer();

  useEffect(() => {
    const { activeSubs } = getSubscriptionsWithStatus(subscriptions);

    // default the cart terms to accepted since they have previously purchased!
    updateCart({
      agreement: {
        ...cart.agreement,
        isAgreedTerms: true,
      },
      shipNow: !activeSubs.length,
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);

    const products = getDeepProductsFromGroupedProducts(cart.products);
    const categories = retrieveCategoriesFromUrl(location);

    // checkout shown with product
    sendAbandonEvent({
      event: 'CHECKOUT_SHOWN',
      categories,
      experience: 'request',
      products,
    });
  }, []);
}
