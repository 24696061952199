"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VAGINAL_QUESTIONNAIRE = void 0;
exports.VAGINAL_QUESTIONNAIRE = {
    title: 'Estradiol Vaginal Cream Assessment',
    questions: [
        {
            title: "Let's discuss your genitourinary symptoms.",
            slug: 'all-symptoms-experiencing',
            description: 'Select all symptoms that you are experiencing:',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'MultipleChoice',
            headerTitle: 'Vaginal and vulvar health:',
            options: [
                {
                    answer: 'Bladder symptoms (frequent UTIs, urinary urgency and/or frequency)',
                    slug: 'all-symptoms-experiencing-bladder-symptoms',
                },
                {
                    answer: 'Vaginal symptoms (dryness, burning, itching or decreased lubrication)',
                    slug: 'all-symptoms-experiencing-vaginal-symptoms',
                },
                {
                    answer: 'Vulvar or labial symptoms (dryness, burning, itching)',
                    slug: 'all-symptoms-experiencing-vulvar-symptoms',
                },
            ],
            mdiCaseQuestion: true,
        },
        {
            title: 'When was your last menstrual period?',
            slug: 'what-date-last-period',
            description: 'Approximate month and year',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'SingleLine',
            headerTitle: 'Your menstrual cycle:',
            placeholder: 'Type here',
            options: [],
            whyAskingDescription: 'When you got your last period gives us information on which treatment might be best for you.',
            mdiCaseQuestion: true,
        },
        {
            title: 'Which of the following best describes your menstrual cycle in the past 12 months?',
            slug: 'describe-menstrual-cycle-past-year',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'SingleChoice',
            headerTitle: 'Your menstrual cycle:',
            options: [
                {
                    answer: 'My periods are regular',
                    slug: 'past-year-periods-regular',
                },
                {
                    answer: 'My periods are starting to become unpredictable (e.g., too close together, skipping periods or a change in duration or amount of flow)',
                    slug: 'past-year-periods-unpredictable',
                },
                {
                    answer: 'I have not had a period in over a year',
                    slug: 'past-year-no-period',
                },
                {
                    answer: 'My menstrual cycle has been impacted by a medical procedure',
                    slug: 'past-year-medical-procedure',
                },
            ],
            whyAskingDescription: "We don't mean to pry-- but knowing these details helps us determine what stage of menopause (or perimenopause) you're in.",
            mdiCaseQuestion: true,
        },
        {
            title: 'Which of the following describes how your periods are becoming more unpredictable?',
            slug: 'period-unpredictable',
            description: 'Check all that apply:',
            isRequired: true,
            isImportant: true,
            userKey: '',
            type: 'MultipleChoice',
            headerTitle: 'Your health history:',
            options: [
                {
                    slug: 'period-unpredictable-bleeding-21-days',
                    answer: 'Bleeding is more frequent than every 21 days',
                },
                {
                    slug: 'period-unpredictable-bleeding-8-days',
                    answer: 'Bleeding last longer than 8 days',
                },
                {
                    slug: 'period-unpredictable-periods-heavier',
                    answer: 'Periods are now heavier',
                },
                {
                    slug: 'period-unpredictable-tampon-1-2-hours',
                    answer: 'I bleed through a tampon or pad in 1-2 hours',
                },
                {
                    slug: 'period-unpredictable-bleeding-less-frequent',
                    answer: 'Bleeding is less frequent and/or I skip more than 2 months at a time',
                },
                {
                    slug: 'period-unpredictable-bleeding-lighter',
                    answer: 'Bleeding is much lighter',
                },
                {
                    slug: 'period-unpredictable-other',
                    answer: 'Other',
                },
            ],
            orLogic: {
                'describe-menstrual-cycle-past-year': ['past-year-periods-unpredictable'],
            },
            mdiCaseQuestion: true,
        },
        {
            title: 'Please describe how your periods are becoming more unpredictable.',
            slug: 'describe-how-periods-becoming-unpredictable',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'MultipleLine',
            headerTitle: 'Your health history:',
            placeholder: 'Type here',
            options: [],
            orLogic: {
                'period-unpredictable': ['period-unpredictable-other'],
            },
            mdiCaseQuestion: true,
        },
        {
            title: 'Have you had a recent GYN evaluation?',
            slug: 'how-recent-gyn-evaluation',
            description: 'Check all that apply:',
            isRequired: true,
            isImportant: true,
            userKey: '',
            type: 'MultipleChoice',
            headerTitle: 'Your health history:',
            options: [
                {
                    slug: 'how-recent-gyn-evaluation-regular',
                    answer: 'I have had a regular GYN evaluation in past 12 months',
                },
                {
                    slug: 'how-recent-gyn-evaluation-transvaginal-ultrasound',
                    answer: 'I have had a pelvic or transvaginal ultrasound',
                },
                {
                    slug: 'how-recent-gyn-evaluation-endometrial-biopsy',
                    answer: 'I have had an endometrial biopsy',
                },
                {
                    slug: 'how-recent-gyn-evaluation-d-c',
                    answer: 'I have had a D&C',
                },
                {
                    slug: 'how-recent-gyn-evaluation-hysteroscopy',
                    answer: 'I have had a hysteroscopy',
                },
                {
                    slug: 'how-recent-gyn-evaluation-blood-work-done',
                    answer: 'I have had blood work done',
                },
                {
                    slug: 'how-recent-gyn-evaluation-other',
                    answer: 'Other - please explain',
                },
            ],
            orLogic: {
                'period-unpredictable': [
                    'period-unpredictable-bleeding-21-days',
                    'period-unpredictable-bleeding-8-days',
                    'period-unpredictable-periods-heavier',
                    'period-unpredictable-tampon-1-2-hours',
                ],
            },
            mdiCaseQuestion: true,
        },
        {
            title: 'Please tell us more.',
            slug: 'please-tell-us-more',
            description: 'Please provide details, or in your own words, a summary of relevant results.',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'MultipleLine',
            headerTitle: 'Your health history:',
            placeholder: 'Type here',
            options: [],
            orLogic: {
                'how-recent-gyn-evaluation': [
                    'how-recent-gyn-evaluation-transvaginal-ultrasound',
                    'how-recent-gyn-evaluation-endometrial-biopsy',
                    'how-recent-gyn-evaluation-d-c',
                    'how-recent-gyn-evaluation-hysteroscopy',
                    'how-recent-gyn-evaluation-blood-work-done',
                    'how-recent-gyn-evaluation-other',
                ],
            },
            mdiCaseQuestion: true,
        },
        {
            title: 'Has it been 10 years since your last period?',
            slug: '10-years-since-last-period',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'SingleChoice',
            headerTitle: 'Your menstrual cycle:',
            options: [
                {
                    slug: '10-years-yes',
                    answer: 'Yes',
                },
                {
                    slug: '10-years-no',
                    answer: 'No',
                },
            ],
            orLogic: {
                'describe-menstrual-cycle-past-year': ['past-year-no-period'],
            },
            whyAskingDescription: "We just want to know where you're at! How long you have been in menopause and which treatments, if any, you have been using, help to determine the right treatment for you today. According to The Menopause Society guidelines, for women over 60 or women who have not had a period in over 10 years and have not started MHT, the patch should be recommended over the pill.",
            mdiCaseQuestion: true,
        },
        {
            title: 'Which of the following medical procedures has impacted your menstrual cycle?',
            slug: 'medical-procedures-impacted-menstrual-cycle',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'SingleChoice',
            headerTitle: 'Your menstrual cycle:',
            options: [
                {
                    answer: 'Bilateral oophorectomy',
                    slug: 'medical-procedures-bilateral-oophorectomy',
                },
                {
                    answer: 'Endometrial ablation',
                    slug: 'medical-procedures-endometrial-ablation',
                },
                {
                    answer: 'Uterine artery embolization (UAE)',
                    slug: 'medical-procedures-uterine-embolization',
                },
                {
                    answer: 'Hormonal IUD',
                    slug: 'medical-procedures-hormonal-iud',
                },
                {
                    answer: 'Hysterectomy (surgical removal of the uterus)',
                    slug: 'medical-procedures-hysterectomy',
                },
                {
                    answer: 'Other',
                    slug: 'medical-procedures-other',
                },
            ],
            orLogic: {
                'describe-menstrual-cycle-past-year': ['past-year-medical-procedure'],
            },
            mdiCaseQuestion: true,
        },
        {
            title: 'How many periods have you had in the past 12 months?',
            slug: 'number-of-periods-past-year',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'SingleChoice',
            headerTitle: 'Your menstrual cycle:',
            options: [
                {
                    slug: 'periods-past-year-0-2',
                    answer: '0-2',
                },
                {
                    answer: '3-5',
                    slug: 'periods-past-year-3-5',
                },
                {
                    answer: '6-12+',
                    slug: 'periods-past-year-6-12+',
                },
            ],
            orLogic: {
                'describe-menstrual-cycle-past-year': [
                    'past-year-periods-regular',
                    'past-year-periods-unpredictable',
                ],
                'medical-procedures-impacted-menstrual-cycle': [
                    'medical-procedures-endometrial-ablation',
                    'medical-procedures-hormonal-iud',
                ],
            },
            mdiCaseQuestion: true,
        },
        {
            title: 'At what age did you have a bilateral oophorectomy (removal of both ovaries)?',
            slug: 'age-bilateral-oophorectomy',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'MultipleLine',
            headerTitle: 'Your menstrual cycle:',
            placeholder: 'Type here',
            options: [],
            orLogic: {
                'medical-procedures-impacted-menstrual-cycle': [
                    'medical-procedures-bilateral-oophorectomy',
                ],
            },
            mdiCaseQuestion: true,
        },
        {
            title: 'Why did you have a bilateral oophorectomy (removal of both ovaries)?',
            slug: 'why-bilateral-oophorectomy',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'MultipleLine',
            headerTitle: 'Your menstrual cycle:',
            placeholder: 'Type here',
            options: [],
            orLogic: {
                'medical-procedures-impacted-menstrual-cycle': [
                    'medical-procedures-bilateral-oophorectomy',
                ],
            },
            mdiCaseQuestion: true,
        },
        {
            title: 'What other medical procedure has impacted your menstrual cycle?',
            slug: 'other-medical-procedure-impacted-menstrual-cycle',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'MultipleLine',
            headerTitle: 'Your menstrual cycle:',
            placeholder: 'Type here',
            options: [],
            orLogic: {
                'medical-procedures-impacted-menstrual-cycle': ['medical-procedures-other'],
            },
            mdiCaseQuestion: true,
        },
        {
            title: 'Please indicate if you have or have had any of the following conditions.',
            slug: 'indicate-if-had-conditions',
            description: '',
            isRequired: true,
            isImportant: true,
            userKey: '',
            type: 'MultipleChoice',
            headerTitle: 'Your health history:',
            options: [
                {
                    answer: 'None',
                    slug: 'indicate-if-had-conditions-none',
                },
                {
                    answer: 'Personal history of breast cancer',
                    slug: 'indicate-if-had-conditions-breast-cancer',
                },
                {
                    answer: 'Lichen Sclerosis or other vulvar skin condition',
                    slug: 'indicate-if-had-conditions-lichen-sclerosis',
                },
                {
                    answer: "Unexplained episodes of postmenopausal bleeding (if you've had no period in over a year)",
                    slug: 'indicate-if-had-conditions-post-meno-bleeding',
                },
                {
                    answer: 'Endometrial cancer',
                    slug: 'indicate-if-had-conditions-endometrial-cancer',
                },
            ],
            whyAskingDescription: 'This information will help us make the best recommendation for treating any vaginal and bladder symptoms.',
            mdiCaseQuestion: true,
        },
        {
            title: 'You have options!',
            slug: 'breast-cancer-treatment-awareness',
            description: '**While systemic hormone therapy is generally not prescribed to patients with a history of invasive breast cancer, there are other treatment options available to manage your menopause symptoms.\\***  \n\n**All doctors who prescribe for Alloy are menopause-trained and follow the guidelines provided by ACOG and The Menopause Society.**  \n\nYour consultation will provide:  \n * Access to a specialist in menopause care  \n * Information on suitable treatment options  \n * Resources to help manage your symptoms  \n\nMany breast cancer patients are candidates for vaginal estrogen and non-hormonal treatments. A consultation can help you explore these options and help you find an approach for your individual needs.  \n\n ###### *Please note that if you have a BRCA gene mutation but have not had breast cancer, you are still a candidate for hormone therapy! For additional information, check out our [Breast Cancer FAQ](https://www.myalloy.com/breast-cancer-faqs).',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'MarkdownCheckbox',
            options: [
                {
                    slug: 'breast-cancer-treatment-awareness-confirm',
                    answer: 'I have read and understand',
                },
            ],
            orLogic: {
                'indicate-if-had-conditions': ['indicate-if-had-conditions-breast-cancer'],
            },
            mdiCaseQuestion: true,
        },
        {
            title: 'Please provide the year of your breast cancer diagnosis, stage of breast cancer, estrogen receptor status.',
            slug: 'provide-year-breast-cancer-diagnosis',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'MultipleLine',
            headerTitle: 'Your health history:',
            placeholder: 'Type here',
            options: [],
            orLogic: {
                'indicate-if-had-conditions': ['indicate-if-had-conditions-breast-cancer'],
            },
            mdiCaseQuestion: true,
        },
        {
            title: 'Have you had an evaluation for the unexplained episodes of post-menopausal bleeding?',
            slug: 'eval-post-men-bleeding',
            description: '',
            isRequired: true,
            isImportant: true,
            userKey: '',
            type: 'SingleChoice',
            headerTitle: 'Your health history:',
            options: [
                {
                    slug: 'eval-post-men-bleeding-yes',
                    answer: 'Yes',
                },
                {
                    slug: 'eval-post-men-bleeding-no',
                    answer: 'No',
                },
            ],
            orLogic: {
                'indicate-if-had-conditions': ['indicate-if-had-conditions-post-meno-bleeding'],
            },
            mdiCaseQuestion: true,
        },
        {
            title: 'Please select the type of evaluation.',
            slug: 'type-eval-post-men-bleeding',
            description: 'Check all that apply:',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'MultipleChoice',
            headerTitle: 'Your health history:',
            placeholder: 'Type here',
            options: [
                {
                    slug: 'type-eval-post-men-bleeding-pelvic-exam',
                    answer: 'Pelvic exam',
                },
                {
                    slug: 'type-eval-post-men-bleeding-pelvic-transvaginal',
                    answer: 'Pelvic or transvaginal ultrasound',
                },
                {
                    slug: 'type-eval-post-men-bleeding-endometrial-biopsy',
                    answer: 'Endometrial biopsy',
                },
                {
                    slug: 'type-eval-post-men-bleeding-hysteroscopy',
                    answer: 'Hysteroscopy',
                },
                {
                    slug: 'type-eval-post-men-bleeding-d-c',
                    answer: 'D&C',
                },
                {
                    slug: 'type-eval-post-men-bleeding-other',
                    answer: 'Other',
                },
            ],
            orLogic: {
                'eval-post-men-bleeding': ['eval-post-men-bleeding-yes'],
            },
            mdiCaseQuestion: true,
        },
        {
            title: 'Please provide more detail.',
            slug: 'detail-eval-post-men-bleeding',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'MultipleLine',
            headerTitle: 'Your health history:',
            placeholder: 'Type here',
            options: [],
            orLogic: {
                'eval-post-men-bleeding': ['eval-post-men-bleeding-yes'],
            },
            mdiCaseQuestion: true,
        },
        {
            title: 'What stage of endometrial cancer?',
            slug: 'stage-endometrial-cancer',
            description: '',
            isRequired: true,
            isImportant: true,
            userKey: '',
            type: 'SingleChoice',
            headerTitle: 'Your health history:',
            options: [
                {
                    answer: 'Stage 1 or 2',
                    slug: 'stage-endometrial-cancer-1-or-2',
                },
                {
                    answer: 'Stage 3 or 4',
                    slug: 'stage-endometrial-cancer-3-or-4',
                },
            ],
            orLogic: {
                'indicate-if-had-conditions': ['indicate-if-had-conditions-endometrial-cancer'],
            },
            whyAskingDescription: "If you've been diagnosed with stage 1 or 2 endometrial cancer, you are still a candidate for vaginal estradiol cream; if your cancer was stage 3 or 4, your doctor will review your case and decide if vaginal estrogen is appropriate for you.",
            mdiCaseQuestion: true,
        },
        {
            title: 'Are you currently using any birth control products?',
            slug: 'using-birth-control-products',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'SingleChoice',
            headerTitle: 'Medications and allergies:',
            options: [
                {
                    answer: 'Yes',
                    slug: 'using-birth-control-products-yes',
                },
                {
                    answer: 'No',
                    slug: 'using-birth-control-products-no',
                },
            ],
            whyAskingDescription: 'It is important for the doctor to understand other medications you are currently using to provide overall care, but you can take vaginal estradiol cream with birth control!',
            mdiCaseQuestion: true,
        },
        {
            title: 'Which birth control product are you currently using?',
            slug: 'which-birth-control-products',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'MultipleChoice',
            headerTitle: 'Medications and allergies:',
            options: [
                {
                    answer: 'Birth control pill',
                    slug: 'which-birth-control-products-pill',
                },
                {
                    answer: 'Birth control patch',
                    slug: 'which-birth-control-products-patch',
                },
                {
                    answer: 'Birth control vaginal ring (Nuvaring or Annovera)',
                    slug: 'which-birth-control-products-ring',
                },
                {
                    answer: 'Nexplanon contraceptive arm implant',
                    slug: 'which-birth-control-products-arm-impant',
                },
                {
                    answer: 'Hormonal IUD',
                    slug: 'which-birth-control-products-hormonal-iud',
                },
                {
                    answer: 'Non-hormonal IUD (Copper T or Paragard)',
                    slug: 'which-birth-control-products-non-hormonal-iud',
                },
                {
                    answer: 'I have had sterilization (tubal ligation)',
                    slug: 'which-birth-control-products-tubal-ligation',
                },
                {
                    answer: 'My partner has a vasectomy',
                    slug: 'which-birth-control-products-vasectomy',
                },
                {
                    answer: 'Condoms',
                    slug: 'which-birth-control-products-condoms',
                },
                {
                    answer: 'Phexxi',
                    slug: 'which-birth-control-products-phexxi',
                },
                {
                    answer: 'Other',
                    slug: 'which-birth-control-products-other',
                },
            ],
            orLogic: {
                'using-birth-control-products': ['using-birth-control-products-yes'],
            },
            mdiCaseQuestion: true,
        },
        {
            title: 'Have you ever taken any menopausal hormone treatment?',
            slug: 'taking-or-stopped-taking-menopausal-hormone-treatment',
            description: '(e.g. birth control pills, compounded hormones or creams, sprays, gels, or hormonal pellets)',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'SingleChoice',
            headerTitle: 'Medications and allergies:',
            options: [
                {
                    answer: 'Yes, I am currently taking hormones',
                    slug: 'taking-or-stopped-taking-mht-yes',
                },
                {
                    answer: 'I recently stopped taking hormones (within the last 90 days)',
                    slug: 'taking-or-stopped-taking-mht-recently-stopped',
                },
                {
                    answer: 'I have taken hormones in the past',
                    slug: 'taking-or-stopped-taking-mht-taken-past',
                },
                {
                    answer: 'No',
                    slug: 'taking-or-stopped-taking-mht-no',
                },
            ],
            whyAskingDescription: 'We want to make sure that you get the right treatment at the right dose for you.',
            mdiCaseQuestion: true,
        },
        {
            title: 'What menopausal hormone treatment are you currently taking or have you recently stopped taking?',
            slug: 'menopausal-hormone-treatment-taking-or-stopped-taking',
            description: 'Please include name of medication and dosage.',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'MultipleLine',
            headerTitle: 'Medications and allergies:',
            placeholder: 'Type here',
            options: [],
            orLogic: {
                'taking-or-stopped-taking-menopausal-hormone-treatment': [
                    'taking-or-stopped-taking-mht-yes',
                    'taking-or-stopped-taking-mht-recently-stopped',
                ],
            },
            mdiCaseQuestion: true,
        },
        {
            title: 'Please let us know more about your current or recent hormone therapy.',
            slug: 'current-hormone-therapy',
            description: 'Check all that apply:',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'MultipleChoice',
            headerTitle: 'Medications and allergies:',
            options: [
                {
                    answer: 'I am happy with my current hormone therapy',
                    slug: 'current-hormone-therapy-happy',
                },
                {
                    answer: 'My symptoms are not completely gone on my current hormone therapy',
                    slug: 'current-hormone-therapy-symptoms-not-completely-gone',
                },
                {
                    answer: 'I am having side effects to my current hormone therapy',
                    slug: 'current-hormone-therapy-side-effects',
                },
                {
                    answer: 'I do not need a RX for hormone therapy, I am getting one from my own clinician',
                    slug: 'current-hormone-therapy-not-need-rx',
                },
                {
                    answer: 'I would like Alloy to recommend and provide the hormone therapy',
                    slug: 'current-hormone-therapy-alloy-recommend-provide',
                },
                {
                    answer: 'I would like a change from my current hormone therapy',
                    slug: 'current-hormone-therapy-change-from-current',
                },
                {
                    answer: 'I have tried birth control pills for perimenopausal symptoms and I want other options like MHT',
                    slug: 'current-hormone-therapy-tried-birth-control',
                },
            ],
            orLogic: {
                'using-birth-control-products': ['using-birth-control-products-yes'],
            },
            mdiCaseQuestion: true,
        },
        {
            title: 'Please add more details.',
            slug: 'menopausal-hormone-treatment-more-details',
            description: '',
            isRequired: false,
            isImportant: false,
            userKey: '',
            type: 'MultipleLine',
            headerTitle: 'Medications and allergies:',
            placeholder: 'Type here',
            options: [],
            orLogic: {
                'using-birth-control-products': ['using-birth-control-products-yes'],
            },
            whyAskingDescription: "The recommended treatments will depend on how long it's been since your last period and what treatments you have used since then.",
            mdiCaseQuestion: true,
        },
        {
            title: 'Please list all current medications.',
            slug: 'list-all-other-medications',
            description: 'If you have no medications, continue to the next question.',
            isRequired: false,
            isImportant: false,
            userKey: 'currentMedications',
            type: 'Medications',
            headerTitle: 'Your health history:',
            options: [],
            mdiCaseQuestion: true,
        },
        {
            title: 'Do you have any additional allergies or reactions to medications or adhesives?',
            slug: 'any-allergies',
            description: 'This includes all allergies, intolerances, and reactions to foods or previous medications (e.g. peanut allergies)',
            conclusion: true,
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'SingleChoice',
            headerTitle: 'Your health history:',
            options: [
                {
                    answer: 'Yes',
                    slug: 'any-allergies-yes',
                },
                {
                    answer: 'No',
                    slug: 'any-allergies-no',
                },
            ],
            mdiCaseQuestion: true,
        },
        {
            title: 'Please list all allergies or reactions to medications or adhesives.',
            slug: 'list-all-allergies-and-reactions',
            description: '',
            conclusion: true,
            isRequired: false,
            isImportant: false,
            userKey: 'currentAllergies',
            type: 'Allergies',
            headerTitle: 'Your health history:',
            options: [],
            orLogic: {
                'any-allergies': ['any-allergies-yes'],
            },
            mdiCaseQuestion: true,
        },
        {
            title: 'What is your date of birth?',
            slug: 'date-of-birth',
            description: '',
            conclusion: true,
            isRequired: true,
            isImportant: false,
            userKey: 'dob',
            type: 'Date',
            headerTitle: 'Your health history:',
            placeholder: 'MM/DD/YYYY',
            options: [],
            mdiCaseQuestion: true,
        },
        {
            title: 'Anything else you would like to share with the doctor?',
            slug: 'anything-else-share-doctor',
            description: '',
            conclusion: true,
            isRequired: false,
            isImportant: true,
            userKey: '',
            type: 'MultipleLine',
            headerTitle: 'Your health history:',
            placeholder: 'Type here',
            options: [],
            mdiCaseQuestion: true,
        },
    ],
};
