const mimeToExtensionMap: Record<string, string> = {
  'text/plain': 'txt',
  'text/html': 'html',
  'application/json': 'json',
  'application/pdf': 'pdf',
  'image/png': 'png',
  'image/jpeg': 'jpg',
  'audio/mpeg': 'mp3',
  'audio/mp3': 'mp3',
  'audio/webm': 'webm',
  'audio/ogg': 'ogg',
  'video/mp4': 'mp4',
  'video/webm': 'webm',
  'application/octet-stream': 'bin',
};

export const getFileNameFromBlob = (blob: Blob, baseName: string = 'file') => {
  const extension = mimeToExtensionMap[blob.type] || 'bin';

  return `${baseName}.${extension}`;
};
