import { useCallback, useEffect, useRef, useState } from 'react';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSave: (blob: Blob, baseName: string) => void;
}

export default function useRecordVideoModalState({ isOpen, onClose, onSave }: Props) {
  const [hasCameraAccess, setHasCameraAccess] = useState<boolean>(true);
  const [videoBlob, setVideoBlob] = useState<Blob | null>(null);
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [recordedChunks, setRecordedChunks] = useState<Blob[]>([]);

  const webcamRef = useRef<any>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const mediaStreamRef = useRef<MediaStream | null>(null);

  useEffect(() => {
    if (isOpen) {
      const mediaOptions = getMediaOptions();

      if (!mediaOptions) {
        setHasCameraAccess(false);
      }
    }
  }, [isOpen]);

  /**
   * certain devices need different compression which is why you see the codecs there for webm,
   * it allows for better targeting so that not everything falls back and potentially errors
   */
  const getMediaOptions = () => {
    if (MediaRecorder.isTypeSupported('video/mp4')) {
      return { mimeType: 'video/mp4', videoBitsPerSecond: 1600000 };
    } else if (MediaRecorder.isTypeSupported('video/webm;codecs=vp9')) {
      return { mimeType: 'video/webm;codecs=vp9', videoBitsPerSecond: 1600000 };
    } else if (MediaRecorder.isTypeSupported('video/webm;codecs=vp8')) {
      return { mimeType: 'video/webm;codecs=vp8', videoBitsPerSecond: 1600000 };
    } else if (MediaRecorder.isTypeSupported('video/webm')) {
      return { mimeType: 'video/webm', videoBitsPerSecond: 1600000 };
    } else {
      return { mimeType: 'video/webm' }; // default to video/webm on the device, same behavior as CE
    }
  };

  const handleDataAvailable = useCallback(
    ({ data }: { data: Blob }) => {
      if (data.size > 0) {
        const chunks = recordedChunks.concat(data);
        setRecordedChunks(chunks);

        const mediaOptions = getMediaOptions();

        const mimeType = mediaOptions.mimeType;

        const blob = new Blob(chunks, {
          type: mimeType,
        });

        setVideoBlob(blob);
        setRecordedChunks([]);
      }
    },
    [setRecordedChunks],
  );

  const onStartRecording = useCallback(() => {
    setIsRecording(true);

    const mediaOptions = getMediaOptions();

    if (!mediaOptions) {
      setHasCameraAccess(false);

      return;
    }

    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, mediaOptions);

    mediaRecorderRef.current.addEventListener('dataavailable', handleDataAvailable);

    mediaRecorderRef.current.start();
  }, [webcamRef, setIsRecording, mediaRecorderRef]);

  const onStopRecording = useCallback(() => {
    setIsRecording(false);

    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
    }
  }, [mediaRecorderRef, setIsRecording]);

  const onRemove = () => {
    if (mediaStreamRef.current) {
      mediaStreamRef.current.getTracks().forEach((track) => track.stop());
      mediaStreamRef.current = null;
    }

    setRecordedChunks([]);
    setVideoBlob(null);
  };

  const onSubmit = async () => {
    if (videoBlob) {
      onSave(videoBlob, 'video_recording');
    }

    onCloseModal();
  };

  const onCloseModal = () => {
    onStopRecording();
    onRemove();
    onClose();
  };

  return {
    isRecording,

    videoBlob,

    webcamRef,

    hasCameraAccess,
    setHasCameraAccess,

    onStartRecording,
    onStopRecording,

    onSubmit,
    onRemove,
    onCloseModal,
  };
}
