import './ShipOptionBlock.scss';

import classNames from 'classnames';
import { format } from 'date-fns';
import { first, minBy } from 'lodash';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import {
  GetShippingMethods200Item,
  ShippingMethodType,
  useGetAllSubscriptionsForCustomer,
  useGetShippingMethods,
} from 'client/dist/generated/alloy';
import ProductRegistry from 'client/dist/product/productRegistry';
import { ShippingSpeed, ShippingSpeedType } from 'common/dist/models/shipping';
import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';

import { shippingSpeeds } from 'modules/request-experience/data/shipping-speed';

import useCart from 'modules/shared/hooks/useCart';

import {
  cleanGroupedPurchasableProducts,
  cleanPurchasableProducts,
} from 'modules/request-experience/lib/flow';
import {
  getDeepProductsFromGroupedProducts,
  getProductsToBeBundledWith,
} from 'modules/shared/lib/product';
import { formatProductNames } from 'modules/shared/lib/product/name';
import { getProductPrice } from 'modules/shared/lib/product/pricing';
import { getSubscriptionsWithStatus } from 'modules/shared/lib/subscriptions/status';
import { cleanShippingTitle } from 'modules/shared/sub-modules/checkout/lib/shipping';

import chevronDownIcon from 'shared/assets/svg/common/chevron-down.svg';

import { convertCentsToDollars } from 'shared/lib/convert';

import { useAppSelector } from 'shared/store/reducers';

interface Props {
  hideBorder?: boolean;
}

export default function ShipOptionBlock({ hideBorder = false }: Props) {
  const location = useLocation();

  const { cart, updateCart } = useCart();

  const customer = useAppSelector((state) => state.alloy.customer!);

  const stateAbbr = customer.stateAbbr ?? '';

  const { data: shippingMethods = [] } = useGetShippingMethods(stateAbbr);
  const { data: subscriptions = [], isLoading: isLoadingSubscriptions } =
    useGetAllSubscriptionsForCustomer();

  const [showShippingDetails, setShowShippingDetails] = useState<boolean>(false);
  const [selectedShippingType, setSelectedShippingType] = useState<ShippingMethodType>('STANDARD');
  const [selectedShippingSpeed, setSelectedShippingSpeed] =
    useState<ShippingSpeedType>('NEXT_SHIPMENT');
  const [parentsSelected, setParentsSelected] = useState<GroupedContentfulProduct[]>([]);
  const [discountValue, setDiscountValue] = useState<number>(0);

  const selectedShippingMethod = shippingMethods.find((sm) => sm.method === selectedShippingType);
  const { activeSubs } = getSubscriptionsWithStatus(subscriptions);

  const activeSubscriptionsProducts = activeSubs
    .flatMap((sub) => sub.products)
    .map((p) => p.product);
  const nextRecurrence = minBy(activeSubs, (sub) => new Date(sub.nextRecurrenceOn));
  const nextShipmentDate =
    nextRecurrence && !isLoadingSubscriptions
      ? `(${format(new Date(nextRecurrence.nextRecurrenceOn), 'MM/dd')})`
      : '';

  const isRequestExperience = location.pathname.includes('request-experience');

  useEffect(() => {
    const getDiscountPrice = async () => {
      const selectedProducts = cart.products;

      // only one item in cart
      // only continue and get the discount to show if the product in cart is a bundle product, otherwise don't show it
      if (selectedProducts.length === 1 && (await isBundledCart(selectedProducts))) {
        const product = first(selectedProducts[0].alloyProduct.parent);
        if (product) {
          const parents = parentsSelected.flatMap((parent) => parent.alloyProduct.parent);

          const [unbundledPrice, bundledPrice] = await Promise.all([
            getProductPrice(product),
            getProductPrice(product, parents),
          ]);

          // if is a product that has a bundled price, we set it, otherwise keep it as 0 and we won't show the tag
          if (unbundledPrice && bundledPrice) {
            const differenceInPrice = unbundledPrice / 100 - bundledPrice / 100;
            setDiscountValue(differenceInPrice);
          }
        }
      }
    };

    getDiscountPrice();
  }, []);

  useEffect(() => {
    const getParentSelected = async () => {
      const selectedProducts = cart.products;

      // only one item in cart
      // only continue and get the parent's name to show if the product in cart is a bundle product, otherwise don't show it
      if (selectedProducts.length === 1 && (await isBundledCart(selectedProducts))) {
        const productIds = getDeepProductsFromGroupedProducts(selectedProducts);

        const cartProducts = await ProductRegistry.get().getRecurringProductsForV2(productIds);
        const subProducts = await ProductRegistry.get().getRecurringProductsForV2(
          activeSubscriptionsProducts,
        );

        const cartAndSubProducts = [...cartProducts, ...subProducts].flat();

        const parents = getProductsToBeBundledWith(first(cartProducts.flat())!, [
          cartAndSubProducts,
        ]);

        if (parents.length) {
          setParentsSelected(parents);
        }
      }
    };

    getParentSelected();
  }, []);

  const isBundledCart = async (selectedGcps: GroupedContentfulProduct[]) => {
    const selectedPfs = getDeepProductsFromGroupedProducts(selectedGcps);

    const purchasableProducts = await cleanPurchasableProducts(
      selectedPfs,
      activeSubscriptionsProducts,
    );
    return purchasableProducts.some((gcp) => gcp.alloyProduct.parent[0].isBundledPrice);
  };

  const updateCartBasedOnShippingSpeed = async (shippingSpeed: ShippingSpeedType) => {
    const selectedProducts = cart.products;

    const cartProducts = getDeepProductsFromGroupedProducts(selectedProducts);

    const cleanedProducts =
      shippingSpeed === 'NEXT_SHIPMENT'
        ? await cleanGroupedPurchasableProducts(cartProducts, activeSubscriptionsProducts)
        : await ProductRegistry.get().getRecurringProductsForV2(cartProducts);

    if (isRequestExperience) {
      updateCart({
        products: cleanedProducts.flat(),
        shipNow: shippingSpeed === 'SHIP_NOW',
      });
    } else {
      updateCart({
        products: cleanedProducts.flat(),
        shipNow: shippingSpeed === 'SHIP_NOW',
      });
    }
  };

  const handleSelectingShipping = (sm: GetShippingMethods200Item) => {
    updateCart({
      shipping: sm,
    });

    setSelectedShippingType(sm.method);
  };

  const onSelectShippingSpeed = async (ss: ShippingSpeed) => {
    await updateCartBasedOnShippingSpeed(ss.shippingSpeedType);

    setSelectedShippingSpeed(ss.shippingSpeedType);
  };

  const cleanSpeedTypeDescription = (ss: ShippingSpeed) => {
    if (ss.shippingSpeedType === 'NEXT_SHIPMENT') {
      return (
        <p className='option-title'>
          {`${ss.description} ${formatProductNames(parentsSelected)} order ${nextShipmentDate}`}
          {!!discountValue && (
            <span className='next-shipment-discount-tag'>{`Get $${Number(
              discountValue.toFixed(2),
            )} off`}</span>
          )}
        </p>
      );
    }

    return <p className='option-title'>{ss.description}</p>;
  };

  return (
    <div
      className={classNames(
        're-shipping-method-block',
        showShippingDetails && ' re-active-shipping',
      )}
    >
      <div className='re-shipping-options-wrapper'>
        <p className='shipping-header-title'>Shipping Speed</p>

        {shippingSpeeds.map((ss, index) => (
          <div
            key={index}
            className={`shipping-option ${
              ss.shippingSpeedType === selectedShippingSpeed ? 'selected' : ''
            }`}
            onClick={() => onSelectShippingSpeed(ss)}
          >
            <div className='option-outer-circle'></div>

            <div className='option-content'>{cleanSpeedTypeDescription(ss)}</div>
          </div>
        ))}
      </div>

      {selectedShippingSpeed === 'SHIP_NOW' && (
        <>
          <div className={classNames('re-shipping-method-wrapper', hideBorder && 're-hide-border')}>
            <div className='shipping-content-header'>
              <div
                className={`shipping-content ${showShippingDetails ? 'open' : 'closed'}`}
                onClick={() => setShowShippingDetails(!showShippingDetails)}
              >
                {showShippingDetails ? (
                  <>
                    <p className='shipping-text'>
                      Processing time for orders can take 1-3 business days.
                    </p>

                    <img src={chevronDownIcon} alt='chevron right' className='shipping-chevron' />
                  </>
                ) : (
                  <>
                    <p className='shipping-text'>
                      {selectedShippingMethod
                        ? cleanShippingTitle(selectedShippingMethod)
                        : 'Standard Shipping (3-5 business days)'}
                    </p>

                    <div className='shipping-dropdown'>
                      <p className='shipping-text-bold'>
                        {selectedShippingMethod
                          ? selectedShippingMethod.method === 'STANDARD'
                            ? 'FREE'
                            : `$${convertCentsToDollars(selectedShippingMethod.priceInCents)}`
                          : 'FREE'}
                      </p>

                      <img src={chevronDownIcon} alt='chevron right' className='shipping-chevron' />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          <div className='re-shipping-options-wrapper shipping-method-options'>
            {shippingMethods
              .sort((a, b) => a.priceInCents - b.priceInCents)
              .map((sm, index) => (
                <div
                  key={index}
                  className={`shipping-option ${
                    sm.method === selectedShippingType ? 'selected' : ''
                  }`}
                  onClick={() => handleSelectingShipping(sm)}
                >
                  <div className='option-outer-circle'></div>

                  <div className='option-content'>
                    <p className='option-title'>{cleanShippingTitle(sm)}</p>
                    <p className='option-price'>
                      {sm.method === 'STANDARD'
                        ? 'FREE'
                        : `$${convertCentsToDollars(sm.priceInCents)}`}
                    </p>
                  </div>
                </div>
              ))}
          </div>
        </>
      )}
    </div>
  );
}
