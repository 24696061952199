import { first } from 'lodash';
import { useState } from 'react';
import { useLocation, useMatch, useNavigate } from 'react-router-dom';

import { SubmissionAnswer } from 'client/dist/generated/alloy';
import { setItem } from 'client/dist/localstorage';
import { Question } from 'common/dist/models/questionnaire';

import { getFilteredPages, getPages, getQuestionSlugs } from 'modules/questionnaire/lib/question';
import { getAnswersForSubmission } from 'modules/questionnaire/lib/submission';
import { retrieveCategoriesFromUrl } from 'modules/shared/lib/experience/experience';

import {
  PreviousAnswersLocalStorage,
  QuestionnaireAnswers,
} from 'modules/questionnaire/models/questionnaire-answers';
import { RelevantProduct } from 'modules/shared/models/experience';

import { useQueryParams } from 'shared/context/url/query';

interface Props {
  questions: Question[];
  previousResults: QuestionnaireAnswers;
  onSubmit: (results: SubmissionAnswer[]) => void;
  onBack?: () => void;
  relevantProducts?: RelevantProduct[];
  basePath: string;
}

export default function useQuestionnaire({
  questions,
  previousResults,
  onSubmit,
  onBack: rootOnBack,
  relevantProducts,
  basePath,
}: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  const { buildPathWith } = useQueryParams();

  const match = useMatch(`${basePath}/*`);
  const parentPath = match?.pathnameBase || basePath;

  const [answers, setAnswers] = useState<QuestionnaireAnswers>(previousResults);

  const categories = retrieveCategoriesFromUrl(location);

  const pages = getPages(questions, relevantProducts);

  const slugs = getQuestionSlugs(questions, relevantProducts);

  /**
   * For filtering through the slugs, we need to be able to understand what part of
   * the pageSections the user is on
   *
   * @returns number - page number or index from the array slugs
   */
  const getPageFromUrl = (): number => {
    const urlSplit = location.pathname.split('/');

    return slugs.findIndex((s) => urlSplit.includes(s));
  };

  /**
   * Go forward within the Questionnaire and make sure we go to the correct page based on answer selections
   *
   * @param newAnswers QuestionnaireAnswers - updated / selected answers passed from the child component of QuestionnaireSection
   */
  const onNext = (newAnswers: QuestionnaireAnswers) => {
    window.scrollTo(0, 0);

    const page = getPageFromUrl();

    const updatedAnswers: QuestionnaireAnswers = {
      ...answers,
      ...newAnswers,
    };

    setAnswers(updatedAnswers);

    const previousAnswersUpdated: PreviousAnswersLocalStorage = {
      categories,
      answers: updatedAnswers,
    };

    setItem('recentIntakeAnswers', previousAnswersUpdated, true);

    const filteredPages: Question[][] = getFilteredPages(pages, updatedAnswers);

    let nextPage = page + 1;

    while (nextPage < pages.length && filteredPages[nextPage].length < 1) {
      nextPage++;
    }

    const nextSlug = slugs[nextPage];

    if (nextPage < pages.length) {
      navigate(buildPathWith(parentPath + '/' + nextSlug));
    } else {
      const results = getAnswersForSubmission(questions, updatedAnswers);

      onSubmit(results);
    }
  };

  /**
   * Go backwards within the ui in the questionnaire, we need to take them back
   * either one pageSection (that is filtered) or take them back through checkout experience
   */
  const onBack = () => {
    window.scrollTo(0, 0);

    const page = getPageFromUrl();

    const filteredPages: Question[][] = getFilteredPages(pages, answers);

    let prevPage = page - 1;

    while (prevPage >= 0 && filteredPages[prevPage].length < 1) {
      prevPage--;
    }

    if (prevPage >= 0) {
      navigate(-1);
    } else if (rootOnBack) {
      rootOnBack();
    }
  };

  const getInitialRoute = () => buildPathWith(parentPath + '/' + first(slugs));

  return {
    pages,
    slugs,
    answers,
    categories,
    parentPath,

    getInitialRoute,

    onNext,
    onBack,
  };
}
