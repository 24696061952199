import { bindActionCreators } from '@reduxjs/toolkit';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  useGenerateSetupIntent,
  useGetCustomerReferralCreditBalance,
  useGetNonCustomerUpsellProducts,
  useGetPaymentMethods,
} from 'client/dist/generated/alloy';

import useCart from 'modules/shared/hooks/useCart';

import { retrieveCategoriesFromUrl } from 'modules/shared/lib/experience/experience';
import { getDeepProductsFromGroupedProducts } from 'modules/shared/lib/product';
import sendAbandonEvent from 'modules/tracking/lib/sendAbandonEvent';

import { processCheckout } from 'modules/shared/sub-modules/checkout/store/actions';

export default function useCheckoutState() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { cart } = useCart();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { isLoading: isLoadingReferralCredits } = useGetCustomerReferralCreditBalance();
  const { isLoading: isLoadingPaymentMethods } = useGetPaymentMethods();
  const { data: setupIntent, isLoading: isLoadingSetupIntent } = useGenerateSetupIntent();
  const { mutate: mutateUpsellProducts } = useGetNonCustomerUpsellProducts();

  const isAnyLoading =
    isLoading || isLoadingReferralCredits || isLoadingPaymentMethods || isLoadingSetupIntent;

  const dispatchProcessCheckout = bindActionCreators(processCheckout, dispatch);

  const onPlaceOrder = async () => {
    window.scrollTo(0, 0);

    try {
      setIsLoading(true);

      const products = getDeepProductsFromGroupedProducts(cart.products);
      const categories = retrieveCategoriesFromUrl(location);

      await dispatchProcessCheckout(categories, 'request');
      await mutateUpsellProducts();

      // checkout completed with product
      sendAbandonEvent({
        event: 'CHECKOUT_COMPLETED',
        categories,
        experience: 'request',
        products,
      });

      const searchParams = new URLSearchParams(location.search);

      navigate({
        pathname: '/request-confirmation',
        search: `?${searchParams.toString()}`,
      });
    } catch (error) {
      setIsLoading(false);
    }
  };

  return { isAnyLoading, setupIntent, onPlaceOrder };
}
