import { useCallback, useEffect, useRef, useState } from 'react';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSave: (blob: Blob, baseName: string) => void;
}

export default function useRecordAudioModalState({ isOpen, onClose, onSave }: Props) {
  const [hasAudioAccess, setHasAudioAccess] = useState(true);
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null);
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [recordedChunks, setRecordedChunks] = useState<Blob[]>([]);

  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const mediaStreamRef = useRef<MediaStream | null>(null);

  useEffect(() => {
    if (isOpen) {
      const mediaOptions = getMediaOptions();

      if (!mediaOptions) {
        setHasAudioAccess(false);
      }
    }
  }, [isOpen]);

  const getMediaOptions = () => {
    if (MediaRecorder.isTypeSupported('audio/mp3')) {
      return { mimeType: 'audio/mp3' };
    } else if (MediaRecorder.isTypeSupported('audio/ogg')) {
      return { mimeType: 'audio/ogg' };
    } else if (MediaRecorder.isTypeSupported('audio/webm')) {
      return { mimeType: 'audio/webm' };
    } else if (MediaRecorder.isTypeSupported('video/mp4')) {
      // iOS apparently only supports this for audio :eye_roll:
      return { mimeType: 'video/mp4' };
    } else {
      return null;
    }
  };

  const handleDataAvailable = useCallback(
    ({ data }: { data: Blob }) => {
      if (data.size > 0) {
        const chunks = recordedChunks.concat(data);
        setRecordedChunks(chunks);

        const mediaOptions = getMediaOptions();

        const mimeType = mediaOptions?.mimeType || 'audio/webm';

        const blob = new Blob(chunks, {
          type: mimeType,
        });

        setAudioBlob(blob);
        setRecordedChunks([]);
      }
    },
    [setRecordedChunks],
  );

  const onStartRecording = useCallback(() => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        mediaStreamRef.current = stream;

        const mediaOptions = getMediaOptions();

        if (!mediaOptions) {
          setHasAudioAccess(false);

          return;
        }

        const mediaRecorder = new MediaRecorder(stream, mediaOptions);
        mediaRecorderRef.current = mediaRecorder;

        mediaRecorder.addEventListener('dataavailable', handleDataAvailable);
        mediaRecorder.start();

        setIsRecording(true);
      })
      .catch(() => setHasAudioAccess(false));
  }, [handleDataAvailable, getMediaOptions]);

  const onStopRecording = useCallback(() => {
    setIsRecording(false);

    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
    }
  }, [mediaRecorderRef, setIsRecording]);

  const onRemove = () => {
    if (mediaStreamRef.current) {
      mediaStreamRef.current.getTracks().forEach((track) => track.stop());
      mediaStreamRef.current = null;
    }

    setRecordedChunks([]);
    setAudioBlob(null);
  };

  const onSubmit = () => {
    if (audioBlob) {
      onSave(audioBlob, 'audio_recording');
    }

    onCloseModal();
  };

  const onCloseModal = () => {
    onStopRecording();
    onRemove();
    onClose();
  };

  return {
    isRecording,

    audioBlob,

    hasAudioAccess,

    onStartRecording,
    onStopRecording,

    onSubmit,
    onRemove,
    onCloseModal,
  };
}
