/**
 * allows for trimming the file name for display purposes otherwise a very long file
 * will get annoying to look (used for messages)
 *
 * @param filename string
 * @param maxLength maxLength
 * @returns string
 */
export const trimFilename = (filename: string, maxLength: number = 60): string => {
  const extensionMatch = filename.match(/\.[0-9a-z]+$/i);
  const extension = extensionMatch ? extensionMatch[0] : '';
  const baseName = filename.replace(extension, '');

  if (filename.length <= maxLength) {
    return filename;
  }

  const prefixLength = Math.floor((maxLength - extension.length - 3) / 2);
  const suffixLength = maxLength - extension.length - 3 - prefixLength;

  return `${baseName.slice(0, prefixLength)}…${baseName.slice(-suffixLength)}${extension}`;
};
