"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HEADER_ABOUT_US_SURVEY_QUESTIONNAIRE = void 0;
exports.HEADER_ABOUT_US_SURVEY_QUESTIONNAIRE = {
    title: 'Heard About Us Survey',
    questions: [
        {
            title: 'How did you hear about Alloy?',
            slug: 'heard-about-us',
            description: 'Select all that apply.',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'MultipleChoice',
            headerTitle: '',
            options: [
                {
                    answer: 'Search engine (Google, Bing, Yahoo, etc.)',
                    slug: 'heard-about-us-search-engine',
                },
                {
                    answer: 'Alloy blog',
                    slug: 'heard-about-us-blog',
                },
                {
                    answer: 'Podcast',
                    slug: 'heard-about-us-podcast',
                    followUp: {
                        title: 'Which podcast?',
                        slug: 'heard-about-us-podcast-follow-up',
                        description: 'Type here',
                        isRequired: true,
                        isImportant: false,
                        type: 'MultipleLine',
                        mdiCaseQuestion: false,
                    },
                },
                {
                    answer: 'Youtube',
                    slug: 'heard-about-us-youtube',
                    followUp: {
                        title: 'Which Youtube channel?',
                        slug: 'heard-about-us-youtube-follow-up',
                        description: 'Type here',
                        isRequired: true,
                        isImportant: false,
                        type: 'MultipleLine',
                        mdiCaseQuestion: false,
                    },
                },
                {
                    answer: 'Event',
                    slug: 'heard-about-us-event',
                    followUp: {
                        title: 'Which event?',
                        slug: 'heard-about-us-event-follow-up',
                        description: 'Type here',
                        isRequired: true,
                        isImportant: false,
                        type: 'MultipleLine',
                        mdiCaseQuestion: false,
                    },
                },
                {
                    answer: 'Social media ad (Instagram, Facebook, TikTok, etc.)',
                    slug: 'heard-about-us-ad',
                },
                {
                    answer: 'Social media influencer',
                    slug: 'heard-about-us-influencer',
                    followUp: {
                        title: 'Which social media influencer?',
                        slug: 'heard-about-us-influencer-follow-up',
                        description: 'Type here',
                        isRequired: true,
                        isImportant: false,
                        type: 'MultipleLine',
                        mdiCaseQuestion: false,
                    },
                },
                {
                    answer: 'Friend or family',
                    slug: 'heard-about-us-friend-family',
                },
                {
                    answer: 'TV commercial',
                    slug: 'heard-about-us-commercial',
                },
                {
                    answer: 'Radio',
                    slug: 'heard-about-us-radio',
                },
                {
                    answer: 'Press mention (online, magazine, TV, etc.)',
                    slug: 'heard-about-us-press',
                    followUp: {
                        title: 'Which press mention?',
                        slug: 'heard-about-us-press-follow-up',
                        description: 'Type here',
                        isRequired: true,
                        isImportant: false,
                        type: 'MultipleLine',
                        mdiCaseQuestion: false,
                    },
                },
                {
                    answer: 'Other',
                    slug: 'heard-about-us-other',
                    followUp: {
                        title: 'How did you hear about us?',
                        slug: 'heard-about-us-other-follow-up',
                        description: 'Type here',
                        isRequired: true,
                        isImportant: false,
                        type: 'MultipleLine',
                        mdiCaseQuestion: false,
                    },
                },
            ],
            mdiCaseQuestion: false,
        },
        {
            title: 'How did you first hear about Alloy?',
            slug: 'first-heard',
            parentSlug: 'heard-about-us',
            description: 'Select one.',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'SingleChoice',
            headerTitle: '',
            options: [
                {
                    answer: 'Search engine (Google, Bing, Yahoo, etc.)',
                    slug: 'first-heard-search-engine',
                    parentOptionSlug: 'heard-about-us-search-engine',
                },
                {
                    answer: 'Alloy blog',
                    slug: 'first-heard-blog',
                    parentOptionSlug: 'heard-about-us-blog',
                },
                {
                    answer: 'Podcast',
                    slug: 'first-heard-podcast',
                    parentOptionSlug: 'heard-about-us-podcast',
                },
                {
                    answer: 'Youtube',
                    slug: 'first-heard-youtube',
                    parentOptionSlug: 'heard-about-us-youtube',
                },
                {
                    answer: 'Event',
                    slug: 'first-heard-event',
                    parentOptionSlug: 'heard-about-us-event',
                },
                {
                    answer: 'Social media ad (Instagram, Facebook, TikTok, etc.)',
                    slug: 'first-heard-ad',
                    parentOptionSlug: 'heard-about-us-ad',
                },
                {
                    answer: 'Social media influencer',
                    slug: 'first-heard-influencer',
                    parentOptionSlug: 'heard-about-us-influencer',
                },
                {
                    answer: 'Friend or family',
                    slug: 'first-heard-friend-family',
                    parentOptionSlug: 'heard-about-us-friend-family',
                },
                {
                    answer: 'TV commercial',
                    slug: 'first-heard-commercial',
                    parentOptionSlug: 'heard-about-us-commercial',
                },
                {
                    answer: 'Radio',
                    slug: 'first-heard-radio',
                    parentOptionSlug: 'heard-about-us-radio',
                },
                {
                    answer: 'Press mention (online, magazine, TV, etc.)',
                    slug: 'first-heard-press',
                    parentOptionSlug: 'heard-about-us-press',
                },
                {
                    answer: 'Other',
                    slug: 'first-heard-other',
                    parentOptionSlug: 'heard-about-us-other',
                },
            ],
            mdiCaseQuestion: false,
        },
    ],
};
