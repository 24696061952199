"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SEXUAL_QUESTIONNAIRE = void 0;
exports.SEXUAL_QUESTIONNAIRE = {
    title: 'O-mazing Cream Assessment',
    questions: [
        {
            title: "Let's discuss your sexual health concerns.",
            slug: 'sexual-health-concerns',
            description: 'Check all that apply:',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'MultipleChoice',
            headerTitle: 'Vaginal and vulvar health:',
            options: [
                {
                    answer: "Decreased sexual desire (I don't feel the desire to engage in sexual activity)",
                    slug: 'sexual-health-concerns-decreased-sexual-desire',
                },
                {
                    answer: 'Decreased sexual arousal (once engaged in sexual activity, I find it difficult to become aroused)',
                    slug: 'sexual-health-concerns-decreased-sexual-arousal',
                },
                {
                    answer: 'Difficulty with orgasm',
                    slug: 'sexual-health-concerns-difficulty-orgasm',
                },
                {
                    answer: 'Painful sex',
                    slug: 'sexual-health-concerns-painful-sex',
                },
                {
                    answer: 'Decreased lubrication',
                    slug: 'sexual-health-concerns-decreased-lubrication',
                },
            ],
            mdiCaseQuestion: true,
        },
        {
            title: 'Are you currently using blood thinners and/or nitrates?',
            slug: 'using-blood-thinners-andor-nitrates',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'SingleChoice',
            headerTitle: 'Your health history:',
            options: [
                {
                    answer: 'Yes',
                    slug: 'using-blood-thinners-andor-nitrates-yes',
                },
                {
                    answer: 'No',
                    slug: 'using-blood-thinners-andor-nitrates-no',
                },
            ],
            mdiCaseQuestion: true,
        },
        {
            title: 'Are you allergic to Sildenafil, Pentoxifylline, and/or Ergoloid Mesylate?',
            slug: 'allergic-to-sildenafil-pentoxifylline-ergoloid-mesylate',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'SingleChoice',
            headerTitle: 'Your health history:',
            options: [
                {
                    answer: 'Yes',
                    slug: 'allergic-to-sildenafil-pentoxifylline-ergoloid-mesylate-yes',
                },
                {
                    answer: 'No',
                    slug: 'allergic-to-sildenafil-pentoxifylline-ergoloid-mesylate-no',
                },
            ],
            mdiCaseQuestion: true,
        },
        {
            title: 'Are you pregnant and/or breastfeeding?',
            slug: 'pregnant-andor-breastfeeding',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'SingleChoice',
            headerTitle: 'Your health history:',
            options: [
                {
                    answer: 'Yes',
                    slug: 'pregnant-andor-breastfeeding-yes',
                },
                {
                    answer: 'No',
                    slug: 'pregnant-andor-breastfeeding-no',
                },
            ],
            mdiCaseQuestion: true,
        },
        {
            title: 'Please list all current medications.',
            slug: 'list-all-other-medications',
            description: 'If you have no medications, continue to the next question.',
            isRequired: false,
            isImportant: false,
            userKey: 'currentMedications',
            type: 'Medications',
            headerTitle: 'Your health history:',
            options: [],
            mdiCaseQuestion: true,
        },
        {
            title: 'Do you have any additional allergies or reactions to medications or adhesives?',
            slug: 'any-allergies',
            description: 'This includes all allergies, intolerances, and reactions to foods or previous medications (e.g. peanut allergies)',
            conclusion: true,
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'SingleChoice',
            headerTitle: 'Your health history:',
            options: [
                {
                    answer: 'Yes',
                    slug: 'any-allergies-yes',
                },
                {
                    answer: 'No',
                    slug: 'any-allergies-no',
                },
            ],
            mdiCaseQuestion: true,
        },
        {
            title: 'Please list all allergies or reactions to medications or adhesives.',
            slug: 'list-all-allergies-and-reactions',
            description: '',
            conclusion: true,
            isRequired: false,
            isImportant: false,
            userKey: 'currentAllergies',
            type: 'Allergies',
            headerTitle: 'Your health history:',
            options: [],
            orLogic: {
                'any-allergies': ['any-allergies-yes'],
            },
            mdiCaseQuestion: true,
        },
        {
            title: 'What is your date of birth?',
            slug: 'date-of-birth',
            description: '',
            conclusion: true,
            isRequired: true,
            isImportant: false,
            userKey: 'dob',
            type: 'Date',
            headerTitle: 'Your health history:',
            placeholder: 'MM/DD/YYYY',
            options: [],
            mdiCaseQuestion: true,
        },
        {
            title: 'Anything else you would like to share with the doctor?',
            slug: 'anything-else-share-doctor',
            description: '',
            conclusion: true,
            isRequired: false,
            isImportant: true,
            userKey: '',
            type: 'MultipleLine',
            headerTitle: 'Your health history:',
            placeholder: 'Type here',
            options: [],
            mdiCaseQuestion: true,
        },
    ],
};
