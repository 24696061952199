import { useLocation } from 'react-router-dom';

import { useGetMessagingStatus } from 'client/dist/generated/alloy';

import BannerWrapper from 'shared/components/wrappers/Banner';

import { useAppSelector } from 'shared/store/reducers';

/**
 * only used in the messages tab for when we need to communicate certain copy to customers
 * via the messages tab when things could be down, some specific info, etc
 */
export default function MessagesBannerWrapper() {
  const { pathname } = useLocation();

  const { messagesBanner } = useAppSelector((state) => state.alloy);

  const { data: messaginStatus = 'PENDING' } = useGetMessagingStatus();

  if (
    messaginStatus === 'INACTIVE' ||
    !messagesBanner?.fields.isDashboardOn ||
    pathname.includes('-confirmation')
  ) {
    return <BannerWrapper />;
  }

  return <BannerWrapper overrideBanner={messagesBanner} />;
}
