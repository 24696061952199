import { useEffect, useRef } from 'react';

interface Props {
  dataTemplateId: string;
  dataStyleHeight?: string;
  dataStyleWidth?: string;
}

// Trustpilot doesn't allow us to display their reviews unless we use one of the widgets they provide.
export default function TrustpilotWidget({
  dataTemplateId,
  dataStyleHeight = '240px',
  dataStyleWidth = '100%',
}: Props) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded just yet.
    // When it is, it will automatically load the TrustBox.

    if (!window.Trustpilot) {
      return;
    }
    window.Trustpilot.loadFromElement(ref.current, true);
  }, []);

  return (
    <div
      ref={ref}
      data-locale='en-US'
      data-template-id={dataTemplateId}
      data-businessunit-id='61b244919580764bc1d1b631'
      data-style-height={dataStyleHeight}
      data-style-width={dataStyleWidth}
      data-tags='SelectedReview'
      data-review-languages='en'
      data-text-color='#09063b'
    >
      <a href='https://www.trustpilot.com/review/myalloy.com' target='_blank' rel='noopener'>
        Trustpilot
      </a>
    </div>
  );
}
