import './NewProducts.scss';

import classNames from 'classnames';
import { uniq } from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';

import { requestFlows } from 'modules/request-experience/data/flows';

import { formatExperienceURL } from 'modules/shared/lib/url';

interface Props {
  theme: 'light' | 'dark';
}

export default function NewProductsBlock({ theme }: Props) {
  const navigate = useNavigate();
  const location = useLocation();

  const experienceUrl = formatExperienceURL(
    '/request-experience',
    location,
    ['skin-health'],
    [['productIds[]', uniq(requestFlows['skin-health'].productIds).join(',')]],
  );
  const onStart = () => navigate(experienceUrl);

  return (
    <div className={classNames('new-products-block', `new-products-theme-${theme}`)}>
      <h1 className='new-products-title'>This just in - New M4 Skincare</h1>
      <p>
        With a newly formulated face and eye serum! Save up to $70 when you buy two or more skincare
        products.
      </p>

      <button type='button' onClick={onStart} className='new-products-link'>
        UPGRADE YOUR ROUTINE
      </button>
    </div>
  );
}
