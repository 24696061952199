import { useEffect, useState } from 'react';

import DomProductRegistry from 'client/dist/product/productRegistry';
import { DeepProduct } from 'common/dist/products/productFrequency';

import { getProductPrice } from '../lib/product/pricing';

interface Props {
  products: DeepProduct[];
  dependencies?: (string | boolean)[];
}

export default function useTotalProductPrices({ products, dependencies = [] }: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [unbundledTotal, setUnbundledTotal] = useState(0);
  const [bundledTotal, setBundledTotal] = useState(0);

  useEffect(() => {
    getTotals();
  }, dependencies);

  const getTotals = async () => {
    setIsLoading(true);
    /**
     * Grouping products here so we can discard child MHT products when calculating totals.
     * This is because prices for products like Progesterone aren't displayed when they are
     * bundled with other MHT products.
     * */
    const groupedProducts = (
      await DomProductRegistry.get().getRecurringProductsForV2(products)
    ).flat();

    const prices = await Promise.all(
      groupedProducts
        .flatMap((gp) => gp.alloyProduct.parent)
        .map(async (dp) => {
          return {
            unbundled: await getProductPrice(dp),
            bundled: await getProductPrice(dp, products),
          };
        }),
    );

    setUnbundledTotal(prices.map((p) => p.unbundled).reduce((a, p) => a + p, 0) / 100);
    setBundledTotal(prices.map((p) => p.bundled).reduce((a, p) => a + p, 0) / 100);

    setIsLoading(false);
  };

  return {
    isLoading,

    unbundledTotal,
    bundledTotal,
  };
}
