import { bindActionCreators } from '@reduxjs/toolkit';
import { first, isEmpty, uniqueId } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  PickCustomerMedicationNameOrDose as PickCustomerMedicationFields,
  createCustomerMedications,
  deactivateCustomerMedication,
  updateCustomerMedications,
} from 'client/dist/generated/alloy';

import { updateCustomer } from 'modules/shared/store/customer';
import { updateLocalPreCustomer } from 'modules/shared/store/local-pre-customer-slice';

import { useAppSelector } from 'shared/store/reducers';

import { MedicationEdit } from '../models/medication-edit';

const INIT_MEDICATION: PickCustomerMedicationFields = {
  name: '',
  dose: '',
};

export default function useMedicationsQuestionState() {
  const dispatch = useDispatch();

  const [newMedication, setNewMedication] = useState<PickCustomerMedicationFields>(INIT_MEDICATION);

  const { customer, isAuthenticated } = useAppSelector((state) => state.alloy);
  const { localPreCustomer } = useAppSelector((state) => state.experience);

  const [medications, setMedications] = useState<MedicationEdit[]>(
    (isAuthenticated
      ? customer?.currentMedications || []
      : localPreCustomer.currentMedications || []
    ).map((medication) => ({ editId: uniqueId(), medication, isEditing: false })),
  );

  const newMedicationRef = useRef(newMedication);
  const medicationsRef = useRef(medications);

  const dispatchUpdateCustomer = bindActionCreators(updateCustomer, dispatch);

  useEffect(() => {
    newMedicationRef.current = newMedication;
  }, [JSON.stringify(newMedication)]);

  useEffect(() => {
    medicationsRef.current = medications;
  }, [JSON.stringify(medications)]);

  useEffect(() => {
    return () => {
      handleUnmount();
    };
  }, []);

  /**
   * TODO: with the current design, we need to be able to handle unmount since there is no clear save and continue button
   * this is something that would absolutely love to fix for phase 2 where the ui is a bit better and allows for more
   * clear adding, editing, and removing without relying on continue if you have a sort of filled out new field
   *
   * for phase 2, also incorporate a more shared layout with medications that way the hooks don't feel super the same,
   * reason for not fully consolidating atm is timing
   *
   * reason for using a ref for medications is because on unmount, the useEffect won't have the most recent data
   * without using a ref, would have just used the redux values but it is the same thing, no recent data on unmount.
   * if you add a dependency then this unmount creates duplicate values anytime you use the add new medication button
   */
  const handleUnmount = async () => {
    const latestNewMedicatoin = newMedicationRef.current;
    const latestMedications = medicationsRef.current;

    if (isEmpty(latestNewMedicatoin.name.trim()) || !latestMedications) return;

    if (isAuthenticated) {
      const createdMedications = await createCustomerMedications([latestNewMedicatoin]);

      // since partial on medications (we know these will exist if auth) so just init
      const currentMedications = latestMedications
        .filter(({ medication }) => !!medication.id)
        .map(({ medication }) => ({
          id: medication.id!,
          customerId: medication.customerId!,
          name: medication.name!,
          dose: medication.dose,
          active: medication.active!,
        }));

      dispatchUpdateCustomer({
        currentMedications: [...currentMedications, ...createdMedications],
      });
    } else {
      const currentMedications = latestMedications.map(({ medication }) => medication);

      dispatch(
        updateLocalPreCustomer({
          currentMedications: [...currentMedications, latestNewMedicatoin],
        }),
      );
    }
  };

  /**
   * used to create an medication on action of 'add another medication' either in the db and attach to customer in redux or
   * create locally and attach via redux
   */
  const onCreate = async () => {
    let newMedicationData: MedicationEdit = {
      editId: uniqueId(),
      medication: newMedication,
      isEditing: false,
    };

    if (isAuthenticated) {
      const medications = await createCustomerMedications([newMedication]);

      dispatchUpdateCustomer({
        currentMedications: [...(customer?.currentMedications || []), ...medications],
      });

      newMedicationData.medication = first(medications)!;
    } else {
      dispatch(
        updateLocalPreCustomer({
          currentMedications: [...(localPreCustomer.currentMedications || []), newMedication],
        }),
      );
    }

    setMedications((prevMedications) => [...prevMedications, newMedicationData]);

    setNewMedication(INIT_MEDICATION);
  };

  const onUpdate = async (id: string) => {
    const medicationIndex = medications.findIndex(({ editId }) => editId === id);

    if (medicationIndex === -1) return;

    const updatedMedication = medications[medicationIndex].medication;

    if (isAuthenticated && !!updatedMedication.id) {
      const updatedMedications = await updateCustomerMedications([
        {
          id: updatedMedication.id,
          name: updatedMedication.name!,
          dose: updatedMedication.dose,
        },
      ]);

      dispatchUpdateCustomer({
        currentMedications: (customer?.currentMedications || []).map((existingMedication) => {
          const updatedMedication = updatedMedications.find(
            (medication) => medication.id === existingMedication.id,
          );

          return updatedMedication || existingMedication;
        }),
      });
    } else {
      const updatedMedications = [...(localPreCustomer.currentMedications || [])];
      updatedMedications[medicationIndex] = updatedMedication;

      dispatch(
        updateLocalPreCustomer({
          currentMedications: updatedMedications,
        }),
      );
    }

    setMedications((prev) => {
      const newMedications = [...prev];

      newMedications[medicationIndex].isEditing = false;

      return newMedications;
    });
  };

  const onOpenEdit = (id: string) => {
    const medicationIndex = medications.findIndex(({ editId }) => editId === id);

    if (medicationIndex === -1) return;

    setMedications((prev) =>
      prev.map((medication, index) =>
        index === medicationIndex ? { ...medication, isEditing: true } : medication,
      ),
    );
  };

  const onEditMedication = (id: string, key: string, value: string) =>
    setMedications((prev) =>
      prev.map((prevMedicationEdit) =>
        prevMedicationEdit.editId === id
          ? {
              ...prevMedicationEdit,
              medication: { ...prevMedicationEdit.medication, [key]: value },
            }
          : prevMedicationEdit,
      ),
    );

  const onRemove = async (id: string) => {
    const medicationIndex = medications.findIndex(({ editId }) => editId === id);

    if (medicationIndex === -1) return;

    const medicationToRemove = medications[medicationIndex].medication;

    if (!medicationToRemove) return;

    if (isAuthenticated && !!medicationToRemove.id) {
      await deactivateCustomerMedication(medicationToRemove.id);

      const customerMedications = (customer?.currentMedications || []).filter(
        (medication) => medication.id !== medicationToRemove.id,
      );

      dispatchUpdateCustomer({
        currentMedications: customerMedications,
      });
    } else if (!isAuthenticated) {
      const updatedMedications = [...(localPreCustomer.currentMedications || [])];

      updatedMedications.splice(medicationIndex, 1);

      dispatch(
        updateLocalPreCustomer({
          currentMedications: updatedMedications,
        }),
      );
    }

    setMedications((prev) => prev.filter((_, index) => index !== medicationIndex));
  };

  return {
    medications,

    newMedication,
    setNewMedication,

    onUpdate,
    onOpenEdit,
    onEditMedication,
    onRemove,
    onCreate,
  };
}
