"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOCTOR_SURVEY_QUESTIONNAIRE = void 0;
exports.DOCTOR_SURVEY_QUESTIONNAIRE = {
    title: 'Doctor Survey',
    questions: [
        {
            title: 'Who is your doctor?',
            slug: 'doctor-name',
            description: '',
            isRequired: false,
            isImportant: false,
            userKey: '',
            type: 'Hidden',
            options: [],
            mdiCaseQuestion: false,
        },
        {
            title: 'Encounter Id',
            slug: 'encounter-id',
            description: '',
            isRequired: false,
            isImportant: false,
            userKey: '',
            type: 'Hidden',
            options: [],
            mdiCaseQuestion: false,
        },
        {
            title: 'Timeliness of Service',
            slug: 'timeliness-of-service',
            description: "Please rate your satisfaction with the timeliness of your telemedicine consultation, including the waiting time and the doctor's responsiveness, on a scale of 1-5, where 1 is 'very dissatisfied' and 5 is 'very satisfied.'",
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'SingleChoice',
            headerTitle: 'Doctor Survey:',
            options: [
                {
                    answer: '1 - Very dissatisfied',
                    slug: 'timeliness-of-service-1',
                },
                {
                    answer: '2 - Dissatisfied',
                    slug: 'timeliness-of-service-2',
                },
                {
                    answer: '3 - Neutral',
                    slug: 'timeliness-of-service-3',
                },
                {
                    answer: '4 - Satisfied',
                    slug: 'timeliness-of-service-4',
                },
                {
                    answer: '5 - Very satisfied',
                    slug: 'timeliness-of-service-5',
                },
            ],
            mdiCaseQuestion: false,
        },
        {
            title: 'Quality of Interaction',
            slug: 'quality-of-interaction',
            description: "Please rate your satisfaction with the quality of recommendations and information provided by your doctor on a scale of 1-5, where 1 is 'very dissatisfied' and 5 is 'very satisfied.'",
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'SingleChoice',
            headerTitle: 'Doctor Survey:',
            options: [
                {
                    answer: '1 - Very dissatisfied',
                    slug: 'quality-of-interaction-1',
                },
                {
                    answer: '2 - Dissatisfied',
                    slug: 'quality-of-interaction-2',
                },
                {
                    answer: '3 - Neutral',
                    slug: 'quality-of-interaction-3',
                },
                {
                    answer: '4 - Satisfied',
                    slug: 'quality-of-interaction-4',
                },
                {
                    answer: '5 - Very satisfied',
                    slug: 'quality-of-interaction-5',
                },
            ],
            mdiCaseQuestion: false,
        },
        {
            title: 'Communication Effectiveness',
            slug: 'communication-effectiveness',
            description: "Please rate how effective your provider was in their communication (supportive, clear, made you feel comfortable) on a scale of 1-5, where 1 is 'not at all effective' and 5 is 'extremely effective.'",
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'SingleChoice',
            headerTitle: 'Doctor Survey:',
            options: [
                {
                    answer: '1 - Not at all effective',
                    slug: 'communication-effectiveness-1',
                },
                {
                    answer: '2 - Slightly effective',
                    slug: 'communication-effectiveness-2',
                },
                {
                    answer: '3 - Moderately effective',
                    slug: 'communication-effectiveness-3',
                },
                {
                    answer: '4 - Very effective',
                    slug: 'communication-effectiveness-4',
                },
                {
                    answer: '5 - Extremely effective',
                    slug: 'communication-effectiveness-5',
                },
            ],
            mdiCaseQuestion: false,
        },
        {
            title: 'Treatment Plan Satisfaction',
            slug: 'treatment-plan-satisfaction',
            description: "Please rate your satisfaction with the treatment plan or recommendations provided by your doctor on a scale of 1-5, where 1 is 'very dissatisfied' and 5 is 'very satisfied.'",
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'SingleChoice',
            headerTitle: 'Doctor Survey:',
            options: [
                {
                    answer: '1 - Very dissatisfied',
                    slug: 'treatment-plan-satisfaction-1',
                },
                {
                    answer: '2 - Dissatisfied',
                    slug: 'treatment-plan-satisfaction-2',
                },
                {
                    answer: '3 - Neutral',
                    slug: 'treatment-plan-satisfaction-3',
                },
                {
                    answer: '4 - Satisfied',
                    slug: 'treatment-plan-satisfaction-4',
                },
                {
                    answer: '5 - Very satisfied',
                    slug: 'treatment-plan-satisfaction-5',
                },
            ],
            mdiCaseQuestion: false,
        },
        {
            title: 'Overall Experience',
            slug: 'overall-experience',
            description: "Please rate your overall satisfaction with your experience with your doctor on a scale of 1-5, where 1 is 'very dissatisfied' and 5 is 'very satisfied.'",
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'SingleChoice',
            headerTitle: 'Doctor Survey:',
            options: [
                {
                    answer: '1 - Very dissatisfied',
                    slug: 'overall-experience-1',
                },
                {
                    answer: '2 - Dissatisfied',
                    slug: 'overall-experience-2',
                },
                {
                    answer: '3 - Neutral',
                    slug: 'overall-experience-3',
                },
                {
                    answer: '4 - Satisfied',
                    slug: 'overall-experience-4',
                },
                {
                    answer: '5 - Very satisfied',
                    slug: 'overall-experience-5',
                },
            ],
            mdiCaseQuestion: false,
        },
        {
            title: 'Additional Comments',
            slug: 'additional-comments',
            description: 'Is there anything else you would like to share about your experience with your doctor? \n\n These answers will not be shared with your doctor and will only be used to improve patient experience. If you do have a question for your doctor, please reach out via your dashboard.',
            isRequired: false,
            isImportant: false,
            userKey: '',
            type: 'MultipleLine',
            headerTitle: 'Doctor Survey:',
            placeholder: 'Type here',
            options: [],
            mdiCaseQuestion: false,
        },
    ],
};
