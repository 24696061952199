import './TextAreaField.scss';

import { useEffect, useRef } from 'react';
import { Input, InputGroup } from 'rsuite';

interface Props {
  name: string;
  label?: string;
  value?: string;
  placeholder?: string;
  onChange: (value: string) => void;
  classes?: string;
  rows?: number;
  isFollowUp?: boolean;
  onSend?: () => void;
}

export const TextAreaField = ({
  name,
  label,
  value,
  placeholder,
  onChange,
  classes = 'field-input',
  rows = 4,
  isFollowUp = false,
  onSend,
}: Props) => {
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

  // allow for auto scrolling to a new line
  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.scrollTop = textAreaRef.current.scrollHeight;
    }
  }, [value]);

  /**
   * mimics the idea that when 'sending':
   * - on cmd/alt + enter you can create a new line
   * - on enter you can send an action (like a type=submit button basically)
   */
  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (!onSend) return;

    if ((event.metaKey || event.altKey) && event.key === 'Enter') {
      event.preventDefault();

      // new line on cmd+enter || alt+enter
      onChange(value + '\n');
    } else if (event.key === 'Enter') {
      event.preventDefault();

      // act like a send button
      onSend();
    }
  };

  return (
    <div className='input-field-wrapper text-area-field'>
      {isFollowUp ? (
        <InputGroup inside>
          <InputGroup.Addon>{label}</InputGroup.Addon>
          <Input
            name={name}
            as='textarea'
            rows={rows}
            placeholder={placeholder}
            className={classes}
            value={value}
            onChange={onChange}
            data-private='lipsum'
            data-sentry-mask
          />
        </InputGroup>
      ) : (
        <>
          {label && <p className='field-label'>{label}</p>}

          <Input
            ref={textAreaRef}
            name={name}
            as='textarea'
            rows={rows}
            placeholder={placeholder}
            className={classes}
            value={value}
            onChange={onChange}
            onKeyDown={onKeyDown}
            data-private='lipsum'
            data-sentry-mask
          />
        </>
      )}
    </div>
  );
};
