import { StyleSheet } from '@react-pdf/renderer';

/**
 * Styles used in PDF invoice components.
 */
export const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: 'white',
    margin: 10,
    padding: 20,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
  },
  headerLeft: {
    width: '50%',
    fontSize: 20,
  },
  headerRight: {
    width: '50%',
  },
  headerLogo: {
    width: 75,
    marginLeft: 'auto',
    paddingRight: 10,
  },
  orderNumberAndDate: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'row',
  },
  orderNumberAndDateSpacing: {
    marginLeft: 40,
  },
  fontEmphasized: {
    fontSize: 14,
  },
  fontNormalSpaced: {
    fontSize: 12,
    marginTop: 5,
  },
  addressInfo: {
    marginTop: 40,
    display: 'flex',
    flexDirection: 'row',
  },
  addressInfoLeft: {
    width: '50%',
  },
  addressInfoRight: {
    width: '50%',
  },
  amountSummary: {
    marginTopFirstPage: 30,
    marginTopOtherPages: 195,
    fontSize: 20,
    marginBottom: 30,
  },
  table: {
    width: '100%',
    fontSize: 10,
    paddingRight: 20,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: '1px solid #EEE',
    paddingTop: 8,
    paddingBottom: 8,
  },
  tableHeader: {
    borderTop: 'none',
  },
  description: {
    width: '60%',
  },
  unitPrice: {
    width: '15%',
    textAlign: 'right',
  },
  qty: {
    width: '10%',
    textAlign: 'right',
  },
  extendedPrice: {
    width: '15%',
    textAlign: 'right',
  },
  vendorInfoAndLineItemsSummary: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 20,
  },
  vendorInfoSpacing: {
    marginTop: 20,
  },
  vendorInfoAndLineItemsSummaryLeft: {
    width: '60%',
    display: 'flex',
    flexDirection: 'column',
  },
  vendorInfoAndLineItemsSummaryRight: {
    width: '40%',
    display: 'flex',
    flexDirection: 'column',
    fontSize: 10,
    paddingRight: 20,
  },
  lineItemNpiAndNdc: {
    fontSize: 8,
    marginTop: 5,
    marginLeft: 5,
  },
  lineItemSummaryDescription: {
    width: '70%',
  },
  lineItemSummaryAmount: {
    width: '30%',
    textAlign: 'right',
  },
  amountPaidDescription: {
    width: '70%',
    fontSize: 14,
  },
  amountPaidAmount: {
    width: '30%',
    textAlign: 'right',
    fontSize: 14,
  },
  footer: {
    marginTop: 'auto',
    paddingRight: 20,
  },
  footerLine: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: '1px solid #EEE',
    fontSize: 8,
  },
  footerSummaryInfo: {
    marginTop: 4,
  },
  footerPageInfo: {
    marginLeft: 'auto',
    marginTop: 4,
    alignSelf: 'center',
  },
});
