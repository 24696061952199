export function getUtms(location) {
    const params = new URLSearchParams(location.search);
    return {
        utm_source: params.get('utm_source'),
        utm_campaign: params.get('utm_campaign'),
        utm_content: params.get('utm_content'),
        utm_medium: params.get('utm_medium'),
        utm_term: params.get('utm_term'),
    };
}
