import { groupBy } from 'lodash';

import { SubscriptionWithRenewal } from 'client/dist/generated/alloy';

import { sortSubscriptions } from './sort';

type SubscriptionsWithStatus = {
  activeSubs: SubscriptionWithRenewal[];
  cancelledSubs: SubscriptionWithRenewal[];
  pausedSubs: SubscriptionWithRenewal[];
  paymentFailedSubs: SubscriptionWithRenewal[];
};

/**
 * helper function that allows to quickly get filtered by status subscriptions that way
 * we don't have to keep writing checks all over the app
 *
 * @param subscriptions SubscriptionWithRenewal[]
 * @returns SubscriptionsWithStatus
 */
export const getSubscriptionsWithStatus = (
  subscriptions: SubscriptionWithRenewal[],
): SubscriptionsWithStatus => {
  const grouped = groupBy(sortSubscriptions(subscriptions), (sub) => sub.status);

  return {
    activeSubs: grouped['ACTIVE'] ?? [],
    cancelledSubs: grouped['INACTIVE'] ?? [],
    pausedSubs: grouped['PAUSED'] ?? [],
    paymentFailedSubs: grouped['PAYMENT_FAILED'] ?? [],
  };
};
