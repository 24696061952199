import {
  DOCTOR_CONSULT_PRODUCT_ID,
  M4_EYE_SERUM_PRODUCT_ID,
  M4_FACE_SERUM_PRODUCT_ID,
  M4_PRODUCT_ID,
  OMAZING_PRODUCT_ID,
  ORAL_MINOXIDIL_ID,
  SYNBIOTIC_PRODUCT_ID,
  TRETINOIN_ID,
  VAGINAL_PRODUCT_ID,
} from 'common/dist/products/productRegistry';
import { HAIR_QUESTIONNAIRE } from 'common/dist/questionnaire/hair-health';
import { MHT_QUESTIONNAIRE } from 'common/dist/questionnaire/mht';
import { RENEWAL_QUESTIONNAIRE } from 'common/dist/questionnaire/renewal';
import { SEXUAL_QUESTIONNAIRE } from 'common/dist/questionnaire/sexual-health';
import { SKIN_QUESTIONNAIRE } from 'common/dist/questionnaire/skin-health';
import { VAGINAL_QUESTIONNAIRE } from 'common/dist/questionnaire/vaginal-health';

import { ExperienceFlowsDict } from 'modules/shared/models/experience';

import { mhtSteps, otcSteps, renewalSteps, standardSteps } from './steps';

export const alloyFlows: ExperienceFlowsDict = {
  mht: {
    intakeQuestions: MHT_QUESTIONNAIRE.questions,
    steps: mhtSteps,
    productIds: [DOCTOR_CONSULT_PRODUCT_ID],
  },
  'sexual-health': {
    intakeQuestions: SEXUAL_QUESTIONNAIRE.questions,
    steps: standardSteps,
    productIds: [OMAZING_PRODUCT_ID],
  },
  'skin-health': {
    intakeQuestions: SKIN_QUESTIONNAIRE.questions,
    steps: standardSteps,
    productIds:
      process.env.REACT_APP_SHOW_NEW_SKINCARE === 'true'
        ? [M4_PRODUCT_ID, TRETINOIN_ID, M4_FACE_SERUM_PRODUCT_ID, M4_EYE_SERUM_PRODUCT_ID]
        : [M4_PRODUCT_ID, TRETINOIN_ID],
  },
  'gut-health': {
    steps: otcSteps,
    productIds: [SYNBIOTIC_PRODUCT_ID],
  },
  renewal: {
    intakeQuestions: RENEWAL_QUESTIONNAIRE.questions,
    steps: renewalSteps,
    productIds: [],
  },
  'vaginal-health': {
    intakeQuestions: VAGINAL_QUESTIONNAIRE.questions,
    steps: standardSteps,
    productIds: [VAGINAL_PRODUCT_ID],
  },
  'hair-health': {
    intakeQuestions: HAIR_QUESTIONNAIRE.questions,
    steps: standardSteps,
    productIds: [ORAL_MINOXIDIL_ID],
  },
};
