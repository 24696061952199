import { bindActionCreators } from '@reduxjs/toolkit';
import { uniq } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import ProductRegistry from 'client/dist/product/productRegistry';
import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';

import drMennImg from 'modules/shared/assets/images/dr-menn.png';

import { useExperience } from 'modules/shared/context/experience';

import { SELECTIONS } from 'modules/shared/data/relief-type';

import { retrieveCategoriesFromUrl } from 'modules/shared/lib/experience/experience';
import {
  filterProductsByCategories,
  getDeepProductsFromGroupedProducts,
} from 'modules/shared/lib/product';
import { sendExceptionToSentry } from 'modules/tracking/lib/sentry';

import { updateRequestingProducts } from 'modules/shared/store/requesting-products';

import ViewableProduct from 'modules/shared/ui/blocks/ViewableProduct';

import EditProductsDrawer from 'modules/shared/ui/drawers/EditProductsDrawer';

import { useAppSelector } from 'shared/store/reducers';

import ProductReviewCardBlock from '../blocks/ProductReviewCard';
import ReviewLayoutWrapper from '../wrappers/ReviewLayout';

export default function DoctorConsultContent() {
  const dispatch = useDispatch();
  const location = useLocation();

  const { onBack, onNext } = useExperience();

  const categories = retrieveCategoriesFromUrl(location);
  const filteredCategories = SELECTIONS.filter(({ categories: c }) =>
    categories.some((category) => c.includes(category)),
  ).map(({ text }) => text);

  const { requestingDeepProductIds } = useAppSelector((state) => state.experience);

  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const [mhtProducts, setMhtProducts] = useState<GroupedContentfulProduct[][]>([]);
  const [upsellProducts, setUpsellProducts] = useState<GroupedContentfulProduct[][]>([]);
  const [requestedProducts, setRequestedProducts] = useState<GroupedContentfulProduct[][]>([]);

  const dispatchUpdateRequesting = bindActionCreators(updateRequestingProducts, dispatch);

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    setIsLoading(true);
    const [fetchedRequested, fetchedMht, fetchedUpsell] = await Promise.all([
      ProductRegistry.get().getIndividualGroupedContentfulProductsFor(
        'specific',
        requestingDeepProductIds,
      ),
      ProductRegistry.get().getIndividualGroupedContentfulProductsFor('mht'),
      ProductRegistry.get().getIndividualGroupedContentfulProductsFor('upsell'),
    ]);

    // at this point, the customer has already filled out the intake, we need to only show them
    // non mht products that would line up with the categories filled out otherwise
    // we don't have submission data on upsell products not selected
    // we also want gut health no matter what
    const filteredUpsellProducts = filterProductsByCategories(
      fetchedUpsell,
      uniq(categories.concat('gut-health')),
    );

    setRequestedProducts(fetchedRequested);
    setMhtProducts(fetchedMht);
    setUpsellProducts(filteredUpsellProducts);

    setIsLoading(false);
  };

  const onSaveRequesting = (products: GroupedContentfulProduct[][]) => {
    const sortedRequestedProducts = ProductRegistry.get().sortGroupedContentfulProducts(products);

    setRequestedProducts(sortedRequestedProducts);

    dispatchUpdateRequesting(
      getDeepProductsFromGroupedProducts(sortedRequestedProducts.flat()).map((pf) => pf.id),
    );

    setIsEditing(false);
  };

  const onContinue = async () => {
    try {
      setIsLoading(true);
      onNext();
    } catch (error) {
      setIsLoading(false);
      sendExceptionToSentry(error as Error);
    }
  };

  return (
    <ReviewLayoutWrapper
      title='Review'
      isLoading={isLoading}
      onBack={onBack}
      isContinueDisabled={false}
      onContinue={onContinue}
    >
      <ProductReviewCardBlock
        topImg={drMennImg}
        title='Doctor Consultation'
        subtitle='$49.00 (1-time cost)'
        description='A menopause specialized doctor will review your intake and work with you to find a treatment that is the best for you.'
        benefitsList={filteredCategories}
      />

      <div className='content-header-wrapper'>
        <h1 className='header-title'>
          {requestedProducts.length === 1 ? 'Requested treatment' : 'Requested treatments'}
        </h1>

        <button
          className='secondary-border-button'
          type='button'
          onClick={() => setIsEditing(true)}
        >
          Edit
        </button>
      </div>

      {requestedProducts.length === 0 ? (
        <p className='content-text-md'>
          I would like the doctor to make a treatment recommendation.
        </p>
      ) : (
        <>
          <p className='content-text-md'>
            You won't be charged for or shipped prescription treatments unless a doctor prescribes
            it to you.
          </p>

          <div className='content-product-list'>
            {requestedProducts.map((gcpList, index) =>
              gcpList.map((gcp) => (
                <ViewableProduct key={index} groupedProduct={gcp} hideDetails showProgDetails />
              )),
            )}
          </div>
        </>
      )}

      <EditProductsDrawer
        open={isEditing}
        setOpen={setIsEditing}
        onSave={onSaveRequesting}
        productsWithType={{
          mht: mhtProducts,
          upsell: upsellProducts,
        }}
        prevSelectedProducts={requestedProducts.flat()}
      />
    </ReviewLayoutWrapper>
  );
}
