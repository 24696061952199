import './TextField.scss';

import classNames from 'classnames';
import { Input } from 'rsuite';

interface Props {
  name: string;
  label?: string;
  value?: string;
  placeholder?: string;
  onChange: (value: string) => void;
  type?: string;
  classes?: string;
  disabled?: boolean;
  isError?: boolean;
  errorMessage?: string;
}

export const TextField = ({
  name,
  label,
  value,
  placeholder,
  onChange,
  type = 'text',
  classes = 'field-input',
  disabled = false,
  isError = false,
  errorMessage = 'Required',
}: Props) => (
  <div className={classNames('input-field-wrapper', isError && 'input-field-error')}>
    {label && <p className='field-label'>{label}</p>}

    <Input
      name={name}
      type={type}
      placeholder={placeholder}
      className={classes}
      value={value}
      onChange={onChange}
      onBlur={(v) => {
        const trimmed = v.target.value.trim();

        onChange(trimmed);
      }}
      disabled={disabled}
    />

    {isError && <p className='field-required-label'>{errorMessage}</p>}
  </div>
);
