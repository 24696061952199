import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  useGetAllOrders,
  useGetAllSubscriptionsForCustomer,
  useGetTreatmentPlan,
} from 'client/dist/generated/alloy';

import useCart from 'modules/shared/hooks/useCart';

import useMarkNotificationsAsRead from 'shared/hooks/useMarkNotificationsAsRead';

import { useAppSelector } from 'shared/store/reducers';

export default function useTreatmentPlanState() {
  // mark notifications as read once on everytime going to page OR reloading
  useMarkNotificationsAsRead([
    'NEW_PRESCRIPTION',
    'TREATMENT_PLAN_READY',
    'TREATMENT_PLAN_UPDATED',
  ]);

  const navigate = useNavigate();

  const { clearCart } = useCart();

  const customer = useAppSelector((state) => state.alloy.customer!);
  const { cart } = useCart();

  const [loading, setLoading] = useState<boolean>(false);
  const [isOrderPlaced, setIsOrderPlaced] = useState<boolean>(cart.isPurchased);

  const {
    data: treatmentPlan,
    isLoading: isLoadingTreatmentPlan,
    mutate: mutateTreatmentPlan,
  } = useGetTreatmentPlan();
  const {
    data: subscriptions = [],
    isLoading: isLoadingSubscriptions,
    mutate: mutateSubscriptions,
  } = useGetAllSubscriptionsForCustomer();
  const { data: orders = [], isLoading: isLoadingOrders } = useGetAllOrders();

  // See if anything is loading
  const isLoading = loading || isLoadingTreatmentPlan || isLoadingSubscriptions || isLoadingOrders;

  const onContinueCheckout = () => navigate('/treatment-plan/checkout');

  /**
   * View the treatment plan after purchase
   */
  const onViewTreatmentPlan = async () => {
    window.scrollTo(0, 0);

    setLoading(true);

    await Promise.all([mutateTreatmentPlan(), mutateSubscriptions()]);

    clearCart();

    setIsOrderPlaced(false);
    setLoading(false);
  };

  return {
    isLoading,

    isOrderPlaced,

    customer,
    treatmentPlan,
    subscriptions,
    orders,

    cart,

    onContinueCheckout,
    onViewTreatmentPlan,
  };
}
