/**
 * allows for looping through markdown text when there aren't proper links setup
 * and creating urls that will display appropriately, main use is not to use long
 * unclickable urls when rendered
 *
 * @param text string
 * @param title string
 * @returns (string | JSX.Element)[]
 */
export const renderWithLinks = (text: string, title: string): (string | JSX.Element)[] => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;

  const parts = text.split(urlRegex);

  return parts.map((part, index) =>
    urlRegex.test(part) ? (
      <a key={index} href={part} target='_blank' rel='noopener noreferrer'>
        {title}
      </a>
    ) : (
      part
    ),
  );
};
