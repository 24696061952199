"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SYMPTOM_SURVEY_QUESTIONNAIRE = void 0;
exports.SYMPTOM_SURVEY_QUESTIONNAIRE = {
    title: 'Symptom Survey',
    questions: [
        {
            title: 'Which of these menopause symptoms were you experiencing prior to starting treatment with Alloy?',
            slug: 'menopause-symptoms-prior-to-starting-alloy',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'MultipleChoice',
            headerTitle: 'Symptom Survey:',
            options: [
                {
                    answer: 'Hot Flashes / Night Sweats',
                    slug: 'symptoms-prior-to-alloy-hot-flashes',
                },
                {
                    answer: 'Mood Swings / Irritability / Anxiety / Depression',
                    slug: 'symptoms-prior-to-alloy-mood-swings',
                },
                {
                    answer: 'Irregular Periods',
                    slug: 'symptoms-prior-to-alloy-irregular-periods',
                },
                {
                    answer: 'Vaginal Issues',
                    slug: 'symptoms-prior-to-alloy-vaginal-issues',
                },
                {
                    answer: 'Weight Gain',
                    slug: 'symptoms-prior-to-alloy-weight-gain',
                },
                {
                    answer: 'Low Libido',
                    slug: 'symptoms-prior-to-alloy-low-libido',
                },
                {
                    answer: 'Headaches',
                    slug: 'symptoms-prior-to-alloy-headaches',
                },
                {
                    answer: 'Joint Pain / Bone Fragility',
                    slug: 'symptoms-prior-to-alloy-joint-pain',
                },
                {
                    answer: 'Dry, Itchy Skin',
                    slug: 'symptoms-prior-to-alloy-dry-skin',
                },
                {
                    answer: 'Bladder Issues / Frequent UTIs',
                    slug: 'symptoms-prior-to-alloy-bladder-issues',
                },
                {
                    answer: 'Hair Loss',
                    slug: 'symptoms-prior-to-alloy-hair-loss',
                },
                {
                    answer: 'Brain Fog',
                    slug: 'symptoms-prior-to-alloy-brain-fog',
                },
                {
                    answer: 'Fatigue / Trouble with Sleep',
                    slug: 'symptoms-prior-to-alloy-fatigue',
                },
                {
                    answer: 'Painful Sex',
                    slug: 'symptoms-prior-to-alloy-painful-sex',
                },
                {
                    answer: 'Facial Acne',
                    slug: 'symptoms-prior-to-alloy-facial-acne',
                },
            ],
            mdiCaseQuestion: false,
        },
        {
            title: 'Which Alloy treatment are you taking?',
            slug: 'alloy-treatment',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'SingleChoice',
            headerTitle: 'Symptom Survey:',
            options: [
                {
                    answer: 'Estradiol Pill',
                    slug: 'alloy-treatment-pill',
                },
                {
                    answer: 'Estradiol Patch',
                    slug: 'alloy-treatment-patch',
                },
                {
                    answer: 'Evamist Estradiol Spray',
                    slug: 'alloy-treatment-evamist',
                },
                {
                    answer: 'Estradiol Gel',
                    slug: 'alloy-treatment-gel',
                },
                {
                    answer: 'Paroxetine',
                    slug: 'alloy-treatment-paroxetine',
                },
                {
                    answer: 'Birth Control',
                    slug: 'alloy-treatment-birth-control',
                },
            ],
            mdiCaseQuestion: false,
        },
        {
            title: 'How are you feeling since starting treatment with Alloy?',
            slug: 'feeling-since-starting-alloy',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'SingleChoice',
            headerTitle: 'Symptom Survey:',
            options: [
                {
                    answer: 'Much better',
                    slug: 'feeling-since-starting-alloy-much-better',
                },
                {
                    answer: 'Somewhat better',
                    slug: 'feeling-since-starting-alloy-somewhat-better',
                },
                {
                    answer: 'Not better',
                    slug: 'feeling-since-starting-alloy-not-better',
                },
            ],
            mdiCaseQuestion: false,
        },
        {
            title: 'How long did it take you to start feeling better after starting treatment with Alloy?',
            slug: 'feeling-better-length-starting-alloy',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'SingleChoice',
            headerTitle: 'Symptom Survey:',
            options: [
                {
                    answer: '24 hours',
                    slug: 'feeling-better-length-starting-alloy-24-hours',
                },
                {
                    answer: 'A few days',
                    slug: 'feeling-better-length-starting-alloy-days',
                },
                {
                    answer: 'A week',
                    slug: 'feeling-better-length-starting-alloy-a-week',
                },
                {
                    answer: '2 weeks',
                    slug: 'feeling-better-length-starting-alloy-two-weeks',
                },
                {
                    answer: 'Not there yet',
                    slug: 'feeling-better-length-starting-alloy-not-better',
                },
            ],
            mdiCaseQuestion: false,
        },
        {
            title: 'Thank you for completing our survey!',
            slug: 'thank-you-completing-survey',
            description: "Your answers will help us make the Alloy experience even better. We're grateful to have you in our community.",
            isRequired: false,
            isImportant: false,
            userKey: '',
            type: 'Information',
            headerTitle: 'Symptom Survey:',
            options: [],
            mdiCaseQuestion: false,
        },
    ],
};
