"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HAIR_QUESTIONNAIRE = void 0;
exports.HAIR_QUESTIONNAIRE = {
    title: "Let's talk about your hair",
    questions: [
        {
            title: 'Do you have a family history of hair loss?',
            slug: 'family-history-hair-loss',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'SingleChoice',
            options: [
                {
                    answer: 'Yes',
                    slug: 'family-history-hair-loss-yes',
                },
                {
                    answer: 'No',
                    slug: 'family-history-hair-loss-no',
                },
            ],
            mdiCaseQuestion: true,
        },
        {
            title: 'Are you experiencing hair thinning or loss?',
            slug: 'experiencing-hair-symptoms',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'SingleChoice',
            options: [
                {
                    answer: 'Hair thinning',
                    slug: 'experiencing-hair-symptom-thinning',
                },
                {
                    answer: 'Hair loss',
                    slug: 'experiencing-hair-symptom-loss',
                },
                {
                    answer: 'Both',
                    slug: 'experiencing-hair-symptom-both-thinning-loss',
                },
                {
                    answer: 'Neither',
                    slug: 'experiencing-hair-symptom-neither-thinning-loss',
                },
            ],
            mdiCaseQuestion: true,
        },
        {
            title: 'Where is this happening?',
            slug: 'hair-loss-location',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'SingleChoice',
            options: [
                {
                    answer: 'Certain spots on top of my head',
                    slug: 'hair-loss-top-of-head',
                },
                {
                    answer: 'Spots on sides of my head',
                    slug: 'hair-loss-sides-head',
                },
                {
                    answer: 'All over my head',
                    slug: 'hair-loss-all-over-head',
                },
            ],
            orLogic: {
                'experiencing-hair-symptoms': [
                    'experiencing-hair-symptom-thinning',
                    'experiencing-hair-symptom-loss',
                    'experiencing-hair-symptom-both-thinning-loss',
                ],
            },
            mdiCaseQuestion: true,
        },
        {
            title: 'When did you first start noticing changes to your hair?',
            slug: 'first-noticed-hair-changes',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'SingleChoice',
            options: [
                {
                    answer: 'In the past month',
                    slug: 'hair-changes-past-month',
                },
                {
                    answer: 'Over the past 3 - 6 months',
                    slug: 'hair-changes-past-3-6-months',
                },
                {
                    answer: 'Within the past year',
                    slug: 'hair-changes-past-year',
                },
                {
                    answer: 'Over a year ago',
                    slug: 'hair-changes-over-year-ago',
                },
                {
                    answer: 'Not sure',
                    slug: 'hair-changes-not-sure',
                },
            ],
            orLogic: {
                'experiencing-hair-symptoms': [
                    'experiencing-hair-symptom-thinning',
                    'experiencing-hair-symptom-loss',
                    'experiencing-hair-symptom-both-thinning-loss',
                ],
            },
            mdiCaseQuestion: true,
        },
        {
            title: 'Did these changes happen suddenly or gradually?',
            slug: 'hair-changes-pace',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'SingleChoice',
            options: [
                {
                    answer: 'All of a sudden',
                    slug: 'hair-changes-pace-sudden',
                },
                {
                    answer: 'Gradually over time',
                    slug: 'hair-changes-pace-gradual',
                },
                {
                    answer: 'Not sure',
                    slug: 'hair-changes-pace-not-sure',
                },
            ],
            orLogic: {
                'experiencing-hair-symptoms': [
                    'experiencing-hair-symptom-thinning',
                    'experiencing-hair-symptom-loss',
                    'experiencing-hair-symptom-both-thinning-loss',
                ],
            },
            mdiCaseQuestion: true,
        },
        {
            title: 'Was there a trigger for your hair loss?',
            slug: 'hair-loss-trigger',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'MultipleChoice',
            options: [
                {
                    answer: 'Medication',
                    slug: 'hair-loss-trigger-medication',
                },
                {
                    answer: 'Stress',
                    slug: 'hair-loss-trigger-stress',
                },
                {
                    answer: 'Scalp inflammation',
                    slug: 'hair-loss-trigger-scalp-inflammation',
                },
                {
                    answer: 'Grooming practices',
                    slug: 'hair-loss-trigger-grooming',
                },
                {
                    answer: 'Other',
                    slug: 'hair-loss-trigger-other',
                },
                {
                    answer: 'Not sure',
                    slug: 'hair-loss-trigger-not-sure',
                },
            ],
            orLogic: {
                'experiencing-hair-symptoms': [
                    'experiencing-hair-symptom-thinning',
                    'experiencing-hair-symptom-loss',
                    'experiencing-hair-symptom-both-thinning-loss',
                ],
            },
            mdiCaseQuestion: true,
        },
        {
            title: 'Do you have any existing concerns about facial or body hair?',
            slug: 'concerns-facial-body-hair',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'SingleChoice',
            options: [
                {
                    answer: 'Yes',
                    slug: 'concerns-facial-body-hair-yes',
                },
                {
                    answer: 'No',
                    slug: 'concerns-facial-body-hair-no',
                },
            ],
            orLogic: {
                'experiencing-hair-symptoms': [
                    'experiencing-hair-symptom-thinning',
                    'experiencing-hair-symptom-loss',
                    'experiencing-hair-symptom-both-thinning-loss',
                ],
            },
            mdiCaseQuestion: true,
        },
        {
            title: 'Please elaborate on your concerns',
            slug: 'concerns-elaborate-facial-body-hair',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'MultipleLine',
            placeholder: 'Type here',
            options: [],
            orLogic: {
                'concerns-facial-body-hair': ['concerns-facial-body-hair-yes'],
            },
            mdiCaseQuestion: true,
        },
        {
            title: 'Do you have a personal history of any of the following:',
            slug: 'personal-hair-medical-history',
            description: '',
            isRequired: true,
            isImportant: true,
            userKey: '',
            type: 'MultipleChoice',
            options: [
                {
                    answer: 'Anemia',
                    slug: 'hair-history-anemia',
                },
                {
                    answer: 'Heavy periods (e.g. bleeding is more frequent than every 21 days, bleeding lasts longer than 8 days, bleeding through a tampon or pad in 1-2 hours)',
                    slug: 'hair-history-heavy-periods',
                },
                {
                    answer: 'Significant cardiac disease (i.e. History of pericardial effusion, pericarditis, congestive heart failure, pulmonary hypertension)',
                    slug: 'hair-history-significant-cardiac-disease',
                },
                {
                    answer: 'Tachycardia or cardiac arrhythmia',
                    slug: 'hair-history-tachycardia-arrhythmia',
                },
                {
                    answer: 'Liver disease',
                    slug: 'hair-history-liver-disease',
                },
                {
                    answer: 'Kidney disease or on dialysis',
                    slug: 'hair-history-kidney-disease-dialysis',
                },
                {
                    answer: 'Low blood pressure ( BP consistently <= 90/60 )',
                    slug: 'hair-history-low-blood-pressure',
                },
                {
                    answer: 'Other types of diagnosed hair loss, including scarring alopecia',
                    slug: 'hair-history-other-hair-loss',
                },
                {
                    answer: 'Thyroid Disorder (hypothyroid or hyperthyroid)',
                    slug: 'hair-history-thyroid-disorder',
                },
                {
                    answer: 'None of the above',
                    slug: 'hair-history-none',
                },
            ],
            mdiCaseQuestion: true,
        },
        {
            title: 'Is your thyroid condition well controlled?',
            slug: 'thyroid-condition-controlled',
            description: '',
            isRequired: true,
            isImportant: true,
            userKey: '',
            type: 'SingleChoice',
            options: [
                {
                    answer: 'Yes',
                    slug: 'thyroid-controlled-yes',
                },
                {
                    answer: 'No',
                    slug: 'thyroid-controlled-no',
                },
            ],
            orLogic: {
                'personal-hair-medical-history': ['hair-history-thyroid-disorder'],
            },
            mdiCaseQuestion: true,
        },
        {
            title: 'Are you pregnant and/or breastfeeding?',
            slug: 'pregnant-andor-breastfeeding',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'SingleChoice',
            headerTitle: 'Your medical history:',
            options: [
                {
                    answer: 'Yes',
                    slug: 'pregnant-andor-breastfeeding-yes',
                },
                {
                    answer: 'No',
                    slug: 'pregnant-andor-breastfeeding-no',
                },
            ],
            mdiCaseQuestion: true,
        },
        {
            title: 'Are you less than 6 months postpartum?',
            slug: 'less-than-6-months-postpartum',
            description: '',
            isRequired: true,
            isImportant: true,
            userKey: '',
            type: 'SingleChoice',
            options: [
                {
                    answer: 'Yes',
                    slug: 'less-than-6-months-postpartum-yes',
                },
                {
                    answer: 'No',
                    slug: 'less-than-6-months-postpartum-no',
                },
            ],
            mdiCaseQuestion: true,
        },
        {
            title: 'Which of the following describes your periods?',
            slug: 'hair-eval-describe-period',
            description: 'Check all that apply:',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'MultipleChoice',
            headerTitle: 'Your menstrual cycle:',
            options: [
                {
                    slug: 'hair-eval-period-unpredictable-bleeding-21-days',
                    answer: 'Bleeding is more frequent than every 21 days',
                },
                {
                    slug: 'hair-eval-period-unpredictable-bleeding-8-days',
                    answer: 'Bleeding last longer than 8 days',
                },
                {
                    slug: 'hair-eval-period-unpredictable-periods-heavier',
                    answer: 'Periods are now heavier',
                },
                {
                    slug: 'hair-eval-period-unpredictable-tampon-1-2-hours',
                    answer: 'I bleed through a tampon or pad in 1-2 hours',
                },
                {
                    slug: 'hair-eval-period-unpredictable-other',
                    answer: 'Other',
                },
            ],
            orLogic: {
                'personal-hair-medical-history': ['hair-history-heavy-periods'],
            },
            mdiCaseQuestion: true,
        },
        {
            title: 'Please describe how your periods are becoming more unpredictable.',
            slug: 'describe-how-periods-becoming-unpredictable',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'MultipleLine',
            headerTitle: 'Your menstrual cycle:',
            placeholder: 'Type here',
            options: [],
            orLogic: {
                'hair-eval-describe-period': ['hair-eval-period-unpredictable-other'],
            },
            mdiCaseQuestion: true,
        },
        {
            title: 'Have you had a recent GYN evaluation?',
            slug: 'how-recent-gyn-evaluation',
            description: 'Check all that apply:',
            isRequired: true,
            isImportant: true,
            userKey: '',
            type: 'MultipleChoice',
            headerTitle: 'Your menstrual cycle:',
            options: [
                {
                    slug: 'how-recent-gyn-evaluation-regular',
                    answer: 'I have had a regular GYN evaluation in past 12 months',
                },
                {
                    slug: 'how-recent-gyn-evaluation-transvaginal-ultrasound',
                    answer: 'I have had a pelvic or transvaginal ultrasound',
                },
                {
                    slug: 'how-recent-gyn-evaluation-endometrial-biopsy',
                    answer: 'I have had an endometrial biopsy',
                },
                {
                    slug: 'how-recent-gyn-evaluation-d-c',
                    answer: 'I have had a D&C',
                },
                {
                    slug: 'how-recent-gyn-evaluation-hysteroscopy',
                    answer: 'I have had a hysteroscopy',
                },
                {
                    slug: 'how-recent-gyn-evaluation-blood-work-done',
                    answer: 'I have had blood work done',
                },
                {
                    slug: 'how-recent-gyn-evaluation-other',
                    answer: 'Other - please explain',
                },
            ],
            orLogic: {
                'hair-eval-describe-period': [
                    'hair-eval-period-unpredictable-bleeding-21-days',
                    'hair-eval-period-unpredictable-bleeding-8-days',
                    'hair-eval-period-unpredictable-periods-heavier',
                    'hair-eval-period-unpredictable-tampon-1-2-hours',
                ],
            },
            mdiCaseQuestion: true,
        },
        {
            title: 'Please tell us more.',
            slug: 'please-tell-us-more',
            description: 'Please provide details, or in your own words, a summary of relevant results.',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'MultipleLine',
            headerTitle: 'Your menstrual cycle:',
            placeholder: 'Type here',
            options: [],
            orLogic: {
                'how-recent-gyn-evaluation': [
                    'how-recent-gyn-evaluation-transvaginal-ultrasound',
                    'how-recent-gyn-evaluation-endometrial-biopsy',
                    'how-recent-gyn-evaluation-d-c',
                    'how-recent-gyn-evaluation-hysteroscopy',
                    'how-recent-gyn-evaluation-blood-work-done',
                    'how-recent-gyn-evaluation-other',
                ],
            },
            mdiCaseQuestion: true,
        },
        {
            title: 'Have any of the following medical procedures impacted your menstrual cycle?',
            slug: 'medical-procedures-impacted-menstrual-cycle',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'SingleChoice',
            headerTitle: 'Your menstrual cycle:',
            options: [
                {
                    answer: 'Bilateral oophorectomy',
                    slug: 'medical-procedures-bilateral-oophorectomy',
                },
                {
                    answer: 'Hysterectomy',
                    slug: 'medical-procedures-hysterectomy',
                },
                {
                    answer: 'Neither',
                    slug: 'medical-procedures-neither',
                },
            ],
            orLogic: {
                'personal-hair-medical-history': ['hair-history-heavy-periods'],
            },
            mdiCaseQuestion: true,
        },
        {
            title: 'At what age did you have a bilateral oophorectomy (removal of both ovaries)?',
            slug: 'age-bilateral-oophorectomy',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'MultipleLine',
            placeholder: 'Type here',
            options: [],
            orLogic: {
                'medical-procedures-impacted-menstrual-cycle': [
                    'medical-procedures-bilateral-oophorectomy',
                ],
            },
            mdiCaseQuestion: true,
        },
        {
            title: 'Why did you have a bilateral oophorectomy (removal of both ovaries)?',
            slug: 'why-bilateral-oophorectomy',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'MultipleLine',
            headerTitle: 'Your menstrual cycle:',
            placeholder: 'Type here',
            options: [],
            orLogic: {
                'medical-procedures-impacted-menstrual-cycle': [
                    'medical-procedures-bilateral-oophorectomy',
                ],
            },
            mdiCaseQuestion: true,
        },
        {
            title: 'At what age did you have a hysterectomy?',
            slug: 'age-hysterectomy',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'MultipleLine',
            headerTitle: 'Your medical history:',
            placeholder: 'Type here',
            options: [],
            orLogic: {
                'medical-procedures-impacted-menstrual-cycle': ['medical-procedures-hysterectomy'],
            },
            mdiCaseQuestion: true,
        },
        {
            title: 'Why did you have a hysterectomy?',
            slug: 'why-hysterectomy',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'MultipleLine',
            headerTitle: 'Your medical history:',
            placeholder: 'Type here',
            options: [],
            orLogic: {
                'medical-procedures-impacted-menstrual-cycle': ['medical-procedures-hysterectomy'],
            },
            mdiCaseQuestion: true,
        },
        {
            title: 'Are you allergic to vitamin D and/or minoxidil?',
            slug: 'allergies-vitamin-d-minoxidil',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'SingleChoice',
            options: [
                {
                    answer: 'Yes',
                    slug: 'allergies-vitamin-d-minoxidil-yes',
                },
                {
                    answer: 'No',
                    slug: 'allergies-vitamin-d-minoxidil-no',
                },
            ],
            mdiCaseQuestion: true,
        },
        {
            title: 'Please list all allergies or reactions to medications or adhesives.',
            slug: 'other-allergies',
            description: '',
            isRequired: false,
            isImportant: false,
            userKey: 'currentAllergies',
            type: 'Allergies',
            options: [],
            mdiCaseQuestion: true,
        },
        {
            title: 'Are you currently taking oral or topical minoxidil?',
            slug: 'currently-taking-minoxidil',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'SingleChoice',
            options: [
                {
                    answer: 'Yes, oral only',
                    slug: 'currently-oral-minoxidil-only',
                },
                {
                    answer: 'Yes, topical only',
                    slug: 'currently-topical-minoxidil-only',
                },
                {
                    answer: 'Yes, oral and topical',
                    slug: 'currently-oral-and-topical-minoxidil',
                },
                {
                    answer: 'No, neither',
                    slug: 'currently-neither-minoxidil',
                },
            ],
            mdiCaseQuestion: true,
        },
        {
            title: 'What dose of oral minoxidil are you currently taking?',
            slug: 'minoxidil-dose',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'SingleChoice',
            options: [
                {
                    answer: '0.5mg',
                    slug: 'strength-minoxidil-dose-005',
                },
                {
                    answer: '1mg',
                    slug: 'strength-minoxidil-dose-01',
                },
                {
                    answer: '1.5mg',
                    slug: 'strength-minoxidil-dose-015',
                },
                {
                    answer: '2mg',
                    slug: 'strength-minoxidil-dose-02',
                },
            ],
            orLogic: {
                'currently-taking-minoxidil': [
                    'currently-oral-minoxidil-only',
                    'currently-oral-and-topical-minoxidil',
                ],
            },
            mdiCaseQuestion: true,
        },
        {
            title: 'What topical formulation are you taking?',
            slug: 'topical-formulation',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'SingleChoice',
            options: [
                {
                    answer: 'Minoxidil 2%',
                    slug: 'strength-topical-minoxidil-dose-002',
                },
                {
                    answer: 'Minoxidil 5%',
                    slug: 'strength-topical-minoxidil-dose-005',
                },
                {
                    answer: 'Compounded/high concentration',
                    slug: 'strength-topical-compounded-high-concentration',
                },
            ],
            orLogic: {
                'currently-taking-minoxidil': [
                    'currently-topical-minoxidil-only',
                    'currently-oral-and-topical-minoxidil',
                ],
            },
            mdiCaseQuestion: true,
        },
        {
            title: 'Are you satisfied with your oral minoxidil dose?',
            slug: 'minoxidil-dose-satisfaction',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'SingleChoice',
            options: [
                {
                    answer: 'Yes',
                    slug: 'minoxidil-dose-satisfied-yes',
                },
                {
                    answer: 'No',
                    slug: 'minoxidil-dose-satisfied-no',
                },
            ],
            orLogic: {
                'currently-taking-minoxidil': [
                    'currently-oral-minoxidil-only',
                    'currently-oral-and-topical-minoxidil',
                ],
            },
            mdiCaseQuestion: true,
        },
        {
            title: 'Are you satisfied with your topical minoxidil dose?',
            slug: 'minoxidil-dose-satisfaction-topical',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'SingleChoice',
            options: [
                {
                    answer: 'Yes',
                    slug: 'minoxidil-dose-satisfied-topical-yes',
                },
                {
                    answer: 'No',
                    slug: 'minoxidil-dose-satisfied-topical-no',
                },
            ],
            orLogic: {
                'currently-taking-minoxidil': [
                    'currently-topical-minoxidil-only',
                    'currently-oral-and-topical-minoxidil',
                ],
            },
            mdiCaseQuestion: true,
        },
        {
            title: 'Please list all current medications.',
            slug: 'list-all-other-medications',
            description: 'If you have no medications, continue to the next question.',
            isRequired: false,
            isImportant: false,
            userKey: 'currentMedications',
            type: 'Medications',
            headerTitle: 'Medications and allergies:',
            options: [],
            mdiCaseQuestion: true,
        },
        {
            title: 'Are you currently taking any of the following supplements for hair?',
            slug: 'current-hair-supplements',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'MultipleChoice',
            options: [
                {
                    answer: 'Nutrifol',
                    slug: 'current-hair-supplements-nutrifol',
                },
                {
                    answer: 'Viviscal',
                    slug: 'current-hair-supplements-viviscal',
                },
                {
                    answer: 'Biotin',
                    slug: 'current-hair-supplements-biotin',
                },
                {
                    answer: 'Vitamin D',
                    slug: 'current-hair-supplements-vitamin-d',
                },
                {
                    answer: 'Zinc',
                    slug: 'current-hair-supplements-zinc',
                },
                {
                    answer: 'Other (specify)',
                    slug: 'current-hair-supplements-other-supplement',
                },
                {
                    answer: 'None',
                    slug: 'current-hair-supplements-none',
                },
            ],
            mdiCaseQuestion: true,
        },
        {
            title: 'Please specify the other hair supplements you are taking.',
            slug: 'specify-other-hair-supplements',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'MultipleLine',
            headerTitle: 'Your hair supplements:',
            placeholder: 'Type here',
            options: [],
            orLogic: {
                'current-hair-supplements': ['current-hair-supplements-other-supplement'],
            },
            mdiCaseQuestion: true,
        },
        {
            title: 'What is your sex?',
            slug: 'what-is-your-sex',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'SingleLine',
            placeholder: 'Type here',
            options: [],
            mdiCaseQuestion: true,
        },
        {
            title: 'What is your date of birth?',
            slug: 'date-of-birth',
            description: '',
            conclusion: true,
            isRequired: true,
            isImportant: false,
            userKey: 'dob',
            type: 'Date',
            headerTitle: 'Your Health:',
            placeholder: 'MM/DD/YYYY',
            options: [],
            mdiCaseQuestion: true,
        },
        {
            title: "Is there anything else you'd like to tell the doctor?",
            slug: 'anything-else-share-doctor',
            description: '',
            conclusion: true,
            isRequired: false,
            isImportant: false,
            userKey: '',
            type: 'MultipleLine',
            headerTitle: 'Your medical history:',
            placeholder: 'Type here',
            options: [],
            mdiCaseQuestion: true,
        },
    ],
};
