/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * api
 * OpenAPI spec version: 1.0.0
 */
import useSwr from 'swr';
import useSWRMutation from 'swr/mutation';
import { customInstance } from '../authenticatingAxiosInstance';
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PickPendingChargeIdOrStatusOrShippingMethodIdStatus = {
    READY: 'READY',
    PENDING_CUSTOMER_APPROVAL: 'PENDING_CUSTOMER_APPROVAL',
    CUSTOMER_REJECTED: 'CUSTOMER_REJECTED',
    MANUAL_INTERVENTION: 'MANUAL_INTERVENTION',
    COMPLETED: 'COMPLETED',
    ERROR: 'ERROR',
    PENDING_CX_APPROVAL: 'PENDING_CX_APPROVAL',
    CX_REQUESTED: 'CX_REQUESTED',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MDIProductType = {
    compound: 'compound',
    medication: 'medication',
    encounter: 'encounter',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AlloyProductType = {
    OTC: 'OTC',
    RX: 'RX',
    SERVICE: 'SERVICE',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProductCategory = {
    mht: 'mht',
    'non-hormonal-treatment': 'non-hormonal-treatment',
    'birth-control': 'birth-control',
    'vaginal-health': 'vaginal-health',
    'skin-health': 'skin-health',
    'sexual-health': 'sexual-health',
    'gut-health': 'gut-health',
    'hair-health': 'hair-health',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FormFactorType = {
    Pill: 'Pill',
    Patch: 'Patch',
    Gel: 'Gel',
    Spray: 'Spray',
    Cream: 'Cream',
    Face_Cream: 'Face Cream',
    'N/A': 'N/A',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RecurrenceType = {
    ONE_TIME: 'ONE_TIME',
    RECURRING: 'RECURRING',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StripeStripeCouponDuration = {
    forever: 'forever',
    once: 'once',
    repeating: 'repeating',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StripeStripeCouponObject = {
    coupon: 'coupon',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RenewalInfoReason = {
    EXPIRING: 'EXPIRING',
    ZERO_REFILLS: 'ZERO_REFILLS',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TreatmentPlanStatus = {
    AWAITING_DOCTOR: 'AWAITING_DOCTOR',
    PROCESSING: 'PROCESSING',
    CX_PROCESSING: 'CX_PROCESSING',
    AWAITING_CUSTOMER_APPROVAL: 'AWAITING_CUSTOMER_APPROVAL',
    ACTIVE: 'ACTIVE',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SubscriptionStatus = {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
    PAUSED: 'PAUSED',
    PAYMENT_FAILED: 'PAYMENT_FAILED',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UnbundleSubscriptionType = {
    RESCHEDULE: 'RESCHEDULE',
    SHIP_NOW: 'SHIP_NOW',
    PAUSE: 'PAUSE',
    CANCEL: 'CANCEL',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IntakeCategory = {
    'skin-health': 'skin-health',
    mht: 'mht',
    'sexual-health': 'sexual-health',
    renewal: 'renewal',
    'vaginal-health': 'vaginal-health',
    'hair-health': 'hair-health',
    'symptom-survey': 'symptom-survey',
    'heard-about-us-survey': 'heard-about-us-survey',
    'doctor-survey': 'doctor-survey',
    cancel: 'cancel',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ShippingMethodType = {
    STANDARD: 'STANDARD',
    TWO_DAY: 'TWO_DAY',
    NEXT_DAY: 'NEXT_DAY',
    LEGACY: 'LEGACY',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ShippingCarrier = {
    UPS: 'UPS',
    USPS: 'USPS',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CurexaShippingMethod = {
    usps_first: 'usps_first',
    ups_second_day_air: 'ups_second_day_air',
    ups_next_day_air: 'ups_next_day_air',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StripeStripePriceType = {
    one_time: 'one_time',
    recurring: 'recurring',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StripeStripePriceBillingScheme = {
    per_unit: 'per_unit',
    tiered: 'tiered',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StripeStripePriceCurrencyOptionsTaxBehavior = {
    exclusive: 'exclusive',
    inclusive: 'inclusive',
    unspecified: 'unspecified',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StripeStripeProductObject = {
    product: 'product',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StripeStripeDeletedProductObject = {
    product: 'product',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StripeStripePriceRecurringAggregateUsage = {
    last_during_period: 'last_during_period',
    last_ever: 'last_ever',
    max: 'max',
    sum: 'sum',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StripeStripePriceRecurringInterval = {
    day: 'day',
    month: 'month',
    week: 'week',
    year: 'year',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StripeStripePriceRecurringUsageType = {
    licensed: 'licensed',
    metered: 'metered',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StripeStripePriceTaxBehavior = {
    exclusive: 'exclusive',
    inclusive: 'inclusive',
    unspecified: 'unspecified',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StripeStripePriceTiersMode = {
    graduated: 'graduated',
    volume: 'volume',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StripeStripePriceTransformQuantityRound = {
    down: 'down',
    up: 'up',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StripeStripePriceObject = {
    price: 'price',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StripeStripeTaxCodeObject = {
    tax_code: 'tax_code',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StripeStripeProductType = {
    good: 'good',
    service: 'service',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PickStripeStripePriceExcludeKeyofstripeStripePriceDeletedObject = {
    price: 'price',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RegionType = {
    ALL: 'ALL',
    WEST: 'WEST',
    MIDWEST: 'MIDWEST',
    NORTHEAST: 'NORTHEAST',
    SOUTH: 'SOUTH',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CheckoutType = {
    RENEWAL: 'RENEWAL',
    MEDICAL_INTAKE: 'MEDICAL_INTAKE',
    CONSULT: 'CONSULT',
    OTC: 'OTC',
    CUSTOMER_ADDON_REQUEST: 'CUSTOMER_ADDON_REQUEST',
    CUSTOMER_FORM_FACTOR_CHANGE: 'CUSTOMER_FORM_FACTOR_CHANGE',
    CUSTOMER_DOSAGE_CHANGE: 'CUSTOMER_DOSAGE_CHANGE',
    CANCEL_RETENTION: 'CANCEL_RETENTION',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Status = {
    PAID: 'PAID',
    SENT_TO_FULFILLMENT: 'SENT_TO_FULFILLMENT',
    ERROR: 'ERROR',
    SHIPPED: 'SHIPPED',
    DELIVERED: 'DELIVERED',
    MANUAL_INTERVENTION: 'MANUAL_INTERVENTION',
    RESEND: 'RESEND',
    REQUEUE: 'REQUEUE',
    CANCEL_REQUESTED: 'CANCEL_REQUESTED',
    CANCELLED: 'CANCELLED',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StripeStripePaymentMethodType = {
    acss_debit: 'acss_debit',
    affirm: 'affirm',
    afterpay_clearpay: 'afterpay_clearpay',
    alipay: 'alipay',
    amazon_pay: 'amazon_pay',
    au_becs_debit: 'au_becs_debit',
    bacs_debit: 'bacs_debit',
    bancontact: 'bancontact',
    blik: 'blik',
    boleto: 'boleto',
    card: 'card',
    card_present: 'card_present',
    cashapp: 'cashapp',
    customer_balance: 'customer_balance',
    eps: 'eps',
    fpx: 'fpx',
    giropay: 'giropay',
    grabpay: 'grabpay',
    ideal: 'ideal',
    interac_present: 'interac_present',
    klarna: 'klarna',
    konbini: 'konbini',
    link: 'link',
    mobilepay: 'mobilepay',
    multibanco: 'multibanco',
    oxxo: 'oxxo',
    p24: 'p24',
    paynow: 'paynow',
    paypal: 'paypal',
    pix: 'pix',
    promptpay: 'promptpay',
    revolut_pay: 'revolut_pay',
    sepa_debit: 'sepa_debit',
    sofort: 'sofort',
    swish: 'swish',
    twint: 'twint',
    us_bank_account: 'us_bank_account',
    wechat_pay: 'wechat_pay',
    zip: 'zip',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrderVendor = {
    CUREXA: 'CUREXA',
    GOGO_MEDS: 'GOGO_MEDS',
    JUNGLE_JIMS: 'JUNGLE_JIMS',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EventType = {
    SESSION_START: 'SESSION_START',
    REGISTRATION: 'REGISTRATION',
    UPDATE_CUSTOMER: 'UPDATE_CUSTOMER',
    CONSULT_PLACED: 'CONSULT_PLACED',
    ORDER_PLACED: 'ORDER_PLACED',
    SUBSCRIBE_TO_NEWSLETTER: 'SUBSCRIBE_TO_NEWSLETTER',
    SUBSCRIBE_TO_SMS_TRANSACTIONAL: 'SUBSCRIBE_TO_SMS_TRANSACTIONAL',
    SUBSCRIBE_TO_SMS_MARKETING: 'SUBSCRIBE_TO_SMS_MARKETING',
    SUBSCRIBE_TO_SMS_NOTIFICATIONS: 'SUBSCRIBE_TO_SMS_NOTIFICATIONS',
    UPDATE_WITH_REFERRAL_CODE: 'UPDATE_WITH_REFERRAL_CODE',
    NEW_PRESCRIPTION: 'NEW_PRESCRIPTION',
    TREATMENT_PLAN_READY: 'TREATMENT_PLAN_READY',
    TREATMENT_PLAN_UPDATED: 'TREATMENT_PLAN_UPDATED',
    SEND_PAYMENT: 'SEND_PAYMENT',
    NEW_DOCTOR_MESSAGE: 'NEW_DOCTOR_MESSAGE',
    NEW_CX_MESSAGE: 'NEW_CX_MESSAGE',
    ORDER_SHIPPED: 'ORDER_SHIPPED',
    ORDER_DELIVERED: 'ORDER_DELIVERED',
    ADDON_BUNDLED: 'ADDON_BUNDLED',
    REFILL_REMINDER: 'REFILL_REMINDER',
    FAILED_PAYMENT: 'FAILED_PAYMENT',
    REFERRAL_REDEMPTION: 'REFERRAL_REDEMPTION',
    PAUSED_BY_CUSTOMER: 'PAUSED_BY_CUSTOMER',
    REFUND_ISSUED: 'REFUND_ISSUED',
    ENCOUNTER_CANCELED: 'ENCOUNTER_CANCELED',
    SUBSCRIPTION_BUNDLED: 'SUBSCRIPTION_BUNDLED',
    SUBSCRIPTION_UNBUNDLED: 'SUBSCRIPTION_UNBUNDLED',
    SUBSCRIPTION_CANCELLED: 'SUBSCRIPTION_CANCELLED',
    SUBSCRIPTION_REACTIVATED: 'SUBSCRIPTION_REACTIVATED',
    RENEWAL_KICKOFF: 'RENEWAL_KICKOFF',
    CUSTOMER_STARTED_RENEWAL: 'CUSTOMER_STARTED_RENEWAL',
    RENEWAL_COMPLETION: 'RENEWAL_COMPLETION',
    PRESCRIPTION_AUTOPAUSED: 'PRESCRIPTION_AUTOPAUSED',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RenewalReason = {
    ZERO_REFILLS_LEFT: 'ZERO_REFILLS_LEFT',
    EXPIRING: 'EXPIRING',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MessageSource = {
    clinician: 'clinician',
    support: 'support',
    system: 'system',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SubscriptionGroupConsentStatus = {
    subscribed: 'subscribed',
    unsubscribed: 'unsubscribed',
    opted_in: 'opted_in',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExpandOmitAllEventFieldsChargeOrInvoiceOrSubscriptionSubscriptionEditType = {
    RESCHEDULE: 'RESCHEDULE',
    SHIP_NOW: 'SHIP_NOW',
    PAUSE: 'PAUSE',
    CANCEL: 'CANCEL',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExpandOmitAllEventFieldsChargeOrInvoiceOrSubscriptionSource = {
    RETOOL: 'RETOOL',
    CUSTOMER_DASHBOARD: 'CUSTOMER_DASHBOARD',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MessagingStatus = {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
    PENDING: 'PENDING',
    PARTNER_DOWN: 'PARTNER_DOWN',
    AV_SYNC_REQUIRED: 'AV_SYNC_REQUIRED',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BundleParentProductOperator = {
    AND: 'AND',
    OR: 'OR',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CustomerStatus = {
    NEW: 'NEW',
    PENDING: 'PENDING',
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AdaptedCreateMdiMessageChannel = {
    patient: 'patient',
    clinician: 'clinician',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SubmissionByCategoriesExistsTimeUnit = {
    day: 'day',
    week: 'week',
    month: 'month',
    year: 'year',
};
export const getTreatmentPlan = () => {
    return customInstance({ url: `/treatment-plan`, method: 'GET' });
};
export const getGetTreatmentPlanKey = () => [`/treatment-plan`];
export const useGetTreatmentPlan = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const isEnabled = (swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.enabled) !== false;
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : (() => (isEnabled ? getGetTreatmentPlanKey() : null));
    const swrFn = () => getTreatmentPlan();
    const query = useSwr(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const getPendingSwitch = () => {
    return customInstance({ url: `/treatment-plan/pending-switch`, method: 'GET' });
};
export const getGetPendingSwitchKey = () => [`/treatment-plan/pending-switch`];
export const useGetPendingSwitch = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const isEnabled = (swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.enabled) !== false;
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : (() => (isEnabled ? getGetPendingSwitchKey() : null));
    const swrFn = () => getPendingSwitch();
    const query = useSwr(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const submitTreatmentPlan = (treatmentPlanOrderRequest) => {
    return customInstance({
        url: `/treatment-plan/submit`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: treatmentPlanOrderRequest,
    });
};
export const getSubmitTreatmentPlanMutationFetcher = () => {
    return (_, { arg }) => {
        return submitTreatmentPlan(arg);
    };
};
export const getSubmitTreatmentPlanMutationKey = () => [`/treatment-plan/submit`];
export const useSubmitTreatmentPlan = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : getSubmitTreatmentPlanMutationKey();
    const swrFn = getSubmitTreatmentPlanMutationFetcher();
    const query = useSWRMutation(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const addToTreatmentPlan = (pickTreatmentPlanOrderRequestExcludeKeyofTreatmentPlanOrderRequestPendingChargeIds) => {
    return customInstance({
        url: `/treatment-plan/add`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: pickTreatmentPlanOrderRequestExcludeKeyofTreatmentPlanOrderRequestPendingChargeIds,
    });
};
export const getAddToTreatmentPlanMutationFetcher = () => {
    return (_, { arg, }) => {
        return addToTreatmentPlan(arg);
    };
};
export const getAddToTreatmentPlanMutationKey = () => [`/treatment-plan/add`];
export const useAddToTreatmentPlan = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : getAddToTreatmentPlanMutationKey();
    const swrFn = getAddToTreatmentPlanMutationFetcher();
    const query = useSWRMutation(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const viewedPendingTreatmentPlan = (treatmentPlanViewedRequest) => {
    return customInstance({
        url: `/treatment-plan/viewed-pending`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: treatmentPlanViewedRequest,
    });
};
export const getViewedPendingTreatmentPlanMutationFetcher = () => {
    return (_, { arg }) => {
        return viewedPendingTreatmentPlan(arg);
    };
};
export const getViewedPendingTreatmentPlanMutationKey = () => [`/treatment-plan/viewed-pending`];
export const useViewedPendingTreatmentPlan = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : getViewedPendingTreatmentPlanMutationKey();
    const swrFn = getViewedPendingTreatmentPlanMutationFetcher();
    const query = useSWRMutation(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const getCalculatedTax = (taxesShipping) => {
    return customInstance({
        url: `/taxjar`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: taxesShipping,
    });
};
export const getGetCalculatedTaxMutationFetcher = () => {
    return (_, { arg }) => {
        return getCalculatedTax(arg);
    };
};
export const getGetCalculatedTaxMutationKey = () => [`/taxjar`];
export const useGetCalculatedTax = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : getGetCalculatedTaxMutationKey();
    const swrFn = getGetCalculatedTaxMutationFetcher();
    const query = useSWRMutation(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const validateAddress = (pickCustomerCityOrZipOrStateAbbrOrShippingAddressLineOne) => {
    return customInstance({
        url: `/taxjar/validate`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: pickCustomerCityOrZipOrStateAbbrOrShippingAddressLineOne,
    });
};
export const getValidateAddressMutationFetcher = () => {
    return (_, { arg }) => {
        return validateAddress(arg);
    };
};
export const getValidateAddressMutationKey = () => [`/taxjar/validate`];
export const useValidateAddress = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : getValidateAddressMutationKey();
    const swrFn = getValidateAddressMutationFetcher();
    const query = useSWRMutation(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const runSuggestions = (suggestionPayload) => {
    return customInstance({
        url: `/suggestion`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: suggestionPayload,
    });
};
export const getRunSuggestionsMutationFetcher = () => {
    return (_, { arg }) => {
        return runSuggestions(arg);
    };
};
export const getRunSuggestionsMutationKey = () => [`/suggestion`];
export const useRunSuggestions = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : getRunSuggestionsMutationKey();
    const swrFn = getRunSuggestionsMutationFetcher();
    const query = useSWRMutation(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const getAllSubscriptionsForCustomer = () => {
    return customInstance({ url: `/subscriptions`, method: 'GET' });
};
export const getGetAllSubscriptionsForCustomerKey = () => [`/subscriptions`];
export const useGetAllSubscriptionsForCustomer = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const isEnabled = (swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.enabled) !== false;
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : (() => (isEnabled ? getGetAllSubscriptionsForCustomerKey() : null));
    const swrFn = () => getAllSubscriptionsForCustomer();
    const query = useSwr(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
/**
 * TODO: more details in the ticket below, but ideally we remove this endpoint and attach isProcessing onto the
actual subscription object that goes to the FE in the getAllSubscriptionForCustomer func above

ticket: https://app.shortcut.com/myalloy/story/18407
 */
export const isProcessing = (subId) => {
    return customInstance({ url: `/subscriptions/${subId}/isProcessing`, method: 'GET' });
};
export const getIsProcessingKey = (subId) => [`/subscriptions/${subId}/isProcessing`];
export const useIsProcessing = (subId, options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const isEnabled = (swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.enabled) !== false && !!subId;
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : (() => (isEnabled ? getIsProcessingKey(subId) : null));
    const swrFn = () => isProcessing(subId);
    const query = useSwr(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const setNextRecurrence = (subId, millis, setNextRecurrenceBody) => {
    return customInstance({
        url: `/subscriptions/${subId}/set-next-recurrence/${millis}`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: setNextRecurrenceBody,
    });
};
export const getSetNextRecurrenceMutationFetcher = (subId, millis) => {
    return (_, { arg }) => {
        return setNextRecurrence(subId, millis, arg);
    };
};
export const getSetNextRecurrenceMutationKey = (subId, millis) => [`/subscriptions/${subId}/set-next-recurrence/${millis}`];
export const useSetNextRecurrence = (subId, millis, options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : getSetNextRecurrenceMutationKey(subId, millis);
    const swrFn = getSetNextRecurrenceMutationFetcher(subId, millis);
    const query = useSWRMutation(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const unnpauseSubscription = (subId) => {
    return customInstance({
        url: `/subscriptions/${subId}/unpause`,
        method: 'POST',
    });
};
export const getUnnpauseSubscriptionMutationFetcher = (subId) => {
    return (_, __) => {
        return unnpauseSubscription(subId);
    };
};
export const getUnnpauseSubscriptionMutationKey = (subId) => [`/subscriptions/${subId}/unpause`];
export const useUnnpauseSubscription = (subId, options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : getUnnpauseSubscriptionMutationKey(subId);
    const swrFn = getUnnpauseSubscriptionMutationFetcher(subId);
    const query = useSWRMutation(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const billSubscriptionNow = (subId, billSubscriptionNowBody) => {
    return customInstance({
        url: `/subscriptions/${subId}/bill_now`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: billSubscriptionNowBody,
    });
};
export const getBillSubscriptionNowMutationFetcher = (subId) => {
    return (_, { arg }) => {
        return billSubscriptionNow(subId, arg);
    };
};
export const getBillSubscriptionNowMutationKey = (subId) => [`/subscriptions/${subId}/bill_now`];
export const useBillSubscriptionNow = (subId, options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : getBillSubscriptionNowMutationKey(subId);
    const swrFn = getBillSubscriptionNowMutationFetcher(subId);
    const query = useSWRMutation(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const reactivateSubscription = (subId) => {
    return customInstance({ url: `/subscriptions/${subId}/reactivate`, method: 'POST' });
};
export const getReactivateSubscriptionMutationFetcher = (subId) => {
    return (_, __) => {
        return reactivateSubscription(subId);
    };
};
export const getReactivateSubscriptionMutationKey = (subId) => [`/subscriptions/${subId}/reactivate`];
export const useReactivateSubscription = (subId, options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : getReactivateSubscriptionMutationKey(subId);
    const swrFn = getReactivateSubscriptionMutationFetcher(subId);
    const query = useSWRMutation(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const applyPromoCode = (subId, code) => {
    return customInstance({
        url: `/subscriptions/${subId}/promo/apply/${code}`,
        method: 'POST',
    });
};
export const getApplyPromoCodeMutationFetcher = (subId, code) => {
    return (_, __) => {
        return applyPromoCode(subId, code);
    };
};
export const getApplyPromoCodeMutationKey = (subId, code) => [`/subscriptions/${subId}/promo/apply/${code}`];
export const useApplyPromoCode = (subId, code, options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : getApplyPromoCodeMutationKey(subId, code);
    const swrFn = getApplyPromoCodeMutationFetcher(subId, code);
    const query = useSWRMutation(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const removePromoCode = (subId) => {
    return customInstance({ url: `/subscriptions/${subId}/promo/remove`, method: 'POST' });
};
export const getRemovePromoCodeMutationFetcher = (subId) => {
    return (_, __) => {
        return removePromoCode(subId);
    };
};
export const getRemovePromoCodeMutationKey = (subId) => [`/subscriptions/${subId}/promo/remove`];
export const useRemovePromoCode = (subId, options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : getRemovePromoCodeMutationKey(subId);
    const swrFn = getRemovePromoCodeMutationFetcher(subId);
    const query = useSWRMutation(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const bundleSubscriptions = (bundleSubscription) => {
    return customInstance({
        url: `/subscriptions/bundle`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: bundleSubscription,
    });
};
export const getBundleSubscriptionsMutationFetcher = () => {
    return (_, { arg }) => {
        return bundleSubscriptions(arg);
    };
};
export const getBundleSubscriptionsMutationKey = () => [`/subscriptions/bundle`];
export const useBundleSubscriptions = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : getBundleSubscriptionsMutationKey();
    const swrFn = getBundleSubscriptionsMutationFetcher();
    const query = useSWRMutation(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
/**
 * TODO: to not mess with more functions than NEEDED for the moment especially messing with a lot of things that work. I propose later to adjust
the EDITING* of subscriptions by just having probably one endpoint that has a field such as level: 'product' | 'subscription' which
has similar behavior as below where it unbundles a product if at the product level and then does the needful for ship now, reschedule, or pause
or doing it at the subscription level. this imo will help clean out a few endpoints above and remove potential redundancy.
see ticket: https://app.shortcut.com/myalloy/story/18761
 */
export const unbundleProductFromSubscription = (unbundleSubscription) => {
    return customInstance({
        url: `/subscriptions/unbundle`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: unbundleSubscription,
    });
};
export const getUnbundleProductFromSubscriptionMutationFetcher = () => {
    return (_, { arg }) => {
        return unbundleProductFromSubscription(arg);
    };
};
export const getUnbundleProductFromSubscriptionMutationKey = () => [`/subscriptions/unbundle`];
export const useUnbundleProductFromSubscription = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : getUnbundleProductFromSubscriptionMutationKey();
    const swrFn = getUnbundleProductFromSubscriptionMutationFetcher();
    const query = useSWRMutation(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const cancel = (cancelSubscriptionProducts) => {
    return customInstance({
        url: `/subscriptions/cancel`,
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        data: cancelSubscriptionProducts,
    });
};
export const getCancelMutationFetcher = () => {
    return (_, { arg }) => {
        return cancel(arg);
    };
};
export const getCancelMutationKey = () => [`/subscriptions/cancel`];
export const useCancel = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : getCancelMutationKey();
    const swrFn = getCancelMutationFetcher();
    const query = useSWRMutation(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const postSubmission = (pickSubmissionExcludeKeyofSubmissionId) => {
    return customInstance({
        url: `/submission`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: pickSubmissionExcludeKeyofSubmissionId,
    });
};
export const getPostSubmissionMutationFetcher = () => {
    return (_, { arg }) => {
        return postSubmission(arg);
    };
};
export const getPostSubmissionMutationKey = () => [`/submission`];
export const usePostSubmission = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : getPostSubmissionMutationKey();
    const swrFn = getPostSubmissionMutationFetcher();
    const query = useSWRMutation(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const updatePartialSubmission = (partialSubmission) => {
    return customInstance({
        url: `/submission`,
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        data: partialSubmission,
    });
};
export const getUpdatePartialSubmissionMutationFetcher = () => {
    return (_, { arg }) => {
        return updatePartialSubmission(arg);
    };
};
export const getUpdatePartialSubmissionMutationKey = () => [`/submission`];
export const useUpdatePartialSubmission = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : getUpdatePartialSubmissionMutationKey();
    const swrFn = getUpdatePartialSubmissionMutationFetcher();
    const query = useSWRMutation(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const getRecentSubmission = (params) => {
    return customInstance({ url: `/submission`, method: 'GET', params });
};
export const getGetRecentSubmissionKey = (params) => [`/submission`, ...(params ? [params] : [])];
export const useGetRecentSubmission = (params, options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const isEnabled = (swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.enabled) !== false;
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : (() => (isEnabled ? getGetRecentSubmissionKey(params) : null));
    const swrFn = () => getRecentSubmission(params);
    const query = useSwr(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const getSubmissions = () => {
    return customInstance({ url: `/submission/me`, method: 'GET' });
};
export const getGetSubmissionsKey = () => [`/submission/me`];
export const useGetSubmissions = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const isEnabled = (swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.enabled) !== false;
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : (() => (isEnabled ? getGetSubmissionsKey() : null));
    const swrFn = () => getSubmissions();
    const query = useSwr(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const submissionByCategoriesExists = (params) => {
    return customInstance({ url: `/submission/me/exists`, method: 'GET', params });
};
export const getSubmissionByCategoriesExistsKey = (params) => [`/submission/me/exists`, ...(params ? [params] : [])];
export const useSubmissionByCategoriesExists = (params, options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const isEnabled = (swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.enabled) !== false;
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : (() => (isEnabled ? getSubmissionByCategoriesExistsKey(params) : null));
    const swrFn = () => submissionByCategoriesExists(params);
    const query = useSwr(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const postPreIdentifiedSubmission = (pickSubmissionExcludeKeyofSubmissionIdOrCustomerId) => {
    return customInstance({
        url: `/preidentified-submission`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: pickSubmissionExcludeKeyofSubmissionIdOrCustomerId,
    });
};
export const getPostPreIdentifiedSubmissionMutationFetcher = () => {
    return (_, { arg }) => {
        return postPreIdentifiedSubmission(arg);
    };
};
export const getPostPreIdentifiedSubmissionMutationKey = () => [`/preidentified-submission`];
export const usePostPreIdentifiedSubmission = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : getPostPreIdentifiedSubmissionMutationKey();
    const swrFn = getPostPreIdentifiedSubmissionMutationFetcher();
    const query = useSWRMutation(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const updatePreIdentifiedPartialSubmission = (partialOmitSubmissionCustomerId) => {
    return customInstance({
        url: `/preidentified-submission`,
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        data: partialOmitSubmissionCustomerId,
    });
};
export const getUpdatePreIdentifiedPartialSubmissionMutationFetcher = () => {
    return (_, { arg }) => {
        return updatePreIdentifiedPartialSubmission(arg);
    };
};
export const getUpdatePreIdentifiedPartialSubmissionMutationKey = () => [`/preidentified-submission`];
export const useUpdatePreIdentifiedPartialSubmission = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : getUpdatePreIdentifiedPartialSubmissionMutationKey();
    const swrFn = getUpdatePreIdentifiedPartialSubmissionMutationFetcher();
    const query = useSWRMutation(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const handleStripeWebhook = (handleStripeWebhookBody) => {
    return customInstance({
        url: `/stripe/webhook`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: handleStripeWebhookBody,
    });
};
export const getHandleStripeWebhookMutationFetcher = () => {
    return (_, { arg }) => {
        return handleStripeWebhook(arg);
    };
};
export const getHandleStripeWebhookMutationKey = () => [`/stripe/webhook`];
export const useHandleStripeWebhook = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : getHandleStripeWebhookMutationKey();
    const swrFn = getHandleStripeWebhookMutationFetcher();
    const query = useSWRMutation(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const signUpCustomer = (unsavedCognitoCustomer) => {
    return customInstance({
        url: `/signup`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: unsavedCognitoCustomer,
    });
};
export const getSignUpCustomerMutationFetcher = () => {
    return (_, { arg }) => {
        return signUpCustomer(arg);
    };
};
export const getSignUpCustomerMutationKey = () => [`/signup`];
export const useSignUpCustomer = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : getSignUpCustomerMutationKey();
    const swrFn = getSignUpCustomerMutationFetcher();
    const query = useSWRMutation(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const getShippingMethods = (state) => {
    return customInstance({
        url: `/shipping-method/${state}`,
        method: 'GET',
    });
};
export const getGetShippingMethodsKey = (state) => [`/shipping-method/${state}`];
export const useGetShippingMethods = (state, options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const isEnabled = (swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.enabled) !== false && !!state;
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : (() => (isEnabled ? getGetShippingMethodsKey(state) : null));
    const swrFn = () => getShippingMethods(state);
    const query = useSwr(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const getAllShippingMethods = () => {
    return customInstance({ url: `/shipping-method`, method: 'GET' });
};
export const getGetAllShippingMethodsKey = () => [`/shipping-method`];
export const useGetAllShippingMethods = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const isEnabled = (swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.enabled) !== false;
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : (() => (isEnabled ? getGetAllShippingMethodsKey() : null));
    const swrFn = () => getAllShippingMethods();
    const query = useSwr(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const getOrderCarrier = (orderId) => {
    return customInstance({ url: `/shipping-method/carrier/${orderId}`, method: 'GET' });
};
export const getGetOrderCarrierKey = (orderId) => [`/shipping-method/carrier/${orderId}`];
export const useGetOrderCarrier = (orderId, options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const isEnabled = (swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.enabled) !== false && !!orderId;
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : (() => (isEnabled ? getGetOrderCarrierKey(orderId) : null));
    const swrFn = () => getOrderCarrier(orderId);
    const query = useSwr(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const startRenewalAlert = (renewalProductNames) => {
    return customInstance({
        url: `/renewal/start`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: renewalProductNames,
    });
};
export const getStartRenewalAlertMutationFetcher = () => {
    return (_, { arg }) => {
        return startRenewalAlert(arg);
    };
};
export const getStartRenewalAlertMutationKey = () => [`/renewal/start`];
export const useStartRenewalAlert = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : getStartRenewalAlertMutationKey();
    const swrFn = getStartRenewalAlertMutationFetcher();
    const query = useSWRMutation(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const processRenewalComplete = (renewalPayload) => {
    return customInstance({
        url: `/renewal/complete`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: renewalPayload,
    });
};
export const getProcessRenewalCompleteMutationFetcher = () => {
    return (_, { arg }) => {
        return processRenewalComplete(arg);
    };
};
export const getProcessRenewalCompleteMutationKey = () => [`/renewal/complete`];
export const useProcessRenewalComplete = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : getProcessRenewalCompleteMutationKey();
    const swrFn = getProcessRenewalCompleteMutationFetcher();
    const query = useSWRMutation(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const checkValidity = (code, params) => {
    return customInstance({
        url: `/promotion-code/validity/${code}`,
        method: 'GET',
        params,
    });
};
export const getCheckValidityKey = (code, params) => [`/promotion-code/validity/${code}`, ...(params ? [params] : [])];
export const useCheckValidity = (code, params, options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const isEnabled = (swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.enabled) !== false && !!code;
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : (() => (isEnabled ? getCheckValidityKey(code, params) : null));
    const swrFn = () => checkValidity(code, params);
    const query = useSwr(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const getAllProductFrequencies = () => {
    return customInstance({ url: `/products`, method: 'GET' });
};
export const getGetAllProductFrequenciesKey = () => [`/products`];
export const useGetAllProductFrequencies = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const isEnabled = (swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.enabled) !== false;
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : (() => (isEnabled ? getGetAllProductFrequenciesKey() : null));
    const swrFn = () => getAllProductFrequencies();
    const query = useSwr(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const getNonCustomerUpsellProducts = () => {
    return customInstance({
        url: `/products-customer/non-customer-upsell-products`,
        method: 'GET',
    });
};
export const getGetNonCustomerUpsellProductsKey = () => [`/products-customer/non-customer-upsell-products`];
export const useGetNonCustomerUpsellProducts = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const isEnabled = (swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.enabled) !== false;
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : (() => (isEnabled ? getGetNonCustomerUpsellProductsKey() : null));
    const swrFn = () => getNonCustomerUpsellProducts();
    const query = useSwr(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const createDosageSwitchReason = (createDosageSwitchReasonBody) => {
    return customInstance({
        url: `/product-switch/reason`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: createDosageSwitchReasonBody,
    });
};
export const getCreateDosageSwitchReasonMutationFetcher = () => {
    return (_, { arg }) => {
        return createDosageSwitchReason(arg);
    };
};
export const getCreateDosageSwitchReasonMutationKey = () => [`/product-switch/reason`];
export const useCreateDosageSwitchReason = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : getCreateDosageSwitchReasonMutationKey();
    const swrFn = getCreateDosageSwitchReasonMutationFetcher();
    const query = useSWRMutation(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const processProductSwitch = (productSwitchPayload) => {
    return customInstance({
        url: `/product-switch/process`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: productSwitchPayload,
    });
};
export const getProcessProductSwitchMutationFetcher = () => {
    return (_, { arg }) => {
        return processProductSwitch(arg);
    };
};
export const getProcessProductSwitchMutationKey = () => [`/product-switch/process`];
export const useProcessProductSwitch = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : getProcessProductSwitchMutationKey();
    const swrFn = getProcessProductSwitchMutationFetcher();
    const query = useSWRMutation(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const sendSessionStart = (sessionStart) => {
    return customInstance({
        url: `/prepurchase/session-start`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: sessionStart,
    });
};
export const getSendSessionStartMutationFetcher = () => {
    return (_, { arg }) => {
        return sendSessionStart(arg);
    };
};
export const getSendSessionStartMutationKey = () => [`/prepurchase/session-start`];
export const useSendSessionStart = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : getSendSessionStartMutationKey();
    const swrFn = getSendSessionStartMutationFetcher();
    const query = useSWRMutation(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const getMessage = () => {
    return customInstance({ url: `/ping`, method: 'GET' });
};
export const getGetMessageKey = () => [`/ping`];
export const useGetMessage = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const isEnabled = (swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.enabled) !== false;
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : (() => (isEnabled ? getGetMessageKey() : null));
    const swrFn = () => getMessage();
    const query = useSwr(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const getAllOrders = () => {
    return customInstance({ url: `/orders`, method: 'GET' });
};
export const getGetAllOrdersKey = () => [`/orders`];
export const useGetAllOrders = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const isEnabled = (swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.enabled) !== false;
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : (() => (isEnabled ? getGetAllOrdersKey() : null));
    const swrFn = () => getAllOrders();
    const query = useSwr(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const getAllUnreadNotifications = () => {
    return customInstance({
        url: `/notifications/notifications`,
        method: 'GET',
    });
};
export const getGetAllUnreadNotificationsKey = () => [`/notifications/notifications`];
export const useGetAllUnreadNotifications = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const isEnabled = (swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.enabled) !== false;
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : (() => (isEnabled ? getGetAllUnreadNotificationsKey() : null));
    const swrFn = () => getAllUnreadNotifications();
    const query = useSwr(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const markNotificationsAsReadByEventType = (markNotificationsAsReadByEventTypeBody) => {
    return customInstance({
        url: `/notifications/notifications/read-by-event-type`,
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        data: markNotificationsAsReadByEventTypeBody,
    });
};
export const getMarkNotificationsAsReadByEventTypeMutationFetcher = () => {
    return (_, { arg }) => {
        return markNotificationsAsReadByEventType(arg);
    };
};
export const getMarkNotificationsAsReadByEventTypeMutationKey = () => [`/notifications/notifications/read-by-event-type`];
export const useMarkNotificationsAsReadByEventType = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : getMarkNotificationsAsReadByEventTypeMutationKey();
    const swrFn = getMarkNotificationsAsReadByEventTypeMutationFetcher();
    const query = useSWRMutation(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
/**
 * Subscribe the given email to the newsletter. Unauthenticated and used in marketing
and dashboard sites.
 */
export const subscribeToNewsletter = (subscribeToNewsletterBody) => {
    return customInstance({
        url: `/newsletter/subscribe`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: subscribeToNewsletterBody,
    });
};
export const getSubscribeToNewsletterMutationFetcher = () => {
    return (_, { arg }) => {
        return subscribeToNewsletter(arg);
    };
};
export const getSubscribeToNewsletterMutationKey = () => [`/newsletter/subscribe`];
export const useSubscribeToNewsletter = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : getSubscribeToNewsletterMutationKey();
    const swrFn = getSubscribeToNewsletterMutationFetcher();
    const query = useSWRMutation(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const handleMDIWebhook = (handleMDIWebhookBody) => {
    return customInstance({
        url: `/mdi/webhook`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: handleMDIWebhookBody,
    });
};
export const getHandleMDIWebhookMutationFetcher = () => {
    return (_, { arg }) => {
        return handleMDIWebhook(arg);
    };
};
export const getHandleMDIWebhookMutationKey = () => [`/mdi/webhook`];
export const useHandleMDIWebhook = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : getHandleMDIWebhookMutationKey();
    const swrFn = getHandleMDIWebhookMutationFetcher();
    const query = useSWRMutation(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const signup = (landingPageSignup) => {
    return customInstance({
        url: `/landing-page-signup/signup`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: landingPageSignup,
    });
};
export const getSignupMutationFetcher = () => {
    return (_, { arg }) => {
        return signup(arg);
    };
};
export const getSignupMutationKey = () => [`/landing-page-signup/signup`];
export const useSignup = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : getSignupMutationKey();
    const swrFn = getSignupMutationFetcher();
    const query = useSWRMutation(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const uploadMediaFile = (uploadMediaFileBody) => {
    const formData = new FormData();
    formData.append('file', uploadMediaFileBody.file);
    return customInstance({
        url: `/fileupload/media`,
        method: 'POST',
        headers: { 'Content-Type': 'multipart/form-data' },
        data: formData,
    });
};
export const getUploadMediaFileMutationFetcher = () => {
    return (_, { arg }) => {
        return uploadMediaFile(arg);
    };
};
export const getUploadMediaFileMutationKey = () => [`/fileupload/media`];
export const useUploadMediaFile = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : getUploadMediaFileMutationKey();
    const swrFn = getUploadMediaFileMutationFetcher();
    const query = useSWRMutation(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const uploadIdentificationPhoto = (uploadIdentificationPhotoBody) => {
    const formData = new FormData();
    formData.append('file', uploadIdentificationPhotoBody.file);
    return customInstance({
        url: `/fileupload/idenfitication-photo`,
        method: 'POST',
        headers: { 'Content-Type': 'multipart/form-data' },
        data: formData,
    });
};
export const getUploadIdentificationPhotoMutationFetcher = () => {
    return (_, { arg }) => {
        return uploadIdentificationPhoto(arg);
    };
};
export const getUploadIdentificationPhotoMutationKey = () => [`/fileupload/idenfitication-photo`];
export const useUploadIdentificationPhoto = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : getUploadIdentificationPhotoMutationKey();
    const swrFn = getUploadIdentificationPhotoMutationFetcher();
    const query = useSWRMutation(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const licenseExistsByCustomer = () => {
    return customInstance({ url: `/fileupload/license/exists`, method: 'GET' });
};
export const getLicenseExistsByCustomerKey = () => [`/fileupload/license/exists`];
export const useLicenseExistsByCustomer = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const isEnabled = (swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.enabled) !== false;
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : (() => (isEnabled ? getLicenseExistsByCustomerKey() : null));
    const swrFn = () => licenseExistsByCustomer();
    const query = useSwr(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const uploadAvSyncVideo = (uploadAvSyncVideoBody) => {
    const formData = new FormData();
    formData.append('file', uploadAvSyncVideoBody.file);
    return customInstance({
        url: `/fileupload/av-sync`,
        method: 'POST',
        headers: { 'Content-Type': 'multipart/form-data' },
        data: formData,
    });
};
export const getUploadAvSyncVideoMutationFetcher = () => {
    return (_, { arg }) => {
        return uploadAvSyncVideo(arg);
    };
};
export const getUploadAvSyncVideoMutationKey = () => [`/fileupload/av-sync`];
export const useUploadAvSyncVideo = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : getUploadAvSyncVideoMutationKey();
    const swrFn = getUploadAvSyncVideoMutationFetcher();
    const query = useSWRMutation(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const addDSARequest = (pickDSARequestExcludeKeyofDSARequestIdOrActive) => {
    return customInstance({
        url: `/dsar/request`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: pickDSARequestExcludeKeyofDSARequestIdOrActive,
    });
};
export const getAddDSARequestMutationFetcher = () => {
    return (_, { arg }) => {
        return addDSARequest(arg);
    };
};
export const getAddDSARequestMutationKey = () => [`/dsar/request`];
export const useAddDSARequest = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : getAddDSARequestMutationKey();
    const swrFn = getAddDSARequestMutationFetcher();
    const query = useSWRMutation(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const getDiscountBundles = () => {
    return customInstance({
        url: `/discount-bundle`,
        method: 'GET',
    });
};
export const getGetDiscountBundlesKey = () => [`/discount-bundle`];
export const useGetDiscountBundles = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const isEnabled = (swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.enabled) !== false;
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : (() => (isEnabled ? getGetDiscountBundlesKey() : null));
    const swrFn = () => getDiscountBundles();
    const query = useSwr(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const getCustomer = () => {
    return customInstance({ url: `/customer/me`, method: 'GET' });
};
export const getGetCustomerKey = () => [`/customer/me`];
export const useGetCustomer = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const isEnabled = (swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.enabled) !== false;
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : (() => (isEnabled ? getGetCustomerKey() : null));
    const swrFn = () => getCustomer();
    const query = useSwr(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const getCustomerReferralCode = () => {
    return customInstance({ url: `/customer/me/referral-code`, method: 'GET' });
};
export const getGetCustomerReferralCodeKey = () => [`/customer/me/referral-code`];
export const useGetCustomerReferralCode = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const isEnabled = (swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.enabled) !== false;
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : (() => (isEnabled ? getGetCustomerReferralCodeKey() : null));
    const swrFn = () => getCustomerReferralCode();
    const query = useSwr(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const getCustomerReferralCredits = () => {
    return customInstance({ url: `/customer/me/referral-credits`, method: 'GET' });
};
export const getGetCustomerReferralCreditsKey = () => [`/customer/me/referral-credits`];
export const useGetCustomerReferralCredits = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const isEnabled = (swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.enabled) !== false;
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : (() => (isEnabled ? getGetCustomerReferralCreditsKey() : null));
    const swrFn = () => getCustomerReferralCredits();
    const query = useSwr(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const getCustomerReferralCreditBalance = () => {
    return customInstance({ url: `/customer/me/referral-credit-balance`, method: 'GET' });
};
export const getGetCustomerReferralCreditBalanceKey = () => [`/customer/me/referral-credit-balance`];
export const useGetCustomerReferralCreditBalance = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const isEnabled = (swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.enabled) !== false;
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : (() => (isEnabled ? getGetCustomerReferralCreditBalanceKey() : null));
    const swrFn = () => getCustomerReferralCreditBalance();
    const query = useSwr(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const patchCustomer = (partialOmitUnsavedCustomerEmail) => {
    return customInstance({
        url: `/customer`,
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        data: partialOmitUnsavedCustomerEmail,
    });
};
export const getPatchCustomerMutationFetcher = () => {
    return (_, { arg }) => {
        return patchCustomer(arg);
    };
};
export const getPatchCustomerMutationKey = () => [`/customer`];
export const usePatchCustomer = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : getPatchCustomerMutationKey();
    const swrFn = getPatchCustomerMutationFetcher();
    const query = useSWRMutation(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const getCustomerDoctor = () => {
    return customInstance({ url: `/customer/doctor`, method: 'GET' });
};
export const getGetCustomerDoctorKey = () => [`/customer/doctor`];
export const useGetCustomerDoctor = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const isEnabled = (swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.enabled) !== false;
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : (() => (isEnabled ? getGetCustomerDoctorKey() : null));
    const swrFn = () => getCustomerDoctor();
    const query = useSwr(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const getCustomerSymptoms = () => {
    return customInstance({ url: `/customer/symptoms`, method: 'GET' });
};
export const getGetCustomerSymptomsKey = () => [`/customer/symptoms`];
export const useGetCustomerSymptoms = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const isEnabled = (swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.enabled) !== false;
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : (() => (isEnabled ? getGetCustomerSymptomsKey() : null));
    const swrFn = () => getCustomerSymptoms();
    const query = useSwr(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const createMessage = (adaptedCreateMdiMessage) => {
    return customInstance({
        url: `/customer/messages`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: adaptedCreateMdiMessage,
    });
};
export const getCreateMessageMutationFetcher = () => {
    return (_, { arg }) => {
        return createMessage(arg);
    };
};
export const getCreateMessageMutationKey = () => [`/customer/messages`];
export const useCreateMessage = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : getCreateMessageMutationKey();
    const swrFn = getCreateMessageMutationFetcher();
    const query = useSWRMutation(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const getMessagesByPage = (params) => {
    return customInstance({
        url: `/customer/messages`,
        method: 'GET',
        params,
    });
};
export const getGetMessagesByPageKey = (params) => [`/customer/messages`, ...(params ? [params] : [])];
export const useGetMessagesByPage = (params, options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const isEnabled = (swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.enabled) !== false;
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : (() => (isEnabled ? getGetMessagesByPageKey(params) : null));
    const swrFn = () => getMessagesByPage(params);
    const query = useSwr(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const getMessagingStatus = () => {
    return customInstance({ url: `/customer/messages/status`, method: 'GET' });
};
export const getGetMessagingStatusKey = () => [`/customer/messages/status`];
export const useGetMessagingStatus = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const isEnabled = (swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.enabled) !== false;
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : (() => (isEnabled ? getGetMessagingStatusKey() : null));
    const swrFn = () => getMessagingStatus();
    const query = useSwr(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const getCustomerMedications = (customerId) => {
    return customInstance({
        url: `/customer-medication/${customerId}`,
        method: 'GET',
    });
};
export const getGetCustomerMedicationsKey = (customerId) => [`/customer-medication/${customerId}`];
export const useGetCustomerMedications = (customerId, options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const isEnabled = (swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.enabled) !== false && !!customerId;
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : (() => (isEnabled ? getGetCustomerMedicationsKey(customerId) : null));
    const swrFn = () => getCustomerMedications(customerId);
    const query = useSwr(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const createCustomerMedications = (pickCustomerMedicationNameOrDose) => {
    return customInstance({
        url: `/customer-medication`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: pickCustomerMedicationNameOrDose,
    });
};
export const getCreateCustomerMedicationsMutationFetcher = () => {
    return (_, { arg }) => {
        return createCustomerMedications(arg);
    };
};
export const getCreateCustomerMedicationsMutationKey = () => [`/customer-medication`];
export const useCreateCustomerMedications = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : getCreateCustomerMedicationsMutationKey();
    const swrFn = getCreateCustomerMedicationsMutationFetcher();
    const query = useSWRMutation(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const updateCustomerMedications = (pickCustomerMedicationIdOrNameOrDose) => {
    return customInstance({
        url: `/customer-medication`,
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        data: pickCustomerMedicationIdOrNameOrDose,
    });
};
export const getUpdateCustomerMedicationsMutationFetcher = () => {
    return (_, { arg }) => {
        return updateCustomerMedications(arg);
    };
};
export const getUpdateCustomerMedicationsMutationKey = () => [`/customer-medication`];
export const useUpdateCustomerMedications = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : getUpdateCustomerMedicationsMutationKey();
    const swrFn = getUpdateCustomerMedicationsMutationFetcher();
    const query = useSWRMutation(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const deactivateCustomerMedication = (customerMedicationId) => {
    return customInstance({
        url: `/customer-medication/deactivate/${customerMedicationId}`,
        method: 'POST',
    });
};
export const getDeactivateCustomerMedicationMutationFetcher = (customerMedicationId) => {
    return (_, __) => {
        return deactivateCustomerMedication(customerMedicationId);
    };
};
export const getDeactivateCustomerMedicationMutationKey = (customerMedicationId) => [`/customer-medication/deactivate/${customerMedicationId}`];
export const useDeactivateCustomerMedication = (customerMedicationId, options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : getDeactivateCustomerMedicationMutationKey(customerMedicationId);
    const swrFn = getDeactivateCustomerMedicationMutationFetcher(customerMedicationId);
    const query = useSWRMutation(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const getCustomerAllergies = (customerId) => {
    return customInstance({
        url: `/customer-allergy/${customerId}`,
        method: 'GET',
    });
};
export const getGetCustomerAllergiesKey = (customerId) => [`/customer-allergy/${customerId}`];
export const useGetCustomerAllergies = (customerId, options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const isEnabled = (swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.enabled) !== false && !!customerId;
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : (() => (isEnabled ? getGetCustomerAllergiesKey(customerId) : null));
    const swrFn = () => getCustomerAllergies(customerId);
    const query = useSwr(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const createCustomerAllergies = (pickCustomerAllergyNameOrReactionOrDetails) => {
    return customInstance({
        url: `/customer-allergy`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: pickCustomerAllergyNameOrReactionOrDetails,
    });
};
export const getCreateCustomerAllergiesMutationFetcher = () => {
    return (_, { arg }) => {
        return createCustomerAllergies(arg);
    };
};
export const getCreateCustomerAllergiesMutationKey = () => [`/customer-allergy`];
export const useCreateCustomerAllergies = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : getCreateCustomerAllergiesMutationKey();
    const swrFn = getCreateCustomerAllergiesMutationFetcher();
    const query = useSWRMutation(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const updateCustomerAllergies = (pickCustomerAllergyIdOrNameOrReactionOrDetails) => {
    return customInstance({
        url: `/customer-allergy`,
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        data: pickCustomerAllergyIdOrNameOrReactionOrDetails,
    });
};
export const getUpdateCustomerAllergiesMutationFetcher = () => {
    return (_, { arg }) => {
        return updateCustomerAllergies(arg);
    };
};
export const getUpdateCustomerAllergiesMutationKey = () => [`/customer-allergy`];
export const useUpdateCustomerAllergies = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : getUpdateCustomerAllergiesMutationKey();
    const swrFn = getUpdateCustomerAllergiesMutationFetcher();
    const query = useSWRMutation(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const deactivateCustomerAllergy = (customerAllergyId) => {
    return customInstance({
        url: `/customer-allergy/deactivate/${customerAllergyId}`,
        method: 'POST',
    });
};
export const getDeactivateCustomerAllergyMutationFetcher = (customerAllergyId) => {
    return (_, __) => {
        return deactivateCustomerAllergy(customerAllergyId);
    };
};
export const getDeactivateCustomerAllergyMutationKey = (customerAllergyId) => [`/customer-allergy/deactivate/${customerAllergyId}`];
export const useDeactivateCustomerAllergy = (customerAllergyId, options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : getDeactivateCustomerAllergyMutationKey(customerAllergyId);
    const swrFn = getDeactivateCustomerAllergyMutationFetcher(customerAllergyId);
    const query = useSWRMutation(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const curexaWebhook = (curexaWebhookBody) => {
    return customInstance({
        url: `/curexa/webhook`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: curexaWebhookBody,
    });
};
export const getCurexaWebhookMutationFetcher = () => {
    return (_, { arg }) => {
        return curexaWebhook(arg);
    };
};
export const getCurexaWebhookMutationKey = () => [`/curexa/webhook`];
export const useCurexaWebhook = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : getCurexaWebhookMutationKey();
    const swrFn = getCurexaWebhookMutationFetcher();
    const query = useSWRMutation(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const processCheckoutV2 = (checkoutV2Payload) => {
    return customInstance({
        url: `/checkout-v2`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: checkoutV2Payload,
    });
};
export const getProcessCheckoutV2MutationFetcher = () => {
    return (_, { arg }) => {
        return processCheckoutV2(arg);
    };
};
export const getProcessCheckoutV2MutationKey = () => [`/checkout-v2`];
export const useProcessCheckoutV2 = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : getProcessCheckoutV2MutationKey();
    const swrFn = getProcessCheckoutV2MutationFetcher();
    const query = useSWRMutation(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const processCancelRetention = (cancelRetentionPayload) => {
    return customInstance({
        url: `/checkout-v2/cancel-retention`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: cancelRetentionPayload,
    });
};
export const getProcessCancelRetentionMutationFetcher = () => {
    return (_, { arg }) => {
        return processCancelRetention(arg);
    };
};
export const getProcessCancelRetentionMutationKey = () => [`/checkout-v2/cancel-retention`];
export const useProcessCancelRetention = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : getProcessCancelRetentionMutationKey();
    const swrFn = getProcessCancelRetentionMutationFetcher();
    const query = useSWRMutation(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const generateSetupIntent = () => {
    return customInstance({
        url: `/checkout-v2/generate-setup-intent`,
        method: 'GET',
    });
};
export const getGenerateSetupIntentKey = () => [`/checkout-v2/generate-setup-intent`];
export const useGenerateSetupIntent = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const isEnabled = (swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.enabled) !== false;
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : (() => (isEnabled ? getGenerateSetupIntentKey() : null));
    const swrFn = () => generateSetupIntent();
    const query = useSwr(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const getPaymentMethods = () => {
    return customInstance({
        url: `/checkout-v2/get-payment-methods`,
        method: 'GET',
    });
};
export const getGetPaymentMethodsKey = () => [`/checkout-v2/get-payment-methods`];
export const useGetPaymentMethods = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const isEnabled = (swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.enabled) !== false;
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : (() => (isEnabled ? getGetPaymentMethodsKey() : null));
    const swrFn = () => getPaymentMethods();
    const query = useSwr(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const attachPaymentMethod = (attachPaymentMethodBody) => {
    return customInstance({
        url: `/checkout-v2/attach-payment-method`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: attachPaymentMethodBody,
    });
};
export const getAttachPaymentMethodMutationFetcher = () => {
    return (_, { arg }) => {
        return attachPaymentMethod(arg);
    };
};
export const getAttachPaymentMethodMutationKey = () => [`/checkout-v2/attach-payment-method`];
export const useAttachPaymentMethod = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : getAttachPaymentMethodMutationKey();
    const swrFn = getAttachPaymentMethodMutationFetcher();
    const query = useSWRMutation(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
