import { flatten } from 'lodash';
import { useDispatch } from 'react-redux';

import { ExperienceCategory } from 'common/dist/models/experience';
import { DeepProduct } from 'common/dist/products/productFrequency';

import { getGroupedQualifiedProducts } from 'modules/shared/lib/product';

import { AlloyCart } from 'modules/shared/models/cart';

import { useAppSelector } from 'shared/store/reducers';

import { clear, create, update } from '../store/cart-slice';

interface ReturnProps {
  cart: AlloyCart;

  createCart: (
    products: DeepProduct[],
    categories: ExperienceCategory[],
    submissionExists: boolean,
  ) => Promise<void>;
  updateCart: (cartUpdate: Partial<AlloyCart>) => void;
  clearCart: () => void;
}

export default function useCart(): ReturnProps {
  const dispatch = useDispatch();

  const { cart } = useAppSelector((state) => state.experience);

  const createCart = async (
    products: DeepProduct[],
    categories: ExperienceCategory[],
    submissionExists: boolean,
  ) => {
    const fetchedProducts = flatten(
      await getGroupedQualifiedProducts(products, categories, submissionExists),
    );

    dispatch(create(fetchedProducts));
  };

  const updateCart = (updatedCart: Partial<AlloyCart>) => dispatch(update(updatedCart));

  const clearCart = () => dispatch(clear());

  return {
    cart,

    createCart,
    updateCart,
    clearCart,
  };
}
