import useCart from 'modules/shared/hooks/useCart';

import { getGroupedCartTotals } from 'modules/shared/lib/cart';

import { useAppSelector } from 'shared/store/reducers';

interface Props {
  onPlaceOrder: () => void;
}

export default function OrderButton({ onPlaceOrder }: Props) {
  const { cart } = useCart();

  const { isLoadingTotals } = useAppSelector((state) => state.treatmentPlan);

  const { total } = getGroupedCartTotals(cart);

  return (
    <div className='bottom-order d-block d-md-none'>
      <button
        className='primary-button'
        onClick={onPlaceOrder}
        disabled={cart.products.length === 0 || isLoadingTotals}
      >
        {isLoadingTotals ? 'Place order' : `Place order - $${total.toFixed(2)}`}
      </button>
    </div>
  );
}
