import './AlloyModal.scss';

import classNames from 'classnames';
import { ReactNode } from 'react';
import { Modal } from 'rsuite';
import { ModalSize } from 'rsuite/esm/Modal/Modal';

import closeIcon from 'shared/assets/svg/common/btn-close.svg';

interface Props {
  drawerClass?: string;
  isOpen: boolean;
  onClose: () => void;
  disableClosing?: boolean;
  size?: ModalSize;
  children: ReactNode;
}

export default function AlloyModal({
  drawerClass,
  isOpen,
  onClose,
  disableClosing = false,
  size = 'sm',
  children,
}: Props) {
  return (
    <Modal
      open={isOpen}
      size={size}
      className={classNames('alloy-modal', drawerClass)}
      onClose={!disableClosing ? onClose : undefined}
    >
      <Modal.Body>
        <div className='modal-header'>
          <button onClick={onClose} className='header-btn-close'>
            <img src={closeIcon} alt='close icon' className='close-icon' />
          </button>
        </div>

        {/* modal-body and modal-footer go here */}
        {children}
      </Modal.Body>
    </Modal>
  );
}
