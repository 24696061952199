import './AvSyncUpload.scss';

import { faCamera, faImage, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chip, Skeleton } from '@mui/material';

import useAvSyncUploadState from '../../hooks/useAvSyncUploadState';
import RecordVideoModal from '../modals/RecordVideoModal';

export default function AvSyncUploadWrapper() {
  const {
    file,

    isLoading,

    isVideoOpen,
    setIsVideoOpen,

    onRemoveFile,
    onAttachFile,
    onAddFile,
    onContinue,
  } = useAvSyncUploadState();

  return (
    <>
      <div className='av-sync-upload-wrapper'>
        <h2 className='upload-title'>Record a quick video</h2>
        <p className='upload-text'>
          Please record a short video of yourself stating your full name, date of birth and
          conditions or symptoms you are consulting for. Please take two full steps away from your
          device or hold your camera at arms length.
        </p>

        {isLoading ? (
          <Skeleton variant='rectangular' height={200} />
        ) : !!file ? (
          <div className='file-content-block' data-sentry-mask>
            <Chip
              label={file.name}
              deleteIcon={<FontAwesomeIcon icon={faXmark} size='sm' />}
              onDelete={() => onRemoveFile()}
            />
          </div>
        ) : (
          <div className='upload-actions-block'>
            <label className='action-button'>
              <FontAwesomeIcon icon={faImage} className='action-icon' />
              <span className='action-text'>Pick from gallery</span>

              <input
                type='file'
                accept='.mp4,.webm,.mov'
                style={{ display: 'none' }}
                onChange={onAttachFile}
              />
            </label>

            <button type='button' className='action-button' onClick={() => setIsVideoOpen(true)}>
              <FontAwesomeIcon icon={faCamera} className='action-icon' />

              <span className='action-text'>Take from camera</span>
            </button>
          </div>
        )}

        <div>
          <button className='primary-button' disabled={!file || isLoading} onClick={onContinue}>
            Continue
          </button>
        </div>
      </div>

      <RecordVideoModal
        isOpen={isVideoOpen}
        onClose={() => setIsVideoOpen(false)}
        onSave={onAddFile}
      />
    </>
  );
}
