import { useGetAllSubscriptionsForCustomer } from 'client/dist/generated/alloy';

import { useAppSelector } from 'shared/store/reducers';

import { useMessagesContext } from '../context/messages';

export default function useMessagesData() {
  const customer = useAppSelector((state) => state.alloy.customer!);

  const { messagingStatus, isLoadingMessagingStatus } = useMessagesContext();

  const { data: subscriptions = [], isLoading: isLoadingSubs } =
    useGetAllSubscriptionsForCustomer();

  const isLoading = isLoadingSubs || isLoadingMessagingStatus;

  return {
    isLoading,

    messagingStatus,

    customer,
    subscriptions,
  };
}
