import { Document, Image, Page, Text, View } from '@react-pdf/renderer';
import { format } from 'date-fns';
import _ from 'lodash';
import { FC } from 'react';

import { ApiOrder, Customer } from 'client/dist/generated/alloy';

import logo from 'shared/assets/images/logo.png';

import { convertCentsToDollars } from 'shared/lib/convert';

import PdfInvoiceVendor from './PdfInvoiceVendor';

import { styles } from '../../data/pdf-invoice-styles';

interface Props {
  orderNumber: string;
  order: ApiOrder;
  customer: Customer;
}

// Create Document Component
export const PdfInvoice: FC<Props> = ({ orderNumber, order, customer }) => {
  const longDate = format(order.createdAt!, 'MMMM d, yyyy');
  const amountSummary = `$${convertCentsToDollars(order?.totalInCents)} USD on ${longDate}`;

  const lineItems =
    order.pdfInvoiceLines?.map((l) => ({
      description: l.description,
      npi: l.npi,
      ndc: l.ndc,
      qty: l.qty,
      formattedUnitPrice: convertCentsToDollars(l.unitPriceInCents),
      formattedExtendedPrice: convertCentsToDollars(l.unitPriceInCents * l.qty),
    })) ?? [];

  const maxLineItemsPerPage = 4;
  const paginatedLineItems = _.chunk(lineItems, maxLineItemsPerPage);

  return (
    <Document>
      {paginatedLineItems.map((page, zeroBasedPageNumber) => (
        <Page size='A4' style={styles.page} key={zeroBasedPageNumber}>
          <View style={styles.header}>
            <View style={styles.headerLeft}>
              <Text>Invoice</Text>
            </View>
            <View style={styles.headerRight}>
              <Image style={styles.headerLogo} src={logo}></Image>
            </View>
          </View>
          <View style={styles.orderNumberAndDate}>
            <View>
              <Text style={styles.fontEmphasized}>Order Number</Text>
              <Text style={styles.fontNormalSpaced}>Date</Text>
            </View>
            <View style={styles.orderNumberAndDateSpacing}>
              <Text style={styles.fontEmphasized}>{orderNumber}</Text>
              <Text style={styles.fontNormalSpaced}>{longDate}</Text>
            </View>
          </View>
          {zeroBasedPageNumber === 0 && (
            <View style={styles.addressInfo}>
              <View style={styles.addressInfoLeft}>
                <Text style={styles.fontEmphasized}>Alloy Health Inc.</Text>
                <Text style={styles.fontNormalSpaced}>31 Bond Street</Text>
                <Text style={styles.fontNormalSpaced}>6th Floor</Text>
                <Text style={styles.fontNormalSpaced}>New York, New York 10012</Text>
                <Text style={styles.fontNormalSpaced}>United States</Text>
                <Text style={styles.fontNormalSpaced}>support@myalloy.com</Text>
              </View>
              <View style={styles.addressInfoRight}>
                <Text style={styles.fontEmphasized}>Shipping address</Text>
                <Text style={styles.fontNormalSpaced}>
                  {order?.shippingAddress?.firstName} {order?.shippingAddress?.lastName}
                </Text>
                <Text style={styles.fontNormalSpaced}>{order?.shippingAddress?.line1}</Text>
                <Text style={styles.fontNormalSpaced}>{order?.shippingAddress?.line2}</Text>
                <Text style={styles.fontNormalSpaced}>
                  {order?.shippingAddress?.city}, {order?.shippingAddress?.state}{' '}
                  {order?.shippingAddress?.zip}
                </Text>
                <Text style={styles.fontNormalSpaced}>
                  {order?.shippingAddress?.country ?? 'United States'}
                </Text>
                <Text style={styles.fontNormalSpaced}>{customer.email}</Text>
              </View>
            </View>
          )}
          <View>
            <Text
              style={{
                ...styles.amountSummary,

                // Account for addresses only showing on first
                // page. Still want the whitespace even if the
                // addresses are not showing. Otherwise it looks
                // really weird IMO.
                marginTop:
                  zeroBasedPageNumber > 0
                    ? styles.amountSummary.marginTopOtherPages
                    : styles.amountSummary.marginTopFirstPage,
              }}
            >
              {amountSummary}
            </Text>
          </View>
          <View>
            <View style={styles.table}>
              <View style={[styles.row, styles.tableHeader]}>
                <Text style={styles.description}>Description</Text>
                <Text style={styles.unitPrice}>Unit Price</Text>
                <Text style={styles.qty}>Qty</Text>
                <Text style={styles.extendedPrice}>Amount</Text>
              </View>
              {page.map((item, i) => (
                <View key={i} style={styles.row} wrap={false}>
                  <View style={styles.description}>
                    <Text>{item.description}</Text>
                    {item.npi?.length > 0 && (
                      <Text style={styles.lineItemNpiAndNdc}>NPI: {item.npi}</Text>
                    )}
                    {item.ndc?.length > 0 && (
                      <Text style={styles.lineItemNpiAndNdc}>NDC: {item.ndc}</Text>
                    )}
                  </View>
                  <Text style={styles.unitPrice}>${item.formattedUnitPrice}</Text>
                  <Text style={styles.qty}>{item.qty}</Text>
                  <Text style={styles.extendedPrice}>${item.formattedExtendedPrice}</Text>
                </View>
              ))}
            </View>
          </View>
          {zeroBasedPageNumber === paginatedLineItems.length - 1 && (
            <View style={styles.vendorInfoAndLineItemsSummary}>
              <PdfInvoiceVendor vendor={order.vendor} />
              <View style={styles.vendorInfoAndLineItemsSummaryRight}>
                <View style={styles.row} wrap={false}>
                  <Text style={styles.lineItemSummaryDescription}>Sub Total</Text>
                  <Text style={styles.lineItemSummaryAmount}>
                    ${convertCentsToDollars(order.subtotalInCents)}
                  </Text>
                </View>
                {order.discount &&
                  order.discount.map((discount, i) => (
                    <View key={i} style={styles.row} wrap={false}>
                      <Text style={styles.lineItemSummaryDescription}>
                        {`Discount (${discount.name})`}
                      </Text>
                      <Text style={styles.lineItemSummaryAmount}>
                        - ${convertCentsToDollars(discount.amount)}
                      </Text>
                    </View>
                  ))}
                <View style={styles.row} wrap={false}>
                  <Text style={styles.lineItemSummaryDescription}>Taxes</Text>
                  <Text style={styles.lineItemSummaryAmount}>
                    ${convertCentsToDollars(order.taxInCents!)}
                  </Text>
                </View>
                <View style={styles.row} wrap={false}>
                  <Text style={styles.lineItemSummaryDescription}>Shipping</Text>
                  <Text style={styles.lineItemSummaryAmount}>
                    ${convertCentsToDollars(order.shippingMethod?.priceInCents ?? 0)}
                  </Text>
                </View>
                <View style={styles.row} wrap={false}>
                  <Text style={styles.amountPaidDescription}>Amount Paid</Text>
                  <Text style={styles.amountPaidAmount}>
                    ${convertCentsToDollars(order.totalInCents!)}
                  </Text>
                </View>
              </View>
            </View>
          )}
          <View style={styles.footer}>
            <View style={styles.footerLine}>
              <View style={styles.footerSummaryInfo}>
                <Text>{`${orderNumber} - ${amountSummary}`}</Text>
              </View>
              <View style={styles.footerPageInfo}>
                <Text>
                  Page {zeroBasedPageNumber + 1} of {paginatedLineItems.length}
                </Text>
              </View>
            </View>
          </View>
        </Page>
      ))}
    </Document>
  );
};
