import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  SubscriptionWithRenewal,
  useGetAllSubscriptionsForCustomer,
  useIsProcessing,
} from 'client/dist/generated/alloy';

import useInitExperience from 'modules/shared/hooks/useInitExperience';

import { canManageSubscription } from '../lib/subscription-manage';

interface Props {
  subscription: SubscriptionWithRenewal;
  isLoading: boolean;
}

// we know a subscription will always exist at this level
export const SubscriptionContext = createContext<Props>({
  subscription: {} as SubscriptionWithRenewal,
  isLoading: true,
});

export const useSubscriptionContext = () => {
  const subscription = useContext(SubscriptionContext);

  return subscription;
};

export default function SubscriptionContextProvider({ children }: { children: ReactNode }) {
  const navigate = useNavigate();
  const params = useParams<{ id?: string }>();

  const { initCheckoutExperience } = useInitExperience();

  const [isLoading, setIsLoading] = useState(true);
  const [subscription, setSubscription] = useState<SubscriptionWithRenewal>(
    {} as SubscriptionWithRenewal,
  );

  const { data: subscriptions = [], isLoading: isLoadingSubscriptions } =
    useGetAllSubscriptionsForCustomer();
  const { data: isProcessing, isLoading: isLoadingProcessing, error } = useIsProcessing(params.id!);

  useEffect(() => {
    refreshSubscriptionSearch();
  }, [JSON.stringify(subscriptions), isLoadingSubscriptions]);

  useEffect(() => {
    if (isProcessing || error) {
      navigate('/subscriptions');
    }
  }, [isProcessing, error]);

  /**
   * find subscription from url in our subscriptions list
   * if a subscription is in a state of needing renewal, we throw customer in renewal
   * otherwise we let them see subscription
   */
  const refreshSubscriptionSearch = async () => {
    setIsLoading(true);

    let subscriptionId = params.id;

    // exit out early if no id or loading
    if (!subscriptionId || isLoadingSubscriptions) return;

    const subscriptionFound = subscriptions.find(
      (item) => item.stripeSubscriptionId === subscriptionId,
    );

    // no sub or no manageable products we don't want to display the subscription for customer
    if (
      !subscriptionFound ||
      !canManageSubscription(subscriptionFound) ||
      subscriptionFound.status !== 'ACTIVE'
    ) {
      navigate('/subscriptions', { replace: true });
      return;
    }
    setSubscription(subscriptionFound);
    setIsLoading(false);
  };

  return (
    <SubscriptionContext.Provider
      value={{
        subscription,
        isLoading: isLoading || isLoadingSubscriptions || isLoadingProcessing,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
}
