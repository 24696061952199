import './MessagesInfo.scss';

import { Skeleton } from '@mui/material';

import { isOtcOnlyCustomer } from 'modules/dashboard/lib/otc-only-customer';

import DashboardContainer from 'modules/dashboard/ui/containers/DashboardContainer';

import EmptyState from 'modules/shared/ui/content/EmptyState';

import TitleTextWrapper from 'shared/components/wrappers/TitleText';

import useMarkNotificationsAsRead from 'shared/hooks/useMarkNotificationsAsRead';

import AvSyncUploadWrapper from './AvSyncUpload';
import MessagingWrapper from './Messaging';

import useMessagesData from '../../hooks/useMessagesData';

export default function MessagesInfoWrapper() {
  // mark notifications as read once on everytime going to page OR reloading
  useMarkNotificationsAsRead(['NEW_DOCTOR_MESSAGE']);

  const { isLoading, messagingStatus, customer, subscriptions } = useMessagesData();

  let content = <></>;

  if (isLoading) {
    content = (
      <div className='row'>
        <div className='col-12'>
          <Skeleton variant='rectangular' height={350} />
        </div>
      </div>
    );
  } else if (messagingStatus === 'AV_SYNC_REQUIRED') {
    content = (
      <div className='row'>
        <div className='col-12'>
          <AvSyncUploadWrapper />
        </div>
      </div>
    );
  } else if (messagingStatus === 'ACTIVE' || messagingStatus === 'PARTNER_DOWN') {
    content = (
      <div className='row'>
        <div className='col-12'>
          <MessagingWrapper />
        </div>
      </div>
    );
  } else if (
    customer.status === 'NEW' ||
    messagingStatus === 'INACTIVE' ||
    isOtcOnlyCustomer(subscriptions)
  ) {
    content = <EmptyState type='NEW_CONSULT' />;
  } else if (customer.status === 'PENDING') {
    content = <EmptyState type='PENDING' />;
  }

  return (
    <DashboardContainer title='Messages | Alloy' desc='' currentPage='messages'>
      <section className='dashboard-section'>
        <div className='container'>
          <TitleTextWrapper
            title='Messages'
            description={
              <>
                If you have questions about your symptoms, medical history, or prescription, please
                message your doctor here. Your doctor will respond within 24-48 hours during normal
                business hours. For non-medical questions (shipping, billing, refills, etc.) please
                email{' '}
                <a href='mailto:support@myalloy.com' className='anchor-link'>
                  support@myalloy.com
                </a>
                .
              </>
            }
          />

          {content}
        </div>
      </section>
    </DashboardContainer>
  );
}
