import './SelectableProductsGroup.scss';

import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';

import { RequestingDeepProductIds } from 'modules/shared/models/requesting-deep-products';

import Divider from 'modules/checkout-experience/ui/blocks/Divider';
import SelectableProduct from 'modules/shared/ui/blocks/SelectableProduct';

import { useAppSelector } from 'shared/store/reducers';

import TextSelectableProduct from './TextSelectableProduct';

interface Props {
  products: GroupedContentfulProduct[];
  requestedDeepProductIds: RequestingDeepProductIds;
  onSelect: (groupedProduct?: GroupedContentfulProduct) => void;
}

export default function SelectableProductsGroup({
  products,
  requestedDeepProductIds,
  onSelect,
}: Props) {
  const { showKnownPreferencesTesting } = useAppSelector((state) => state.abTesting);

  return (
    <div className='selectable-products-group-wrapper'>
      {!showKnownPreferencesTesting && (
        <>
          <TextSelectableProduct
            isSelected={requestedDeepProductIds.length === 0}
            onSelect={onSelect}
          />

          <Divider text='Or' />
        </>
      )}

      {products.map((gcp, index) => {
        const isSelected = requestedDeepProductIds.some((pid) =>
          gcp.alloyProduct.parent.every((pf) => pf.id === pid),
        );

        return (
          <SelectableProduct
            key={index}
            groupedProduct={gcp}
            isSelected={isSelected}
            onSelect={onSelect}
            multiSelect
            hideDetails
            showProgDetails
          />
        );
      })}
    </div>
  );
}
