import './MessagingHeader.scss';

import { Skeleton } from '@mui/material';

import usePrescribingDoctor from 'modules/shared/hooks/usePrescribingDoctor';

import fillerLogo from 'shared/assets/images/alloy-product-filler.png';

export default function MessagingHeaderBlock() {
  const { doctor, isLoading } = usePrescribingDoctor();

  if (isLoading) {
    return (
      <div className='messaging-header-block'>
        <Skeleton variant='text' width={200} />
      </div>
    );
  }

  return (
    <div className='messaging-header-block'>
      {doctor ? (
        <div className='doctor-info-block'>
          <img
            src={doctor.fields.photo?.fields.file.url}
            alt={doctor.fields.name}
            className='doctor-photo'
          />

          <div className='doctor-content'>
            <p className='content-title'>Prescriber:</p>
            <p className='content-subtitle'>{doctor.fields.name}</p>
            <p className='content-text'>{doctor.fields.degrees}</p>
          </div>
        </div>
      ) : (
        <div className='doctor-info-block'>
          <img src={fillerLogo} alt='alloy logo' className='doctor-photo' />

          <div className='doctor-content'>
            <p className='content-title'>Prescriber:</p>
            <p className='content-subtitle'>Alloy Doctor</p>
          </div>
        </div>
      )}

      <div className='actions-block'>
        <p className='actions-text'>
          For non-medical questions (billing, shipping, refills, etc.) please{' '}
          <a
            className='text-link'
            href={process.env.REACT_APP_MARKETING_URL + '/contact-us'}
            target='_blank'
            rel='noreferrer'
          >
            contact support
          </a>
        </p>
      </div>
    </div>
  );
}
