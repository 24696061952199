import { Dispatch } from '@reduxjs/toolkit';

import { setItem } from 'client/dist/localstorage';

import { sendExceptionToSentry } from 'modules/tracking/lib/sentry';

import { updateLocalPreCustomer } from 'modules/shared/store/local-pre-customer-slice';

import { signUp } from 'shared/lib/aws/aws-auth';

import { COGNITO_USER } from 'shared/store/actions/GlobalTypes';
import { RootState } from 'shared/store/reducers';

export const register = (email: string, password: string) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      const { localPreCustomer } = getState().experience;

      const newLocalPreCustomer = {
        ...localPreCustomer,
        email,
      };

      setItem('localPreCustomer', newLocalPreCustomer, true);

      dispatch(updateLocalPreCustomer(newLocalPreCustomer));

      // response is not needed since we just care about email + pass to continue
      await signUp(email, password);

      dispatch({
        type: COGNITO_USER,
        payload: {
          email: email,
          password: password,
        },
      });
    } catch (error) {
      sendExceptionToSentry(error as Error);
      throw error;
    }
  };
};
