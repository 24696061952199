import './ManageDrawer.scss';

import { Skeleton } from '@mui/material';
import { getUnixTime, isToday, startOfDay } from 'date-fns';
import { capitalize, first, upperCase } from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  billSubscriptionNow,
  setNextRecurrence,
  unbundleProductFromSubscription,
  useGetAllSubscriptionsForCustomer,
  useIsProcessing,
} from 'client/dist/generated/alloy';
import DomProductRegistry from 'client/dist/product/productRegistry';
import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';

import CancelContextProvider, {
  useCancelContext,
} from 'modules/dashboard/sub-modules/manage-subscription/context/cancel';
import { useSubscriptionContext } from 'modules/dashboard/sub-modules/manage-subscription/context/manage';

import { getDeepProductIdsFrom } from 'modules/shared/lib/product';
import { getFilteredBundlePairings } from 'modules/shared/lib/product/bundle-pairing';
import { getUnbundledDeepProductIdsFrom } from 'modules/shared/lib/subscriptions/product-filter';

import {
  ManageLevel,
  ManageType,
} from 'modules/dashboard/sub-modules/manage-subscription/models/manage-type';

import AlloyDrawer from 'shared/components/core/AlloyDrawer';
import { showSuccessNotification } from 'shared/components/core/Notification';

import { useQueryParams } from 'shared/context/url/query';

import { getMillisWithRandomTime } from 'shared/lib/date';

import CancelWrapper from '../wrappers/Cancel';
import EditProductWrapper from '../wrappers/EditProduct';
import RescheduleWrapper from '../wrappers/Reschedule';
import ShipNowWrapper from '../wrappers/ShipNow';

interface Props {
  selectedDeepProductIds: number[];
  selectedType: ManageType;
  manageLevel: ManageLevel;
  open: boolean;
  onClose: () => void;
}

export default function ManageDrawer({
  selectedDeepProductIds,
  selectedType,
  manageLevel,
  open,
  onClose,
}: Props) {
  const navigate = useNavigate();

  const { subscription } = useSubscriptionContext();
  const { getParam } = useQueryParams();

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingCancel, setIsLoadingCancel] = useState(false);
  const [manageType, setManageType] = useState<ManageType>(selectedType);

  const [managingProducts, setManagingProducts] = useState<GroupedContentfulProduct[][]>([]);
  const [parentProducts, setParentProducts] = useState<GroupedContentfulProduct[]>([]);

  const { mutate: mutateSubscriptions } = useGetAllSubscriptionsForCustomer();
  const { mutate: mutateIsProcessing } = useIsProcessing(subscription.stripeSubscriptionId);

  const mutate = async () => await Promise.all([mutateSubscriptions(), mutateIsProcessing()]);

  useEffect(() => {
    if (open && manageLevel === 'PRODUCT') {
      setManageType('EDIT');
    }
  }, [open, manageLevel]);

  useEffect(() => {
    setManageType(selectedType);
  }, [selectedType]);

  useEffect(() => {
    const getProducts = async () => {
      setIsLoading(true);

      const subProducts = await DomProductRegistry.get().getRecurringProductsForV2(
        subscription.products.map((pfr) => pfr.product),
      );

      const products = subProducts.map((gcpList) =>
        gcpList.filter((gcp) =>
          getDeepProductIdsFrom(gcp).every((dpId) => selectedDeepProductIds.includes(dpId)),
        ),
      );

      const parents = getFilteredBundlePairings(products, subProducts);

      setManagingProducts(products);

      setParentProducts(parents);

      setIsLoading(false);
    };

    getProducts();
  }, [JSON.stringify(selectedDeepProductIds)]);

  /**
   * since CancelContextProvider is not wrapping ManageDrawer (this component), the loading
   * is not able to be accessed unless it is called back, potentially resolution is to wrap
   * ManageDrawer with CancelContextProvider BUT there is additional work with making sure
   * the product is also fetched before wrapping, things to think about architecturally but for now
   * just allow loading to display as needed (not allow closing drawer mainly)
   *
   * reason for loading is just if cancel is in process, lets not allow to close drawer
   */
  const onUpdateLoadingCancel = (isLoading: boolean) => setIsLoadingCancel(isLoading);

  const onSetClose = async () => {
    const outcome = upperCase(getParam('outcome') || '');

    if (!!outcome && ['CANCEL', 'RETENTION'].includes(outcome)) {
      setIsLoading(true);

      await mutate();

      setIsLoading(false);
    }

    if (outcome === 'CANCEL') {
      showSuccessNotification('Your subscription has successfully been cancelled');
    } else if (outcome === 'RETENTION') {
      showSuccessNotification('Your subscription has successfully been paused');
    }

    onClose();
  };

  const onConfirmReschedule = async (selectedDate: Date, shippingMethodId?: number) => {
    try {
      setIsLoading(true);

      if (isToday(selectedDate)) {
        onConfirmShipNow(shippingMethodId);
      } else if (manageLevel === 'PRODUCT') {
        const startTimestamp = getUnixTime(selectedDate);

        // filter the pf ids off the sub that will be moved
        const deepProductIds = await getUnbundledDeepProductIdsFrom(
          subscription,
          managingProducts.flat().flatMap((p) => getDeepProductIdsFrom(p)),
        );
        await unbundleProductFromSubscription({
          stripeSubscriptionId: subscription.stripeSubscriptionId,
          productFrequencyIds: deepProductIds,
          type: 'RESCHEDULE',
          startTimestamp,
          shippingMethodId,
        });
      } else {
        // Getting a random time allows us to mix up the future shipments so that they don't all get
        // hit at the same exact date in the future potentially creating problems for curexa
        const millis = getMillisWithRandomTime(startOfDay(selectedDate));

        await setNextRecurrence(subscription.stripeSubscriptionId, millis, {
          shippingMethodId,
        });
      }

      await mutate();

      if (manageLevel === 'PRODUCT') {
        showSuccessNotification('Your product has successfully been rescheduled');
        navigate('/subscriptions', { replace: true });
      } else {
        showSuccessNotification('Your shipment has successfully been rescheduled');
        onClose();
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const onConfirmShipNow = async (shippingMethodId?: number, promotionCodeId?: string) => {
    try {
      setIsLoading(true);

      if (manageLevel === 'PRODUCT') {
        const deepProductIds = await getUnbundledDeepProductIdsFrom(
          subscription,
          managingProducts.flat().flatMap((p) => getDeepProductIdsFrom(p)),
        );

        await unbundleProductFromSubscription({
          stripeSubscriptionId: subscription.stripeSubscriptionId,
          productFrequencyIds: deepProductIds,
          type: 'SHIP_NOW',
          promotionCodeId,
          shippingMethodId,
        });
      } else {
        await billSubscriptionNow(subscription.stripeSubscriptionId, {
          shippingMethodId,
        });
      }

      await mutate();

      showSuccessNotification('Your next order has successfully been placed');

      navigate('/subscriptions', { replace: true });

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <AlloyDrawer
      title={manageType !== 'CANCEL' ? capitalize(manageType.replaceAll('_', ' ')) : ''}
      drawerClass='manage-drawer'
      open={open}
      onClose={onSetClose}
      disableClosing={isLoading || isLoadingCancel}
      onBack={
        manageType === 'CANCEL' &&
        !!getParam('reason') &&
        !getParam('outcome') &&
        !(isLoading || isLoadingCancel)
          ? () => navigate(-1)
          : undefined
      }
    >
      {isLoading ? (
        <div className='drawer-body'>
          <Skeleton variant='rectangular' height={200} />
        </div>
      ) : (
        <div className='drawer-body'>
          {manageType === 'EDIT' && (
            <EditProductWrapper products={managingProducts} parentProducts={parentProducts} />
          )}

          {manageType === 'RESCHEDULE' && (
            <RescheduleWrapper
              products={managingProducts}
              onConfirm={onConfirmReschedule}
              parentProducts={parentProducts}
            />
          )}

          {manageType === 'SHIP_NOW' && (
            <ShipNowWrapper
              products={managingProducts}
              manageLevel={manageLevel}
              onConfirm={onConfirmShipNow}
              parentProducts={parentProducts}
            />
          )}

          {manageType === 'CANCEL' && (
            <CancelContextProvider product={first(managingProducts.flat())!}>
              <CancelWrapper onUpdateLoading={onUpdateLoadingCancel} />
            </CancelContextProvider>
          )}
        </div>
      )}
    </AlloyDrawer>
  );
}
