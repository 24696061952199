import './Shipping.scss';

import { bindActionCreators } from '@reduxjs/toolkit';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  Customer,
  useGetAllSubscriptionsForCustomer,
  validateAddress,
} from 'client/dist/generated/alloy';
import isNameValid from 'common/dist/customer/isNameValid';

import useCart from 'modules/shared/hooks/useCart';

import { updateCustomerWithProfileUpdates } from 'modules/shared/lib/customer';
import { getSubscriptionsWithStatus } from 'modules/shared/lib/subscriptions/status';
import { isConsultCart } from 'modules/shared/sub-modules/checkout/lib/cart';
import { identifySentry } from 'modules/tracking/lib/sentry';

import { updateCustomer } from 'modules/shared/store/customer';
import { updateIsEditingShipping } from 'modules/shared/sub-modules/checkout/store/actions';

import ShipOptionBlock from 'modules/request-experience/ui/blocks/ShipOptionBlock';

import { showErrorNotification } from 'shared/components/core/Notification';

import { useAppSelector } from 'shared/store/reducers';

import AddressBlock from '../blocks/Address';
import InfoBlock from '../blocks/Info';
import ShippingMethodBlock from '../blocks/ShippingMethod';

export type ShippingAddress = Pick<
  Customer,
  | 'firstName'
  | 'lastName'
  | 'shippingAddressLineOne'
  | 'shippingAddressLineTwo'
  | 'city'
  | 'stateAbbr'
  | 'zip'
  | 'phoneNumber'
>;
interface Props {
  onContinueClick?: () => void;
}

export default function ShippingWrapper({ onContinueClick }: Props) {
  const location = useLocation();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [incompleteKeys, setIncompleteKeys] = useState<string[]>([]);

  const { cart } = useCart();
  const isConsult = isConsultCart(cart);

  const customer = useAppSelector((state) => state.alloy.customer!);
  const isEditingShipping = useAppSelector((state) => state.experience.isEditingShipping);

  const { data: subscriptions = [] } = useGetAllSubscriptionsForCustomer();

  const { activeSubs } = getSubscriptionsWithStatus(subscriptions);

  const INIT_SHIPPING: ShippingAddress = {
    firstName: customer.firstName ?? '',
    lastName: customer.lastName ?? '',
    shippingAddressLineOne: customer.shippingAddressLineOne ?? '',
    shippingAddressLineTwo: customer.shippingAddressLineTwo ?? '',
    city: customer.city ?? '',
    stateAbbr: customer.stateAbbr ?? '',
    zip: customer.zip ?? '',
    phoneNumber: customer.phoneNumber ?? '',
  };

  const [shippingForm, setShippingForm] = useState<ShippingAddress>(INIT_SHIPPING);

  const { shippingAddressLineTwo, firstName, lastName, ...restShipping } = shippingForm;
  const isRequestExperience = location.pathname.includes('request-experience');

  const dispatchUpdateEditingShipping = bindActionCreators(updateIsEditingShipping, dispatch);
  const dispatchUpdateCustomer = bindActionCreators(updateCustomer, dispatch);

  useEffect(() => {
    if (isShippingIncomplete()) {
      dispatchUpdateEditingShipping(true);
    }
  }, []);

  const onChangeShipping = (updatedForm: ShippingAddress) => {
    setShippingForm(updatedForm);
  };

  const isShippingIncomplete = () =>
    !isNameValid(firstName) ||
    !isNameValid(lastName) ||
    Object.values(restShipping).some((value) => value === '' || value.includes('_'));

  const getIncompleteKeys = () =>
    [
      ...Object.entries(restShipping)
        .filter(([_, value]) => value === '' || value.includes('_'))
        .map(([key, _]) => key),
      !isNameValid(firstName) ? 'firstName' : undefined,
      !isNameValid(lastName) ? 'lastName' : undefined,
    ].filter((key): key is string => key !== undefined);

  const onContinue = async () => {
    try {
      if (isShippingIncomplete()) {
        setIncompleteKeys(getIncompleteKeys());

        showErrorNotification('Please complete missing fields.');
        return;
      }

      setIncompleteKeys([]);

      const line1Length = shippingForm.shippingAddressLineOne?.length ?? 0;
      const line2Length = shippingForm.shippingAddressLineTwo
        ? shippingForm.shippingAddressLineTwo.length
        : 0;

      if (line1Length + line2Length >= 35) {
        showErrorNotification(
          `Address fields (Street Address and Apt#, Floor, etc.) can't have more than 35 characters combined.`,
        );
        return;
      }

      setIsLoading(true);

      const {
        shippingAddressLineTwo,
        phoneNumber,
        firstName,
        lastName,
        ...addressValidationPayload
      } = shippingForm;

      const validAddress = await validateAddress(addressValidationPayload);

      if (validAddress) {
        const savedCustomer = await updateCustomerWithProfileUpdates(shippingForm);

        dispatchUpdateCustomer(savedCustomer);

        dispatchUpdateEditingShipping(false);

        identifySentry(customer);
      } else {
        showErrorNotification('Invalid address');
      }

      setIsLoading(false);
      onContinueClick && onContinueClick();
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className='ce-shipping-wrapper'>
        <div className='ce-block-header'>
          <p className='header-title'>
            <span className='header-number'>1</span>
            Shipment information
          </p>

          {!isEditingShipping && (
            <button
              className='primary-link-button'
              onClick={() => dispatchUpdateEditingShipping(true)}
              type='button'
            >
              Edit
            </button>
          )}
        </div>

        {isEditingShipping ? (
          <AddressBlock
            addressForm={shippingForm}
            includePhone
            onChange={onChangeShipping}
            incompleteKeys={incompleteKeys}
          />
        ) : (
          <InfoBlock />
        )}

        {!isConsult &&
          shippingForm.stateAbbr &&
          !shippingForm.stateAbbr!.includes('_') &&
          (isRequestExperience && !!activeSubs.length ? (
            <ShipOptionBlock hideBorder />
          ) : (
            <ShippingMethodBlock stateAbbr={shippingForm.stateAbbr} hideBorder />
          ))}

        {/* CTA for any device larger than mobile */}

        {isEditingShipping && (
          <div className='ce-shipping-footer-block d-none d-sm-block'>
            <button className='primary-button' disabled={isLoading} onClick={onContinue}>
              {isEditingShipping ? 'Save and continue' : 'Continue to payment'}
            </button>
          </div>
        )}
      </div>

      {/* Sticky CTA for any mobile device */}
      <div className='ce-sticky-button-block'>
        <button className='primary-button' disabled={isLoading} onClick={onContinue}>
          {isEditingShipping ? 'Save and continue' : 'Continue to payment'}
        </button>
      </div>
    </>
  );
}
