import './EmptyPlan.scss';

import { useNavigate } from 'react-router-dom';

import emptyPlanBg from 'modules/dashboard/sub-modules/treatment-plan/assets/images/empty-plan.png';

interface Props {
  title?: string;
  buttonTitle?: string;
}

export default function EmptyStateBlock({
  title = 'Menopause relief',
  buttonTitle = 'Start your assessment',
}: Props) {
  const navigate = useNavigate();

  const onStart = () => navigate('/checkout-experience');

  return (
    <div className='empty-state-block'>
      <img src={emptyPlanBg} alt='alloy' className='empty-state-img' />

      <div className='empty-state-content'>
        <p className='content-title'>{title}</p>
        <p className='content-subtitle'>$49</p>

        <ul className='content-list'>
          <li className='list-item'>Take a quick assessment & select products</li>
          <li className='list-item'>
            A menopause-trained doctor reviews your history and answers your questions
          </li>
          <li className='list-item'>Get your prescription products delivered</li>
        </ul>

        <div className='text-center'>
          <button className='primary-button' onClick={onStart}>
            {buttonTitle}
          </button>
        </div>
      </div>
    </div>
  );
}
