import { useLocation } from 'react-router-dom';

import useCart from 'modules/shared/hooks/useCart';

import { retrieveCategoriesFromUrl } from 'modules/shared/lib/experience/experience';
import { isConsultCart } from 'modules/shared/sub-modules/checkout/lib/cart';

export default function useReviewData() {
  const location = useLocation();

  const { cart } = useCart();

  const categories = retrieveCategoriesFromUrl(location);

  const isConsult = isConsultCart(cart);

  const bundledCategories = [['sexual-health', 'vaginal-health']];
  const isBundled = categories.every((c) => bundledCategories.some((bc) => bc.includes(c)));
  const isSkin = categories.every((c) => [['skin-health']].some((bc) => bc.includes(c)));

  return {
    isBundled,
    isConsult,
    isSkin,
  };
}
