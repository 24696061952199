import { ID_FILE_ERROR_MESSAGE } from 'common/dist/file/size';

import IdentityBlock from 'modules/shared/sub-modules/verify-identity/ui/blocks/Identity';
import PhotoModal from 'modules/shared/sub-modules/verify-identity/ui/content/PhotoModal';

import CheckoutExperienceSection from 'modules/checkout-experience/ui/wrappers/CheckoutExperienceSection';

import Loader from 'shared/components/content/Loader';
import Form from 'shared/components/core/Form';
import { showErrorNotification } from 'shared/components/core/Notification';
import { UploadField } from 'shared/components/fields/UploadField';
import BottomBar from 'shared/components/wrappers/BottomBar';

import { isFileTooBig } from 'shared/lib/file';
import isNarrowScreen from 'shared/lib/narrow-screen';

import useVerifyIdentityState from '../../hooks/useVerifyIdentityState';

export default function VerifyIdentityFormWrapper() {
  const {
    isLoading,

    fileBlob,
    setFileBlob,

    showPhotoModal,
    setShowPhotoModal,

    onClose,
    onRemove,
    onSubmit,
  } = useVerifyIdentityState();

  if (isLoading) return <Loader />;

  return (
    <Form onSubmit={onSubmit}>
      <CheckoutExperienceSection>
        <h1 className='content-title'>Verify your identity</h1>
        <p className='content-text-bold'>Upload a Photo</p>

        <IdentityBlock file={fileBlob} onRemove={onRemove} />
      </CheckoutExperienceSection>

      <BottomBar>
        {fileBlob ? (
          <button className='primary-button' type='submit'>
            Next
          </button>
        ) : (
          <div className='buttons-list-wrapper'>
            <UploadField
              name='validId'
              onChange={async (blobs) => {
                const blob = blobs[blobs.length - 1];

                if (isFileTooBig(blob)) {
                  showErrorNotification(ID_FILE_ERROR_MESSAGE, true);
                  onRemove();
                } else {
                  setFileBlob(blob);
                }
              }}
            />

            {!isNarrowScreen() && (
              <button className='primary-button' onClick={() => setShowPhotoModal(true)}>
                Take photo
              </button>
            )}
          </div>
        )}
      </BottomBar>

      <PhotoModal open={showPhotoModal} setIsOpen={setShowPhotoModal} onNext={onClose} />
    </Form>
  );
}
