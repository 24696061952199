import { sendExceptionToSentry } from 'modules/tracking/lib/sentry';

import { LocalPreCustomer } from 'modules/shared/models/local-pre-customer';

import allergiesMedicationsClient from 'shared/lib/clients/allergies-medications-client';

/**
 * on login/verification, we want to update our customer with any potential allergies/medications
 * they may have filled out while being unauthenticated, this saves a headache of figuring out
 * later why the doc doesn't have specific info
 *
 * only issue is since unauth, they might fill out again duplicate data (but when we add a way to edit
 * via dash later, it'll be easy to dedupe)
 *
 * @param localPreCustomer LocalPreCustomer
 */
export const handleAllergiesAndMedications = async (localPreCustomer: LocalPreCustomer) => {
  try {
    // create any allergies and medications for the customer and then store locally on register
    const [allergies, medications] = [
      localPreCustomer.currentAllergies || [],
      localPreCustomer.currentMedications || [],
    ];

    const [currentAllergies, currentMedications] = await Promise.all([
      !!allergies.length
        ? allergiesMedicationsClient.createCustomerAllergies(
            allergies.map((allergy) => ({
              name: allergy.name || '',
              details: allergy.details,
              reaction: allergy.reaction,
            })),
          )
        : Promise.resolve([]),
      !!medications.length
        ? allergiesMedicationsClient.createCustomerMedications(
            medications.map((medication) => ({
              name: medication.name || '',
              dose: medication.dose,
            })),
          )
        : Promise.resolve([]),
    ]);

    return {
      currentAllergies,
      currentMedications,
    };
  } catch (error) {
    sendExceptionToSentry(error as Error);

    return {
      currentAllergies: [],
      currentMedications: [],
    };
  }
};
