import { Route, Routes, useLocation } from 'react-router-dom';

import { useRequestExperience } from 'modules/request-experience/context/request-experience';
import { useExperience } from 'modules/shared/context/experience';

import { requestStepsWithReview } from 'modules/request-experience/data/flows';

import { PrivateFlowRoute } from 'modules/routes/ui/content/HandleExperience';

import Loader from 'shared/components/content/Loader';

export default function RequestExperienceRoutesWrapper() {
  const location = useLocation();

  const { isLoading } = useRequestExperience();
  const { onBack, onNext } = useExperience();

  if (isLoading) return <Loader />;

  return (
    <Routes>
      {requestStepsWithReview.map((step, index) => (
        <Route
          key={index}
          path={step.path + (!!step.hasNested ? '/*' : '')}
          element={
            <PrivateFlowRoute
              component={step.component}
              basePath='/request-experience'
              onNext={onNext}
              onBack={onBack}
              location={location}
            />
          }
        />
      ))}
    </Routes>
  );
}
