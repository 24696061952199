import { Text, View } from '@react-pdf/renderer';

import { OrderVendor } from 'client/dist/generated/alloy';

import { styles } from '../../data/pdf-invoice-styles';
import { VENDOR_PDF_INVOICE_INFO_MAP } from '../../data/vendor-pdf-invoice-info-map';

interface Props {
  vendor?: OrderVendor;
}

export default function PdfInvoiceVendor({ vendor }: Props) {
  if (!vendor) {
    return null;
  }
  // The keys here correspond to `order.vendor` values, NOT to `pharmacy.pharmacy_name` values. It can be confusing,
  // because all current vendors ARE pharmacies, and consequently the component labels things "Pharmacy..." below.
  // But, rest assured, this comes from the order, and is the vendor.
  const vendorInfo = VENDOR_PDF_INVOICE_INFO_MAP.get(vendor);
  if (!vendorInfo) {
    return null;
  }
  return (
    <View style={styles.vendorInfoAndLineItemsSummaryLeft}>
      <View>
        {/* All current vendors are pharmacies */}
        <Text style={styles.fontEmphasized}>Pharmacy Address:</Text>
        <Text style={styles.fontNormalSpaced}>{vendorInfo.name}</Text>
        <Text style={styles.fontNormalSpaced}>{vendorInfo.street}</Text>
        <Text style={styles.fontNormalSpaced}>{vendorInfo.cityStateZip}</Text>
      </View>
      <View style={styles.vendorInfoSpacing}>
        <Text style={styles.fontEmphasized}>Pharmacy Number:</Text>
        <Text style={styles.fontNormalSpaced}>{vendorInfo.phoneNumber}</Text>
      </View>
      <View style={styles.vendorInfoSpacing}>
        <Text style={styles.fontEmphasized}>Tax ID:</Text>
        {/*
            Tax ID is the same for everyone. It's probably MDI (the provider or provider network). The customer might
            need this when submitting insurance claims.
          */}
        <Text style={styles.fontNormalSpaced}>852662697</Text>
      </View>
    </View>
  );
}
