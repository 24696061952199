import posthog from 'posthog-js';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { submissionByCategoriesExists } from 'client/dist/generated/alloy';
import { getItem } from 'client/dist/localstorage';
import ProductRegistry from 'client/dist/product/productRegistry';
import { ExperienceCategory } from 'common/dist/models/experience';

import useABTests from 'modules/ab-tests/hooks/useABTests';

import {
  ExperienceValidationKey,
  isStepValidated,
} from 'modules/checkout-experience/lib/validation';
import { retrieveProductIdsFromUrl } from 'modules/request-experience/lib/flow';
import { getCurrentStepIndex, getIntakeCategories } from 'modules/shared/lib/experience/experience';
import { localSubmissionExists } from 'modules/shared/lib/local-submission';
import { formatExperienceURL } from 'modules/shared/lib/url';
import { sendExceptionToSentry } from 'modules/tracking/lib/sentry';

import { ExperienceFlow } from 'modules/shared/models/experience';

import { REQUESTING_PRODUCTS } from 'shared/store/actions/GlobalTypes';
import { useAppSelector } from 'shared/store/reducers';

import useCart from './useCart';

import { getCheckoutFlowFrom, getRequestFlowFrom } from '../lib/experience/experience-flow';
import { updateLocalPreCustomer } from '../store/local-pre-customer-slice';

export default function useInitExperience() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { initABTests } = useABTests();

  const { createCart, clearCart } = useCart();

  const { isAuthenticated } = useAppSelector((state) => state.alloy);

  const productIds = retrieveProductIdsFromUrl(location);

  const url = location.pathname;

  const initCheckoutExperience = async (categories: ExperienceCategory[]) => {
    try {
      await initABTests();

      posthog.capture('checkoutExperienceStarted', {
        $set: {
          intake_categories: categories,
        },
      });

      await createCheckoutExperience(categories);
    } catch (error) {
      sendExceptionToSentry(error as Error);
    }
  };

  const initRequestExperience = async (categories: ExperienceCategory[]) => {
    try {
      await createRequestExperience(categories);
    } catch (error) {
      sendExceptionToSentry(error as Error);
    }
  };

  /**
   * -- create checkout experience --
   */
  const createCheckoutExperience = async (
    categories: ExperienceCategory[],
    shouldReplace: boolean = true,
  ) => {
    const localPreCustomer = getItem('localPreCustomer', true) || {};

    const requestingDeepProductIds = getItem('requestingDeepProductIds', true) || [];
    const clonedRequestingDeepProductIds = [...requestingDeepProductIds]; // Need to either get or define the local products.
    // This is mainly for the recommendation flow for a users previously selected products
    // (if they had selected before and come back)
    dispatch({ type: REQUESTING_PRODUCTS, payload: clonedRequestingDeepProductIds });

    const clonedLocalPreCustomer = JSON.parse(JSON.stringify(localPreCustomer));

    dispatch(updateLocalPreCustomer(clonedLocalPreCustomer));

    const selectedFlow = getCheckoutFlowFrom(categories);

    const splitUrl = url.split('/').filter((s) => s !== '');

    let submissionExists: boolean = false;

    if (isAuthenticated && !categories.every((c) => c === 'gut-health' || c === 'renewal')) {
      const intakeCategories = getIntakeCategories(categories);

      submissionExists = await submissionByCategoriesExists({
        categories: intakeCategories,
        timeAmount: 2,
        timeUnit: 'week',
      });
    } else {
      submissionExists = localSubmissionExists(categories, localPreCustomer);
    }

    let stepIndex =
      selectedFlow.steps.findIndex(
        (sf) => !isStepValidated(sf.validationKey, submissionExists, categories),
      ) || 0;

    /**
     * Here we take the end of the url (intake, register, etc) and we check if the user can
     * go to that page based on path
     */
    if (splitUrl.length >= 2 && splitUrl.includes('checkout-experience')) {
      if (!selectedFlow.steps.some((step) => splitUrl.includes(step.path))) {
        navigate('/404');
        return;
      }

      let nextNeededStepIndex =
        selectedFlow.steps.findIndex(
          (sf) => !isStepValidated(sf.validationKey, submissionExists, categories),
        ) ?? 0;

      stepIndex = selectedFlow.steps.findIndex((step) => splitUrl.includes(step.path)) || 0;

      /**
       * We are checking if the next needed step that requires data to be filled it out
       * is before the page the user tried going to. If it is, then we redirect them there!
       */
      if (nextNeededStepIndex < stepIndex) {
        stepIndex = nextNeededStepIndex;
      }
    }

    stepIndex = getPastAuthenticated(stepIndex, isAuthenticated, selectedFlow);

    // on initial load, if the customer is coming from a link that is only '/checkout-experience' then we want to
    // place them in the relief type selection page (since they will be defaulted to mht, we want to allow them the options)
    // to select more categories

    // this is more so an issue that flow id exists but categories does not and the flow id is meant for mht,
    // so to clean just if it is renewal then don't go into any validation steps otherwise do so if categories
    // is not in url

    // TODO: Clean this up a bit better later
    const searchParams = new URLSearchParams(window.location.search);
    const hasCategoriesInUrl = searchParams.has('categories[]');

    if (!hasCategoriesInUrl && categories.every((c) => c !== 'renewal')) {
      const reliefTypeIndex = selectedFlow.steps.findIndex(
        (step) => step.validationKey === ExperienceValidationKey.reliefType,
      );

      if (reliefTypeIndex && stepIndex > reliefTypeIndex) {
        stepIndex = reliefTypeIndex;
      }
    }

    const path = selectedFlow.steps[stepIndex].path;

    const checkoutExperienceUrl = formatExperienceURL(
      `/checkout-experience/${path}`,
      window.location,
      categories,
    );

    const products = await ProductRegistry.get().getDefaultProductsByIds(selectedFlow.productIds);

    await createCart(products, categories, submissionExists);

    navigate(checkoutExperienceUrl, { replace: shouldReplace });
  };

  /**
   *  -- switch checkout experience --
   *
   * To efficiently switch flows, such as from a relief type,
   * we need to minimize unnecessary transactions.
   * This enables quick rerouting, optimizing this particular step through intake.
   */
  const switchCheckoutExperience = async (categories: ExperienceCategory[]) => {
    const newFlow = getCheckoutFlowFrom(categories);
    const currentIndex = getCurrentStepIndex(location.pathname, newFlow);

    let stepIndex = Math.min(currentIndex + 1, newFlow.steps.length - 1);

    const selectedFlow = getCheckoutFlowFrom(categories);

    stepIndex = getPastAuthenticated(stepIndex, isAuthenticated, selectedFlow);

    const path = selectedFlow.steps[stepIndex].path;

    const checkoutExperienceUrl = formatExperienceURL(
      `/checkout-experience/${path}`,
      window.location,
      categories,
    );

    const intakeIndex = selectedFlow.steps.findIndex(
      (step) => step.validationKey === ExperienceValidationKey.intake,
    );

    /**
     * When switching flows in the checkout experience, we should only create a ‘cart’ with default products
     * when the customer is in the consult flow. This ensures the consult product is included when moving out of intake,
     * since intake determines cart validity and triggers the actual cart creation.
     *
     * If this condition is false we should initialize an empty cart instead,
     * allowing the correct products to be added after the customer completes intake.
     * Avoiding unnecessary cart updates reduces latency.
     *
     * TODO: consolidating flows (checkout, request, etc.) could potentially improve efficiency and simplify the process.
     */
    if (categories.includes('mht') || stepIndex > intakeIndex) {
      const products = await ProductRegistry.get().getDefaultProductsByIds(selectedFlow.productIds);

      await createCart(products, categories, false);
    } else {
      clearCart();
    }

    navigate(checkoutExperienceUrl);
  };

  /**
   * -- create request experience --
   */
  const createRequestExperience = async (categories: ExperienceCategory[]) => {
    const selectedFlow = getRequestFlowFrom(categories);

    const splitUrl = url.split('/').filter((s) => s !== '');

    // v1 have no check for submission exists just throw them in there to take assessment, maybe
    // we could just make a func to check if submission exists in 24 hr so refreshing doesn't take them back to intake
    // if they are on checkout page...
    let submissionExists: boolean = false;

    // const intakeCategories = getIntakeCategories(categories);

    // submissionExists = await submissionByCategoriesExists({
    //   categories: intakeCategories,
    //   timeAmount: 1,
    //   timeUnit: 'day',
    // });

    let stepIndex =
      selectedFlow.steps.findIndex(
        (sf) => !isStepValidated(sf.validationKey, submissionExists, categories),
      ) ?? 0;

    // /**
    //  * Here we take the end of the url (intake, register, etc) and we check if the user can
    //  * go to that page based on path
    //  */

    if (splitUrl.length >= 2 && splitUrl.includes('request-experience')) {
      if (!selectedFlow.steps.some((step) => splitUrl.includes(step.path))) {
        navigate('/404');
        return;
      }

      let nextNeededStepIndex =
        selectedFlow.steps.findIndex(
          (sf) => !isStepValidated(sf.validationKey, submissionExists, categories),
        ) ?? 0;

      stepIndex = selectedFlow.steps.findIndex((step) => splitUrl.includes(step.path)) ?? 0;

      /**
       * We are checking if the next needed step that requires data to be filled it out
       * is before the page the user tried going to. If it is, then we redirect them there!
       */
      if (nextNeededStepIndex < stepIndex) {
        stepIndex = nextNeededStepIndex;
      }
    }

    const path = selectedFlow.steps[stepIndex].path;
    const experienceUrl = formatExperienceURL(
      `/request-experience/${path}`,
      window.location,
      categories,
    );

    // search params used for validation of mht request experience
    const searchParams = new URLSearchParams(window.location.search);

    const isSwitchType = searchParams.has('type') && searchParams.get('type') === 'switch';

    // valid products used for any flow that has restricted products ie skin-health, etc
    const validatedProductIds =
      productIds.length !== 0 && productIds.every((pid) => selectedFlow.productIds.includes(pid));

    // validate products only for flows that require product ids which would be everything but
    // switch since switch has so many various products ids it would be a mess to maintain
    if (!isSwitchType && !validatedProductIds && selectedFlow.productIds.length !== 0) {
      navigate('/', { replace: true });
      return;
    }

    // for switch we do not care for product ids, instead we need to know what product the customer had and which one
    // they potentially want
    if (
      selectedFlow.productIds.length === 0 &&
      (!searchParams.has('currentPfIds[]') ||
        !searchParams.has('requestedPfIds[]') ||
        !searchParams.has('checkoutType'))
    ) {
      navigate('/', { replace: true });
      return;
    }

    // only create a cart for non switch flow since switch we create the cart before going in
    if (!isSwitchType) {
      const products = await ProductRegistry.get().getDefaultProductsByIds(productIds);
      // await dispatch(createCart(products, categories, submissionExists));
    }

    navigate(experienceUrl, { replace: true });
  };

  /**
   *
   * For placing the user in a certain step based on the provided flow, we need to check if they are
   * logged in, if they are then we need to skip those steps and place the user in the next available step [index],
   * otherwise just return the current step [index]
   *
   * @param index number
   * @param isAuthenticated boolean
   * @param selectedFlow FlowMode,
   * @returns number
   */
  const getPastAuthenticated = (
    stepIndex: number,
    isAuthenticated: boolean,
    selectedFlow: ExperienceFlow,
  ): number => {
    if (isAuthenticated) {
      /**
       * Not allow authenticated users to go to register or verification (unauth) pages
       */
      while (
        selectedFlow.steps[stepIndex].path === 'register' ||
        selectedFlow.steps[stepIndex].path === 'verification'
      ) {
        stepIndex = Math.min(stepIndex + 1, selectedFlow.steps.length - 1);
      }
    }

    return stepIndex;
  };

  return {
    initCheckoutExperience,
    createCheckoutExperience,
    switchCheckoutExperience,

    initRequestExperience,
    createRequestExperience,
  };
}
