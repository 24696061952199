import './MessagingFooter.scss';

import {
  faMicrophone,
  faPaperPlane,
  faPaperclip,
  faVideo,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chip } from '@mui/material';
import { isEmpty } from 'lodash';

import Form from 'shared/components/core/Form';
import { TextAreaField } from 'shared/components/fields/TextAreaField';

import useMessagingFooterState from '../../hooks/useMessagingFooterState';
import { trimFilename } from '../../lib/trim-file-name';
import RecordAudioModal from '../modals/RecordAudioModal';
import RecordVideoModal from '../modals/RecordVideoModal';

export default function MessagingFooterBlock() {
  const {
    messagingStatus,

    newMessage,
    setNewMessage,

    isVideoOpen,
    setIsVideoOpen,

    isAudioOpen,
    setIsAudioOpen,

    onSendMessage,
    onAttachFile,
    onRemoveFile,
    onAddFile,
  } = useMessagingFooterState();

  if (messagingStatus === 'PARTNER_DOWN') {
    return <></>;
  }

  if (messagingStatus === 'INACTIVE') {
    return (
      <div className='messaging-footer-block'>
        <p className='footer-text'>
          Sorry, you no longer have access to send messages, but you can still read your messaging
          history. Reactivate your Alloy subscription to regain access.
        </p>
      </div>
    );
  }

  return (
    <>
      <Form onSubmit={onSendMessage}>
        <div className='messaging-footer-block'>
          <div className='input-content-block'>
            <TextAreaField
              name='new-message-text'
              value={newMessage.text}
              placeholder='Type a message to your doctor...'
              onChange={(value) => setNewMessage((prev) => ({ ...prev, text: value }))}
              rows={2}
              onSend={onSendMessage}
              data-private='lipsum'
              data-sentry-mask
            />

            {!!newMessage.files.length && (
              <div className='new-message-files-list'>
                {newMessage.files.map((file) => (
                  <Chip
                    key={file.id}
                    label={trimFilename(file.name, 25)}
                    deleteIcon={<FontAwesomeIcon icon={faXmark} size='sm' />}
                    onDelete={() => onRemoveFile(file.id)}
                    data-private='lipsum'
                    data-sentry-mask
                  />
                ))}
              </div>
            )}
          </div>

          <div className='actions-block'>
            {isEmpty(newMessage.text.trim()) && !newMessage.files.length && (
              <button type='button' className='action-button' onClick={() => setIsVideoOpen(true)}>
                <FontAwesomeIcon icon={faVideo} size='sm' />
              </button>
            )}

            <label className='action-button'>
              <FontAwesomeIcon icon={faPaperclip} size='sm' />

              <input
                type='file'
                accept='.mp3,.mp4,.webm,.mov,.jpg,.jpeg,.png,.webp,.pdf,.gif'
                style={{ display: 'none' }}
                onChange={onAttachFile}
              />
            </label>

            {isEmpty(newMessage.text.trim()) && !newMessage.files.length && (
              <button type='button' className='action-button' onClick={() => setIsAudioOpen(true)}>
                <FontAwesomeIcon icon={faMicrophone} size='sm' />
              </button>
            )}

            {(!isEmpty(newMessage.text.trim()) || !!newMessage.files.length) && (
              <button type='submit' className='action-button send-button' onClick={onSendMessage}>
                <FontAwesomeIcon icon={faPaperPlane} size='sm' />
              </button>
            )}
          </div>
        </div>
      </Form>

      <RecordVideoModal
        isOpen={isVideoOpen}
        onClose={() => setIsVideoOpen(false)}
        onSave={onAddFile}
      />

      <RecordAudioModal
        isOpen={isAudioOpen}
        onClose={() => setIsAudioOpen(false)}
        onSave={onAddFile}
      />
    </>
  );
}
