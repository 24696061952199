import { bindActionCreators } from '@reduxjs/toolkit';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Drawer, Loader } from 'rsuite';

import { updateCustomerWithProfileUpdates } from 'modules/shared/lib/customer';
import { sendExceptionToSentry } from 'modules/tracking/lib/sentry';

import { ProfileUpdates } from 'modules/shared/models/profile-updates';

import { updateCustomer } from 'modules/shared/store/customer';

import closeIcon from 'shared/assets/svg/common/btn-close.svg';

import { EmailUs } from 'shared/components/content/Buttons';
import Form from 'shared/components/core/Form';
import {
  showErrorNotification,
  showSuccessNotification,
} from 'shared/components/core/Notification';
import { DateField } from 'shared/components/fields/DateField';
import { PhoneField } from 'shared/components/fields/PhoneField';
import { TextField } from 'shared/components/fields/TextField';

import { ValidDob, transformDateString, validDob } from 'shared/lib/date';

import { useAppSelector } from 'shared/store/reducers';

import PasswordDrawer from './PasswordDrawer';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function ProfileDrawer({ open, setOpen }: Props) {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState<boolean>(false);
  const [isPasswordOpen, setIsPasswordOpen] = useState<boolean>(false);

  const customer = useAppSelector((state) => state.alloy.customer!);

  const [profileForm, setProfileForm] = useState<ProfileUpdates>({
    firstName: customer.firstName,
    lastName: customer.lastName,
    phoneNumber: customer.phoneNumber,
    dateOfBirth: transformDateString(customer.dateOfBirth?.toISOString() ?? '', 'MM/DD/YYYY'),
  });

  const dispatchUpdateCustomer = bindActionCreators(updateCustomer, dispatch);

  const isFormIncomplete = Object.entries(profileForm)
    .filter(([key]) => key !== 'dateOfBirth')
    .some(([, field]) => !field?.trim() || field.includes('_'));

  const isDobIncomplete = isEmpty(profileForm.dateOfBirth?.trim()) && !!customer.dateOfBirth;

  const onSave = async () => {
    try {
      if (profileForm.dateOfBirth) {
        const response: ValidDob = validDob(profileForm.dateOfBirth);
        if (response.error) {
          showErrorNotification(response.error);
          throw new Error(response.error);
        }
      }

      setLoading(true);

      const savedCustomer = await updateCustomerWithProfileUpdates(profileForm);

      dispatchUpdateCustomer(savedCustomer);

      showSuccessNotification('Successfully updated your profile information');

      setLoading(false);
      setOpen(false);
    } catch (error) {
      sendExceptionToSentry(error as Error);
      setLoading(false);
    }
  };

  const onClose = () => {
    if (!loading) {
      setOpen(false);
    }
  };

  const onChangePassword = () => {
    setIsPasswordOpen(true);
  };

  return (
    <>
      <Drawer
        open={open}
        size='sm'
        className={classNames(
          'alloy-drawer profile-drawer',
          open ? 'drawer-open' : 'drawer-closed',
        )}
        onClose={onClose}
      >
        <Drawer.Body>
          <div className='drawer-header'>
            <p className='header-title'>Profile Information</p>

            <button onClick={onClose} type='button' className='header-btn-close'>
              <img src={closeIcon} alt='close icon' className='close-icon' />
            </button>
          </div>

          {loading ? (
            <Loader center size='lg' />
          ) : (
            <Form onSubmit={onSave}>
              <div className='drawer-body'>
                <div className='profile-form-wrapper'>
                  <TextField
                    name='firstName'
                    label='First name'
                    value={profileForm.firstName}
                    onChange={(value) => setProfileForm({ ...profileForm, firstName: value })}
                  />

                  <TextField
                    name='lastName'
                    label='Last name'
                    value={profileForm.lastName}
                    onChange={(value) => setProfileForm({ ...profileForm, lastName: value })}
                  />

                  <PhoneField
                    name='phone'
                    label='Phone number'
                    value={profileForm.phoneNumber ?? customer.phoneNumber ?? ''}
                    onChange={(value) => setProfileForm({ ...profileForm, phoneNumber: value })}
                  />

                  <DateField
                    name='dateOfBirth'
                    label='Birthday'
                    value={profileForm.dateOfBirth}
                    onChange={(value) => setProfileForm({ ...profileForm, dateOfBirth: value })}
                  />
                </div>

                <button className='primary-link-button' type='button' onClick={onChangePassword}>
                  Change Password
                </button>

                <div className='profile-help-block'>
                  <p className='help-title'>Need Help?</p>
                  <p className='help-text'>
                    To make changes to your email contact us.{' '}
                    <EmailUs btnClass='primary-link-button' />
                  </p>
                </div>
              </div>

              <div className='drawer-footer'>
                <button
                  type='submit'
                  className='btn-save'
                  disabled={isFormIncomplete || isDobIncomplete}
                >
                  Save
                </button>
              </div>
            </Form>
          )}
        </Drawer.Body>
      </Drawer>

      <PasswordDrawer open={isPasswordOpen} setOpen={setIsPasswordOpen} />
    </>
  );
}
