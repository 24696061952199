import './RecordVideoModal.scss';

import Webcam from 'react-webcam';

import AlloyModal from 'shared/components/core/AlloyModal';

import useRecordVideoModalState from '../../hooks/useRecordVideoModalState';

const videoConstraints = {
  width: 400,
  facingMode: 'user',
};

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSave: (blob: Blob, baseName: string) => void;
}

export default function RecordVideoModal({ isOpen, onClose, onSave }: Props) {
  const {
    isRecording,

    videoBlob,

    webcamRef,

    hasCameraAccess,
    setHasCameraAccess,

    onStartRecording,
    onStopRecording,

    onSubmit,
    onRemove,
    onCloseModal,
  } = useRecordVideoModalState({ isOpen, onClose, onSave });

  return (
    <AlloyModal isOpen={isOpen} onClose={onCloseModal} drawerClass='record-video-modal' size='sm'>
      <div className='modal-body'>
        {hasCameraAccess ? (
          <div className='video-modal-block'>
            {!!videoBlob ? (
              <>
                <h4 className='modal-title'>Video recorded</h4>

                <div className='modal-example-wrapper'>
                  <video controls data-sentry-mask>
                    <source src={URL.createObjectURL(videoBlob)} type='video/mp4' />
                    Your browser does not support videos
                  </video>
                </div>

                <div className='modal-btns-block'>
                  <button type='button' className='primary-button' onClick={onSubmit}>
                    Save
                  </button>

                  <button type='button' className='secondary-border-button' onClick={onRemove}>
                    Record a new video
                  </button>
                </div>
              </>
            ) : (
              <>
                <h4 className='modal-title'>{isRecording ? 'Recording...' : 'Record a video'}</h4>

                <div className='modal-webcam-wrapper'>
                  <Webcam
                    className='video-webcam'
                    audio
                    muted
                    width={400}
                    height={300}
                    ref={webcamRef}
                    videoConstraints={videoConstraints}
                    onUserMediaError={() => setHasCameraAccess(false)}
                    data-sentry-mask
                  />
                </div>

                <div className='modal-btns-block'>
                  {isRecording ? (
                    <button type='button' className='primary-button' onClick={onStopRecording}>
                      Finish recording
                    </button>
                  ) : (
                    <button type='button' className='primary-button' onClick={onStartRecording}>
                      Start recording
                    </button>
                  )}
                </div>
              </>
            )}
          </div>
        ) : (
          <div className='video-modal-block'>
            <h4 className='modal-title'>No camera access</h4>

            <p className='modal-text'>
              To record a video, please change your browser settings to allow camera access
            </p>
          </div>
        )}
      </div>
    </AlloyModal>
  );
}
