import { bindActionCreators } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { getLeadId } from 'client/dist/events/vendor';
import { getUtms } from 'client/dist/events/vendors/tinuiti';
import { sendSessionStart } from 'client/dist/generated/alloy';

import { RedirectRoutes } from 'modules/routes/data/redirects';
import { AUTHENTICATION_ROUTES, PRIVATE_ROUTES, PUBLIC_ROUTES } from 'modules/routes/data/routes';

import { formatURL } from 'modules/shared/lib/url';
import { sendExceptionToSentry } from 'modules/tracking/lib/sentry';

import { initializeApp } from 'modules/shared/store/app-init';

import { AuthRoute, PrivateRoute } from 'modules/routes/ui/content/Routes';

import PosthogShimWrapper from 'modules/tracking/ui/wrappers/PosthogShim';

import NotFound from 'pages/other/NotFound';

import Loader from 'shared/components/content/Loader';

import { useAppSelector } from 'shared/store/reducers';

export default function AppRouter() {
  const dispatch = useDispatch();

  const dispatchInitializeApp = bindActionCreators(initializeApp, dispatch);

  const { isAppLoaded } = useAppSelector((state) => state.alloy);

  useEffect(() => {
    dispatchInitializeApp();
  }, []);

  useEffect(() => {
    try {
      const { leadId, isNewId } = getLeadId('tinuiti', 'PREPURCHASE_ID');
      // we only want to send the SESSION_START event if a customer's session begins at dash
      // otherwise we send the SESSION_START event from marketing
      if (isNewId) {
        const utms = getUtms(window.location);
        const referrer = document.referrer;
        sendSessionStart({
          lead: {
            id: leadId,
            ...(utms.utm_campaign ? { utmCampaign: utms.utm_campaign } : {}),
            ...(utms.utm_source ? { utmSource: utms.utm_source } : {}),
            ...(utms.utm_medium ? { utmMedium: utms.utm_medium } : {}),
            ...(referrer ? { referrer } : {}),
          },
        }).catch((e) => {
          sendExceptionToSentry(e as Error);
        });
      }
    } catch (e) {
      sendExceptionToSentry(e as Error);
    }
  }, []);

  if (!isAppLoaded) return <Loader />;

  return (
    <BrowserRouter>
      <PosthogShimWrapper>
        <Routes>
          {PUBLIC_ROUTES.map((route, index) => (
            <Route key={index} path={route.path} element={<route.component />} />
          ))}

          {AUTHENTICATION_ROUTES.map((route, index) => (
            <Route key={index} path={route.path} element={<AuthRoute comp={route.component} />} />
          ))}

          {PRIVATE_ROUTES.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={<PrivateRoute path={route.path} comp={route.component} />}
            />
          ))}

          {/* Redirect routes */}
          {RedirectRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.oldPath}
              element={
                <Navigate to={formatURL(route.newPath, window.location, route.params)} replace />
              }
            />
          ))}

          {/* 404 route */}
          <Route path='*' element={<Navigate to='/404' replace />} />
          <Route path='/404' element={<NotFound />} />
        </Routes>
      </PosthogShimWrapper>
    </BrowserRouter>
  );
}
