import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { setItem } from 'client/dist/localstorage';

import { LocalPreCustomer } from '../models/local-pre-customer';

const INITIAL_LOCAL_PRE_CUSTOMER: LocalPreCustomer = {};

const localPreCustomerSlice = createSlice({
  name: 'localPreCustomer',
  initialState: INITIAL_LOCAL_PRE_CUSTOMER,
  reducers: {
    updateLocalPreCustomer: (state, action: PayloadAction<LocalPreCustomer>) => {
      const localPreCustomer = { ...state, ...action.payload };

      setItem('localPreCustomer', localPreCustomer, true);

      return localPreCustomer;
    },
  },
});

export const { updateLocalPreCustomer } = localPreCustomerSlice.actions;

export const localPreCustomerReducer = localPreCustomerSlice.reducer;
