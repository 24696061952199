import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  addToTreatmentPlan,
  getCustomer,
  submitTreatmentPlan,
  useGenerateSetupIntent,
  useGetAllSubscriptionsForCustomer,
  useGetPaymentMethods,
  useGetTreatmentPlan,
} from 'client/dist/generated/alloy';

import useCart from 'modules/shared/hooks/useCart';

import { getDeepProductsFromGroupedProducts } from 'modules/shared/lib/product';
import { trackFreshpaint } from 'modules/tracking/lib/freshpaint';

import useGroupedProducts from 'shared/hooks/product/useGroupedProducts';

export default function useCheckoutState() {
  const navigate = useNavigate();

  const { cart, updateCart } = useCart();

  const [isProcessingLoading, setIsProcessingLoading] = useState(false);

  const {
    data: treatmentPlan = {
      pendingCharges: {
        consultApproval: [],
        cancelRetentionApproval: [],
      },
    },
    isLoading: isLoadingTreatmentPlan,
  } = useGetTreatmentPlan();
  const { isLoading: isLoadingPaymentMethods } = useGetPaymentMethods();
  const { data: setupIntent, isLoading: isLoadingSetupIntent } = useGenerateSetupIntent();
  const { data: subscriptions = [] } = useGetAllSubscriptionsForCustomer();

  const { products: cartProducts, isLoading: isLoadingGrouped } = useGroupedProducts({
    deepProducts: getDeepProductsFromGroupedProducts(cart.products),
    cachedProducts: cart.products.length === 0 || cart.isPurchased ? [] : undefined,
  });

  const isLoading =
    isLoadingGrouped ||
    isProcessingLoading ||
    isLoadingTreatmentPlan ||
    isLoadingPaymentMethods ||
    isLoadingSetupIntent;

  const cartDeepProductIds = cart.products
    .flatMap((p) => p.alloyProduct.parent.concat(p.alloyProduct.child ?? []))
    .map((pf) => pf.id);

  const pendingCharges = treatmentPlan?.pendingCharges.consultApproval.length
    ? treatmentPlan?.pendingCharges.consultApproval
    : treatmentPlan?.pendingCharges.cancelRetentionApproval;

  // Confirm whether any products in the cart are in the pending charge
  // this just gives us extra fluff instead of checking if any pending charges do exist.
  // we don't want to blindly check if pc.length != 0 since a customer could actually come from
  // an add on product instead of the approve plan
  const isPendingChargeCheckout = pendingCharges
    .flatMap((pc) => pc.products)
    .some((pf) => cartDeepProductIds.includes(pf.id));
  const isAddOnTreatmentPlan = !isPendingChargeCheckout;

  useEffect(() => {
    if (cart.products.length === 0 || cart.isPurchased) {
      navigate('/treatment-plan', { replace: true });
    }
  }, []);

  const onPlaceOrder = async () => {
    try {
      setIsProcessingLoading(true);

      if (!isAddOnTreatmentPlan) {
        // same idea from the actual treatment plan page in terms of approving pending charges
        // only reason for submitting is because a customer might have previous products purchased and later did consult to which
        // they need to accept the consult products and we need to mark pending charges as complete!

        // only send along the pending charges we've selected products from, the BE will handle the rest in the submit endpoint
        const selectedPendingChargeIds = pendingCharges
          .filter((pc) => {
            const pendingChargeDeepProductIds = pc.products.map((p) => p.id);
            return pendingChargeDeepProductIds.some((pcdp) => cartDeepProductIds.includes(pcdp));
          })
          .map((pc) => pc.id);

        await submitTreatmentPlan({
          pendingChargeIds: selectedPendingChargeIds,
          productFrequencyIds: cartDeepProductIds,
          promotionCodeId: cart.promotionCode.id,
          shippingMethodId: cart.shipping?.id,
          shipNow: cart.shipNow,
        });
      } else {
        await addToTreatmentPlan({
          productFrequencyIds: cartDeepProductIds,
          promotionCodeId: cart.promotionCode.id,
          shippingMethodId: cart.shipping?.id,
          shipNow: cart.shipNow,
        });
      }

      trackFreshpaint('TP_CHECKOUT_CONFIRMATION', await getCustomer());

      updateCart({
        isPurchased: true,
      });

      setIsProcessingLoading(false);

      navigate('/treatment-plan');
    } catch (error) {
      setIsProcessingLoading(false);
    }
  };

  return {
    isLoading,

    isAddOnTreatmentPlan,

    cartProducts,
    subscriptions,

    setupIntent,

    onPlaceOrder,
  };
}
