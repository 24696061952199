import './AddressDrawer.scss';

import { bindActionCreators } from '@reduxjs/toolkit';
import classNames from 'classnames';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Drawer, Loader } from 'rsuite';

import { validateAddress } from 'client/dist/generated/alloy';

import { updateCustomerWithProfileUpdates } from 'modules/shared/lib/customer';
import { sendExceptionToSentry } from 'modules/tracking/lib/sentry';

import { ProfileUpdates } from 'modules/shared/models/profile-updates';

import { updateCustomer } from 'modules/shared/store/customer';

import closeIcon from 'shared/assets/svg/common/btn-close.svg';

import Form from 'shared/components/core/Form';
import {
  showErrorNotification,
  showSuccessNotification,
} from 'shared/components/core/Notification';
import { PhoneField } from 'shared/components/fields/PhoneField';
import { SelectField } from 'shared/components/fields/SelectField';
import { TextField } from 'shared/components/fields/TextField';
import { ZipCodeField } from 'shared/components/fields/ZipCodeField';

import states from 'shared/data/core/states.json';

import { useAppSelector } from 'shared/store/reducers';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function AddressDrawer({ open, setOpen }: Props) {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState<boolean>(false);

  const customer = useAppSelector((state) => state.alloy.customer!);

  const [addressForm, setAddressForm] = useState<ProfileUpdates>({
    shippingAddressLineOne: customer.shippingAddressLineOne ?? '',
    shippingAddressLineTwo: customer.shippingAddressLineTwo ?? '',
    city: customer.city ?? '',
    stateAbbr: customer.stateAbbr ?? '',
    zip: customer.zip ?? '',
    phoneNumber: customer.phoneNumber ?? '',
  });

  const dispatchUpdateCustomer = bindActionCreators(updateCustomer, dispatch);

  const onSave = async () => {
    try {
      setLoading(true);

      const line1Length = addressForm.shippingAddressLineOne
        ? addressForm.shippingAddressLineOne.length
        : 0;
      const line2Length = addressForm.shippingAddressLineTwo
        ? addressForm.shippingAddressLineTwo.length
        : 0;

      if (line1Length + line2Length >= 35) {
        showErrorNotification(
          `Addresses fields (Street Address and Apt#, Floor, etc.) can't have more than 35 characters combined.`,
        );

        setLoading(false);
        return;
      }

      const { shippingAddressLineTwo, phoneNumber, ...addressValidationPayload } = addressForm;

      const validAddress = await validateAddress(addressValidationPayload);

      if (validAddress) {
        const savedCustomer = await updateCustomerWithProfileUpdates(addressForm);

        dispatchUpdateCustomer(savedCustomer);

        showSuccessNotification('Successfully updated your shipping address');
      }

      setLoading(false);
      setOpen(false);
    } catch (error) {
      sendExceptionToSentry(error as Error);
      setLoading(false);
    }
  };

  const onClose = () => {
    if (!loading) {
      setOpen(false);
    }
  };

  return (
    <Drawer
      open={open}
      size='sm'
      className={classNames('alloy-drawer address-drawer', open ? 'drawer-open' : 'drawer-closed')}
      onClose={onClose}
    >
      <Drawer.Body>
        <div className='drawer-header'>
          <p className='header-title'>Shipping Address</p>

          <button onClick={onClose} type='button' className='header-btn-close'>
            <img src={closeIcon} alt='close icon' className='close-icon' />
          </button>
        </div>

        {loading ? (
          <Loader center size='lg' />
        ) : (
          <Form onSubmit={onSave}>
            <div className='drawer-body'>
              <div className='address-form-wrapper'>
                <TextField
                  name='line1'
                  label='Address Line 1'
                  value={addressForm.shippingAddressLineOne}
                  placeholder={customer.shippingAddressLineOne}
                  onChange={(value) =>
                    setAddressForm({ ...addressForm, shippingAddressLineOne: value })
                  }
                />

                <TextField
                  name='line2'
                  label='Address Line 2 (optional)'
                  value={addressForm.shippingAddressLineTwo}
                  placeholder={customer.shippingAddressLineTwo}
                  onChange={(value) =>
                    setAddressForm({ ...addressForm, shippingAddressLineTwo: value })
                  }
                />

                <TextField
                  name='city'
                  label='City'
                  value={addressForm.city}
                  placeholder={customer.city}
                  onChange={(value) => setAddressForm({ ...addressForm, city: value })}
                />

                <div className='form-grid'>
                  <SelectField
                    name='stateAbbr'
                    label='State'
                    value={addressForm.stateAbbr ? addressForm.stateAbbr.toUpperCase() : ''}
                    placeholder={customer.stateAbbr}
                    data={states.States}
                    onChange={(value) =>
                      setAddressForm({ ...addressForm, stateAbbr: value?.toUpperCase() ?? '' })
                    }
                  />

                  <ZipCodeField
                    name='zip'
                    label='Zip code'
                    value={addressForm.zip}
                    placeholder={customer.zip}
                    onChange={(value) => setAddressForm({ ...addressForm, zip: value })}
                  />
                </div>

                <PhoneField
                  name='phone'
                  label='Phone number'
                  value={addressForm.phoneNumber ?? customer.phoneNumber ?? ''}
                  placeholder='Phone number'
                  onChange={(phoneText) =>
                    setAddressForm({ ...addressForm, phoneNumber: phoneText })
                  }
                />
              </div>
            </div>

            <div className='drawer-footer'>
              <button
                className='btn-save'
                type='submit'
                disabled={
                  !addressForm.shippingAddressLineOne?.trim() ||
                  !addressForm.city?.trim() ||
                  !addressForm.stateAbbr?.trim() ||
                  !addressForm.zip?.trim() ||
                  !addressForm.phoneNumber?.trim() ||
                  Object.values(addressForm).some((field) => field?.includes('_'))
                }
              >
                Save
              </button>
            </div>
          </Form>
        )}
      </Drawer.Body>
    </Drawer>
  );
}
