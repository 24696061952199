import { isWithinInterval, subDays } from 'date-fns';
import _, { uniqBy } from 'lodash';
import { store } from 'shared/store';

import {
  getAllSubscriptionsForCustomer,
  getRecentSubmission,
  runSuggestions,
} from 'client/dist/generated/alloy';
import ProductRegistry from 'client/dist/product/productRegistry';
import { ExperienceCategory } from 'common/dist/models/experience';
import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';
import { ProductCategory } from 'common/dist/products/product';
import { DeepProduct } from 'common/dist/products/productFrequency';
import {
  M4_EYE_SERUM_PRODUCT_ID,
  M4_FACE_SERUM_PRODUCT_ID,
} from 'common/dist/products/productRegistry';
import areProductsEquivalent from 'common/dist/products/utils/areProductsEquivalent';

import { NewUpsell } from 'modules/shared/models/new-upsell';

import { RootState } from 'shared/store/reducers';

import { getBundledUpdatedCategory } from '../category';
import { getIntakeCategories } from '../experience/experience';
import { localSubmissionExists } from '../local-submission';
import { getSubscriptionsWithStatus } from '../subscriptions/status';

export type ProductCleanNameDosage = {
  cleanName: string;
  dosage?: string;
};

/**
 * TODO tests
 *
 * Get new upsell products grouped by category to show
 * depending on purchased products and not purchased products.
 *
 * @param purchasedProducts
 * @param notPurchasedProducts
 * @param upsellProducts
 * @returns an NewUpsell array, each one of them including the display name of the category,
 * the category union type string and the products for that category to show
 */
export const getNewUpsellContentfulProducts = async (
  purchasedProducts: DeepProduct[],
  notPurchasedProducts: DeepProduct[],
  upsellProducts: DeepProduct[],
): Promise<NewUpsell[]> => {
  const groupedAllUpsellProducts = await ProductRegistry.get().getNewUpsellContentfulProducts(
    purchasedProducts,
    notPurchasedProducts,
    upsellProducts,
  );

  const groupedByCategory = _.groupBy(groupedAllUpsellProducts, ({ alloyProduct }) =>
    _.uniq([
      ...alloyProduct.parent.map((p) => p.category),
      ...(alloyProduct.child && alloyProduct.child.length
        ? alloyProduct.child.map((c) => c.category)
        : []),
    ]).toString(),
  );

  const groupedCategoryContentfulProduct = Object.keys(groupedByCategory).map((key) => ({
    categories: [key] as ProductCategory[],
    products: groupedByCategory[key],
  }));

  // get bundled category, so sexual and vaginal health get in the same bundled category
  const groupedBundledCategory = getBundledUpdatedCategory(groupedCategoryContentfulProduct);

  return groupedBundledCategory;
};

/**
 * get the price for all products combined, this is mainly used to confirm that pill+prog are correctly combined in terms of price
 * and then returned.
 *
 * @param groupedProduct GroupedContentfulProduct
 * @returns number
 */
export const getPriceForCombinedProducts = (groupedProduct: GroupedContentfulProduct): number => {
  const { alloyProduct } = groupedProduct;
  const allProducts = [...alloyProduct.parent, ...(alloyProduct.child ?? [])];

  return allProducts.reduce((a, p) => a + p.priceInCents, 0);
};

/**
 * Helper function for checking if active subscriptions exist,
 * and if so removing them from retrieval.
 *
 * TODO: convert to cache use
 *
 * @returns DeepProduct[] containing deep product for all active subscriptions
 */
export const retrieveActiveSubProducts = async (): Promise<DeepProduct[]> => {
  const { isAuthenticated } = store.getState().alloy;

  if (isAuthenticated) {
    const subscriptions = await getAllSubscriptionsForCustomer();

    const { activeSubs, pausedSubs, paymentFailedSubs } = getSubscriptionsWithStatus(subscriptions);

    return [...activeSubs, ...pausedSubs, ...paymentFailedSubs].flatMap((sub) =>
      sub.products.map((p) => p.product),
    );
  }

  return [];
};

/**
 *
 * @param products DeepProduct[]
 * @param categories ExperienceCategory[]
 * @param submissionExists boolean
 * @returns GroupedContentfulProduct[]
 */
export const getGroupedQualifiedProducts = async (
  products: DeepProduct[],
  categories: ExperienceCategory[],
  submissionExists: boolean,
): Promise<GroupedContentfulProduct[][]> => {
  const { alloy, experience } = store.getState() as RootState;
  const { isAuthenticated } = alloy;
  const { localPreCustomer } = experience;

  const intakeCategories = getIntakeCategories(categories);

  let submissionId = localSubmissionExists(categories, localPreCustomer)
    ? localPreCustomer.alloySubmissionId!
    : '';

  if (isAuthenticated && !intakeCategories.includes('mht') && submissionExists) {
    submissionId = (await getRecentSubmission({ categories: intakeCategories })).id;
  }

  let qualifiedProducts: DeepProduct[] = [];
  let disqualifiedProducts: DeepProduct[] = [];
  let bundableProducts: DeepProduct[] = [];

  if (!intakeCategories.includes('mht') && !!submissionId.length) {
    const suggestions = await runSuggestions({ submissionId });

    qualifiedProducts = suggestions.qualified.map((q) => q.product);
    disqualifiedProducts = suggestions.disqualified.map((dq) => dq.product);

    // Identify bundable products
    bundableProducts = await Promise.all(
      products.filter((p) => ProductRegistry.get().canBeBundled(p.productId)),
    );
  }

  const activeSubProducts = await retrieveActiveSubProducts();

  const filteredProducts = products
    .filter(
      (product) =>
        !disqualifiedProducts.some((dp) => areProductsEquivalent([dp, product])) &&
        !bundableProducts.some((bp) => areProductsEquivalent([bp, product])),
    )
    .concat(qualifiedProducts)
    .filter((product) => !activeSubProducts.some((asp) => areProductsEquivalent([asp, product])));

  return await ProductRegistry.get().getRecurringProductsForV2(filteredProducts);
};

export const getReadablePrice = (priceInCents: number): number => {
  return priceInCents / 100;
};

/**
 * Retrieves all of the pf ids from a grouped contentful product and returns them flattened
 *
 * @param gcp GroupedContentfulProduct
 * @returns number[]
 */
export const getDeepProductIdsFrom = (gcp: GroupedContentfulProduct): number[] => {
  return [
    ...gcp.alloyProduct.parent.map((pf) => pf.id),
    ...(gcp.alloyProduct.child ?? []).map((pf) => pf.id),
  ];
};

/**
 * Given a list of GroupedContentfulProducts, it maps the list
 * and extract all the product frequencies from it,
 * returning them as an array.
 *
 * @param gcps GroupedContentfulProduct[]
 * @returns DeepProduct[]
 */
export const getDeepProductsFromGroupedProducts = (
  gcps: GroupedContentfulProduct[],
): DeepProduct[] => {
  return uniqBy(
    gcps.flatMap((gcp) => [...gcp.alloyProduct.parent, ...(gcp.alloyProduct.child ?? [])]),
    'id',
  );
};

/**
 * Given a specific product and a list of GroupedContentfulProducts,
 * it returns products from the list where the specific product
 * can be bundled with
 *
 * // TODO: it would be better to rely on our db than contentful,
 * we should move away from this func and over to getFilteredBundlePairings as that
 * will allow us to use more of the db and have true pairings of the bundle!
 * ticket: https://app.shortcut.com/myalloy/story/23925
 *
 * @param bundledProduct GroupedContentfulProduct
 * @param products GroupedContentfulProduct[][]
 * @returns GroupedContentfulProduct
 */
export const getProductsToBeBundledWith = (
  bundledProduct: GroupedContentfulProduct,
  products: GroupedContentfulProduct[][],
): GroupedContentfulProduct[] => {
  if (!bundledProduct.contentfulProduct.fields.bundledPrice) {
    return [];
  }

  const bundledProducts =
    products.find((gcpList) =>
      gcpList.some(
        (gcp) => gcp.contentfulProduct.sys.id === bundledProduct.contentfulProduct.sys.id,
      ),
    ) || [];

  return bundledProducts.filter(
    (gcp) => gcp.contentfulProduct.sys.id !== bundledProduct.contentfulProduct.sys.id,
  );
};

/**
 * given a product, check if the same "parent" product is present
 * in the grouped contentful product.
 * for example: if I'm in the middle of a switch from estradiol patch -> pill,
 * this method identifies if estradiol is found on both products
 *
 * todo: add tests
 *
 * @param gcp
 * @param requested
 */
export const isSameParentProduct = (
  gcp: GroupedContentfulProduct,
  requested: GroupedContentfulProduct | undefined,
): boolean => {
  return getDeepProductsFromGroupedProducts([gcp]).some((dp) =>
    [...(requested?.alloyProduct.parent ?? []), ...(requested?.alloyProduct.child ?? [])]
      .map((p) => p.productId)
      .includes(dp.productId),
  );
};

/**
 * Given a grouped contentful product (tretinoin for example),
 * we try to find the other matching bundle part in the subscription
 * products, if we find it, we return, otherwise just return undefined
 * (indicating that is not present)
 *
 * todo: add tests
 *
 * @param products
 * @param subProducts
 * @returns
 */
export const getBundledProductsFrom = async (
  subProducts: DeepProduct[],
  product: GroupedContentfulProduct,
): Promise<GroupedContentfulProduct[]> => {
  const [deepProduct] = getDeepProductsFromGroupedProducts([product]);

  const activeSubProducts = await ProductRegistry.get().getRecurringProductsForV2([
    deepProduct,
    ...subProducts,
  ]);

  const missingBundleProductsPresent = getProductsToBeBundledWith(product, activeSubProducts);

  return missingBundleProductsPresent;
};

export const filterProductsByCategories = (
  products: GroupedContentfulProduct[][],
  categories: ExperienceCategory[],
) =>
  products.map((gcpList) =>
    gcpList.filter((gcp) =>
      gcp.contentfulProduct.fields.categories.some((c) => categories.includes(c)),
    ),
  );

/**
 * Given a list of deep products and a grouped contentful product,
 * this functions returns true if the gcp parent is equal to any
 * of the products in the list.
 *
 * This is useful for cases where we need to verify if a gcp is selected
 * in a component that lists products.
 *
 * @param products
 * @param gcp
 * @returns boolean
 */
export const isGroupedProductInProductsList = (
  products: DeepProduct[],
  gcp: GroupedContentfulProduct,
): boolean => {
  return products.some((product) =>
    gcp.alloyProduct.parent.every((parent) => areProductsEquivalent([parent, product])),
  );
};

/**
 * for when we have a switchable grouped product (currently only m4 and tretinoin)
 * we need to show them separately in the /treatment-plan and then only tretinoin
 * will show the option for switch dosage ("request a change")
 *
 */
export const getSeparatedGroupedContentfulProducts = async (
  deepProducts: DeepProduct[],
): Promise<GroupedContentfulProduct[]> => {
  const switchableProducts = await ProductRegistry.get().getSwitchableProducts();

  const groupedSwitchableBundleProducts: DeepProduct[] = [];
  const individualSwitchableBundleProducts: DeepProduct[] = [];

  const productsList = await Promise.all(
    deepProducts.map(async (dp) => {
      const isSwitchable = switchableProducts.map((sp) => sp.id).includes(dp.id);
      const isBundled = await ProductRegistry.get().canBeBundled(dp.productId);

      return { dp, isSwitchable, isBundled };
    }),
  );

  productsList.forEach((plf) => {
    if (plf.isSwitchable && plf.isBundled && plf.dp.category !== 'mht') {
      individualSwitchableBundleProducts.push(plf.dp);
    } else {
      groupedSwitchableBundleProducts.push(plf.dp);
    }
  });

  return [
    ...(
      await ProductRegistry.get().getRecurringProductsForV2(groupedSwitchableBundleProducts)
    ).flat(),
    ...(
      await Promise.all(
        individualSwitchableBundleProducts.map((dp) =>
          ProductRegistry.get().getRecurringProductsForV2([dp]),
        ),
      )
    ).flat(2),
  ];
};

export const isOtcProduct = (gcp: GroupedContentfulProduct) => {
  const products = [...gcp.alloyProduct.parent, ...(gcp.alloyProduct.child || [])];

  return products.every((dp) => dp.type === 'OTC');
};

/**
 * MARK: for now this is only for skin new products (just based on getting out) but check can be removed later
 *
 * Allows for showing a tag on viewable products
 *
 * @param gcp GroupedContentfulProduct
 * @returns boolean
 */
export const isNewProduct = (gcp: GroupedContentfulProduct) => {
  const products = [...gcp.alloyProduct.parent, ...(gcp.alloyProduct.child || [])];

  return products.every(
    (dp) =>
      isWithinInterval(dp.createdAt, {
        start: subDays(new Date(), 90),
        end: new Date(),
      }) && [M4_FACE_SERUM_PRODUCT_ID, M4_EYE_SERUM_PRODUCT_ID].includes(dp.productId),
  );
};
